<template>
  <c-modal-action-header tag="ion-header" :show-close="userCloseModal">
    <template #start>
      <ion-button class="c-modal-header-back-button" color="transparent-text" @click="back">
        <ion-icon name="chevron-back-outline" />
      </ion-button>
    </template>
    {{ $t('components.add_category_modal.view_category_title') }}
  </c-modal-action-header>

  <c-modal-content>
    <c-content-loading :action="loadCatagory">
      <c-modal-select class="show-animation">
          <c-modal-select-option
            v-for="categoryItem in categories"
            :key="categoryItem.id"
            :selected="!!categoriesSelectedList[categoryItem.id]"
            @click="selectCategory(categoryItem)"
          >
            {{ categoryItem.name }}
          </c-modal-select-option>
        </c-modal-select>
    </c-content-loading>
  </c-modal-content>
</template>

<script lang="ts">
import { useStore } from '@/composables/useApp';
import { PointTypeCategoryItem, ThisClientPointTypeItem } from '@/repositories/Models/Point';
import { defineComponent, ref, DeepReadonly, PropType, computed, ComputedRef } from 'vue';
import { VisitTypeAndCategory } from '@/helpers/visit';
import { keyBy, pick } from 'lodash';
import { useModalNav } from './modal';
import SelectProvidedServicesView from './SelectProvidedServicesView.vue';
import { useMetrica } from '@/composables/useMetrica';
import { ChangeProvidedServicesAction } from '@/components/service/add-category-modal/modal';

export default defineComponent({
  inheritAttrs: false,

  props: {
    typeId: {
      type: [String, Number] as PropType<string|number>,
      required: true,
    },
    carId: {
      type: String,
      required: false,
    },
    setCategory: {
      type: Function as PropType<(cat: PointTypeCategoryItem, typeId: number) => void>,
      required: false,
    },
    selectedTypeAndCategoriesCRef: {
      type: Object as PropType<ComputedRef<VisitTypeAndCategory[]>>,
      default: () => computed(() => [] as VisitTypeAndCategory[]),
    },
    action: {
      type: String as PropType<ChangeProvidedServicesAction>,
      required: false,
    },
    userCloseModal: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const store = useStore();
    const nav = useModalNav();
    const { emitEvent } = useMetrica();

    const categoriesSelectedList = computed(() => keyBy(props.selectedTypeAndCategoriesCRef.value, 'catId'));
    const pointType = ref<DeepReadonly<ThisClientPointTypeItem|null>>(null);
    const categories = computed<DeepReadonly<PointTypeCategoryItem[]>>(() => {
      return pointType.value?.carCategories || [];
    });
    const categorySelected = ref<DeepReadonly<PointTypeCategoryItem>|null>(null);

    function selectCategory(category: DeepReadonly<PointTypeCategoryItem>) {
      emitEvent('visit/edit/category', pick(category, ['id', 'name']));

      categorySelected.value = category;
      props.setCategory?.call(null, category, +props.typeId);

      // NOTE: Если требуется выбрать только категорию, то далее нам идти не нужно
      if (props.action === ChangeProvidedServicesAction.SelectCategory) {
        return;
      }
      
      nav.pushView(SelectProvidedServicesView, {
        catId: category.id,
        typeId: props.typeId,
        carId: props.carId,
      });
    }

    async function loadCatagory() {
      pointType.value = await store.point.filterTypePoint(+props.typeId) || null;
    }

    return {
      selectCategory,
      loadCatagory,
      categorySelected,
      categories,
      categoriesSelectedList,
      back: () => nav.backView(),
    };
  }
});
</script>

<style lang="scss">
</style>