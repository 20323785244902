<script lang="ts" setup>
import { defineProps, toRef, withDefaults, computed } from 'vue';
import { useVisitImages } from '@/composables/visit/visitImages';
import VisitImageContainer from '@/components/visit/VisitImageContainer.vue';
import VisitImagesSlider from '@/components/visit/VisitImagesSlider.vue';
import { useStore } from '@/composables/useApp';

interface Props {
  visitId: string;
  isNewPreentry?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  isNewPreentry: false,
});

const store = useStore();
const { imagesItems: savedVisitImages } = useVisitImages({
  visitId: toRef(props, 'visitId'),
});

const viewImages = computed<string[]>(() => {
  // У предварительной записи во время создания нет изображения,
  // и по умолчанию берется URL с фотографией последнего визита.
  if (props.isNewPreentry) {
    const preentryPreviewImageUrl = store.visit.preentryVisitState.meta.visitPhotoUrl || null;
    if (!preentryPreviewImageUrl) {
      return [];
    }

    return [ preentryPreviewImageUrl ];
  }

  return savedVisitImages.value;
});

</script>

<template>
  <visit-image-container rounded>
    <visit-images-slider :images="viewImages" :show-no-image="true" />
  </visit-image-container>
</template>

<style lang="scss">
</style>