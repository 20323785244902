import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_big = _resolveComponent("app-header-big")!
  const _component_c_nav_cards_list_item = _resolveComponent("c-nav-cards-list-item")!
  const _component_c_nav_cards_list = _resolveComponent("c-nav-cards-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header_big, {
        title: _ctx.$t('views.reports.title')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_c_nav_cards_list, null, {
            default: _withCtx(() => [
              _createVNode(_component_c_nav_cards_list_item, { onClick: _ctx.reportCurrent }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.reports.item_report_current')), 1)
                ]),
                _: 1
              }, 8, ["onClick"]),
              _createVNode(_component_c_nav_cards_list_item, { onClick: _ctx.reportPrevious }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.$t('views.reports.item_report_previous')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_app_footer)
    ]),
    _: 1
  }))
}