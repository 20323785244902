import { BaseRepository, AsyncResponse} from './BaseRepository'
import { BaseSuccessResponse } from './Models/Base'
import {
  UserCollectionQuery, ManagerCollectionQuery, DoerCollectionQuery,
  CurrentUserResponse, CurrentUserQuery,
  UserCollectionResponse,
  ManagerCollectionResponse, DoerCollectionResponse,
} from './Models/User'

export class UserRepository extends BaseRepository {
  getCurrent(params: CurrentUserQuery): AsyncResponse<CurrentUserResponse> {
    return this.client.get('myself', { params });
  }

  logout(cancelTimeout?: number): AsyncResponse<BaseSuccessResponse> {
    return this.client.get('managers/logout', {
      timeout: cancelTimeout,
    });
  }

  getUsersCollection(params: UserCollectionQuery = {}): AsyncResponse<UserCollectionResponse> {
    return this.client.get('users', { params });
  }

  getManagerCollection(params: ManagerCollectionQuery = {}): AsyncResponse<ManagerCollectionResponse> {
    return this.client.get('managers', { params });
  }

  getDoerCollection(params: DoerCollectionQuery = {}): AsyncResponse<DoerCollectionResponse> {
    return this.client.get('doers', { params });
  }
}
