import { computed, ref } from 'vue';
import { throttle } from 'lodash';

/** @private */
const windowInnerSize = ref({
  width: window.innerWidth,
  height: window.innerHeight
});

/** @private */
const resizeHandler = throttle(() => {
  windowInnerSize.value = {
    width: window.innerWidth,
    height: window.innerHeight
  };
}, 200);

window.addEventListener('resize', resizeHandler);

export const SCREEN_SM = 576;  // phone
export const SCREEN_MD = 768;  // tablet
export const SCREEN_LG = 992;  // laptop
export const SCREEN_XL = 1200; // desktop

export const screenWidth = computed(() => windowInnerSize.value.width);
export const screenHeight = computed(() => windowInnerSize.value.height);

export const windowSize = computed(() => ({ ...windowInnerSize.value }));

/** Планшет и больше */
export const isTabletAndUp = computed(() => windowInnerSize.value.width >= SCREEN_MD);

/** Меньше планшета */
export const isTabletDown = computed(() => windowInnerSize.value.width < SCREEN_MD);

export function isTabletUpScreenWidth(): boolean {
  return window.innerWidth >= SCREEN_MD;
}

export function isTabletDownScreenWidth(): boolean {
  return window.innerWidth < SCREEN_MD;
}