/**
 * Данный объект специально сделан с минимальной привязкой к зависимостям (vue, ionic),
 * т.к. данное хранилище будет использоваться еще и в service worker. 
 */
import { AppDB } from '@/database/AppDB';
import { NotificationData } from '@/composables/notifications';

export interface BaseStoreContext {
  db: AppDB;
}

/**
 * NOTE: Не наследуется от BaseStore, чтобы не тащить лишние зависимости
 */
export class NotificationStoreSw {
  constructor(protected ctx: BaseStoreContext) {}

  /**
   * Добавит уведомление в иторию 
   * 
   * @param notification уведомление
   * @param forcedUpdate принудительно обновить, даже если оно присутствует
   * 
   * @returns идентификатор уведомления
   */
  async mergeNotification(notification: NotificationData, forcedUpdate = false) {
    const notificationFromDb = await this.ctx.db.share.notificationHistory.get(notification.id);

    if (notificationFromDb && false === forcedUpdate) {
      return notificationFromDb.id;
    }

    return await this.ctx.db.share.notificationHistory.put(notification, notification.id);
  }

  /**
   * Получить все уведомления из истории
   * @returns 
   */
  async getAll(): Promise<NotificationData[]> {
    return await this.ctx.db.share.notificationHistory
      .orderBy('createAt')
      .reverse() // Новые вверху
      .toArray()
    ;
  }

  get notificationHistoryTable() {
    return this.ctx.db.share.notificationHistory;
  }
}