import { renderSlot as _renderSlot, resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "core-spinner" }
const _hoisted_2 = { class: "core-spinner__spinner" }
const _hoisted_3 = {
  key: 0,
  class: "core-spinner__text"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_spinner = _resolveComponent("ion-spinner")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "spinner", {}, () => [
        _createVNode(_component_ion_spinner, { name: "lines" })
      ])
    ]),
    (_ctx.loadingText || _ctx.loadingText === undefined)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _renderSlot(_ctx.$slots, "text", {}, () => [
            _createTextVNode(_toDisplayString(_ctx.loadingText || _ctx.$t('components.spinner.loading')), 1)
          ])
        ]))
      : _createCommentVNode("", true),
    _renderSlot(_ctx.$slots, "default")
  ]))
}