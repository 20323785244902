<script lang="ts" setup>
import { onMounted, ref, computed, watch, defineProps, withDefaults, defineEmits } from 'vue';
// import '@fullcalendar/core/vdom' // for vite
import FullCalendar from '@fullcalendar/vue3';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import ruLocale from '@fullcalendar/core/locales/ru';
import { usePreentryVisits } from '@/composables/usePreentry';
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit';
import { parseToDate } from '@/helpers/datetime';
import { DateTime } from 'luxon';
import { kebabCase, values } from 'lodash';
import {
  type CalendarOptions,
  type Calendar,
  type EventInput
} from '@fullcalendar/core';
import { awaitLoading } from '@/ext/loading';

interface Props {
  month?: Date;
}

const props = withDefaults(defineProps<Props>(), {
  month: () => new Date(),
});

const emit = defineEmits<{
  (e: 'click-date', value: Date): void;
}>();

const {
  visitsPreentry,
  loadVisits: loadVisitsBeetween,
} = usePreentryVisits();

const calendarOptions = ref<CalendarOptions>({
  initialDate: props.month,
  locale: ruLocale,
  plugins: [ dayGridPlugin, interactionPlugin ],
  initialView: 'dayGridMonth',
  headerToolbar: false,
  titleFormat: {
    month: 'long',
    year: 'numeric',
    day: 'numeric',
    weekday: 'narrow'
  },
  dateClick(arg) {
    emit('click-date', arg.date);
  },
  dayMaxEventRows: 5,
});

const beetweenDate = computed(() => {
  const currentMount = DateTime.fromJSDate(props.month);

  return {
    from: currentMount.startOf('month').toJSDate(),
    to: currentMount.endOf('month').toJSDate(),
  };
});

const fullcalendarEl = ref<InstanceType<typeof FullCalendar>|null>(null);
function getCalendar(): Calendar {
  if (!fullcalendarEl.value) {
    throw new Error('Не установлена ref ссылка на компоненете');
  }

  return fullcalendarEl.value.getApi();
}

watch(() => props.month, () => {
  getCalendar().gotoDate(props.month);
  loadVisits();
});

/**
 * Загрузить список визитов зазаданный диапазон (месяц)
 */
async function loadVisits() {
  const { from, to } = beetweenDate.value;
  return loadVisitsBeetween(from, to);
}

/**
 * Грузить визиты с сервера и отображает их в календаре
 */
async function syncCalendarVisits() {
  const calendar = getCalendar();

  try {
    await awaitLoading(() => loadVisits());
  } catch {
    visitsPreentry.value = [];
  }

  calendar.removeAllEvents();

  /** Счетчик кол-ва записей по дням (NOTE: Пока отказались от такого варианта) */
  // const dateindexCounters: Record<string, number> = {};

  for (const visit of visitsPreentry.value) {
    // const dateindex = getPreentryDateIndex(visit.creationDate);
    // dateindexCounters[dateindex] = (dateindexCounters[dateindex] || 0) + 1;

    // // Отображаем только первые 4 события в день
    // if (dateindexCounters[dateindex] > 4) {
    //   continue;
    // }

    const eventCard = convertVisitToCalendarEvent(visit);

    // if (dateindexCounters[dateindex] === 4) {
    //   // У Последнего элемента добавляется [...]
    //   eventCard.title = truncate(eventCard.title, { length: 6, omission: '' }) + '...';
    // }

    calendar.addEvent(eventCard);
  }
}

function convertVisitToCalendarEvent(visit: CarVisitCollectionItem): EventInput {
  if (!visit.creationDate) { // NOTE: Маловероятно
    console.warn('У визита не задана дата создания', visit);
  }

  const visitDateObject = parseToDate(visit.creationDate || '1970-01-01');

  const classNames = [
    'calendar-visit-badge',
    `--status-${visit.status}`,
    `--chekup-${kebabCase(visit.checkup)}`
  ];

  if (DateTime.fromJSDate(visitDateObject).plus({ minutes: 15}).toJSDate() < new Date()) {
    classNames.push('--expired');
  }

  classNames.push(
    ...values(visit.labels).map(l => `--label-${l}`)
  );

  return {
    id: visit.id,
    title: visit.car?.number || visit.car?.ownerName || '???',
    display: 'block',
    start: visitDateObject,
    classNames,
  };
}

// function getPreentryDateIndex(datetimeRaw?: string): string {
//   const datetime = parseToDate(datetimeRaw || '1970-01-01');
//   return DateTime.fromJSDate(datetime).toISODate() || '1970-01-01';
// }

onMounted(() => {
  setTimeout(() => getCalendar().updateSize(), 150); // crutch

  syncCalendarVisits();
});

watch(beetweenDate, () => syncCalendarVisits(), { deep: true });

</script>

<template>
  <div class="c-visits-preentry-calendar-mounth">
    <full-calendar ref="fullcalendarEl" class="c-visits-preentry-calendar-mounth__calendar" :options="calendarOptions" />
  </div>
</template>

<style lang="scss">
.c-visits-preentry-calendar-mounth {
  height: calc(100% - 84px); // -74px == (button: height + margin-top + margin-bottom) - 10px toolbar margin
  margin: 10px;

  &__calendar {
    height: 100%;
  }

  //#region Переопределяем нативные стили календаря
  .fc-daygrid-day-events {
    // NOTE: Чтобы кликая по дням, события не останавливались на эвентах (визитах)
    pointer-events: none;
  }

  .fc-scrollgrid  {
    border: none;

    &, th, td {
      border-color: transparent;
    }
  }

  .fc-scrollgrid-section-header {
    color: var(--ion-toolbar-color, inherit);
    font-size: var(--core-font-size);
    font-weight: 400;

    .fc-scroller-harness, .fc-scroller {
      overflow: visible !important;
    }

    .fc-col-header {
      background: var(--ion-toolbar-background);
      margin: -10px -10px 10px -10px !important;
      width: calc(100% + 20px) !important;
    }

    .fc-col-header-cell {
      height: 46px;
      vertical-align: middle;
    }

    .fc-col-header-cell-cushion {
      color: inherit;
      font-weight: normal;
    }
  }

  .fc-daygrid-body {
    $col-radius: 6px;

    .fc-daygrid-day-frame {
      background-color: var(--core-toolbar-bg);
      
    }

    tr:first-child .fc-daygrid-day-frame {
      border-top-left-radius: $col-radius;
      border-top-right-radius: $col-radius;
    }

    tr:last-child .fc-daygrid-day-frame {
      border-bottom-left-radius: $col-radius;
      border-bottom-right-radius: $col-radius;
    }

    .fc-daygrid-day-top {
      flex-direction: row;
      justify-content: center;
      padding-top: 8px;
      padding-bottom: 8px;

      .fc-daygrid-day-number {
        font-size: inherit;
        color: rgba(var(--core-text-rgb), 0.5);
        min-width: 27px;
        text-align: center;
        border-radius: 1000px;
      }
    }

    .fc-day {
      &.fc-day-today {
        background-color: transparent;

        .fc-daygrid-day-number {
          background-color: var(--ion-color-primary);
          color: var(--ion-color-primary-contrast);
        }
      }
    }
  }

  .calendar-visit-badge {
    background-color: var(--ion-color-secondary);
    color: #ffffff; //var(--ion-color-secondary-contrast);
    border: none;
    font-size: 14px;

    &.--expired {
      opacity: 0.7;
    }

    &.--expired.--status-new {
      background-color: var(--ion-color-danger);
    }

    &:not(.--expired).--status-processing {
      background-color: var(--ion-color-primary);
      color: var(--ion-color-primary-contrast);
    }

    // &.--label-yandex {
    //   position: relative;

    //   &::before {
    //     content: 'Y';
    //     display: block;
    //     width: 10px;
    //     height: 10px;
    //     font-size: 9px;
    //     font-weight: 600;
    //     border-radius: 0 0 0 4px;
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     background-color: #ddc145;
    //     color: #040404;
    //   }
    // }

    .fc-event-main {
      color: inherit;
    }

    .fc-event-time {
      font-weight: normal;
      opacity: 0.5;
    }
    
    @media (max-width: 1024px) {
      padding: 4px 6px;
      text-align: center;

      .fc-event-time {
        display: none;
      }
    }

    @media (max-width: 560px) {
      font-size: 8px;
      margin-left: 0 !important;
      margin-right: 0 !important;
      padding: 3px 3px;
    }
  }

  .fc-daygrid-more-link {
    font-size: 10px;
    color: inherit;
    opacity: 0.4;
    text-transform: lowercase;
    padding: 4px 0;
    display: inline-block;
  }
  //#endregion
}
</style>