<script lang="ts" setup>
import { defineProps, computed } from 'vue'
import VisitCard from '@/components/visit/VisitCard.vue';
import { CarVisitItem, CarVisitStatusEnum } from '@/repositories/Models/CarVisit';
import VisitAddPhotoButton from '@/components/visit/VisitAddPhotoButton.vue';

const props = defineProps<{
  visit: CarVisitItem;
}>();

/** Добавлять фотографии можно только к открытым визитам */
const oppUploadPhotoVisit = computed(() => [
  CarVisitStatusEnum.New,
  CarVisitStatusEnum.Processed,
  CarVisitStatusEnum.Opened,
].includes(props.visit?.status || CarVisitStatusEnum.Archive));
</script>

<template>
  <visit-card :visit="visit">
    <template #header-buttons>
      <visit-add-photo-button
        v-if="oppUploadPhotoVisit"
        :visitId="visit.id"
        :metrica-event-name="`visit/card/photo/add`"
      />
    </template>
  </visit-card>
</template>

<style lang="scss">
</style>