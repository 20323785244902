import { BaseRepository } from './BaseRepository'
import qs from 'qs'

export class ReportRepository extends BaseRepository {
  /**
   * Ссылка на отчет, за текущий период
   * 
   * NOTE: Необходимо отображать в <embed type="application/pdf" ...
   * или использовать специальный PDFViewer
   * 
   * @param pointId идентификатор пункта автосервиса 
   * @param datetime 
   * @returns по ссылке возвращается PDF документ
   */
  shiftCurrent(pointId: number, datetime?: Date): string {
    const query = qs.stringify({
      point: pointId,
      time: datetime?.getTime() //datetime?.toISOString(), // YYYY-MM-DDTHH:mm:ssZZ
    });

    return `${this.client.defaults.baseURL}/reports/export/detailed/shift-current?${query}`;
  }

  /**
   * Ссылка на отчет, за прошлый период
   * 
   * NOTE: Необходимо отображать в <embed type="application/pdf" ...
   * или использовать специальный PDFViewer
   * 
   * @param pointId идентификатор пункта автосервиса 
   * @param datetime 
   * @returns по ссылке возвращается PDF документ
   */
   shiftPrevious(pointId: number, datetime?: Date): string {
    const query = qs.stringify({
      point: pointId,
      time: datetime?.toISOString(), // YYYY-MM-DDTHH:mm:ssZZ
    });

    return `${this.client.defaults.baseURL}/reports/export/detailed/shift-previous?${query}`;
  }
}