<template>
  <div class="c-input-pos">
    <div class="c-input-pos__input-wrap">
      <input
        ref="input"
        readonly
        class="c-input-pos__input"
        type="text"
        v-model.number="sum"
        name="pos_sum"
      />

      <button class="c-input-pos__backspace-btn" @click="backspaceAct">
        <ion-icon name="backspace-outline" />
      </button>
    </div>

    <div ref="keyboardContainer" class="c-input-pos__keyboard-container" />
  </div>
</template>

<script lang="ts">
import { useVirtualKeyboard } from '@/composables/useVirtualKeyboard'
import SimpleKeyboard from 'simple-keyboard';
import { defineComponent, PropType, ref, onMounted, watch } from 'vue'

export default defineComponent({
  emits: ['update:modelValue'],

  props: {
    modelValue: {
      type: [String, Number] as PropType<string|number>,
      default: 0,
    }
  },
  setup(props, { emit }) {
    const sum = ref(props.modelValue);

    watch(sum, inputValue => emit('update:modelValue', inputValue));
    watch(() => props.modelValue, value => {
      keyboard.setCurrentInputValue(String(value));
    });

    //#region Keyboard
    const keyboardContainer = ref(null);
    const input = ref<HTMLInputElement|null>(null);
    const keyboard = useVirtualKeyboard(keyboardContainer, {
      onInit: onInitKeyboard,
      disableCaretPositioning: true,
      onKeyPress,
      layout: {
        default: [
          "1 2 3 {add100}",
          "4 5 6 {add500}",
          "7 8 9 {add1000}",
          "{point} 0 {clear} {add5000}"
        ],
      },
      display: {
        '{add100}': '100',
        '{add500}': '500',
        '{add1000}': '1000',
        '{add5000}': '5000',
        '{clear}': 'X',
        '{point}': '.'
      },
      buttonTheme: [
        {
          class: 'hg-button-secondary',
          buttons: '{add100} {add500} {add1000} {add5000} {clear}',
        }
      ],
    });

    function backspaceAct() {
      keyboard.instance?.handleButtonClicked(
        (String(sum.value).length < 2) ? '{clear}' : '{bksp}'
      );
    }

    function onInitKeyboard(instance?: SimpleKeyboard|null) {
      if (input.value && instance) {
        keyboard.setInput(input.value);
        instance.setInput(String(sum.value));
      }
    }

    function onKeyPress(button: string) {
      let addValue = 0;

      switch (button) {
        case '{clear}':
          keyboard.setCurrentInputValue('0');
          return; 
        case '{point}':
          keyboard.setCurrentInputValue(String(sum.value) + '.');
          return;
        

        case '{add100}': addValue = 100; break;
        case '{add500}': addValue = 500; break;
        case '{add1000}': addValue = 1000; break;
        case '{add5000}': addValue = 5000; break;
      }

      if (addValue) {
        const newValue = (+sum.value || 0) + addValue;
        keyboard.setCurrentInputValue(String(newValue));
      }
    }

    onMounted(() => {
      onInitKeyboard(keyboard.instance);
    });
    //#endregion Keyboard

    return {
      sum,
      backspaceAct,
      input,
      keyboardContainer,
    };
  }
});
</script>

<style lang="scss">
@import "@/scss/abstract";

.c-input-pos {
  @include core-biginput-component;

  &__input {
    text-align: left;
    margin-bottom: var(--ion-padding);
  }

  .hg-button {
    max-width: 25%;
    align-items: center;
    display: flex;
    justify-content: center;
    flex: 1 1 0%;
  }
}
</style>