<template>
  <div class="l-popover-layout">
    <div class="l-popover-layout__title" v-if="title">
      {{ title }}
    </div>

    <div class="l-popover-layout__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  props: {
    title: {
      type: String,
      required: false,
    }
  }
});
</script>

<style lang="scss">
@import '@/scss/abstract';

.l-popover-layout {
  padding: var(--ion-padding);
  padding-bottom: 0;

  &__title {
    font-size: var(--core-font-size-heading-large, 20px);
    padding-bottom: var(--ion-padding);
    border-bottom: $border-divider;
    padding-top: 5px;
    margin-bottom: var(--ion-margin);
  }

  // &__content {}
}
</style>