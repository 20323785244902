import { AppDB } from '@/database/AppDB';
import { ClientInstance, Repositories } from '@/repositories';
import { PointStore } from './PointStore';
import { BoxStore } from './BoxStore';
import { UserStore } from './UserStore';
import { ServiceStore } from './ServiceStore';
import { SuspectStore } from './SuspectStore';
import { DiscountStore } from './DiscountStore';
import { CarStore } from './CarStore';
import { VisitStore } from './VisitStore';
import { CarGroupStore } from './CarGroupStore';
import { PricePolicyStore } from './PricePolicy';
import { ConfigStore } from './ConfigStore';
import { NotificationStoreSw } from './NotificationStoreSw';

export function createStore(db: AppDB, repositories: Repositories, http: ClientInstance): StoreInstance {
  const instances: Partial<StoreInstance> = {
    recreateInstances,
  };

  /**
   * Пресоздаст все экземпляры хранилищ.
   * 
   * NOTE: Это необходимо после смены базового URL,
   * т.к. меняется БД, и весь сохраненный кэш и данные
   * @returns 
   */
  function recreateInstances(db: AppDB): StoreInstance {
    const ctx = { db, repositories, http };

    const config = new ConfigStore(ctx);
    const user = new UserStore(ctx);
    const car = new CarStore(ctx);
    const point = new PointStore({ ...ctx, userStore: user });
    const suspect = new SuspectStore({ ...ctx, pointStore: point });
    const discount = new DiscountStore(ctx);
    const box = new BoxStore(ctx);
    const pricePolicy = new PricePolicyStore(ctx);
    const group = new CarGroupStore(ctx);
    const notification = new NotificationStoreSw({ db }); // Не наследует BaseStore!
    const service = new ServiceStore({
      ...ctx,
      pricePolicyStore: pricePolicy,
      discountStore: discount,
      carStore: car,
      pointStore: point,
    });

    Object.assign(instances, {
      config,
      box,
      service,
      group,
      user,
      car,
      suspect,
      discount,
      point,
      pricePolicy,
      notification,
      visit: undefined,
    });

    const visit = new VisitStore({
      ...ctx,
      store: instances as StoreInstance,
    });

    instances.visit = visit;

    return instances as StoreInstance;
  }

  return recreateInstances(db);
}

export interface StoreInstance {
  recreateInstances: (db: AppDB) => StoreInstance;
  user: UserStore;
  car: CarStore;
  point: PointStore;
  suspect: SuspectStore;
  visit: VisitStore;
  box: BoxStore;
  service: ServiceStore;
  discount: DiscountStore;
  group: CarGroupStore;
  pricePolicy: PricePolicyStore;
  config: ConfigStore;
  notification: NotificationStoreSw;
}