/**
 * Способы оплаты
 */
 export enum PaymentTypeEnum {
  /** Карта */
  Card = 'card',
  /** Наличные */
  Cash = 'cash',
  /** Контракт */
  Contractor = 'contractor'
}

export interface PosInfoResponse {
  /** Текущий баланс кассы */
  balance: number; // decimal

  /** Смена открыта */
  sessionOpened: 1|0; // boolean

  /** @default true */
  success: boolean;
}

export interface PosPayVisitBody {
  /** Способ оплаты */
  type: PaymentTypeEnum;

  /** Сумма */
  sum?: number; // decimal

  /** Использовать бонусы для оплаты */
  useScores: number;

  /** Сдача */
  payback?: number;
}

export interface PosPayVisitInitResponse {
  /** К оплате */
  toPay: number;

  /** Будет оплачено бонусами */
  byScores: number;

  /** @default true */
  success: boolean;
}

export interface PosPayVisitConfirmBody {
  /** Сумма */
  sum: number; // decimal

  /** Способ оплаты */
  type: PaymentTypeEnum;

  /** Будет оплачено бонусами */
  useScores: number;

  /** Сдача */
  payback: number;
}
