<template>
  <ion-card class="visit-card-mini">
    <div class="visit-card-mini__image-container">
      <div class="visit-card-mini__image">
        <visit-labels class="visit-card-mini__badge-container visit-card-badge-container" :labels="badgeList" />
        
        <!-- TODO: По хорошему это лучше переписать -->
        <div class="visit-card-mini__image-scroll">
          <div
            class="visit-card-mini__image"
            v-for="image in images"
            :key="image"
            :style="{ backgroundImage: `url('${image}')` }"
          ></div>
        </div>
      </div>
    </div>

    <div class="visit-card-mini__right-container">
      <div class="visit-card-mini__header">
        <div class="visit-card-mini__title">
          <span v-if="hasCarNumber">{{ formatCarNumber }}</span>
          <span v-else class="text-muted">
            {{ $t('components.visit_card_mini.car_no_number') }}
          </span>
        </div>

        <div class="visit-card-mini__total" v-if="visit.price">
          <cc :price="totalPrice" />
        </div>
      </div>

      <div v-if="isViewProgress" class="visit-card-mini__footer-progress">
        <c-progress-bar :color="progressColor" :value="progressValue" :small="true" />
      </div>

      <div v-else class="visit-card-mini__footer">
        <template v-if="isPreentry">
          {{ $t('components.visit_card_mini.preentry_datetime', {
            date: preentryDateFormat,
            time: preentryTimeFormat
          }) }}
        </template>

        <template v-else>
          {{ $t('components.visit_card_mini.visit_finish', { datetime: finishTime }) }}
        </template>
      </div>
    </div>
  </ion-card>
</template>

<script lang="ts">
import { CarVisitItem, CarVisitStatusEnum } from '@/repositories/Models/CarVisit';
import { computed, defineComponent, PropType, toRef } from 'vue';
import { useVisit, useVisitBadges, useVisitTimings } from '@/composables/visit';
import VisitLabels from '@/components/visit/VisitLabels.vue';
import moment from 'moment';

export default defineComponent({
  components: {
    VisitLabels,
  },
  props: {
    visit: {
      type: Object as PropType<CarVisitItem>,
      required: true,
    },
  },
  setup(props) {
    const visitRef = toRef(props, 'visit');

    const {
      totalPrice,
      formatCarNumber,
      hasCarNumber,
      images,
      isPreentry,
      preentryDateFormat,
      preentryTimeFormat,
    } = useVisit(visitRef);

    const { badgeList } = useVisitBadges(visitRef);

    const finishTime = computed(() => {
      if (!props.visit.finishedDate) return '—';
      return moment(props.visit.finishedDate).format('H:mm');
    });

    const isViewProgress = computed(() => {
      return props.visit.status === CarVisitStatusEnum.Processed
        || (props.visit.status === CarVisitStatusEnum.Finished && props.visit.isPayed !== true);
    });

    const {
      progressValue,
      progressColor,
    } = useVisitTimings(visitRef);

    return {
      totalPrice,
      formatCarNumber,
      hasCarNumber,
      images,
      finishTime,
      badgeList,
      isViewProgress,
      progressValue,
      progressColor,
      isPreentry,
      preentryDateFormat,
      preentryTimeFormat,
    };
  },
});
</script>

<style lang="scss">
.visit-card-mini {
  display: flex;

  &__image-container {
    position: relative;
    max-width: 33%;
    min-width: 33%;
    flex: 1 1 0%;
    background-color: rgba(#000000, 0.1);
  }

  &__image {
    min-height: 110px;
    height: 100%;
    // background-size: cover;
    // background-repeat: no-repeat;
    // background-position: center center;
    position: relative;
    min-width: 100%;
    max-width: 100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  &__right-container {
    flex: 1 1 0%;
    padding: var(--core-spacer-small, 10px) var(--ion-padding, 20px);
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: space-between;
    min-height: 100%;
  }

  &__image-scroll{
    overflow-x: auto;
    display: flex;
    scroll-snap-type: x mandatory;
    -ms-overflow-style: none;
    scrollbar-width: none;
    scroll-behavior: smooth;
    position: relative;
    z-index: 1;

    &::-webkit-scrollbar{
      display: none;
    }
  }

  &__badge-container {
    z-index: 2;
    pointer-events: none; // Чтобы не мешало при работе со слайдером
  }

  &__header {
    color: var(--core-text);
  }

  &__title {
    font-size: var(--core-font-size-heading);
    font-weight: 500;
  }

  &__total {
    font-size: var(--core-font-size);
  }

  &__footer,
  &__footer-progress {
    padding-top: var(--core-spacer-small);
    margin-top: var(--core-spacer-small);
  }

  &__footer-progress {
    padding-bottom: 4px;
  }

  &__footer {
    color: rgba(var(--core-text-rgb), 0.4);
    border-top: 1px solid var(--ion-item-border-color);
    flex-shrink: 1;
  }
}
</style>