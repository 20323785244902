<template>
  <div :class="{
    'c-modal-float': true,
  }">
    <div class="c-modal-float__backgroup" @click="$emit('click-backgroup', $event)" />

    <div class="c-modal-float__modal-container" :style="modalContainerStyle">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  emits: ['click-backgroup'],

  props: {
    containerStyles: {
      type: Object as PropType<Record<string, any>>,
      default: () => ({}),
    }
  },

  setup(props) {
    const modalContainerStyle = computed(() => {
      return {
        ...props.containerStyles,
      };
    });

    return {
      modalContainerStyle,
    };
  }
});
</script>

<style lang="scss">
.c-modal-float {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 20001;
  pointer-events: none;
  touch-action: none;

  > * { pointer-events: all; touch-action: auto; }

  &__modal-container {
    --ion-background-color: var(--core-toolbar-bg);
    --background: var(--core-toolbar-bg);
    overflow: hidden;

    background: var(--core-toolbar-bg);
    border-radius: var(--core-card-radius);
    color: var(--core-light);
    position: absolute;
    z-index: 2;
    box-shadow: 0 4px 24px rgba(0,0,0,0.25);
  }

  &__backgroup {
    background: var(--core-backgroup-color);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 0.5s ease;
  }
}
</style>