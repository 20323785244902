<template>
  <ion-page>
    <app-header :title="viewTitle" />

    <ion-content>
      <pos-slim-layout class="ion-padding" :class="{ 'block-deactivated': initLoading }">
        <c-card-list class="ion-no-margin">
          <ion-item>
            {{ labelTotal }}

            <div class="color-default" slot="end">
              <ion-skeleton-text v-if="initLoading" animated :style="{
                width: '60px',
                height: '1em',
              }" />
              <template v-else>
                <cc :price="totalVisit" />
              </template>
            </div>
          </ion-item>

          <ion-item>
            {{ labelPaymentType }}

            <div class="color-default" slot="end">
              {{ paymentTypeTitle }}
            </div>
          </ion-item>

          <template v-if="showCalculator">
            <ion-item>
              {{ $t('views.visit_pay.label_cash_deposited') }}

              <ion-note :color="cashDepositedError ? 'danger' : 'light'" slot="end">
                <cc :price="cashDeposited" />
              </ion-note>
            </ion-item>

            <ion-item v-if="hasPoints">
              {{ $t('views.visit_pay.label_pay_points') }}

              <div class="color-default" slot="end">
                {{ pointsFormatted }}
              </div>
            </ion-item>

            <ion-item>
              {{ $t('views.visit_pay.label_odd_money') }}

              <div class="color-default" slot="end">
                <cc :price="changeFromCash" />
              </div>
            </ion-item>
          </template>
        </c-card-list>

        <input-pos
          class="mt-2"
          v-if="showCalculator"
          v-model="cashDeposited"
        />

        <ion-card v-else class="visit-pay-sum-card">
          <cc :price="sum" v-slot="{ priceFormatted, currencyConfig }" :options="{ currency: '' }">
            <div class="visit-pay-sum-card__price">
              {{ priceFormatted }}
            </div>

            <div class="visit-pay-sum-card__valute">
              {{ currencyConfig.name }}
            </div>
          </cc>
        </ion-card>

        <template #footer>
          <ion-button
            color="primary" expand="block" shape="round"
            class="mt-2" @click="confirmAction"
            :disabled="buttonConfirmDisabled"
          >
            {{ buttonConfirmText }}
          </ion-button>
        </template>
      </pos-slim-layout>
    </ion-content>
  </ion-page>
</template>

<script lang="ts" setup>
import { computed, onMounted, ref } from 'vue';
import { onBeforeRouteLeave, useRoute } from 'vue-router';
import { includes, toNumber } from 'lodash';
import { PAYMENT_TYPES_LIST, getPaymentTypeTitle } from '@/helpers/visit';
import { PaymentTypeEnum } from '@/repositories/Models/Pos';
import InputPos from '@/components/pos/InputPos.vue';
import { useToast } from '@/composables/toast';
import { useRepositories, useStore } from '@/composables/useApp';
import { useNavManager } from '@/composables/useNavManager';
import { CarVisitStatusEnum } from '@/repositories/Models/CarVisit';
import { useMetrica } from '@/composables/useMetrica';
import { useAlert } from '@/composables/alert';
import { useI18n } from 'vue-i18n';
import { repeatRequest, RepeatRequestOptions } from '@/helpers/requests';

const REPEAT_POS_OPTIONS: RepeatRequestOptions = {
  repeatCount: 6,  // до 6 раз
  timeout: 400,    // 400 мс между запросами
  maxTime: 30_000, // 10 сек макс время
};

const route = useRoute();
const repo = useRepositories();
const toast = useToast();
const navManager = useNavManager();
const store = useStore();
const { emitEvent } = useMetrica();
const { createAlertConfirm } = useAlert();
const { t } = useI18n();

/** если true, то перед уходом необходимо отменить оплату */
let hasAbilityCancel = false;

/** Осуществляется действие возврата? */
const isReturnAction = route.params.action === 'return';
const isStoreVisit = route.meta.visitType === 'market';

// TODO: В будущем добавится preentry
const eventPrefix = 'visit' + (isStoreVisit ? '/market' : '');

onBeforeRouteLeave(async () => {
  if (hasAbilityCancel) await cancelAction();
});

//#region Payment type and sum
const visitId = route.params.visitId as string;
const totalVisit = ref(toNumber(route.query.sum || 0));

const points = route.query.points ? +route.query.points : undefined;
const pointsFormatted = computed(() => points ? Math.min(points, totalVisit.value) : 0);
const hasPoints = computed(() => !!points);

const sum = ref<string|number>(0);
const paymentType = includes(PAYMENT_TYPES_LIST, route.params.paymentType)
  ? route.params.paymentType as PaymentTypeEnum
  : PaymentTypeEnum.Cash;
const paymentTypeTitle = getPaymentTypeTitle(paymentType, {
  [PaymentTypeEnum.Card]: t('views.visit_pay.payment_type_card')
});

const cashDeposited = ref<string|number>(0);
const cashDepositedError = computed(() => paymentType === PaymentTypeEnum.Cash && +cashDeposited.value < +sum.value);
const changeFromCash = computed(() => {
  let payback = cashDepositedError.value ? 0 : (+cashDeposited.value) - (+sum.value);

  // Здесь учитывать не нужно, т.к. при инициализации
  // оплаты сумма просчитывается на сервере.
  // if (points) {
  //   payback += points;
  // }

  return payback;
});
// const changeFromCashFormatted = computed(() => preparePrice(changeFromCash.value));
const showCalculator = false === isReturnAction && includes([
  PaymentTypeEnum.Cash,
  // В будущем возможно при оплате баллами
], paymentType);
//#endregion

const initLoading = ref(false);
/** Предварительная инициализация и загрузка */
async function initAndLoadAction() {
  initLoading.value = true;

  try {
    if (isReturnAction) { // Операция возврата
      await repeatRequest(() => repo.pos.payReturnInit(visitId), REPEAT_POS_OPTIONS);

      sum.value = totalVisit.value;
    } else { // Операция оплаты
      const { data } = await repeatRequest(() => {
        return repo.pos.payInit(visitId, {
          type: paymentType,
          useScores: points || 0,
        });
      }, REPEAT_POS_OPTIONS);

      sum.value = data.toPay;

      // if (isStoreVisit) { // fix: При оплате визита в магазин query sum не передается [уже передается]
      //   totalVisit.value = data.toPay;
      // }
    }

    hasAbilityCancel = true;
  } catch (e) {
    toast.error(e, 5000, {
      defaultHeader: t('views.visit_pay.header_error_init')
    });
  } finally {
    initLoading.value = false;
  }
}

/** Подтвердить выполнение операции */
const operationProcessing = ref(false);

/** Окно подтверждения оплаты и закрытия заказа */
const alertConfirmPay = createAlertConfirm({
  header: t('views.visit_pay.alert_confirm_pay_header'),
  message: t('views.visit_pay.alert_confirm_pay_message'),
  async confirm() {
    emitEvent(`${eventPrefix}/pay/confirm`, { paymentType });
    await confirmPayVisit();
  }
});

/** Окно подтверждения возврата средств */
const confirmAlertReturnPay = createAlertConfirm({
  header: t('views.visit_pay.alert_confirm_return_pay_header'),
  message: t('views.visit_pay.alert_confirm_return_pay_message'),
  async confirm() {
    emitEvent(`${eventPrefix}/pay/return/confirm`, { paymentType });
    await confirmReturnPay();
  }
});

function confirmAction() {
  if (isReturnAction) { // Подтвердить возврат
    confirmAlertReturnPay();
  } else { // Подтвердить оплату
    alertConfirmPay();
  }
}

function getNextPage() {
  if (isStoreVisit) {
    return { name: 'market' };
  }

  return (route.query.status === CarVisitStatusEnum.Finished)
    ? { name: 'visits' }
    : { name: 'visit-single', params: { visitId } };
}

/** Подтверждение оплаты и закрытие визита */
async function confirmPayVisit() {
  operationProcessing.value = true;

  try {
    if (cashDepositedError.value) {
      return toast.error(t('views.visit_pay.cash_deposited_error'), 4000, {
        defaultHeader: t('views.visit_pay.header_error_pos')
      });
    }

    const isCash = (paymentType === PaymentTypeEnum.Cash);

    // Если оплата происходит наличными, то подставляем сумму введеную с
    // клавиатуры, в остальных случаях берем сумму "к оплате" (toPay)
    const paySum = isCash ? +cashDeposited.value : +sum.value;
    const payback = isCash ? changeFromCash.value : 0;

    await repeatRequest(() => {
      return repo.pos.payConfirm(visitId, {
        sum: paySum,
        type: paymentType,
        useScores: points || 0,
        payback,
      });
    }, REPEAT_POS_OPTIONS);

    hasAbilityCancel = false;

    if (isStoreVisit) {
      toast.success(t('views.visit_pay.pay_visit_success_message'));
      store.visit.clearNewStoreVisitState();
    }
    
    navManager.navigate({
      // FIXME: back - почемуто работает некорректно
      // routerDirection: 'back',
      routerDirection: 'root',
      routerLink: getNextPage(),
    });
  } catch (e) {
    toast.error(e, 4000, {
      defaultHeader: t('views.visit_pay.header_error_pay_visit')
    });
  } finally {
    operationProcessing.value = false;
  }
}

/** Подтвердить возврат платежа */
async function confirmReturnPay() {
  operationProcessing.value = true;

  try {
    await repeatRequest(() => repo.pos.payReturnConfirm(visitId), REPEAT_POS_OPTIONS);

    hasAbilityCancel = false;

    navManager.navigate({
      routerDirection: 'root',
      // routerDirection: 'back',
      routerLink: {
        name: 'visit-single',
        params: { visitId }
      },
    });
  } catch(e) {
    toast.error(e, 4000, {
      defaultHeader: t('views.visit_pay.header_error_return_pay_visit')
    });
  } finally {
    operationProcessing.value = false;
  }
}

/** Отменить выполнение операции */
async function cancelAction() {
  try {
    if (isReturnAction) { // Отмена операции возврата
      await repeatRequest(() => repo.pos.payReturnCancel(visitId), REPEAT_POS_OPTIONS);
    } else { // Отмена операции оплаты
      await repeatRequest(() => {
        return repo.pos.payCancel(visitId, {
          type: paymentType,
          useScores: 0, // FIXME: Устанавливать по умолчанию
        });
      }, REPEAT_POS_OPTIONS);
    }
  } catch (e) {
    toast.error(e, 4000, {
      defaultHeader: t('views.visit_pay.header_error_pos')
    });
  }

  hasAbilityCancel = false;
}

const buttonConfirmDisabled = computed(() => {
  return (initLoading.value || operationProcessing.value)
    || (false === isReturnAction && cashDepositedError.value);
});

const buttonConfirmText = computed(() => {
  if (initLoading.value) {
    return t('views.visit_pay.confirm_btn_init');
  }

  if (operationProcessing.value) {
    return t('views.visit_pay.confirm_btn_processing');
  }

  if (isReturnAction) {
    return t('views.visit_pay.confirm_btn_confirm_return');
  }

  return (route.query.status === CarVisitStatusEnum.Finished)
    ? t('views.visit_pay.confirm_btn_confirm_pay_and_close')
    : t('views.visit_pay.confirm_btn_confirm_pay');
});

onMounted(initAndLoadAction);

const viewTitle = computed(() => {
  return (isReturnAction)
    ? t('views.visit_pay.title_return')
    : t('views.visit_pay.title_accept');
});

const labelTotal = computed(() => {
  return (isReturnAction)
    ? t('views.visit_pay.label_total_return')
    : t('views.visit_pay.label_total_accept');
});

const labelPaymentType = computed(() => {
  return (isReturnAction)
    ? t('views.visit_pay.label_payment_type_return')
    : t('views.visit_pay.label_payment_type_accept');
});
</script>

<style lang="scss">
.visit-pay-sum-card {
  padding: 38px 20px;
  text-align: center;
  color: var(--ion-text-color);

  &__price {
    font-size: 2.65rem;
    font-weight: 600;
  }

  &__valute {
    padding-top: 20px;
    font-weight: normal;
    font-size: var(--core-font-size);
  }
}
</style>