<template>
  <c-modal-action-header tag="ion-header" :show-close="userCloseModal">
    <template #start>
      <ion-button class="c-modal-header-back-button" color="transparent-text" @click="back">
        <ion-icon name="chevron-back-outline" />
      </ion-button>
    </template>

    <span v-html="title || $t('components.add_category_modal.view_provided_services_title_default')"></span>
  </c-modal-action-header>

  <c-modal-content>
    <div class="core-searchbar-mobile core-searchbar-mobile-fixed-wrapper ion-padding">
      <ion-searchbar
        class="core-searchbar"
        inputmode="search"
        :placeholder="$t('components.group_partner_select_box.input_search')"
        clear-icon="close-outline"
        search-icon="core-search"
        :debounce="300"
        v-model="searchWords"
      />
    </div>
    <div class="ion-padding-horizontal ion-padding-end">
      <services-provide-by-category-group
        :show-title="false"
        :context="{
          categoryId: +catId,
          typeId: +typeId,
          groupId: !!groupId ? +groupId : undefined,
          discountAccountId: carId,
        }"
        :search-words="searchWords"
        v-model:provided="currentGroupValues"
        v-model:loading-services="loadingServices"
        :replaced="currentGroupReplaced"
      />
    </div>
  </c-modal-content>

  <ion-footer>
    <div class="c-select-provided-services__footer-total">
      <div class="c-select-provided-services__footer-total-text">
        {{ $t('components.add_category_modal.view_provided_services_label_total') }}
      </div>

      <div class="c-select-provided-services__footer-total-price">
        <ion-skeleton-text v-if="loadingServices" animated :style="{
          width: '60px',
          height: '1em',
        }" />

        <cc v-else :price="total" />
      </div>
    </div>

    <div class="core-modal-footer-toolbal">
      <ion-button
        color="primary"
        expand="block"
        shape="round"
        @click="apply"
        :disabled="loadingServices"
      >
        {{ $t('components.add_category_modal.view_provided_services_btn_next') }}
      </ion-button>
    </div>
    <div class="core-modal-searchbar-spacer" />
  </ion-footer>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ComputedRef, provide, ref } from 'vue';
import { useStore } from '@/composables/useApp';
import ServicesProvideByCategoryGroup from '../provide-form/ServicesProvideByCategoryGroup.vue';
import { ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import { generateKeyProvideService, prepareProvidedServicesBodyItems } from '@/helpers/visit';
import { useModalNav } from './modal';
import SelectDoersView from './SelectDoersView.vue';
import { VisitTypeAndCategory } from '@/helpers/visit';
import { cloneDeep, groupBy } from 'lodash';
import { useMetrica } from '@/composables/useMetrica';
import type { ModalServicesContext } from '@/composables/visit';

export default defineComponent({
  inheritAttrs: false,

  components: {
    ServicesProvideByCategoryGroup,
  },

  props: {
    // FIXME: Избавиться от такого большого кол-ча передаваемых
    // значений и передавать их в ctx.visitState
    catId: {
      type: [String, Number] as PropType<string|number>,
      required: true,
    },
    typeId: {
      type: [String, Number] as PropType<string|number>,
      required: true,
    },
    groupId: {
      type: [String, Number] as PropType<string|number>,
      required: false,
    },
    carId: {
      type: String,
      required: false,
    },

    // HACK: Не передаем значения напрямую,
    // т.к. это вызовет проблемы с обновлением props значений
    useModalServicesContext: {
      type: Function as PropType<() => ModalServicesContext>,
      required: true,
    },

    selectedTypeAndCategoriesCRef: {
      type: Object as PropType<ComputedRef<VisitTypeAndCategory[]>>,
      default: () => computed(() => [] as VisitTypeAndCategory[]),
    },

    userCloseModal: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const searchWords = ref('');
    const store = useStore();
    const nav = useModalNav();
    const ctx = props.useModalServicesContext();
    const { emitEvent } = useMetrica();

    provide('visitState', ctx.visitState);

    const title = store.point.getCategoryBreadcrumbsComputed(props.catId);
    const categoryGroupKey = generateKeyProvideService(props.typeId, props.catId); // no reactive!
    const loadingServices = ref(false);

    /** Заменит категорию с сохранением выбранных услуг, если у типа нет мультивыбора */
    async function replaceCategoryIfDetectNotMultichoice() {
      const pointId = +props.typeId;
      const pointType = await store.point.filterTypePoint(pointId);
      if (pointType?.categoryMultichoice) return;

      const groupByTypes = groupBy(props.selectedTypeAndCategoriesCRef.value, 'typeId')
      if (groupByTypes[`${pointId}`]) {
        let savedProvidedServices: ProvideServiceBodyItem[]|null = null;

        for (const item of groupByTypes[`${pointId}`]) {
          const categoryGroupKey = generateKeyProvideService(item.typeId, item.catId);

          if (ctx.providedServicesGroupsValues.value[categoryGroupKey]) {
            // TODO: нужно попробовать сделать дополнительную проверку, есть ли в новой категории эта-же услуга (теориттически она может быть недоступна)
            savedProvidedServices = cloneDeep(ctx.providedServicesGroupsValues.value[categoryGroupKey]);
            delete ctx.providedServicesGroupsValues.value[categoryGroupKey];
          }
        }

        if (savedProvidedServices) {
          // TODO: Свайп в обратную сторону, если зашел в ранее выбранную категорию.
          ctx.providedServicesGroupsReplaced.value[categoryGroupKey] = savedProvidedServices;

          // NOTE: Передадим ранее выбранную информацию с флагом forceUpdate - это позволит после загрузки
          // пунктов услуг, сразу инициализировать механизм пересчета стоимости.
          ctx.providedServicesGroupsValues.value[categoryGroupKey] = cloneDeep(savedProvidedServices).map(s => ({
            ...s,
            meta: {
              forceUpdate: true,
            }
          }));
        }
      }
    }

    replaceCategoryIfDetectNotMultichoice();

    const currentGroupValues = computed<ProvideServiceBodyItem[]>({
      get() {
        return ctx.providedServicesGroupsValues.value[categoryGroupKey] || [];
      },
      set(providedServices) {
        ctx.providedServicesGroupsValues.value[categoryGroupKey] = providedServices;
      }
    });

    const currentGroupReplaced = computed<ProvideServiceBodyItem[]|undefined>(() => {
      return ctx.providedServicesGroupsReplaced.value[categoryGroupKey] || undefined;
    });

    const total = computed(() => {
      return prepareProvidedServicesBodyItems(currentGroupValues.value)
        .reduce((s, item) => s + item.totalPrice, 0)
      ;
    });

    function apply() {
      emitEvent('visit/edit/services/apply');
      nav.pushView(SelectDoersView);
    }

    return {
      searchWords,
      title,
      total,
      currentGroupValues,
      currentGroupReplaced,
      loadingServices,
      apply,
      back: () => nav.backView(),
    };
  },
});
</script>

<style lang="scss">
@import '@/scss/abstract';

.c-select-provided-services { // TODO: Переписать
  &__footer-total {
    display: flex;
    margin: 0 var(--ion-padding);
    padding: var(--core-spacer-small) 0;
    align-items: center;
    min-height: 55px;
  }

  &__footer-total-text {
    flex: 1 1 0%;
  }
}
</style>