import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-input-pos" }
const _hoisted_2 = { class: "c-input-pos__input-wrap" }
const _hoisted_3 = {
  ref: "keyboardContainer",
  class: "c-input-pos__keyboard-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createElementVNode("input", {
        ref: "input",
        readonly: "",
        class: "c-input-pos__input",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.sum) = $event)),
        name: "pos_sum"
      }, null, 512), [
        [
          _vModelText,
          _ctx.sum,
          void 0,
          { number: true }
        ]
      ]),
      _createElementVNode("button", {
        class: "c-input-pos__backspace-btn",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backspaceAct && _ctx.backspaceAct(...args)))
      }, [
        _createVNode(_component_ion_icon, { name: "backspace-outline" })
      ])
    ]),
    _createElementVNode("div", _hoisted_3, null, 512)
  ]))
}