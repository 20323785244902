<template>
  <ion-page>
    <app-header :title="pageTitle" />

    <ion-content class="ion-padding">
      <services-view
        class="m-0"
        :cat-id="catId"
        :mobile-searchbar-container="fixedContent"
        @input-search="inputSearchDebounce"
      />

      <!-- Пробел, для searchbar -->
      <div :style="{ height: '70px' }" />

      <!-- eslint-disable-next-line -->
      <div v-scroll-hide="{}" slot="fixed" ref="fixedContent" class="core-container-fixed-bottom" />
    </ion-content>

    <app-footer />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import ServicesView from '@/components/service/price-view/ServicesView.vue'
import { useStore } from '@/composables/useApp'
import { scrollHide } from '@/directives/scrollHide'
import { debounce } from 'lodash';
import { useMetrica } from '@/composables/useMetrica'
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: { ServicesView },
  directives: { scrollHide },

  setup() {
    const route = useRoute();
    const store = useStore();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    const catId = route.params.catId as string;

    const pageTitle = ref(t('views.pricelist_services.default_title'));
    const fixedContent = ref<HTMLDivElement|null>(null);

    // Чтобы прогрузить названия категорий
    store.point.getThisClient(); // async

    const inputSearchDebounce = debounce((text: string) => {
      if (text) {
        emitEvent('pricelist/search', { text });
      }
    }, 1400);

    return {
      catId,
      pageTitle,
      fixedContent,
      inputSearchDebounce,
    };
  }
});
</script>

<style lang="scss">
</style>