<script lang="ts" setup>
import { computed, PropType, defineProps } from 'vue';
import { CarVisitProvideService } from '@/repositories/Models/CarVisit';
import { groupBy, map } from 'lodash';
import { useStore } from '@/composables/useApp';
import ServicesProvidedViewGroup, { ViewGroup } from './ServicesProvidedViewGroup.vue';
import { useI18n } from 'vue-i18n';

const props = defineProps({
  providedServices: {
    type: Array as PropType<CarVisitProvideService[]>,
    required: true,
  },
  total: {
    type: [String, Number] as PropType<string|number>,
    required: false,
  },
  shortGroupName: {
    type: Boolean,
    default: false,
  }
});


const store = useStore();
const { t } = useI18n();

// Прогрузим список категорий (необходимо для корректной работы getCategoryBreadcrumbsComputed)
store.point.getThisClient(); // async

const groups = computed<ViewGroup[]>(() => {
  const groupCategories = groupBy(
    props.providedServices,
    item => item.carCategory?.id
  );

  return map(groupCategories, (value, key) => {
    return {
      catId: +key,
      providedServices: value,
    }
  });
});

</script>

<template>
  <ion-card class="padding-x-adaptive">
    <services-provided-view-group
      v-for="group in groups"
      :key="group.catId"
      :group="group"
      :short-title="shortGroupName"
    />

    <ion-item class="color-default" v-if="props.total !== undefined">
      {{ t('components.services_provided_view_card.card_item_label_total') }}
      
      <div slot="end">
        <slot name="total-price">
          <cc :price="props.total" />
        </slot>
      </div>
    </ion-item>

    <slot name="after"></slot>
  </ion-card>
</template>
