import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_visit_archive_group = _resolveComponent("visit-archive-group")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visitsArchiveDatesGroups, (group) => {
    return (_openBlock(), _createBlock(_component_visit_archive_group, {
      key: group.title,
      group: group,
      "col-sizes": _ctx.colSizes
    }, null, 8, ["group", "col-sizes"]))
  }), 128))
}