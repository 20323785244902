<template>
  <div class="c-card-header">
    <slot />
  </div>
</template>

<style lang="scss">
.c-card-header {
  margin: 0 0 var(--core-spacer-small, 20px);
  font-size: var(--core-font-size-heading, 20px);
}
</style>