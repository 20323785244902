import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-visit-shortinfo" }
const _hoisted_2 = { class: "c-visit-shortinfo__inner-wrap" }
const _hoisted_3 = { class: "c-visit-shortinfo__image-col" }
const _hoisted_4 = { class: "c-visit-shortinfo__content" }
const _hoisted_5 = { class: "c-visit-shortinfo__header" }
const _hoisted_6 = { class: "c-visit-shortinfo__car-number" }
const _hoisted_7 = {
  size: "auto",
  class: "c-visit-shortinfo__total"
}
const _hoisted_8 = { class: "c-visit-shortinfo__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_visit_labels = _resolveComponent("visit-labels")!
  const _component_cc = _resolveComponent("cc")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("div", {
          class: "c-visit-shortinfo__image",
          style: _normalizeStyle({ backgroundImage: `url('${_ctx.images}')` })
        }, [
          _createVNode(_component_visit_labels, {
            class: "visit-card-badge-container",
            labels: _ctx.badgeList
          }, null, 8, ["labels"])
        ], 4)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.formatCarNumber), 1),
          _createElementVNode("div", _hoisted_7, [
            _createVNode(_component_cc, { price: _ctx.totalPrice }, null, 8, ["price"])
          ])
        ]),
        _createElementVNode("div", _hoisted_8, _toDisplayString(_ctx.progressText), 1)
      ])
    ])
  ]))
}