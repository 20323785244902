<template>
  <ion-page>
    <app-header :title="$t('views.user_edit.title')" />

    <ion-content>
      <tablet-side-layout>
        <template #side>
          <div class="ion-padding-horizontal ion-padding-top">
            <c-card-header>
              {{ carId }}
            </c-card-header>

            <c-image-bg
              class="ion-margin-bottom"
              :ratio="0.6"
              :src="carCover"
            />

            <template v-if="isTabletAndUp">
              <c-card-header v-show="carId">
                {{ $t('views.user_edit.popular_services_card_title') }}
              </c-card-header>
              <services-popular-card
                :car-id="carId"
              />
            </template>
          </div>
        </template>

        <div class="ion-padding">
          <c-card-header>
            {{ $t('views.user_edit.form_title') }}
          </c-card-header>

          <c-content-loading :action="loadCar">
            <div class="c-form">
              <div class="c-form-group">
                <ion-input
                  class="core-input"
                  :placeholder="$t('views.user_edit.input_client_name')"
                  autocapitalize="words"
                  v-model="car.info.ownerName"
                />
              </div>

              <div class="c-form-group">
                <ion-input
                  class="core-input"
                  :placeholder="$t('views.user_edit.input_client_phone')"
                  v-model="car.info.ownerPhone"
                  type="tel"
                  inputmode="tel"
                />
              </div>

              <div class="c-form-group">
                <ion-textarea
                  class="core-input"
                  :placeholder="$t('views.user_edit.input_client_comment')"
                  autocapitalize="sentences"
                  :rows="3"
                  v-model="car.info.comment"
                />
              </div>
            </div>
          </c-content-loading>
        </div>
      </tablet-side-layout>

      <!-- eslint-disable-next-line -->
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-button
          v-show="!car.loadingInfo"
          :disabled="car.loadingSave"
          color="primary"
          shape="round"
          @click="saveAction"
        >
          {{ saveBtnText }}
        </ion-button>
      </ion-fab>
    </ion-content>

    <app-footer />
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent, reactive } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import ServicesPopularCard from '@/components/service/ServicesPopularCard.vue';
import { CarBodyRequest } from '@/repositories/Models/Car';
import { useStore } from '@/composables/useApp';
import { pick } from 'lodash';
import { useToast } from '@/composables/toast';
import carNoImage from '@/img/car-no-image.svg';
import { isTabletAndUp } from '@/helpers/adaptive';
import { useI18n } from 'vue-i18n';
import { preparePhoneCore12LegacyFormat } from '@/utils/string';

export default defineComponent({
  components: { ServicesPopularCard },
  setup() {
    const route = useRoute();
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { t } = useI18n();

    const carId = route.params.carId as string;
    const carCover = computed(() => route.query.visit_image || carNoImage);
    const car = reactive({
      loadingInfo: false,
      loadingSave: false,
      fromСacheInfo: false,
      info: {
        ownerName: '',
        ownerPhone: '',
        comment: '',
      } as CarBodyRequest,
    });

    /** Загрузка информации о владельце */
    async function loadCar() {
      car.loadingInfo = true;

      try {
        const data = await store.car.getCar(carId);
        car.fromСacheInfo = data.fromСache;

        Object.assign(car.info, pick<CarBodyRequest>(data.data, [
          'comment',
          'ownerName',
          'ownerPhone',
        ]));
      } finally {
        car.loadingInfo = false;
      }
    }

    /** Обновление информации об автомобиле */
    async function updateCar() {
      car.loadingSave = true;

      try {
        await store.car.update(carId, {
          id: carId,
          ...car.info,
          ownerPhone: preparePhoneCore12LegacyFormat(car.info.ownerPhone)
        });

        // fix: Чтобы правильно перекэшировать информацию
        await store.car.getCar(carId);
      } catch (e) {
        toast.error(e, 3500, {
          defaultHeader: t('views.user_edit.header_error_saved')
        });
      } finally {
        car.loadingSave = false;
      }
    }

    async function saveAction() {
      await updateCar();

      if (route.query.dir === 'back') {
        router.back();
      }
    }

    const saveBtnText = computed(() => {
      return (car.loadingSave)
        ? t('views.user_edit.btn_loading_save')
        : t('views.user_edit.btn_save');
    });

    return {
      saveBtnText,
      carId,
      carCover,
      car,
      loadCar,
      saveAction,
      isTabletAndUp,
    };
  }
});
</script>

<style lang="scss">
</style>