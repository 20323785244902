<template>
  <div class="c-shedule-grid" :style="sheduleStyles">
    <slot name="root-before" />
    
    <div class="c-shedule-grid__header">
      <div class="c-shedule-grid__header-first-col">
        <slot name="header-first-cell" />
      </div>

      <div class="c-shedule-grid__header-cols-container" ref="headerScrollRef">
        <div class="c-shedule-grid__header-col"
           v-for="(col, i) in cols" :key="i"
        >
          <slot name="header-cell" v-bind="{ col }">
            {{ col }}
          </slot>
        </div>
      </div>
    </div>

    <div class="c-shedule-grid__content">
      <div class="c-shedule-grid__time">
        <slot name="time-before" />

        <div class="c-shedule-grid__time-row"
          v-for="(row, i) in rows" :key="i"
        >
          <slot name="time-row" v-bind="{ row }">
            {{ row }}
          </slot>
        </div>
      </div>

      <div
        class="c-shedule-grid__grid"
        @scroll="onGridScroll"
        @click="$emit('click-grid', $event)"
      >
        <slot />
      </div>

      <slot name="content-after" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType, computed, ref } from 'vue';

export default defineComponent({
  props: {
    cols: {
      type: Array as PropType<any[]>,
      required: true,
    },
    rows: {
      type: Array as PropType<any[]>,
      required: true,
    }
  },

  setup(props) {
    const headerScrollRef = ref<HTMLDivElement|null>(null);
    const sheduleStyles = computed(() => ({
      '--shedule-grid-cols-count': props.cols.length,
      '--shedule-grid-rows-count': props.rows.length,
    }));

    function onGridScroll(ev: Event) {
      if (headerScrollRef.value && ev.target) {
        headerScrollRef.value.scrollLeft = (ev.target as HTMLDivElement).scrollLeft;
      }
    }

    return {
      onGridScroll,
      headerScrollRef,
      sheduleStyles,
    };
  }
});
</script>

<style lang="scss">
.c-shedule-grid {
  $row-height: var(--shedule-grid-col-height, 50px);
  $header-height: var(--shedule-grid-header-height, 46px);
  $time-col-weight: var(--shedule-grid-time-col-width, 100px);
  $cols-count: var(--shedule-grid-cols-count, 1);
  $rows-count: var(--shedule-grid-rows-count, 1);
  $minmax-cell: minmax(var(--shedule-grid-col-min-width, 120px), 1fr);

  height: 100%;
  overflow: hidden;
  display: grid;
  grid-template-columns: auto;
  grid-template-rows: $header-height calc(100% - #{$header-height});
  grid-template-areas: "header" "content";

  &__header {
    grid-area: header;

    display: grid;
    grid-template-columns: $time-col-weight auto;
    grid-template-rows: auto;
    grid-template-areas: "first-col cols-container";
  }

  &__header-first-col {
    grid-area: first-col;
  }

  &__header-cols-container {
    grid-area: cols-container;
    overflow-x: hidden;
    display: grid;
    grid-template-rows: auto;
    grid-template-columns: repeat($cols-count, $minmax-cell);
    grid-auto-rows: auto;
  }

  &__content {
    position: relative;
    grid-area: content;
    display: grid;
    overflow-y: auto;
    grid-template-rows: auto;
    grid-template-columns: 100px 1fr;
    grid-template-areas: "shedule-time shedule-grid";
    -webkit-overflow-scrolling: touch;
  }

  &__time {
    grid-area: shedule-time;
    display: grid;
    grid-template-columns: auto;
    grid-auto-rows: $row-height;
    overflow: visible;
    -webkit-overflow-scrolling: touch;
    color: var(--core-text-muted);
    position: relative;
  }
  
  &__grid {
    grid-area: shedule-grid;
    display: grid;
    grid-auto-rows: $row-height;
    grid-template-columns: repeat($cols-count, $minmax-cell);
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    position: relative;
  }

  // &__header-col {}

  // Hide browser scrollbars
  *::-webkit-scrollbar { width: 0; height: 0; }
  * {
    overflow: -moz-scrollbars-none;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
}
</style>