<template>
  <div>
    <div @click="toDevPage">{{ $t('components.user_settings.fullname_label') }}</div>
    <p class="text-muted">
      {{ fullName }}
    </p>

    <c-divider class="my-0" />

    <ion-button
      fill="clear"
      color="transparent"
      class="ion-no-padding"
      @click="confirmLogout"
    >
      {{ $t('components.user_settings.btn_exit') }}
    </ion-button>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useUser } from '@/composables/useUser';
import { useMetrica } from '@/composables/useMetrica';
import { useNavManager } from '@/composables/useNavManager';
import { useStore } from '@/composables/useApp';
import { useAlert } from '@/composables/alert';
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { popoverController } from '@ionic/vue';

export default defineComponent({
  emits: ['logout'],

  setup(props, { emit }) {
    const store = useStore();
    const router = useRouter();
    const { fullName } = useUser();
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();
    const { createAlertConfirm } = useAlert();
    const { t } = useI18n();

    /** @private */
    async function logout() {
      try {
        await store.user.logout();
      } finally {
        navManager.navigate({
          routerDirection: 'root',
          routerLink: { name: 'login' },
        });

        emit('logout');
      }
    }

    const confirmLogout = createAlertConfirm({
      header: t('components.user_settings.alert_exit_header'),
      message: t('components.user_settings.alert_exit_message'),
      confirmBtnText: t('components.user_settings.alert_exit_btn_confirm'),
      async confirm() {
        emitEvent('user/logout');
        await logout();
      }
    });

    let devPageClickCounter = 0;
    function toDevPage() {
      devPageClickCounter++;

      if (devPageClickCounter >= 10) {
        router.push({ name: 'dev' });
        popoverController.dismiss(); // TODO: В будущем изменится на модальное окно
      }
    }

    return {
      fullName,
      confirmLogout,
      toDevPage,
    };
  }
});
</script>

<style lang="scss">
</style>