import { useStore } from './useApp';
import { toUpper, first } from 'lodash';
import { computed } from 'vue';

export function useUser() {
  const store = useStore();

  const user = store.user.user;
  const iconLabel = computed<string>(() => {
    if (!user.value) return '';

    const firstNameLetter = toUpper(first(user.value.firstName));
    const lastNameLetter = toUpper(first(user.value.lastName));

    return `${firstNameLetter}${lastNameLetter}`;
  });

  const fullName = computed(() => {
    if (!user.value) return '';

    return `${user.value.firstName} ${user.value.lastName}`;
  });

  if (store.user.isAuth && !user.value) {
    store.user.getCurrent(); // async
  }

  return {
    user,
    iconLabel,
    fullName,
  };
}