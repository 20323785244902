import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserSettings = _resolveComponent("UserSettings")!
  const _component_popover_layout = _resolveComponent("popover-layout")!

  return (_openBlock(), _createBlock(_component_popover_layout, {
    class: "c-settings-popover",
    title: _ctx.$t('views.settings.user_popover_title')
  }, {
    default: _withCtx(() => [
      _createVNode(_component_UserSettings, { onLogout: _ctx.close }, null, 8, ["onLogout"])
    ]),
    _: 1
  }, 8, ["title"]))
}