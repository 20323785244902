import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode } from "vue"

const _hoisted_1 = { class: "c-service-provider-cost-modal" }
const _hoisted_2 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_modal_action_header = _resolveComponent("c-modal-action-header")!
  const _component_input_price = _resolveComponent("input-price")!
  const _component_cc = _resolveComponent("cc")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_range = _resolveComponent("ion-range")!
  const _component_c_divider = _resolveComponent("c-divider")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_c_modal_action_header, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.service_provide_item_cost_modal.modal_title')), 1)
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_input_price, {
        modelValue: _ctx.priceValue,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.priceValue) = $event)),
        "currency-unit": _ctx.currencyConfig.symbol
      }, null, 8, ["modelValue", "currency-unit"]),
      _createVNode(_component_ion_row, { class: "text-muted" }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_col, { class: "pl-0" }, {
            default: _withCtx(() => [
              _createVNode(_component_cc, { price: _ctx.minValue }, null, 8, ["price"])
            ]),
            _: 1
          }),
          _createVNode(_component_ion_col, { class: "ion-text-end pr-0" }, {
            default: _withCtx(() => [
              (_ctx.maxValue === Number.MAX_VALUE)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode("∞")
                  ], 64))
                : (_openBlock(), _createBlock(_component_cc, {
                    key: 1,
                    price: _ctx.maxValue
                  }, null, 8, ["price"]))
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      (_ctx.showRange)
        ? (_openBlock(), _createBlock(_component_ion_range, {
            key: 0,
            modelValue: _ctx.priceValue,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.priceValue) = $event)),
            color: "secondary",
            class: "c-service-provider-cost-modal__range",
            min: _ctx.minValue,
            max: _ctx.maxValue,
            step: 10
          }, null, 8, ["modelValue", "min", "max"]))
        : _createCommentVNode("", true),
      _createVNode(_component_c_divider),
      _createVNode(_component_ion_button, {
        color: "primary",
        expand: "block",
        shape: "round",
        onClick: _ctx.apply
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.$t('components.service_provide_item_cost_modal.btn_apply')), 1)
        ]),
        _: 1
      }, 8, ["onClick"])
    ])
  ]))
}