<template>
  <shedule-grid-layout
    :cols="boxes"
    :rows="timeLine"
    :class="{
      'c-shedule-box': true,
      'c-shedule-box--loading': loading,
    }"
    @click-grid="onClickGrid"
  >
    <template #header-cell="{ col }">
      {{ col.name }}
    </template>

    <template #time-row="{ row }">
      <div class="c-shedule-box__time-cell">
        {{ row.text }}
      </div>
    </template>

    <template #header-first-cell>
      {{ $t('components.visits_preentry_shedule_box.col_time_label') }}
    </template>

    <div
      v-for="emptyInfo in emptySheduleGrid"
      :class="{'c-shedule-box-empty-item': true, '--expired': emptyInfo.expired }"
      :key="`${emptyInfo.gridColumnStart}_${emptyInfo.gridRowStart}_${emptyInfo.gridRowEnd}`"
      :data-empty-key="`${emptyInfo.gridColumnStart}_${emptyInfo.gridRowStart}`"
      :style="emptyInfo"
      @click="onClickFreeTime($event, emptyInfo)"
    />

    <div 
      v-for="visitCell in visitsSheduleGrid"
      :class="{ 'c-shedule-box-item': true, ...visitCell.classes, }"
      :key="'visit_' + visitCell.visit.id"
      :style="visitCell.gridStyle"
      @click="onClickVisit(visitCell.visit)"
    >
      <!-- Отображаем имя клиента, если высота больше, чем 1-н час -->
      <template v-if="visitCell.gridStyle.gridRowEnd - visitCell.gridStyle.gridRowStart > 2">
        {{ visitCell.visit.car?.ownerName || $t('components.visits_preentry_shedule_box.visit_card_client_no_name') }}<br />
      </template>

      <b>{{ visitCell.visit.car?.number || $t('components.visits_preentry_shedule_box.visit_card_car_no_number') }}</b>

      <!-- Отображать время, если длительность больше 30 минут -->
      <template v-if="visitCell.gridStyle.gridRowEnd - visitCell.gridStyle.gridRowStart > 1">
        <span>{{ visitCell.time.text }} — {{ visitCell.calcEndTime.text }}</span>
      </template>
    </div>

    <div class="c-shedule-box-pointer"
      v-if="showPointer"
      ref="pointerRef"
      :style="gridPointer?.style"
      @click="onClickPointer"
      @touchstart.prevent
    />

    <template #time-before>
      <div
        v-show="currentTimePointerTop && isCurrentDate"
        class="c-shedule-box-current-time"
        :style="{ top: currentTimePointerTop }"
      />
    </template>

    <template #root-before>
      <div class="c-shedule-box__loading-container" v-show="loading">
        <c-spinner :loading-text="$t('components.visits_preentry_shedule_box.shedule_loading')" />
      </div>
    </template>

    <slot />

    <template #content-after>
      <slot name="content-after" />
    </template>
  </shedule-grid-layout>
</template>

<script lang="ts">
import { defineComponent, toRef, computed, PropType, ref, watch, toRaw, getCurrentInstance, nextTick } from 'vue';
import SheduleGridLayout from '@/components/layout/SheduleGridLayout.vue';
import { usePreentryShedule, TimeGridStyle, EmptyTimeGrid } from '@/composables/usePreentry';
import { useToast } from '@/composables/toast';
import { find, findIndex, toNumber, round } from 'lodash';
import { shiftsSmoothingValue } from '@/helpers/shifts';
import { datetimeToTimeNumber, currentTimeMoment, MINUTES_DAY_NUMBER } from '@/helpers/datetime';
import { createGesture, GestureDetail, Gesture } from '@ionic/vue';
import { Box } from '@/repositories/Models/Box';
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit';
import { useStore } from '@/composables/useApp';

export interface PointerMoveInfo {
  currentEmpty: TimeGridStyle;
  currentEmptyEl: HTMLElement;
  pointerRowStart: number;
}

export interface PreentryValue {
  boxId: number;
  datetime: Date;
}

export interface PreentryGridPointer {
  style: TimeGridStyle;
  timeIndex: number;
  boxIndex: number;
}

export interface PointerInfo {
  box: Box;
  time: number;
  date: Date;
}

export interface PointerRect {
  bottom: number;
  height: number;
  left: number;
  right: number;
  top: number;
  width: number;
  x: number;
  y: number;
  offsetLeft: number;
  offsetTop: number;
  container: {
    scrollHeight: number;
    bottom: number;
    height: number;
    left: number;
    right: number;
    top: number;
    width: number;
    x: number;
    y: number;
  };
}

export default defineComponent({
  components: {
    SheduleGridLayout,
  },

  props: {
    date: {
      type: null,
    },

    /**
     * Норматив времени в минутах, для отображения
     * доступных областей для записи.
     */
    normative: {
      type: Number,
      default: 0,
    },

    modelValue: {
      type: [Object, null] as PropType<PreentryValue|null>,
      default: null,
    },
    
    selectEnable: {
      type: Boolean,
      default: false,
    },

    stepMinutes: {
      type: Number,
      default: 15,
    },

    /** 
     * Автоматически обносить визиты в случае если изменился
     * глобальный счетчик новых заказов и в процессе.
     */
    visitsAutoSync: {
      type: Boolean,
      default: false,
    },

    excludeVisitIds: {
      type: Array as PropType<string[]>,
      default: () => [],
    }
  },

  setup(props, { emit }) {
    const toast = useToast();
    const instance = getCurrentInstance();
    const currenDate = ref(new Date());
    const store = useStore();
    
    const date = toRef(props, 'date');
    const normative = toRef(props, 'normative');

    const {
      boxes,
      timeLine,
      loading,
      visitsSheduleGrid,
      emptySheduleGrid,
      preparedDate,
      beetweenDate,
      minmaxMinutes,
      isCurrentDate,
      loadVisits,
    } = usePreentryShedule(date, {
      autoload: true,
      autoupdateChangeDate: true,
      normative,
      stepMinutes: props.stepMinutes,
      disableBeforeData: props.selectEnable ? currenDate : undefined,
      excludeVisitIds: props.excludeVisitIds,
    });

    // Включено автоматическое обновление визитов,
    // при изменении данных счетчика заказов.
    if (props.visitsAutoSync) {
      const visitsCounters = store.visit.getCountersRef();
      let emptyInited = !!visitsCounters.value.emptyInited;

      watch(visitsCounters, () => {
        // Чтобы при первом запуске не делать лишний повторный запрос 
        if (emptyInited === true) {
          emptyInited = false;
          return;
        }

        loadVisits();
      }, { deep: true });
    }

    function prepareValue(value: any): PreentryValue|null {
      if (!(value?.datetime instanceof Date) || !value?.boxId) return null;

      const boxId = toNumber(value.boxId);
      if (!find(boxes.value, box => box.id === boxId)) return null;

      const { from, to } =  beetweenDate.value;
      const datetime = value.datetime as Date;
      if (datetime < from || datetime > to) return null;

      return { boxId, datetime };
    }

    const lazyValue = ref<PreentryValue|null>(prepareValue(props.modelValue));

    const innerValue = computed<PreentryValue|null>({
      get() {
        return lazyValue.value;
      },
      set(value) {
        lazyValue.value = value;
        emit('update:modelValue', lazyValue.value);
      }
    });

    watch([toRef(props, 'modelValue'), beetweenDate, boxes], ([value]) => {
      if (toRaw(lazyValue.value) !== toRaw(value)) {
        lazyValue.value = prepareValue(value);
      }
    });

    const gridPointerHeight = computed(() => Math.max(1, Math.ceil(props.normative / props.stepMinutes)));
    
    const gridPointer = computed<PreentryGridPointer|null>(() => {
      if (!lazyValue.value) return null;

      const boxId = lazyValue.value.boxId;
      const timeValue = shiftsSmoothingValue(datetimeToTimeNumber(lazyValue.value.datetime), props.stepMinutes);
      const timeIndex = findIndex(timeLine.value, t => t.timeNumber === timeValue);
      const boxIndex = findIndex(boxes.value, b => b.id === boxId);

      if (timeIndex < 0 || boxIndex < 0) return null;

      return {
        boxIndex,
        timeIndex,
        style: {
          gridRowStart: timeIndex + 1,
          gridRowEnd: (timeIndex + 1) + gridPointerHeight.value,
          gridColumnStart: boxIndex + 1,
          gridColumnEnd: boxIndex + 2,
        },
      };
    });

    watch(gridPointer, gp => {
      let info: PointerInfo|null = gp
        ? {
            box: boxes.value[gp.boxIndex],
            time: timeLine.value[gp.timeIndex].minutesBeginDay,
            date: preparedDate.value,
          }
        : null;

      emit('pointer-info', info);
    });

    const showPointer = computed(() => props.selectEnable && !!gridPointer.value);

    const onClickPointer = (ev: PointerEvent) => {
      ev.stopPropagation();
      // innerValue.value = null;
    };

    const onClickGrid = (ev: PointerEvent) => {
      ev.stopPropagation();
      // innerValue.value = null;
    };

    const onClickFreeTime = (ev: PointerEvent, g: EmptyTimeGrid) => {
      ev.stopPropagation();

      if (!props.selectEnable || g.expired) return;

      const target = ev.target as HTMLDivElement;

      const stepValue = target.clientHeight / (g.gridRowEnd - g.gridRowStart);
      const rowOffset = Math.ceil(ev.offsetY / stepValue) - 1;
      const timeIndex = Math.max(g.gridRowStart, Math.min(g.gridRowEnd - gridPointerHeight.value, rowOffset + g.gridRowStart)) - 1;
      const boxIndex = g.gridColumnStart - 1;

      const box = boxes.value[boxIndex];
      const time = timeLine.value[timeIndex];

      if (!box || !time) {
        return toast.error('Failed to calculate the place in the schedule, please contact the administrator');
      }

      const datetime = new Date(preparedDate.value);
      datetime.setMinutes(time.minutesBeginDay);

      innerValue.value = {
        boxId: box.id,
        datetime,
      };

      nextTick(emitPointerRect);
    }

    function emitPointerRect() {
      let rect: PointerRect|null = null;

      if (pointerRef.value && pointerRef.value.offsetParent) {
        const boundingClientRect = pointerRef.value.getBoundingClientRect().toJSON();
        const containerRect = pointerRef.value.offsetParent.getBoundingClientRect().toJSON();
        
        rect = {
          ...boundingClientRect,
          offsetLeft: pointerRef.value.offsetLeft,
          offsetTop: pointerRef.value.offsetTop,
          container: {
            scrollHeight: pointerRef.value.offsetParent.scrollHeight,
            ...containerRect
          },
        }
      }

      emit('pointer-rect', rect);
    }

    //#region Жесты, для перемещения
    function onMovePointer(ev: GestureDetail) {
      if (!lazyValue.value || !pointerStartMoveInfo.value) return;

      const {
        currentEmptyEl,
        pointerRowStart,
        currentEmpty: {
          gridRowStart,
          gridRowEnd,
        }
      } = pointerStartMoveInfo.value;

      const stepValue = currentEmptyEl.clientHeight / (gridRowEnd - gridRowStart);
      const rowOffset = Math.round(ev.deltaY / stepValue);

      const moveRowStart = rowOffset + pointerRowStart;
      const edgeConstraintRowStart = Math.max(gridRowStart, Math.min(gridRowEnd - gridPointerHeight.value, moveRowStart));

      if (gridPointer.value?.style.gridRowStart === edgeConstraintRowStart) {
        return; // Если ячейка не изменилась, далее ничего считать не будем
      }

      const time = timeLine.value[edgeConstraintRowStart - 1];
      if (!time) return; // Такого быть не должно

      const datetime = new Date(preparedDate.value);
      datetime.setMinutes(time.minutesBeginDay);

      lazyValue.value.datetime = datetime;

      nextTick(emitPointerRect);
    }

    /**
     * Содержит информацию необходимую, для определения
     * ограничений, при перемещении указателя жестом
     */
    const pointerStartMoveInfo = ref<PointerMoveInfo|null>(null);
    function getPointerStartMoveInfo(): PointerMoveInfo|null {
      if (!gridPointer.value || !lazyValue.value) return null;

      const colIndex = gridPointer.value.boxIndex + 1;
      const rowIndex = gridPointer.value.timeIndex + 1;

      const currentEmpty = find(emptySheduleGrid.value, item => {
        return (item.gridColumnStart === colIndex)
          && (rowIndex >= item.gridRowStart && rowIndex < item.gridRowEnd); // TODO: check
      });

      if (!currentEmpty) return null;

      const rootEl = instance?.vnode.el as HTMLElement|undefined;
      const emptyItemKey = `${currentEmpty.gridColumnStart}_${currentEmpty.gridRowStart}`;
      const currentEmptyEl = rootEl?.querySelector<HTMLElement>(`[data-empty-key="${emptyItemKey}"]`);
      const pointerRowStart = gridPointer.value.style.gridRowStart;

      if (!currentEmptyEl) return null;

      return {
        currentEmpty,
        currentEmptyEl,
        pointerRowStart,
      };
    }

    function onStartPointer(/*ev: GestureDetail*/) {
      pointerStartMoveInfo.value = getPointerStartMoveInfo();
    }
    
    let gesturePointer: Gesture|null = null;
    const pointerRef = ref<HTMLDivElement|null>(null);
    watch(pointerRef, el => {
      if (gesturePointer) {
        gesturePointer.destroy();
        gesturePointer = null;
      }

      if (!props.selectEnable) return;
      if (!el) return;

      gesturePointer = createGesture({
        el,
        threshold: 15,
        direction: 'y',
        gestureName: 'pointer-move',
        onMove: onMovePointer,
        onStart: onStartPointer
      });

      gesturePointer.enable();
    });
    //#endregion

    function onClickVisit(visit: CarVisitCollectionItem) {
      emit('click-visit', visit);
    }

    const currentTimePointerTop = computed<string|null>(() => {
      if (timeLine.value.length === 0) return null;

      // Время пройденное в минутах с начала ДНЯ
      const minutes = datetimeToTimeNumber(currentTimeMoment.value.toDate());

      // Время пройденное в минутах с начала первой СМЕНЫ
      const minutesStartShift = (minutes > minmaxMinutes.value.min)
        ? minutes - minmaxMinutes.value.min
        : (minutes + MINUTES_DAY_NUMBER) - minmaxMinutes.value.min;

      const shiftDayMinutes = (minmaxMinutes.value.max - minmaxMinutes.value.min)
        // Fix: из-за особенности отображения краев расписания, первая
        // ячейка по сути является последней и при (max - min) не учитывается
        // Но для отображения полоски текущего времени, это смещение необходимо учесть
        + props.stepMinutes;

      const offsetTopPercent = round(minutesStartShift / shiftDayMinutes * 100, 5);

      return `${offsetTopPercent}%`;
    });

    /**
     * Визиты со статусом Processed не должны визуально отображаться
     * NOTE: Незапланированная доработка
     */
    // const visitsSheduleGridView = computed(() => {
    //   return visitsSheduleGrid.value
    //     .filter(visit => visit.visit.status !== CarVisitStatusEnum.Processed);
    // });

    return {
      loading,
      boxes,
      timeLine,
      // visitsSheduleGridView,
      visitsSheduleGrid,
      emptySheduleGrid,
      showPointer,
      gridPointer,
      onClickPointer,
      onClickGrid,
      onClickFreeTime,
      pointerRef,
      onClickVisit,
      currentTimePointerTop,
      isCurrentDate,
    };
  }
});
</script>

<style lang="scss">
.c-shedule-box {
  $cell-gap: 1px;
  $lite-left-offset: 21px;
  $border-radius: 6px;
  $top-spacer: var(--shedule-grid-col-height, 50px);

  --shedule-grid-col-height: 25px;

  user-select: none;
  position: relative;

  &--loading {
    .c-shedule-grid {
      &__grid {
        // fix: В последних версиях сафари css grid заоптимизированы так, что некорректно вызывается
        // перерендеринг его дочерних элементов, из-за этого во время загрузки, и построения внутренних элементов
        // мы его прячем и после загрузки он показывается, чтобы все новые элементы корректно отобразились.
        // Это баг Safary который я надеюсь в будущем будет исправлен.
        display: none;
      }
    }
  }

  &__time-cell {
    position: relative;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    padding-right: var(--core-spacer-small);
    padding-left: $lite-left-offset;
    padding-bottom: 2px;

    &::after {
      content: '';
      height: 0;
      width: calc(100vw - #{$lite-left-offset});
      position: absolute;
      left: $lite-left-offset;
      bottom: 0;
      border-bottom: 1.6px dashed var(--ion-item-border-color);
      touch-action: none;
      z-index: 2;
    }
  }

  &__loading-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 15;
    background-color: rgba(var(--core-backgroup-color-rgb), 0.12);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .c-shedule-grid {
    &__header {
      background-color: var(--ion-toolbar-background);
      color: var(--core-light);
    }

    &__time {
      row-gap: $cell-gap;
    }

    &__time-row {
      &:nth-child(odd) {
        opacity: 0;
        color: transparent;
      }
    }

    &__grid {
      padding-top: $top-spacer;
      row-gap: $cell-gap;
      column-gap: $cell-gap;
      overflow: auto visible;
    }

    &__content {
      padding-bottom: $top-spacer;
    }

    &__header-col {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-left: var(--core-spacer-small);
      padding-right: var(--core-spacer-small);
    }

    &__header-first-col {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      padding-right: var(--core-spacer-small);
      padding-left: $lite-left-offset;
      color: var(--core-text-muted);
    }
  }

  .c-modal-float {
    &__backgroup {
      pointer-events: none;
      touch-action: none;
    }
  }

  .c-shedule-box-item {
    border-radius: $border-radius;
    background-color: var(--ion-color-secondary);
    color: var(--ion-color-secondary-contrast);
    padding: 0 var(--ion-padding);
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    flex-direction: column;
    position: relative;
    z-index: 2;
    text-transform: uppercase;
    font-size: 13px;

    &.--expired {
      opacity: 0.3;
    }

    &.--processed {
      // background-color: var(--ion-color-success);
      // color: var(--ion-color-success-contrast);
      opacity: 0.3;
    }

    &.--new.--expired {
      background-color: var(--ion-color-danger);
      color: var(--ion-color-danger-contrast);
    }
  }

  .c-shedule-box-empty-item {
    border-radius: $border-radius;
    background-color: var(--ion-card-background);

    &.--expired {
      opacity: 0.4;
    }
  }

  .c-shedule-box-pointer {
    background-color: rgba(var(--ion-color-primary-rgb), 0.5);
    border: 1px solid var(--ion-color-primary);
    border-radius: $border-radius;
  }

  .c-shedule-box-current-time {
    position: absolute;
    height: 1px;
    background-color: var(--ion-color-primary);
    left: 85px;
    width: calc(100vw - 85px);
    top: 0;
    transform: translateY($top-spacer);
    z-index: 3;

    &::before {
      content: '';
      width: 9px;
      height: 9px;
      position: absolute;
      left: -9px;
      top: -4px;
      border-radius: 100px;
      background-color: var(--ion-color-primary);
    }
  }
}
</style>