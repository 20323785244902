import { onMounted, onBeforeUnmount, ref } from 'vue';
import { CameraPreview, CameraPreviewOptions, CameraPreviewPictureOptions } from '@capacitor-community/camera-preview';
import { base64ToBlob, blobToImageBase64, compressImage } from '@/helpers/image';

export interface UseCameraPreviewOptions {
  topOffset?: number;
  bottomOffset?: number;
  autostart?: boolean;
  autostop?: boolean;
  className?: string;
  parentId?: string;
}

export function useCameraPreview(options: UseCameraPreviewOptions = {}) {
  const isInited = ref(false);

  function getOptions(): CameraPreviewOptions {
    return {
      position: 'rear',
      x: 0,
      y: (options.topOffset || 0),
      // width: window.screen.width,
      // height: window.screen.height - (options.topOffset || 0) - (options.bottomOffset || 0),
      paddingBottom: options.bottomOffset || 0,
      parent: options.parentId,
      className: options.className,
      disableAudio: true,
      toBack: true,
      rotateWhenOrientationChanged: false,
      disableExifHeaderStripping: true,
      // lockAndroidOrientation: true,
    };
  }

  async function startPreview() {
    try {
      await CameraPreview.start(getOptions());
      isInited.value = true;
      document.body.classList.add('body-transparent');
    } catch (e) {
      console.error(e);
    }
  }

  async function stopPreview() {
    if (isInited.value === false) return;
    document.body.classList.remove('body-transparent');

    try {
      await CameraPreview.stop();
    } finally {
      isInited.value = false;
    }
  }

  // async function capture(options: CameraPreviewPictureOptions = {}) {
  //   const result = await CameraPreview.captureSample({
  //     quality: 95,
  //     ...options,
  //   });

  //   return result.value as string;
  // }

  async function capture(options: CameraPreviewPictureOptions = {}): Promise<string> {
    const result = await CameraPreview.capture({
      quality: 95,
      ...options,
    });

    return result.value;
  }

  /**
   * Делает снимок с камеры и сразу оптимизирует его размер
   * (1080 на 1080 с качеством 70%).
   * 
   * @returns 
   */
  async function captureOptimizeSize(): Promise<string> {
    const result = await CameraPreview.capture({
      quality: 100,
      width: 1080,
      height: 1080,
    });

    const imageBase64 = 'data:image/png;base64,' + result.value;
    const imageBlob = base64ToBlob(imageBase64);
    if (!imageBlob) {
      throw new Error('Не удалось преобразовать изображение, попробуйте еще раз и если ошибка повториться, обратитесь к администратору');
    }

    const compressedImageBlob = await compressImage(imageBlob, {
      width: 1080,
      height: 1080,
      resize: 'cover',
      quality: 0.7,
      checkOrientation: false,
    });

    const compressedImageBase64 = await blobToImageBase64(compressedImageBlob);
    if (!compressedImageBase64) {
      throw new Error('Не удалось осуществить обратное преобразование изображения, попробуйте еще раз и если ошибка повториться, обратитесь к администратору');
    }

    return compressedImageBase64;
  }

  onMounted(() => {
    if (options.autostart) {
      startPreview();
    }
  });

  onBeforeUnmount(() => {
    if (options.autostop) {
      stopPreview();
    }
  });

  return {
    startPreview,
    stopPreview,
    capture,
    isInited,
    captureOptimizeSize,
  };
}