import { errorToMessageData } from '@/helpers/error';
import { toastController } from '@ionic/vue';
import { ToastOptions as IonToastOptions } from '@ionic/core';
import { toNumber } from 'lodash';

export interface ToastOptions extends Omit<IonToastOptions, "message"|"duration"> {
  defaultHeader?: string;
}

async function toastBase(e: any, options: ToastOptions, duration = 3500) {
  let { message, title, meta } = errorToMessageData(e);

  // fix: при переключении в режим оффлайн, может возникнуть
  if (message.toLocaleLowerCase() === 'network error') {
    message = 'Проверьте подключения к интернету';

    if (!options.header) {
      options.header = 'Нет сети';
    }
  }

  // В meta - полях можно указать как долго будет висеть ошибка
  if (meta.duration) {
    duration = toNumber(meta.duration) || duration;
  }

  const toast = await toastController.create({
    color: 'primary',
    position: 'top',
    header: title || options.defaultHeader,
    ...options,
    message,
    duration,
  });

  return toast.present();
}

export async function errorMessageToast(e: any, duration = 3500, options: ToastOptions = {}) {
  return await toastBase(e, {
    // NOTE: По дизайну приложения, цвет ошибок совпадает с основным цветом (ораньжевым)
    color: 'primary', // color: 'danger',
    buttons: [
      {
        role: 'cancel',
        icon: 'core-close',
        cssClass: 'toast-icon-big'
      }
    ],
    ...options
  }, duration);
}

export async function primaryToast(e: any, duration = 3500, options: ToastOptions = {}) {
  return await toastBase(e, {
    color: 'primary',
    keyboardClose: true,
    ...options
  }, duration);
}

export async function successToast(e: any, duration = 3500, options: ToastOptions = {}) {
  return await toastBase(e, {
    color: 'success',
    keyboardClose: true,
    buttons: [
      {
        role: 'cancel',
        icon: 'core-close',
        cssClass: 'toast-icon-big'
      }
    ],
    ...options
  }, duration);
}

export async function warningToast(e: any, duration = 3500, options: ToastOptions = {}) {
  return await toastBase(e, {
    color: 'warning',
    keyboardClose: true,
    ...options
  }, duration);
}

export async function defaultToast(e: any, duration = 0, options: ToastOptions = {}) {
  return await toastBase(e, {
    color: 'light',
    keyboardClose: true,
    ...options,
    buttons: [
      {
        role: 'cancel',
        icon: 'core-close',
        cssClass: 'toast-icon-big c-toast-close-btn'
      },
      ...options.buttons || []
    ],
  }, duration);
}

const toast = {
  error: errorMessageToast,
  primary: primaryToast,
  success: successToast,
  warning: warningToast,
  message: defaultToast,
};

export default toast;

export function useToast() {
  return toast;
}
