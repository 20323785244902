import { Component, inject } from 'vue';

export enum ChangeProvidedServicesAction {
  Update = 'update',
  UpdateDoers = 'updateDoers',
  SelectCategory = 'selectCategory',
}

export interface ViewInfo {
  component: Component;
  params?: Record<string, any>;
}

export interface ModalNav {
  pushView(component: Component, params?: Record<string, any>): void;
  backView(defaultView?: ViewInfo): void;
  backCount: number;
}

export function useModalNav(): ModalNav {
  const nav = inject<ModalNav>('modalNav');
  if (!nav) throw new Error('Not set to provide for navigation control in a modal window');
  return nav;
}
