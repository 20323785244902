<template>
  <div>
    <div class="mb-3">
      {{ $t('components.app_settings.label_input_cid') }}
    </div>

    <div class="c-form-group">
      <cid-input class="core-input" v-model="apiUrl" />

      <ion-button
        v-if="isDifferentApiUrls"
        :disabled="!validApiUrl"
        @click="applyBaseUrl"
        fill="clear"
        color="transparent"
        class="ion-no-padding"
      >
        {{ $t('components.app_settings.btn_apply_cid') }}
      </ion-button>
    </div>

    <c-divider />

    <!-- TODO: Временно отключено для клиентской версии -->
    <!-- <div class="mb-3">
      {{ $t('components.app_settings.label_input_language') }}
    </div>
    <locale-input class="core-input" />

    <c-divider /> -->

    <div class="mb-3">
      {{ $t('components.app_settings.label_network_speed') }}
    </div>
    <network-indicator />

    <!-- Использовать функцию распознавания номера -->
    <div class="number-recognition-item">
      <c-divider />

      <div class="number-recognition-item__row">
        <div class="number-recognition-item__col-title">
          {{ $t('components.app_settings.label_number_recognition') }}
        </div>

        <div class="number-recognition-item__col-switch">
          <ion-toggle class="core-toggle" v-model="useNumberRecognition" />
        </div>
      </div>

      <p class="text-muted">
        {{ $t('components.app_settings.description_number_recognition') }}
      </p>
    </div>

    <!-- Использовать функцию предварительной записи -->
    <div class="number-recognition-item">
      <c-divider />

      <div class="number-recognition-item__row">
        <div class="number-recognition-item__col-title">
          {{ $t('components.app_settings.label_preentry') }}
        </div>

        <div class="number-recognition-item__col-switch">
          <ion-toggle class="core-toggle" v-model="usePreentry" />
        </div>
      </div>

      <p class="text-muted">
        {{ $t('components.app_settings.description_preentry') }}
      </p>
    </div>
    
    <!-- Использовать функцию Фонарика -->
    <div class="number-recognition-item" v-if="flashlightIsAvailable">
      <c-divider />

      <div class="number-recognition-item__row">
        <div class="number-recognition-item__col-title">
          {{ $t('components.app_settings.label_flashlight') }}
        </div>

        <div class="number-recognition-item__col-switch">
          <ion-toggle class="core-toggle" v-model="useFlashlightValue" />
        </div>
      </div>
      
      <p class="text-muted">
        {{ $t('components.app_settings.description_flashlight') }}
      </p>
    </div>

    <c-divider class="mb-0" />

    <ion-button @click="joinCommunity" :href="communityLink" target="_blank" fill="clear" color="transparent" class="ion-no-padding">
      {{ $t('components.app_settings.btn_community') }}
    </ion-button>

    <c-divider class="my-0" />

    <ion-button @click="contactSupport" :href="supportChatUrl" fill="clear" color="transparent" class="ion-no-padding"  target="_blank">
      {{ $t('components.app_settings.btn_support_chat') }}
    </ion-button>

    <c-divider class="my-0" />

    <ion-button fill="clear" color="transparent" class="ion-no-padding" @click="clearCacheAction">
      {{ $t('components.app_settings.btn_clear_cache') }}
    </ion-button>

    <c-divider class="my-0" />

    <ion-button @click="viewPrivacyPolicy" :href="privacyPolicyLink" target="_blank" fill="clear" color="transparent" class="ion-no-padding">
      {{ $t('components.app_settings.btn_view_privacy_policy') }}
    </ion-button>

    <!-- <template v-if="showDevLinkPage">
      <c-divider class="my-0" />

      <ion-button fill="clear" color="transparent" class="ion-no-padding" @click="toDevPage">
        Development page
      </ion-button>
    </template> -->
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import { useApp } from '@/composables/useApp';
import { testValidBaseUrl } from '@/utils/domain';
import { useNavManager } from '@/composables/useNavManager';
import config from '@/config';
import NetworkIndicator from '@/components/app-service/NetworkIndicator.vue';
import { useRouter } from 'vue-router';
import { useMetrica } from '@/composables/useMetrica';
import CidInput from './CidInput.vue';
import { useAlert } from '@/composables/alert';
import { useI18n } from 'vue-i18n';
import { useFlashlight } from '@/composables/flashlight';
// import toast from '@/ext/toast';
// import { createProgressInformation } from '@/composables/progress';
// import LocaleInput from './LocaleInput.vue';

export default defineComponent({
  components: {
    NetworkIndicator,
    CidInput,
    // LocaleInput,
  },

  emits: ['logout', 'clear-cache', 'close-settings'],

  setup(props, { emit }) {
    const app = useApp();
    const router = useRouter();
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();
    const { createAlertConfirm } = useAlert();
    const { t } = useI18n();

    let currentApiUrl = app.getApiDomain();
    const apiUrl = ref<string>(currentApiUrl);
    const isDifferentApiUrls = computed(() => apiUrl.value !== currentApiUrl);
    const validApiUrl = computed(() => testValidBaseUrl(apiUrl.value));

    async function applyBaseUrl() {
      try {
        // После смены url необходимо заного авторизоваться
        await app.store.user.logout();
      } finally {
        app.setApiDomain(apiUrl.value);
        apiUrl.value = currentApiUrl = app.getApiDomain();

        navManager.navigate({
          routerDirection: 'root',
          routerLink: { name: 'login' },
        });
      }

      emitEvent('app/settings/set-domain', { domain: apiUrl.value });
      emit('close-settings');
    }

    const clearCacheAction = createAlertConfirm({
      header: t('components.app_settings.alert_clear_cache_header'),
      message: t('components.app_settings.alert_clear_cache_message'),
      confirmBtnText: t('components.app_settings.alert_clear_cache_btn_confirm'),
      async confirm() {
        emitEvent('app/settings/cache-clear');
        await app.clearCacheJob();
        emit('clear-cache');
      }
    });

    const useNumberRecognition = app.store.config.useNumberRecognition;
    watch(useNumberRecognition, status => {
      const textStatus = status ? 'on' : 'off';
      emitEvent(`app/settings/number-recognition/${textStatus}`);
    });

    const usePreentry = app.store.config.usePreentry;
    watch(usePreentry, status => {
      const textStatus = status ? 'on' : 'off';
      emitEvent(`app/settings/preentry/${textStatus}`);
    });

    const { isAvailable: flashlightIsAvailable } = useFlashlight();
    const useFlashlightValue = app.store.config.useFlashlight;
    watch(useFlashlightValue, status => {
      const textStatus = status ? 'on' : 'off';
      emitEvent(`app/settings/flashlight/${textStatus}`);
    });

    const showDevLinkPage = computed(() => /\/\/cid828\.core12\.ru/.test(app.getApiDomain()));
    function toDevPage() {
      router.push({ name: 'dev' });
      emit('close-settings');
    }

    const contactSupport = () => emitEvent('app/settings/support');
    const viewPrivacyPolicy = () => emitEvent('app/settings/view-privacy-policy');
    const joinCommunity = () => emitEvent('app/settings/join-community');

    return {
      apiUrl,
      isDifferentApiUrls,
      validApiUrl,
      applyBaseUrl,
      useNumberRecognition,
      clearCacheAction,
      showDevLinkPage,
      toDevPage,
      supportChatUrl: config.supportChatUrl,
      privacyPolicyLink: config.privacyPolicyLink,
      contactSupport,
      viewPrivacyPolicy,
      usePreentry,
      useFlashlightValue,
      flashlightIsAvailable,
      communityLink: config.communityLink,
      joinCommunity,
    };
  }
});
</script>

<style lang="scss">
.number-recognition-item {
  &__row {
    display: flex;
  }

  &__col-title {
    flex: 1 1 0%;
  }
}
</style>