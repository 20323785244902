<template>
  <ion-footer>
    <slot name="before" />
    
    <ion-toolbar class="c-footer-navigation-toolbar">
      <div class="c-tab-bar">
        <div
          v-for="(item, key) in navItems"
          :key="key"
          class="c-tab-button"
          :class="{ 'c-tab-button--active': key === currentTab }"
          @click="toNavigate(item.to)"
        >
          <ion-icon :icon="item.icon" />
          <ion-label>{{ item.text }}</ion-label>
        </div>
      </div>
    </ion-toolbar>
  </ion-footer>
</template>

<script lang="ts">
import { RouteLocationRaw, useRoute } from 'vue-router';
import { defineComponent, computed } from 'vue';
import { useNavManager } from '@/composables/useNavManager';
import { useMetrica } from '@/composables/useMetrica';
import { get } from 'lodash';
import { useI18n } from 'vue-i18n';
import checkList from '@/icons/check-list.svg';

export interface NavigationItem {
  to: RouteLocationRaw;
  icon: string;
  text: string;
}

export type NavigationCollection = Record<string, NavigationItem>;

export default defineComponent({
  setup() {
    const route = useRoute();
    const currentTab = route.meta.tab as string|undefined;
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    const navItems = computed<NavigationCollection>(() => ({
      visits: {
        to: { name: 'visits' },
        icon: checkList,
        text: t('components.app_footer.menu_item_visits'),
      },
      pricelist: {
        to: { name: 'pricelist' },
        icon: 'core-price',
        text: t('components.app_footer.menu_item_pricelist'),
      },
      market: {
        to: { name: 'market' },
        icon: 'core-bag',
        text: t('components.app_footer.menu_item_market'),
      },
      pos: {
        to: { name: 'pos' },
        icon: 'core-cash',
        text: t('components.app_footer.menu_item_pos'),
      },
      reports: {
        to: { name: 'reports' },
        icon: 'core-statistic',
        text: t('components.app_footer.menu_item_reports'),
      },
    }));

    function toNavigate(to: RouteLocationRaw) {
      const routeName = get(to, 'name', 'unknown')

      emitEvent(`navigation/footer/${routeName}`);

      navManager.navigate({
        routerLink: to,
        routerDirection: 'root',
      });
    }

    return {
      navItems,
      currentTab,
      toNavigate,
    };
  },
});
</script>

<style lang="scss">
.c-tab-bar {
  display: flex;
  width: 100%;
  box-sizing: content-box !important;
  justify-content: center;
  padding-bottom: 10px;
}

.c-tab-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  color: inherit;
  text-decoration: inherit;
  padding: 4px 6px;
  max-width: 240px;
  flex: 1 1 0%;
  height: 68px;
  user-select: none;
  cursor: pointer;

  &:not(#{&}--active):hover {
    opacity: 0.75;
  }

  &--active {
    color: var(--ion-color-primary);
  }

  ion-badge {
    position: absolute;
    z-index: 1;
    padding: 1px 6px;
    left: calc(50% + 6px);
    top: 4px;
    height: auto;
    font-size: 12px;
    line-height: 16px;
  }

  ion-label {
    font-size: var(--core-font-size-xsmall);
    text-transform: uppercase;
  }

  ion-icon {
    font-size: 33px;
    margin-bottom: 2px;
  }
}

.c-footer-navigation-toolbar {
  .c-keyboard-opened & {
    display: none;
  }
}
</style>