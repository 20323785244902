<template>
  <div :class="{
    'c-nav-cards-list__item': true,
    'c-nav-cards-list__item--disabled': disabled
  }">
    <div class="c-nav-cards-list__item-inner">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    disabled: null
  }
});
</script>

<style lang="scss">
</style>