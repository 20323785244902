import mitt, { Emitter } from 'mitt';
import { App, inject } from '@vue/runtime-core';
import { onUnmounted } from 'vue';

type AppEvents = {
  [ev: string]: any;
};

export type AppEventBus = Emitter<AppEvents>;

export function createEventsBus(): AppEventBus {
  return mitt<AppEvents>();
}

export function useEventBus() {
  return inject('eventBus') as AppEventBus;
}

export function useEventEmit() {
  const bus = useEventBus();
  return bus?.emit;
}

export function onEventApp(eventName: string, handler: (...params: any) => void) {
  const bus = useEventBus();

  bus.on(eventName, handler);
  onUnmounted(() => {
    bus.off(eventName, handler);
  });
}

export const EventBusPlugin = {
  install(app: App) {
    const bus = createEventsBus();
    app.provide('eventBus', bus);

    app.config.globalProperties.$appEventBus = bus;
    app.config.globalProperties.$appEventEmit = bus.emit;
  }
};

export default EventBusPlugin;
