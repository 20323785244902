import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-text-center ion-padding text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_nav_cards_list_item = _resolveComponent("c-nav-cards-list-item")!
  const _component_c_nav_cards_list = _resolveComponent("c-nav-cards-list")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!

  return (_openBlock(), _createBlock(_component_c_content_loading, {
    action: _ctx.loadPointType,
    class: "c-services-categories"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_c_nav_cards_list, { "tablet-adaptive": _ctx.tabletAdaptive }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (category) => {
            return (_openBlock(), _createBlock(_component_c_nav_cards_list_item, {
              key: category.id,
              onClick: ($event: any) => (_ctx.selectCategory(category)),
              class: _normalizeClass({ '--seleted': (category.id == _ctx.selectedCatId) })
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(category.name), 1)
              ]),
              _: 2
            }, 1032, ["onClick", "class"]))
          }), 128)),
          (_ctx.isEmptyCategories)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, " Нет категорий "))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["tablet-adaptive"])
    ]),
    _: 1
  }, 8, ["action"]))
}