<template>
  {{ item.name }}

  <ion-chip color="success" class="chip-size-small chip-filled my-0 mr-0 ml-2">
    -{{ item.percent }}%
  </ion-chip>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { Discount } from '@/repositories/Models/Discount';

export default defineComponent({
  inheritAttrs: false,
  props: {
    item: {
      type: Object as PropType<Discount>,
      required: true,
    }
  },
});
</script>