<template>
  <div class="c-group-partner-select-box">
    <c-modal-action-header>
      {{ $t('components.group_partner_select_box.modal_title') }}
    </c-modal-action-header>

    <c-modal-content>
      <div class="core-searchbar-mobile core-searchbar-mobile-fixed-wrapper ion-padding">
        <ion-searchbar
          class="core-searchbar"
          inputmode="search"
          :placeholder="$t('components.group_partner_select_box.input_search')"
          clear-icon="close-outline"
          search-icon="core-search"
          :debounce="300"
          @ionChange="onSearchChange"
          v-model="searchWords"
        />
      </div>

      <c-modal-select>
        <c-spinner
          v-if="loading"
          class="mt-4 mb-3"
          loading-text="Загружаем контрагентов..."
        />
        <template v-else>
          <div v-if="!listFiltered.length" class="ion-padding ion-text-center text-muted">
            {{ list.length
              ? $t('components.group_partner_select_box.options_not_found')
              : $t('components.group_partner_select_box.options_empty')
            }}
          </div>

          <c-modal-select-option
            :selected="!innerValue"
            @click="clickItem()"
          >
            {{ $t('components.group_partner_select_box.option_empty') }}
          </c-modal-select-option>
          
          <c-modal-select-option
            v-for="item in listFiltered"
            :key="item.id"
            :selected="item.id == innerValue"
            @click="clickItem(item)"
          >
            {{ item.name }}
          </c-modal-select-option>
        </template>
      </c-modal-select>

      <div class="core-modal-searchbar-spacer" />
    </c-modal-content>
  </div>
</template>

<script lang="ts">
import { useStore } from '@/composables/useApp';
import { CarGroupCollectionItem } from '@/repositories/Models/CarGroup';
import { modalController } from '@ionic/vue';
import { defineComponent, onMounted, ref, DeepReadonly, PropType } from 'vue';
import CModalSelectOption from '../core/CModalSelectOption.vue';
import { SetGroupValueCallback } from './GroupPartnerInputCard.vue';
import { filterSerchWords } from '@/helpers/filterEntities';

// TODO: Избавиться от данного компонента в пользу общего
export default defineComponent({
  components: { CModalSelectOption },
  props: {
    selectValue: {
      type: Function as PropType<SetGroupValueCallback>,
      required: false,
    },
    value: {
      type: [String, Number] as PropType<string|number>,
      default: ''
    },
  },
  setup(props) {
    const { group } = useStore();
    const list = ref<DeepReadonly<CarGroupCollectionItem[]>>([]);
    const loading = ref(false);
    const innerValue = ref(props.value);

    async function loadGroupsPartners() {
      loading.value = true;

      try {
        list.value = await group.getAll();
        onSearchChange();
      } finally {
        loading.value = false;
      }

      // TODO: Обработать ошибку
    }

    function clickItem(group?: CarGroupCollectionItem) {
      const selectedGroupId = group ? group.id : '';
      innerValue.value = selectedGroupId;

      if (props.selectValue) {
        props.selectValue(selectedGroupId, group);
      }

      modalController.dismiss();
    }

    //#region Search (Filter)
    const searchWords = ref('');
    const listFiltered = ref<DeepReadonly<CarGroupCollectionItem[]>>([]);
    function onSearchChange() {
      const s = searchWords.value.trim();
      if (!s) {
        listFiltered.value = list.value;
        return;
      }

      listFiltered.value = filterSerchWords([ ...list.value], 'name', searchWords.value);
    }
    //#endregion

    onMounted(() => loadGroupsPartners());

    return {
      list,
      innerValue,
      clickItem,
      onSearchChange,
      searchWords,
      listFiltered,
      loading,
    };
  },
});
</script>

<style lang="scss">
</style>