<template>
  <ion-page>
    <app-header-new-visit
      :title="$t('views.visit_new_discounts.title')"
      :progress="1"
    />

    <ion-content>
      <tablet-side-layout>
        <template #side>
          <car-side-info
            class="ion-padding"
            :car-id="carId"
          >
            <template #preview-image>
              <visit-preview-images :visit-id="tempVisitId" :is-new-preentry="isPreentry" />
            </template>
          </car-side-info>
        </template>

        <div class="ion-padding">
          <c-card-header class="ion-hide-md-down">
            {{ $t('views.visit_new_discounts.discounts_card_title') }}
          </c-card-header>

          <ion-card class="padding-x-adaptive">
            <services-provide-choice-discounts state-inject-key="visitState" />
          </ion-card>
        </div>

        <div class="fixed-actions-spacer" />
      </tablet-side-layout>

      <!-- eslint-disable-next-line -->
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <div class="visits-fixed-actions">
          <ion-button
            color="primary"
            shape="round"
            class="m-0 opacity-1"
            @click="submitVisit"
            :disabled="btnCreateDisabled"
          >
            {{ btnCreateText }}
          </ion-button>
        </div>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, provide, computed, nextTick } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import CarSideInfo from '@/components/car/CarSideInfo.vue';
import { useApp } from '@/composables/useApp';
import ServicesProvideChoiceDiscounts from '@/components/service/provide-form/ServicesProvideChoiceDiscounts.vue';
import { useToast } from '@/composables/toast';
import { useNavManager } from '@/composables/useNavManager';
import { useCreateVisitStep } from '@/composables/visit';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
import { get } from 'lodash';
import { CarVisitBodyRequest } from '@/repositories/Models/CarVisit';
import { errorToMessageData } from '@/helpers/error';
import { isClientError } from '@/repositories';
import VisitPreviewImages from '@/components/visit/VisitPreviewImages.vue';
import { useVisitImages } from '@/composables/visit/visitImages';
import uuid from 'uuid';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    CarSideInfo,
    ServicesProvideChoiceDiscounts,
    VisitPreviewImages,
  },
  setup() {
    const toast = useToast();
    const { store } = useApp();
    const navManager = useNavManager();
    const { emitEvent, emitError } = useMetrica();
    const { t } = useI18n();
    const {
      carId,
      generateBodyVisit,
      clearVisitState,
      visitType,
      isPreentry,
      visitState,
      isUpdateVisitCounterparty,
      visitId: tempVisitId,
    } = useCreateVisitStep({
      stateProvideKey: 'visitState'
    });

    const { uploadAll, updateVisitId } = useVisitImages({ visitId: tempVisitId });

    provide('visitAction', visitType);

    const idempotenceId = uuid.v1();
    const saveLoading = ref(false);
    const visitSaved = ref(false);

    async function uploadVisitPhoto(visitId: string) {
      if (isPreentry) {
        return; // В предварительной записи отсутсвуют фотографии, которые необходимо загружать. 
      }

      try {
        // [!] Возможно стоит сделать какой-то retry (попытку загрузить повторно)
        await uploadAll(visitId, {
          autoremove: true,
          showError: false,
          chunkCount: 3,
        });
      } catch (e) {
        // В случае ошибки обновим идентификатор, чтобы позже можно было загрузить через менеджер
        await updateVisitId(visitId);

        toast.error(t('views.visit_new_discounts.error_fail_upload_photo'));

        // Отправляем ошибку в метрику
        let { message } = errorToMessageData(e);

        if (isClientError(e) && e.code) {
          message = `HTTP(${e.code}): ${message}`;
        }

        emitError('visit_upload_photo', message);
      }
    }

    /**
     * Прикрепляет к пользователю категории, которые были выбраны в этом заказе
     */
    async function syncLastVisitCategories() {
      const catId = get(visitState.selectedTypeAndCategories, '0.catId', null);

      // Если категория совпадает с ранее выбранной, то обновлять значение не требуется
      if (!catId || catId === visitState.meta.lastCategoryId) {
        return;
      }
    
      return await store.car.update(visitState.body.car?.id as string, {
        id: visitState.body.car?.id as string,
        priceCategories: [ { id: catId } ]
      });
    }

    async function submitVisit() {
      // Обновление контрагента (на важно что за заказ)
      if (isUpdateVisitCounterparty) {
        await updateCounterpartyVisit();
      }

      // Создание нового заказа (магазин/предварительная запись/новый визит)
      else {
        await createVisit();
      }
    }

    /**
     * Создание нового визита
     */
    async function createVisit() {
      saveLoading.value = true;

      try {
        syncLastVisitCategories(); // async

        const bodyVisit = await generateBodyVisit();
        bodyVisit.idempotenceId = idempotenceId;
        
        const res = await store.visit.create(bodyVisit);

        visitSaved.value = true;

        // В предварительной записи, загрузка фото во время создания не требуется
        if (!isPreentry) {
          if (res.sendingDelayed) {
            // В оффлайн режиме просто обновим временный идентификатор визита на постоянный,
            // позже во время автовыгрузки оффлайн заказа, они так-же загрузятся.
            await updateVisitId(res.entityId);
          } else {
            await uploadVisitPhoto(res.entityId);
          }
        }

        nextTick(() => {
          clearVisitState();

          emitEvent(`visit/${visitType}/created`);

          navManager.navigate({
            routerDirection: 'root',
            routerLink: {
              name: 'visits',
            },
          });
        });
      } catch (e) {
        toast.error(e);
        visitSaved.value = false;

        // Отправляем ошибку в метрику
        let { message } = errorToMessageData(e);

        if (isClientError(e) && e.code) {
          message = `HTTP(${e.code}): ${message}`;
        }

        emitError('visit_not_created', message);
      } finally {
        saveLoading.value = false;
      }
    }

    /**
     * Обновдение контрагента в существующем визите
     */
    async function updateCounterpartyVisit() {
      saveLoading.value = true;

      try {
        syncLastVisitCategories(); // async

        const fullBodyVisit = await generateBodyVisit();

        // Такое маловерояно, но при определенной сноровке можно получить
        if (!fullBodyVisit.id) {
          toast.error('Невозможно обновить запись, отсутствует идентификатор. Обратитесь к администратору');
          return;
        }

        const updateBody: CarVisitBodyRequest = {
          group: fullBodyVisit.group,
          providedServices: fullBodyVisit.providedServices,
        };

        const res = await store.visit.update(fullBodyVisit.id, updateBody);
        if (res.sendingDelayed) {
          console.log('Интернет недоступен, данные сохранены, и синхрнизируются при поялении интернета');
        }

        visitSaved.value = true;

        // NOTE: Обновление фото при смене контрагента не требуется.
        
        nextTick(() => {
          clearVisitState();

          emitEvent(`visit/counterparty/update`);

          // Вернемся обратно в карточку
          navManager.navigate({
            routerDirection: 'root',
            routerLink: {
              name: 'visit-single',
              params: { visitId: res.entityId },
            },
          });
        });
      } catch (e) {
        toast.error(e);
        visitSaved.value = false;
      } finally {
        saveLoading.value = false;
      }
    }

    const btnCreateText = computed(() => {
      if (saveLoading.value || visitSaved.value) {
        return t('views.visit_new_discounts.btn_loading_create_visit');
      }

      return (isUpdateVisitCounterparty)
        ? t('views.visit_new_discounts.btn_update_couterparty')
        : t('views.visit_new_discounts.btn_create_visit');
    });

    const btnCreateDisabled = computed(() => {
      return (saveLoading.value || visitSaved.value);
    });

    return {
      carId,
      submitVisit,
      saveLoading,
      btnCreateText,
      btnCreateDisabled,
      tempVisitId,
      isPreentry,
    };
  }
});
</script>

<style lang="scss">
</style>