<template>
  <c-content-loading :action="loadPointType" class="c-services-categories">
    <c-nav-cards-list :tablet-adaptive="tabletAdaptive">
      <c-nav-cards-list-item
        v-for="category in categories"
        :key="category.id"
        @click="selectCategory(category)"
        :class="{ '--seleted': (category.id == selectedCatId) }"
      >
        {{ category.name }}
      </c-nav-cards-list-item>

      <div v-if="isEmptyCategories" class="ion-text-center ion-padding text-muted">
        Нет категорий
      </div>
    </c-nav-cards-list>
  </c-content-loading>
</template>

<script lang="ts">
import { defineComponent, PropType, ref, DeepReadonly, computed} from 'vue'
import { ThisClientPointTypeItem, PointTypeCategoryItem } from '@/repositories/Models/Point'
import { useStore } from '@/composables/useApp'
import { isEmpty } from 'lodash'

export default defineComponent({
  emits: ['type-loaded', 'select-category'],

  props: {
    typeId: {
      type: [String, Number] as PropType<string|number>,
      required: true,
    },
    selectedCatId: {
      type: [String, Number] as PropType<string|number>,
      required: false,
    },
    cardsListClasses: {
      type: null,
      default: ''
    },
    tabletAdaptive: {
      type: Boolean,
      default: true,
    },
  },

  setup(props, { emit }) {
    const store = useStore();

    const pointType = ref<DeepReadonly<ThisClientPointTypeItem|null>>(null);
    const categories = computed<DeepReadonly<PointTypeCategoryItem[]>>(() => {
      return pointType.value?.carCategories || [];
    });
    const isEmptyCategories = computed(() => isEmpty(categories.value));

    async function loadPointType() {
      pointType.value = await store.point.filterTypePoint(+props.typeId) || null;
      emit('type-loaded', pointType.value);
    }

    async function selectCategory(category: DeepReadonly<PointTypeCategoryItem>) {
      emit('select-category', category);
    }

    return {
      categories,
      loadPointType,
      selectCategory,
      isEmptyCategories,
    };
  }
});
</script>

<style lang="scss">
.c-services-categories {
  .c-nav-cards-list {
    &__item {
      &.--seleted {
        color: var(--core-green);
      }
    }
  }
}
</style>