import { renderSlot as _renderSlot, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-tab-bar" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_footer = _resolveComponent("ion-footer")!

  return (_openBlock(), _createBlock(_component_ion_footer, null, {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "before"),
      _createVNode(_component_ion_toolbar, { class: "c-footer-navigation-toolbar" }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.navItems, (item, key) => {
              return (_openBlock(), _createElementBlock("div", {
                key: key,
                class: _normalizeClass(["c-tab-button", { 'c-tab-button--active': key === _ctx.currentTab }]),
                onClick: ($event: any) => (_ctx.toNavigate(item.to))
              }, [
                _createVNode(_component_ion_icon, {
                  icon: item.icon
                }, null, 8, ["icon"]),
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(item.text), 1)
                  ]),
                  _: 2
                }, 1024)
              ], 10, _hoisted_2))
            }), 128))
          ])
        ]),
        _: 1
      })
    ]),
    _: 3
  }))
}