import { CarBodyRequest, CarResponse, CarNumberTypeEnum } from '@/repositories/Models/Car'
import { pick, keyBy, flatten, uniqueId, get, values } from 'lodash'
import { StoreInstance } from '@/store'
import { asyncQueryErrorIgnore } from '@/utils/asyncQuery'
import { ThisClientPointTypeItem } from '@/repositories/Models/Point'
import { DoerCollectionItem, UserRoleTypeEnum } from '@/repositories/Models/User'
import { CarGroup, CarGroupCollectionItem } from '@/repositories/Models/CarGroup'
import { DeepReadonly } from '@vue/reactivity'
import { Service, ServiceNomenclature } from '@/repositories/Models/Service'
import { Category } from '@/repositories/Models/Category'
import { Discount, DiscountTypeEnum } from '@/repositories/Models/Discount'
import uuid from 'uuid'

import {
  CarVisitBodyRequest,
  CarVisitProvideService,
  CarVisitResponse,
  CarVisitVeracityEnum,
  CarVisitStatusEnum,
  CarVisitFrame
} from '@/repositories/Models/CarVisit'

/**
 * Воссоздает данные ответа из тела запроса для автомобиля
 * 
 * @param body тело запроса
 * @returns 
 */
export function recreateCarResponseFromBody(body: CarBodyRequest): CarResponse {
  return {
    ...getEmptyCarResponse(body.id),
    ...pick(body, [
      'id', 'number', 'numberType',
      'comment', 'ownerName', 'ownerPhone'
    ]),
  };
}

export async function recreateVisitResponseFromBody(body: CarVisitBodyRequest, store: StoreInstance): Promise<CarVisitResponse> {
  const creationDate = body.creationDate || (new Date()).toISOString();
  const isPayable = false; // ...

  //#region Query data
  const carPromise = asyncQueryErrorIgnore(async () => {
    const cache = await store.car.getCar(get(body, 'car.id', '') as string);
    return cache.data;
  }, getEmptyCarResponse(body.car?.id));

  const pointTypesPromise = asyncQueryErrorIgnore(async () => {
    const thisClient = await store.point.getThisClient();
    return thisClient.pointTypes;
  }, [] as DeepReadonly<ThisClientPointTypeItem[]>);

  const doersPromise = asyncQueryErrorIgnore(async () => {
    const cache = await store.user.getDoers();
    return cache.data;
  }, [] as DoerCollectionItem[]);

  const groupsPromise = asyncQueryErrorIgnore(
    () => store.group.getAll(),
    [] as CarGroupCollectionItem[]
  );

  const informationProvidedServicesPromise = store.service.getProvidedServicesInformation(
    body.providedServices || [],
    body.group?.id,
    body.car?.id,
    true
  );

  const [car, pointTypes, doers, groups, informationProvidedServices] = await Promise.all([
    carPromise, pointTypesPromise, doersPromise, groupsPromise, informationProvidedServicesPromise
  ]);
  //#endregion Query data

  //#region Index data
  const pointTypesIndex = keyBy(pointTypes, 'id');
  const categoriesIndex = keyBy(flatten(pointTypes.map(p => values(p.carCategories))), 'id');
  const doersIndex = keyBy(doers, 'id');
  const groupIndex = keyBy(groups, 'id');
  //#endregion Index data

  const groupId = body.group?.id;
  const group = groupIndex[`${groupId}`] || getEmptyGroup(groupId);

  let providedServices: CarVisitProvideService[] = [];
  for (let ips of informationProvidedServices) {
    const service = ips.service || getEmptyService(ips.provide.service.id);
    const category: Category = categoriesIndex[ips.provide.carCategory.id]
      ? { ...categoriesIndex[ips.provide.carCategory.id], sort: 0 }
      : getEmptyCategory(+uniqueId());

    let discountCampaign: Discount|undefined = undefined;

    if (ips.provide.discountCampaign) {
      discountCampaign = await store.discount.findById(ips.provide.discountCampaign.id) || undefined;

      if (!discountCampaign) {
        discountCampaign = {
          id: ips.provide.discountCampaign.id,
          percent: ips.provide.discountPercent || 0,
          name: '',
          impactsManagerComission: false,
          impactsDoerComission: false,
          activated: true,
          type: DiscountTypeEnum.ManualDiscount,
        };
      }
    }

    providedServices.push({
      id: uniqueId('restore_id_'),
      managerReward: 0,
      service: {
        prices: [],
        category,
        ...service
      } as Service,

      ...pick(ips.provide, [
        'id',
        'qty',
        'singleItemPrice',
        'totalPrice',
        'managerReward',
        'discountPercent',
      ]),

      pointType: pointTypesIndex[ips.provide.pointType.id],
      carCategory: category,
      doers: ips.provide.doers.map(doer => doersIndex[doer.id] || getEmptyDoer(doer.id)),
      discountCampaign,
    });
  }

  const price = providedServices.reduce((s, ps) => s + ps.totalPrice, 0);

  // Достаем кэшированные фотографии визита
  let frames: CarVisitFrame[] = [];
  try {
    const gallery = await store.visit.db.visitGallery.get(body.id!);

    if (gallery) {
      frames = gallery.items.map<CarVisitFrame>(item => { // TODO: Написать под это дело отдельный хэлпер
        return {
          id: `temp_frame_id_${item.id}`,
          image: {
            id: `temp_image_id_${item.id}`,
            path: `temp_image_name_${item.id}`,
            url: item.imageBase64,
            imageUrl: '/',
            thumbUrl: item.imageBase64,
          },
        };
      });
    }
  } catch (e: any) {
    // TODO: devlog
  }

  return {
    ...getEmptyCarVisitResponse(),
    creationDate,
    price,
    suspects: [],
    suspicious: false,
    status: CarVisitStatusEnum.New,

    ...pick(body, [
      'id',
      'creationDate',
      'processedDate',
      'finishedDate',
      'status',
      'price',
      'payed',
      'payedByScores',
      'checkup',
    ]),

    frames,
    car,
    providedServices,
    group,
    isPayable,

    isOffline: true,
  };
}

//#region Entity empty data
/**
 * Вернет пустые данные об автомобиле
 * @param id 
 * @returns 
 */
 export function getEmptyCarResponse(id?: string): CarResponse {
  const number = id || '';
  if (!id) id = 'empty_' + uuid.v1();

  return {
    id,
    number,
    subscribe: true,
    numberType: CarNumberTypeEnum.Unknown,
    priceCategories: [],
    discountAccount: undefined,
  };
}

/**
 * Вернет пустые данные о контрагенте
 * 
 * @param id 
 * @returns 
 */
export function getEmptyGroup(id?: number): CarGroup {
  return {
    id: id || 0,
    name: '{Неизвестно}',
    deleted: false
  };
}

/**
 * Вернет пустые данные о услуге
 * 
 * @param id 
 * @returns 
 */
export function getEmptyService(id?: number): Service {
  return {
    id: id || 0,
    name: '{Неизвестно}',
    isProduct: false,
    sort: 0,
    fluid: false,
    repeatable: true,

    // В данном приложении в них нет необходимости
    category: {} as Category,
    nomenclature: {} as ServiceNomenclature,
  };
}

/**
 * Вернет пустые данные о исполнителе
 * 
 * @param id 
 * @returns 
 */
export function getEmptyDoer(id?: number): DoerCollectionItem {
  return {
    id: id || 0,
    name: '{Неизвестно}',
    salary: 0,
    deleted: false,
    type: UserRoleTypeEnum.Doer,
    percentRulesInterval: { min: 0, max: 0 },
  }
}

/**
 * Вернет минимальные пустые данные для визита
 * 
 * @param id 
 * @returns 
 */
export function getEmptyCarVisitResponse(id?: string): CarVisitResponse {
  if (!id) id = 'empty_' + uuid.v1();

  return {
    id,
    isPayable: false,
    suspicious: false,
    veracity: CarVisitVeracityEnum.Weak,
    historyMaxPrice: 0,
    stations: [],
    managerReward: 0,
  };
}

/**
 * Ветрнет пустую информацию о категории
 * 
 * @param id 
 * @returns 
 */
export function getEmptyCategory(id?: number): Category {
  return {
    id: id || 0,
    name: '{Неизвестно}',
    sort: 0,
  }
}
//#endregion