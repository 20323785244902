<!-- 
  Большая шапка, которая используется на главных страницах приложения
 -->
<script lang="ts" setup>
import { PropType, defineProps } from 'vue';
import { useSettingsApp, useSettingsUser } from '@/composables/useSettingsApp';
import { networkStatusColor } from '@/helpers/network';
import { useMetrica } from '@/composables/useMetrica';
import { isTabletDown } from '@/helpers/adaptive';
import { useNotificationsModal, useNotificationUnreadCount } from '@/composables/notifications';

defineProps({
  title: {
    type: String as PropType<string>,
  },
});

const { emitEvent } = useMetrica();
const { openSettings: openSettingsApp } = useSettingsApp();
const { openModal: openNotificationsModal } = useNotificationsModal();
const { unreadCount } = useNotificationUnreadCount();
const {
  openUserPopover,
  user,
  fullName,
} = useSettingsUser();

function openSettings(ev?: CustomEvent) {
  emitEvent('app/settings/open');
  return openSettingsApp(ev);
}

function openUserInfo(ev?: CustomEvent) {
  emitEvent('app/user/view');
  return openUserPopover(ev);
}
</script>

<template>
  <app-header>
    <template #title>
      <div class="header-big-title">
        Core12<span>{{ title }}</span>
      </div>
    </template>

    <template #end>
      <!-- Полное имя пользователя (только планшеты и десктопы) -->
      <c-transparent-button class="header-user-btn" v-if="(user && !isTabletDown)" @click="openUserInfo">
        {{ fullName }}
      </c-transparent-button>

      <!-- NOTE: Да уже не совсем before, но пока оставлено так, чтобы не ломать логику отображения компонентов -->
      <slot name="buttons-before"></slot>

      <c-transparent-button @click="openNotificationsModal" data-action="settings-button">
        <ion-icon name="core-chatbox" class="icon-medium" />
        <span v-if="unreadCount.count > 0" class="icon-badge-notification"></span>
      </c-transparent-button>

      <!-- Иконка пользователя (только смартфоны) -->
      <c-transparent-button class="header-user-btn" v-if="(user && isTabletDown)" @click="openUserInfo">
        <ion-icon name="core-user" class="icon-medium" />
      </c-transparent-button>

      <c-transparent-button @click="openSettings" data-action="settings-button">
        <ion-icon name="core-settings" class="icon-medium" />
        <span class="icon-badge-network-status" :style="{ backgroundColor: networkStatusColor }"></span>
      </c-transparent-button>
    </template>

    <slot></slot>
  </app-header>
</template>

<style lang="scss">
.icon-badge-network-status {
  display: block;
  position: absolute;
  width: 6px;
  height: 6px;
  top: 0px;
  right: -4px;
  border-radius: 1000px;
}

.icon-badge-notification {
  display: block;
  position: absolute;
  width: 8px;
  height: 8px;
  top: 0px;
  right: -4px;
  border-radius: 1000px;
  background-color: var(--ion-color-danger);
}

.header-user-circle-icon {
  width: 28px;
  height: 28px;
  background-color: var(--ion-color-light);
  color: var(--ion-color-light-contrast);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 1000px;
  font-size: 13px;
  font-weight: 700;
}

.header-user-btn {
  text-transform: none;
}
</style>