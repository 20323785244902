<template>
  <ion-page>
    <app-header-new-visit
      :title="$t('views.visit_new_box.title')"
      :progress="0.375"
    />

    <ion-content>
      <c-content-loading :action="loadBoxes">
        <div v-if="isTabletDown" class="ion-padding-horizontal ion-padding-top">
          <visit-preview-images
            class="ion-margin-bottom"
            :visit-id="visitId"
            :is-new-preentry="isPreentry"
          />
        </div>

        <c-nav-cards-list>
          <c-nav-cards-list-item
            v-for="(box, index) in boxes"
            :key="index"
            @click="selectBox(box)"
          >
            {{ box.name }}
          </c-nav-cards-list-item>
        </c-nav-cards-list>

        <div v-if="isTabletDown" class="ion-padding-horizontal ion-padding-bottom">
          <c-card-header>{{ $t('views.visit_new_box.client_card_info_title') }}</c-card-header>
          <client-info-card :car-id="carId" />
        </div>
      </c-content-loading>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import ClientInfoCard from '@/components/car/ClientInfoCard.vue';
import { Box } from '@/repositories/Models/Box';
import { isTabletDown } from '@/helpers/adaptive';
import { useCreateVisitStep } from '@/composables/visit';
import { useBoxes } from '@/composables/useBoxes';
import { useMetrica } from '@/composables/useMetrica';
import VisitPreviewImages from '@/components/visit/VisitPreviewImages.vue';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    ClientInfoCard,
    VisitPreviewImages,
  },

  setup() {
    const router = useRouter();
    const { boxes, loadBoxes } = useBoxes();
    const { visitState, isPreentry, carId, visitId, visitType } = useCreateVisitStep();
    const { emitEvent } = useMetrica();

    function selectBox(box: Box) {
      // Сохраняем информацию во временном состоянии
      visitState.body.box = { id: box.id };

      emitEvent(`visit/${visitType}/box`, box);

      router.push({
        name: isPreentry ? 'preentry-service-type' : 'visit-new-service-type',
        params: { visitId },
      });
    }
    
    return {
      boxes,
      loadBoxes,
      selectBox,
      carId,
      isTabletDown,
      visitId,
      isPreentry,
    };
  }
});
</script>

<style lang="scss">
</style>