import { UnwrapRef, ComputedRef, reactive } from 'vue';
import { BaseStore, BaseStoreContext } from './BaseStore';
import { CurrentUser, DoerCollectionItem, ManagerCollectionItem } from '@/repositories/Models/User';
import { isOnline, isSlowNetwork } from '@/helpers/network';
import { CacheQueryReturn, minutes } from '@/helpers/cache';
import { isClientError } from '@/repositories';
import { computed } from '@vue/reactivity';
import config from '@/config';

export interface PointStoreState {
  current: CurrentUser|null;
}

export type CacheManagers = CacheQueryReturn<ManagerCollectionItem[]>;
export type CacheDoers = CacheQueryReturn<DoerCollectionItem[]>;

export class UserStore extends BaseStore {
  readonly state: UnwrapRef<PointStoreState>;
  readonly user: ComputedRef<CurrentUser|null>;

  constructor(ctx: BaseStoreContext) {
    super(ctx);

    this.state = reactive({
      current: null,
    });

    this.user = computed(() => this.state.current);
  }

  /**
   * Получение информации о текущем пользователе
   * 
   * CACHE: Данные автоматически кэшируются, и запрашиваются по
   * требованию только первый раз после запуска приложения
   * 
   * @returns 
   */
  async getCurrent(): Promise<CurrentUser> {
    if (!this.state.current) {
      const username = this.getCurrentUserName();
      const cache = await this.cacheQuery(['current_user', username], async () => {
        const { data } = await this.repositories.user.getCurrent({
          appName: config.serviceAppName,
        });
        
        return data;
      }, 0);

      this.state.current = cache.data;
    }

    return this.cloneData(this.state.current);
  }

  /**
   * Текущий логин имя пользователя (по которому производился вход)
   * 
   * @returns 
   */
  getCurrentUserName(): string|null {
    return localStorage.getItem('username');
  }

  /**
   * Вход в аккаунт 
   * 
   * NOTE: По сути пока является заглушкой, т.к. нет нормальной аутентификации
   * 
   * @param username 
   * @param password 
   */
  async login(username: string, password: string): Promise<CurrentUser> {
    const token = btoa(username + ':' + password);
    
    // TODO: Временно, в будущем данные должны храниться в другом месте
    localStorage.setItem('username', username);
    localStorage.setItem('token', token);

    this.state.current = null; // TMP

    if (!this.isOnline) {
      throw new Error('Для входа необходимо быть подключенным к сети');
    }

    try {
      return await this.getCurrent();
    } catch (e) {
      if (isClientError(e)) {
        const status = e.response?.status || 0;

        if (status >= 400 && status < 500) {
          localStorage.removeItem('token');
        }
      }
      
      throw e;
    }
  }

  /**
   * Пользователь вошел в систему
   */
  get isAuth() {
    return !!localStorage.getItem('token');
  }

  /**
   * Выход пользователья из системы
   */
  async logout(): Promise<void> {
    if (false == isOnline()) {
      throw new Error('Для выхода из аккаунта необходимо подключиться к сети');
    }

    if (this.isAuth) {
      await this.repositories.user.logout(2000);
    }

    localStorage.removeItem('token');

    this.state.current = null;

    // TODO: Удалить пароль для base аутентификации
    // (в будущем лучше использовать JWT токен и удалять его)
  }

  /**
   * Вернет список менеджеров
   * 
   * CACHE: При быстром подключении данные кэшируются на 20 минут, при медленном на 60 минут
   * 
   * @returns 
   */
  async getManagers(): Promise<CacheManagers> {
    const cacheRelevantMS = isSlowNetwork() ? minutes(60) : minutes(20);

    return this.cacheQuery(['managers', 0, 1000], async () => {
      const { data } = await this.repositories.user.getManagerCollection({ limit: 1000 });
      return data.items;
    }, cacheRelevantMS);
  }

  /**
   * Вернет список исполнителей
   * 
   * CACHE: При быстром подключении данные кэшируются на 20 минут, при медленном на 60 минут
   * 
   * @returns 
   */
  async getDoers(): Promise<CacheDoers> {
    const cacheRelevantMS = isSlowNetwork() ? minutes(60) : minutes(20);

    return this.cacheQuery(['doers', 0, 1000], async () => {
      const { data } = await this.repositories.user.getDoerCollection({ limit: 1000 });
      return data.items;
    }, cacheRelevantMS);
  }

  async isCahier() {
    const currentUser = await this.getCurrent();
    return currentUser.isCashier == true;
  }
}
