import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_c_modal_action_header = _resolveComponent("c-modal-action-header")!
  const _component_c_modal_select_option = _resolveComponent("c-modal-select-option")!
  const _component_c_modal_select = _resolveComponent("c-modal-select")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!
  const _component_c_modal_content = _resolveComponent("c-modal-content")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_c_modal_action_header, {
      tag: "ion-header",
      "show-close": _ctx.userCloseModal
    }, {
      start: _withCtx(() => [
        _createVNode(_component_ion_button, {
          class: "c-modal-header-back-button",
          color: "transparent-text",
          onClick: _ctx.back
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_icon, { name: "chevron-back-outline" })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      default: _withCtx(() => [
        _createTextVNode(" " + _toDisplayString(_ctx.$t('components.add_category_modal.view_category_title')), 1)
      ]),
      _: 1
    }, 8, ["show-close"]),
    _createVNode(_component_c_modal_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_c_content_loading, { action: _ctx.loadCatagory }, {
          default: _withCtx(() => [
            _createVNode(_component_c_modal_select, { class: "show-animation" }, {
              default: _withCtx(() => [
                (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.categories, (categoryItem) => {
                  return (_openBlock(), _createBlock(_component_c_modal_select_option, {
                    key: categoryItem.id,
                    selected: !!_ctx.categoriesSelectedList[categoryItem.id],
                    onClick: ($event: any) => (_ctx.selectCategory(categoryItem))
                  }, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(categoryItem.name), 1)
                    ]),
                    _: 2
                  }, 1032, ["selected", "onClick"]))
                }), 128))
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["action"])
      ]),
      _: 1
    })
  ], 64))
}