<script lang="ts" setup>
import { defineProps, PropType, inject, ref, watch } from 'vue';
import { ServicesCategoriesFiltered } from '@/composables/useServicesCategoriesContext';
import { ProvideServiceItemPart } from '@/composables/provideServices';
import ServiceProvideItem from './ServiceProvideItem.vue';

const props = defineProps({
  category: {
    type: Object as PropType<ServicesCategoriesFiltered>,
    required: true
  },
  isSearchStarted: {
    type: Boolean,
    defaut: false,
  }
});

const servicesSelected = inject<Record<string, ProvideServiceItemPart|undefined>>('servicesProvide/servicesSelected');
if (!servicesSelected) throw Error('Не удалось получить состояние выбранных элементов, по ключу "servicesProvide/servicesSelected"');

const expanded = ref<boolean>(false);
let expandedSavedValue = expanded.value;

/**
 * Когда начинается процесс поиска, сохраняем предыдущее значение и раскрываем блок.
 * Если поиск закончился, то восстанавливаем значение блока спойлера.
 */
watch(() => props.isSearchStarted, isStarted => {
  if (isStarted) {
    expandedSavedValue = expanded.value;
    expanded.value = true;
  } else {
    expanded.value = expandedSavedValue;
  }
});
</script>

<template>
  <c-spoiler
    :key="props.category.id"
    :title="props.category.name"
    v-model:expand="expanded"
    v-show="props.category.show"
  >
    <service-provide-item
      v-for="item in props.category.services"
      :key="item.service.id"
      :service="item.service"
      v-show="item.show"
      v-model:provided="servicesSelected[item.service.id!]"
    />
  </c-spoiler>
</template>
