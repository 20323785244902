<template>
  <ion-page>
    <app-header-new-visit
      :defailt-title="$t('views.visit_new_service_category.default_title')"
      :progress="0.625"
    />

    <ion-content>
      <c-content-loading :action="loadServiceCategory">
        <div v-if="isTabletDown" class="ion-padding-horizontal ion-padding-top">
          <visit-preview-images
            class="ion-margin-bottom"
            :visit-id="visitId"
            :is-new-preentry="isPreentry"
          />
        </div>

        <c-nav-cards-list>
          <c-nav-cards-list-item
            v-for="cat in categories"
            :key="cat.id"
            :class="{ '--success-color': lastCategoryId == cat.id }"
            @click="selectCategory(cat)"
          >
            {{ cat.name }}
          </c-nav-cards-list-item>
        </c-nav-cards-list>

        <div v-if="isTabletDown" class="ion-padding-horizontal ion-padding-bottom">
          <c-card-header>
            {{ $t('views.visit_new_service_category.client_card_info_title') }}
          </c-card-header>
          
          <client-info-card :car-id="carId" />
        </div>
      </c-content-loading>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, DeepReadonly, computed } from 'vue'
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue'
import { useRoute, useRouter } from 'vue-router'
import { useStore } from '@/composables/useApp'
import { PointTypeCategoryItem, ThisClientPointTypeItem } from '@/repositories/Models/Point'
import ClientInfoCard from '@/components/car/ClientInfoCard.vue'
import { isTabletDown } from '@/helpers/adaptive'
import { useCreateVisitStep } from '@/composables/visit'
import { toNumber } from 'lodash'
import { useMetrica } from '@/composables/useMetrica'
import VisitPreviewImages from '@/components/visit/VisitPreviewImages.vue';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    ClientInfoCard,
    VisitPreviewImages,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const store = useStore();
    const { carId, visitId, visitState, isPreentry, visitType, prefixNamePage } = useCreateVisitStep();
    const { emitEvent } = useMetrica();

    const typeId = toNumber(route.params.typeId);

    const pointType = ref<DeepReadonly<ThisClientPointTypeItem|null>>(null);
    const categories = computed<DeepReadonly<PointTypeCategoryItem[]>>(() => {
      return pointType.value?.carCategories || [];
    });

    async function loadServiceCategory() {
      pointType.value = await store.point.filterTypePoint(typeId) || null;
    }

    function selectCategory(cat: DeepReadonly<PointTypeCategoryItem>) {
      emitEvent(`visit/${visitType}/category`, cat);

      // Сохраняем первую выбранную категорию, чтобы в дальнейшем
      // отображать ее в интерфейсе во время выбора услуг
      visitState.selectedTypeAndCategories = [
        {
          typeId: typeId,
          catId: cat.id
        }
      ];

      router.push({
        name: prefixNamePage('services'),
        params: { visitId },
      });
    }

    const lastCategoryId = visitState.meta.lastCategoryId;

    return {
      loadServiceCategory,
      selectCategory,
      categories,
      carId,
      isTabletDown,
      lastCategoryId,
      visitId,
      isPreentry,
    };
  }
});
</script>

<style lang="scss">
</style>