<script lang="ts">
export default {
  inheritAttrs: false,
}
</script>

<script lang="ts" setup>
import { defineProps, PropType, provide, computed, ref } from 'vue';
import { useModalNav } from './modal';
import type { ModalServicesContext } from '@/composables/visit';
import SelectDiscountsView from './SelectDiscountsView.vue';
import ServicesProvideChoiceDoers from '../provide-form/ServicesProvideChoiceDoers.vue';
import { useI18n } from 'vue-i18n';
import { useToast } from '@/composables/toast';
import { ChangeProvidedServicesAction } from '@/components/service/add-category-modal/modal';
import { ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';

const props = defineProps({
  // HACK: Не передаем значения напрямую,
  // т.к. это вызовет проблемы с обновлением props значений
  useModalServicesContext: {
    type: Function as PropType<() => ModalServicesContext>,
    required: true,
  },

  action: {
    type: String as PropType<ChangeProvidedServicesAction>,
    required: false,
  },

  setProvidedServices: {
    type: Function as PropType<(providedServices: ProvideServiceBodyItem[]) => void>,
    required: false,
  },

  userCloseModal: {
    type: Boolean,
    default: true,
  },

  doerViewNextButtonText: {
    type: String,
    required: false
  }
});

const { t } = useI18n();
const toast = useToast();
const nav = useModalNav();

const isMandatoryDoers = ref(false);
const ctx = props.useModalServicesContext();

provide('visitState', ctx.visitState);

const nextButtonText = computed(() => {
  if (props.doerViewNextButtonText) {
    return props.doerViewNextButtonText;
  }

  return (props.action === ChangeProvidedServicesAction.UpdateDoers)
    ? t('components.add_category_modal.view_doers_btn_apply')
    : t('components.add_category_modal.view_doers_btn_next')
  ;
});

function back() {
  nav.backView();
}

function apply() {
  const providedServices = ctx.visitState.body.providedServices || [];
  props.setProvidedServices?.call(null, [...providedServices]);
}

function next() {
  if (!isMandatoryDoers.value) {
    return toast.error(t('components.add_category_modal.error_doer_all_required'));
  }

  // Если требуется действие только обновить исполниетелей,
  // то применим изменения и далее продолжать не будем.
  if (props.action === ChangeProvidedServicesAction.UpdateDoers) {
    return apply();
  }

  nav.pushView(SelectDiscountsView);
}
</script>

<template>
  <c-modal-action-header tag="ion-header" :show-close="userCloseModal">
    <template #start>
      <ion-button
        v-if="nav.backCount > 0"
        @click="back"
        class="c-modal-header-back-button"
        color="transparent-text"
      >
        <ion-icon name="chevron-back-outline" />
      </ion-button>
    </template>

    {{ t('components.add_category_modal.view_doers_title') }}
  </c-modal-action-header>

  <c-modal-content>
    <services-provide-choice-doers
      class="ion-padding-horizontal"
      state-inject-key="visitState"
      v-model:is-mandatory-doers="isMandatoryDoers"
    />
  </c-modal-content>

  <ion-footer>
    <div class="core-modal-footer-toolbal">
      <ion-button color="primary" expand="block" shape="round" @click="next">
        {{ nextButtonText }}
      </ion-button>
    </div>
  </ion-footer>
</template>
