import { random, round } from "lodash"

/**
 * Вернет рандомное числовое значение (для CSS свойств)
 * 
 * @param min минимальное значение
 * @param max максимальное значение
 * @param unit единицы (по умолчанию %)
 * @returns 
 */
export function randWidth(min: number, max: number, unit = '%') {
  return round(random(min, max), 2) + unit;
}
