import { inject, provide } from 'vue';

export type ActionFunction = () => Promise<any>;

export interface ContentLoadingInnerContext {
  load?<T = any>(): Promise<T>;
  reload?<T = any>(): Promise<T>;
}

export function createContentLoadingContext() {
  return {};
}

export function useContentLoading<ReturnValue = any>() {
  const ctx: ContentLoadingInnerContext = {};

  provide('contentLoadingContext', ctx);

  function load() {
    if (!ctx.load) {
      throw new Error('Контекст ContentLoading не инициализирован');
    }

    return ctx.load<ReturnValue>();
  }

  function reload() {
    if (!ctx.reload) {
      throw new Error('Контекст ContentLoading не инициализирован');
    }

    return ctx.reload<ReturnValue>();
  }

  return {
    load,
    reload,
  };
}

export function getContentLoadingInnerContext() {
  let ctx = inject<ContentLoadingInnerContext|null>('contentLoadingContext', null);
  
  if (!ctx) {
    ctx = createContentLoadingContext();
  }

  return ctx;
}