import { renderSlot as _renderSlot, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-input-counter" }
const _hoisted_2 = ["disabled"]
const _hoisted_3 = { class: "c-input-counter__count" }
const _hoisted_4 = ["disabled"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("button", {
      class: "c-input-counter__button",
      type: "button",
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.decrement && _ctx.decrement(...args))),
      disabled: _ctx.downDisabled
    }, [
      _renderSlot(_ctx.$slots, "decrement-btn", {}, () => [
        _createTextVNode("-")
      ])
    ], 8, _hoisted_2),
    _createElementVNode("span", _hoisted_3, [
      _renderSlot(_ctx.$slots, "count", _normalizeProps(_guardReactiveProps({ intValue: _ctx.intValue })), () => [
        _createTextVNode(_toDisplayString(_ctx.intValue), 1)
      ])
    ]),
    _createElementVNode("button", {
      class: "c-input-counter__button",
      type: "button",
      onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.increment && _ctx.increment(...args))),
      disabled: _ctx.upDisabled
    }, [
      _renderSlot(_ctx.$slots, "increment-btn", {}, () => [
        _createTextVNode("+")
      ])
    ], 8, _hoisted_4)
  ]))
}