<template>
  <c-content-loading v-show="hasProvidedServices" :action="loadServices">
    <services-provided-view-card
      :provided-services="providedServices"
      :total="total"
      :short-group-name="true"
    >
      <template #total-price>
        <div class="visit-store-products-view-total">
          <ion-chip
            v-if="discountPercent"
            color="success"
            class="chip-size-small chip-filled"
          >
            -{{ discountPercent }}%
          </ion-chip>

          <span class="visit-store-products-view-total__price">
            <cc :price="total" />
          </span>
        </div>
      </template>
    </services-provided-view-card>
  </c-content-loading>
</template>

<script lang="ts">
import { defineComponent, computed, ref, inject, DeepReadonly } from 'vue'
import ServicesProvidedViewCard from '@/components/service/provide-view/ServicesProvidedViewCard.vue'
import { ServiceCollectionItem } from '@/repositories/Models/Service'
import { NewVisitSatate } from '@/store/NewVisitState'
import { isEmpty, map, keyBy, Dictionary } from 'lodash'
import { useStore } from '@/composables/useApp'

export default defineComponent({
  components: {
    ServicesProvidedViewCard,
  },
  props: {
    total: {
      type: Number,
      required: true,
    },
    discountPercent: {
      type: Number,
      required: false,
    }
  },
  setup() {
    const store = useStore();

    const allServicesIndex = ref<DeepReadonly<Dictionary<ServiceCollectionItem>>>({});
    async function loadServices() {
      allServicesIndex.value = keyBy(await store.service.getAll(), 'id');
    }

    const visitState = inject<NewVisitSatate|null>('visitState', null);
    if (!visitState) throw new Error('Mandatory state of a new store visit is not passed');

    const hasProvidedServices = computed(() => !isEmpty(visitState.body.providedServices));

    const providedServices = computed(() => {
      if (isEmpty(allServicesIndex.value) || isEmpty(visitState.body.providedServices)) {
        return [];
      }

      return map(visitState.body.providedServices, item => {
        return {
          ...item,
          service: allServicesIndex.value[`${item.service.id}`] || null,
        };
      })
    });

    return {
      providedServices,
      loadServices,
      hasProvidedServices,
    };
  }
});
</script>

<style lang="scss">
.visit-store-products-view-total {
  display: flex;
  align-items: center;

  &__price {
    padding-top: 1px; // visual fix
  }

  ion-chip {
    margin: 0 10px 0 0;
  }
}
</style>