<template>
  <div class="c-network-indicator">
    <div class="c-network-indicator__bulb-status"
      :style="{ backgroundColor: networkStatusColor }"
    />
    <div class="c-network-indicator__text-status">{{ networkStatusText }}</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { networkStatusColor, networkStatusText } from '@/helpers/network'

export default defineComponent({
  setup() {
    return {
      networkStatusText,
      networkStatusColor,
    };
  }
});
</script>

<style lang="scss">
.c-network-indicator {
  display: flex;
  justify-content: flex-start;
  align-items: center;

  &__bulb-status {
    width: 28px;
    height: 28px;
    border-radius: 1000px;
    margin-right: 10px;
  }

  &__text-status {
    color: var(--core-text-muted)
  }
}
</style>