import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "mb-3" }
const _hoisted_2 = { class: "c-form-group" }
const _hoisted_3 = { class: "mb-3" }
const _hoisted_4 = { class: "number-recognition-item" }
const _hoisted_5 = { class: "number-recognition-item__row" }
const _hoisted_6 = { class: "number-recognition-item__col-title" }
const _hoisted_7 = { class: "number-recognition-item__col-switch" }
const _hoisted_8 = { class: "text-muted" }
const _hoisted_9 = { class: "number-recognition-item" }
const _hoisted_10 = { class: "number-recognition-item__row" }
const _hoisted_11 = { class: "number-recognition-item__col-title" }
const _hoisted_12 = { class: "number-recognition-item__col-switch" }
const _hoisted_13 = { class: "text-muted" }
const _hoisted_14 = {
  key: 0,
  class: "number-recognition-item"
}
const _hoisted_15 = { class: "number-recognition-item__row" }
const _hoisted_16 = { class: "number-recognition-item__col-title" }
const _hoisted_17 = { class: "number-recognition-item__col-switch" }
const _hoisted_18 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cid_input = _resolveComponent("cid-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_c_divider = _resolveComponent("c-divider")!
  const _component_network_indicator = _resolveComponent("network-indicator")!
  const _component_ion_toggle = _resolveComponent("ion-toggle")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.$t('components.app_settings.label_input_cid')), 1),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_cid_input, {
        class: "core-input",
        modelValue: _ctx.apiUrl,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.apiUrl) = $event))
      }, null, 8, ["modelValue"]),
      (_ctx.isDifferentApiUrls)
        ? (_openBlock(), _createBlock(_component_ion_button, {
            key: 0,
            disabled: !_ctx.validApiUrl,
            onClick: _ctx.applyBaseUrl,
            fill: "clear",
            color: "transparent",
            class: "ion-no-padding"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.$t('components.app_settings.btn_apply_cid')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
        : _createCommentVNode("", true)
    ]),
    _createVNode(_component_c_divider),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.$t('components.app_settings.label_network_speed')), 1),
    _createVNode(_component_network_indicator),
    _createElementVNode("div", _hoisted_4, [
      _createVNode(_component_c_divider),
      _createElementVNode("div", _hoisted_5, [
        _createElementVNode("div", _hoisted_6, _toDisplayString(_ctx.$t('components.app_settings.label_number_recognition')), 1),
        _createElementVNode("div", _hoisted_7, [
          _createVNode(_component_ion_toggle, {
            class: "core-toggle",
            modelValue: _ctx.useNumberRecognition,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.useNumberRecognition) = $event))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("p", _hoisted_8, _toDisplayString(_ctx.$t('components.app_settings.description_number_recognition')), 1)
    ]),
    _createElementVNode("div", _hoisted_9, [
      _createVNode(_component_c_divider),
      _createElementVNode("div", _hoisted_10, [
        _createElementVNode("div", _hoisted_11, _toDisplayString(_ctx.$t('components.app_settings.label_preentry')), 1),
        _createElementVNode("div", _hoisted_12, [
          _createVNode(_component_ion_toggle, {
            class: "core-toggle",
            modelValue: _ctx.usePreentry,
            "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.usePreentry) = $event))
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("p", _hoisted_13, _toDisplayString(_ctx.$t('components.app_settings.description_preentry')), 1)
    ]),
    (_ctx.flashlightIsAvailable)
      ? (_openBlock(), _createElementBlock("div", _hoisted_14, [
          _createVNode(_component_c_divider),
          _createElementVNode("div", _hoisted_15, [
            _createElementVNode("div", _hoisted_16, _toDisplayString(_ctx.$t('components.app_settings.label_flashlight')), 1),
            _createElementVNode("div", _hoisted_17, [
              _createVNode(_component_ion_toggle, {
                class: "core-toggle",
                modelValue: _ctx.useFlashlightValue,
                "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.useFlashlightValue) = $event))
              }, null, 8, ["modelValue"])
            ])
          ]),
          _createElementVNode("p", _hoisted_18, _toDisplayString(_ctx.$t('components.app_settings.description_flashlight')), 1)
        ]))
      : _createCommentVNode("", true),
    _createVNode(_component_c_divider, { class: "mb-0" }),
    _createVNode(_component_ion_button, {
      onClick: _ctx.joinCommunity,
      href: _ctx.communityLink,
      target: "_blank",
      fill: "clear",
      color: "transparent",
      class: "ion-no-padding"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.app_settings.btn_community')), 1)
      ]),
      _: 1
    }, 8, ["onClick", "href"]),
    _createVNode(_component_c_divider, { class: "my-0" }),
    _createVNode(_component_ion_button, {
      onClick: _ctx.contactSupport,
      href: _ctx.supportChatUrl,
      fill: "clear",
      color: "transparent",
      class: "ion-no-padding",
      target: "_blank"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.app_settings.btn_support_chat')), 1)
      ]),
      _: 1
    }, 8, ["onClick", "href"]),
    _createVNode(_component_c_divider, { class: "my-0" }),
    _createVNode(_component_ion_button, {
      fill: "clear",
      color: "transparent",
      class: "ion-no-padding",
      onClick: _ctx.clearCacheAction
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.app_settings.btn_clear_cache')), 1)
      ]),
      _: 1
    }, 8, ["onClick"]),
    _createVNode(_component_c_divider, { class: "my-0" }),
    _createVNode(_component_ion_button, {
      onClick: _ctx.viewPrivacyPolicy,
      href: _ctx.privacyPolicyLink,
      target: "_blank",
      fill: "clear",
      color: "transparent",
      class: "ion-no-padding"
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.app_settings.btn_view_privacy_policy')), 1)
      ]),
      _: 1
    }, 8, ["onClick", "href"])
  ]))
}