import { get } from 'lodash'

export interface SearchItem<T = any> {
  item: T;
  weight: number;
}

/**
 * Подсчитывает веса для поисковых слов
 * @param items элементы по которым происходит поиск/фильтрация
 * @param path путь до поля, по которому происходит подсчет веса поиска
 * @param s строка поиска
 * @returns 
 */
export function caclcWeightSerchWords<T>(items: T[], path: string, s: string): SearchItem<T>[] {
  s = s.trim().toLocaleLowerCase();

  let calcItems: SearchItem<T>[] = items.map(item => {
    const title = String(get(item, path, '')).toLocaleLowerCase();
    const weight  = title.indexOf(s);

    return { item, weight };
  });

  return calcItems;
}

/**
 * Самый простой фильтр списка по найденным вхождениям строки
 */
export function filterSerchWords<T>(items: T[], path: string, s: string): T[] {
  return caclcWeightSerchWords(items, path, s)
    .filter(item => item.weight >= 0)
    .sort((a, b) => a.weight - b.weight)
    .map(searchItemInfo => searchItemInfo.item);
}

export type MapSerchWordsCallback<T, D> = (item: SearchItem<T>) => D;
export function mapSerchWords<T, D>(items: T[], path: string, s: string, callbackFn: MapSerchWordsCallback<T, D>): D[] {
  return caclcWeightSerchWords(items, path, s)
    .sort((a, b) => a.weight - b.weight)
    .map(callbackFn);
}
