import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, vShow as _vShow, withCtx as _withCtx, withDirectives as _withDirectives, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode } from "vue"

const _hoisted_1 = { class: "c-service-provide-item" }
const _hoisted_2 = {
  class: "c-service-provide-item__checkbox-col",
  ref: "checkboxCol"
}
const _hoisted_3 = { class: "c-service-provide-item__content-col" }
const _hoisted_4 = { class: "c-service-provide-item__footer" }
const _hoisted_5 = { class: "c-service-provide-item__qty" }
const _hoisted_6 = { class: "c-service-provide-item__total-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_checkbox = _resolveComponent("ion-checkbox")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_c_number_counter = _resolveComponent("c-number-counter")!
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_cc = _resolveComponent("cc")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_ion_checkbox, {
        color: "light",
        modelValue: _ctx.checked,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.checked) = $event))
      }, null, 8, ["modelValue"])
    ], 512),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", {
        class: "c-service-provide-item__title",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onClickTitle && _ctx.onClickTitle(...args)))
      }, _toDisplayString(_ctx.service.name), 1),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          (_ctx.service.repeatable)
            ? _withDirectives((_openBlock(), _createBlock(_component_c_number_counter, {
                key: 0,
                modelValue: _ctx.quantity,
                "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.quantity) = $event)),
                modelModifiers: { number: true },
                min: 1,
                max: 100
              }, {
                "decrement-btn": _withCtx(() => [
                  _createVNode(_component_ion_icon, { name: "core-remove" })
                ]),
                "increment-btn": _withCtx(() => [
                  _createVNode(_component_ion_icon, { name: "core-add" })
                ]),
                _: 1
              }, 8, ["modelValue"])), [
                [_vShow, _ctx.checked]
              ])
            : _createCommentVNode("", true)
        ]),
        _createElementVNode("div", _hoisted_6, [
          _withDirectives(_createElementVNode("div", { class: "c-service-provide-item__price-item" }, _toDisplayString(_ctx.priceCalcView), 513), [
            [_vShow, _ctx.priceCalcView]
          ]),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.discountViewBadges, (discountBadge) => {
            return (_openBlock(), _createBlock(_component_ion_chip, {
              key: discountBadge,
              color: "success",
              class: "chip-size-small chip-filled my-0 mr-0 ml-2"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(discountBadge), 1)
              ]),
              _: 2
            }, 1024))
          }), 128)),
          _createElementVNode("div", {
            class: "c-service-provide-item__price-total",
            onClick: _cache[3] || (_cache[3] = 
//@ts-ignore
(...args) => (_ctx.openCostSettings && _ctx.openCostSettings(...args)))
          }, [
            _createVNode(_component_cc, { price: _ctx.priceTotal }, null, 8, ["price"])
          ]),
          _withDirectives(_createVNode(_component_ion_button, {
            color: _ctx.isPriceModify ? 'transparent-primary' : 'transparent-text',
            size: "nospacers",
            onClick: _ctx.openCostSettings,
            disabled: _ctx.costSettingsDisable
          }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, { name: "core-preferences" })
            ]),
            _: 1
          }, 8, ["color", "onClick", "disabled"]), [
            [_vShow, _ctx.costSettingsShow]
          ])
        ])
      ])
    ])
  ]))
}