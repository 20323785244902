<template>
  <ion-card class="c-card-list">
    <ion-list>
      <slot />
    </ion-list>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  
});
</script>

<style lang="scss">
.c-card-list {
  ion-item {
    --color: rgba(var(--core-text), 0.5);
    --padding-start: var(--core-spacer-small, 0);
    --padding-end: var(--core-spacer-small, 0);
    --inner-padding-end: 0;

    &.c-card-list-header {
      --color: var(--core-text);
    }
  }

  ion-note {
    & {
      margin-left: 0;
      margin-right: 0;
    }
  }
}
</style>