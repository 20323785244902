import { useStore } from './useApp';
import { ref, DeepReadonly, toRaw, computed, toRef } from 'vue';
import { Shift } from '@/repositories/Models/Point';
import {
  shiftConvertAllToInformationExtended,
  ShiftTimeGridItem,
  isShiftsInfoIntersectionEnd
} from '@/helpers/shifts';
import { dateToServerDatetimeString, MINUTES_DAY_NUMBER } from '@/helpers/datetime';

export interface ShiftsThisPointOptions {
  stepMinutes?: number;
}

export function useShiftsThisPoint(options: ShiftsThisPointOptions = {}) {
  const store = useStore();

  const opt = {
    stepMinutes: 15,
    ...options,
  };

  /** Информация о графике смен */
  const shifts = ref<DeepReadonly<Shift[]>>([]);

  /**
   * Обработанные данные графика смен.
   * Данные будут обработаны и скорректированы и дополнены.
   */
  const shiftsInformationExtended = computed(() => shiftConvertAllToInformationExtended(toRaw(shifts.value) as Shift[], opt.stepMinutes));

  /**
   * Минимаьное и максимальное кол-во
   * минут проеденных с начала дня в расписании.
   * 
   * При графике смен 7:30 - 23:00 и 23:00 - 7:30 значения будут 440 мин. - 1880 мин.
   * Т.е. график начинается с 7:30 текущего дня и заканчивается в 7:30 следующего дня
   */
  const minmaxMinutes = computed(() => {
    let min = shiftsInformationExtended.value.length ? Number.MAX_VALUE : 0;
    let max = 0;

    for (const shiftInfo of shiftsInformationExtended.value) {
      let {
        timeFromMinutesEdgeSmoothing: from,
        timeToMinutesEdgeSmoothing: to,
      } = shiftInfo;

      if (from > to) {
        to += MINUTES_DAY_NUMBER;
      }

      if (from < min) {
        min = from;
      }

      if (to > max) {
        max = to;
      }
    }

    return { min, max };
  });

  /** Все рабочие промежутки времени всех смен в виде одного списка */
  const timeLine = computed(() => {
    let allTimeGrid: ShiftTimeGridItem[] = [];

    for (const info of shiftsInformationExtended.value) {
      let nextTimeGrid = [...info.timeGrid];

      if (isShiftsInfoIntersectionEnd(allTimeGrid, info.timeGrid)) {
        nextTimeGrid.shift();
      }

      allTimeGrid.push(...nextTimeGrid);
    }
    
    return allTimeGrid;
  });

  /** Загрузить график смен с сервера */
  async function loadShifts() {
    shifts.value = await store.point.getShiftsThisPoint();

    if (shifts.value.length === 0) {
      shifts.value = [
        {
          id: Number.MAX_VALUE,
          name: 'Сутки',
          timeFrom: dateToServerDatetimeString('1970-01-01T00:00:00'),
          timeTo: dateToServerDatetimeString('1970-01-01T23:59:00'),
          clientPoint: { id: 0 },
        }
      ];
    }
  }

  return {
    loadShifts,
    shifts,
    shiftsInformationExtended,
    timeLine,
    minmaxMinutes,
    stepMinutes: toRef(opt, 'stepMinutes'),
  };
}