<script lang="ts" setup>
import { defineProps, PropType, ref, watch } from 'vue';
import { get } from 'lodash';
import { ServicesCategoriesFiltered } from '@/composables/useServicesCategoriesContext';

const props = defineProps({
  category: {
    type: Object as PropType<ServicesCategoriesFiltered>,
    required: true
  },
  isSearchStarted: {
    type: Boolean,
    defaut: false,
  },
});

const expanded = ref<boolean>(false);
let expandedSavedValue = expanded.value;

/**
 * Когда начинается процесс поиска, сохраняем предыдущее значение и раскрываем блок.
 * Если поиск закончился, то восстанавливаем значение блока спойлера.
 */
watch(() => props.isSearchStarted, isStarted => {
  if (isStarted) {
    expandedSavedValue = expanded.value;
    expanded.value = true;
  } else {
    expanded.value = expandedSavedValue;
  }
});
</script>

<template>
  <c-spoiler
    :title="props.category.name"
    v-model:expand="expanded"
    v-show="props.category.show"
  >
    <ion-item
      class="service-view-item ion-align-items-start"
      v-for="item in props.category.services"
      :key="item.service.id"
      v-show="item.show"
    >
      {{ item.service.name }}

      <!-- eslint-disable-next-line -->
      <div class="service-view-item__price-info" slot="end">
        <div><cc :price="get(item.service, 'prices.0.value')" empty-display="-" /></div>
      </div>
    </ion-item>
  </c-spoiler>
</template>