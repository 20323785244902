<template>
  <div class="c-car-side-info pb-0">
    <c-card-header>
      <ion-skeleton-text v-if="!currentCar" animated :style="{
        width: '140px',
        height: '1em',
      }" />
      <template v-else>{{ currentCar.number }}</template>
    </c-card-header>

    <slot name="preview-image" v-bind="{ carImageCover }">
      <c-image-bg
        :ratio="0.6"
        :src="carImageCover"
      />
    </slot>

    <c-card-header class="ion-hide-md-down ion-margin-top">
      {{ $t('components.client_side_info.client_card_info_title') }}
    </c-card-header>
    <client-info-card
      class="ion-hide-md-down"
      :car-id="carId"
      @car-loaded="setCurrentCar"
    />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import carNoImage from '@/img/car-no-image.svg'
import { Car } from '@/repositories/Models/Car'
import ClientInfoCard from './ClientInfoCard.vue'

export default defineComponent({
  components: {
    ClientInfoCard,
  },
  props: {
    carId: {
      type: String,
      required: false,
    },
    carImage: {
      type: String,
      required: false,
    }
  },
  setup(props) {
    const carImageCover = computed(() => props.carImage || carNoImage);

    /**
     * Чтобы дополнительно не подгружать информацию о текущем автомобиле,
     * подождем пока она загрузится в специальном информационном боксе и сохраним ее
     */
    const currentCar = ref<Car|null>(null);
    function setCurrentCar(car: Car) {
      currentCar.value = car;
    }

    return {
      currentCar,
      carImageCover,
      setCurrentCar,
    };
  }
});
</script>

<style lang="scss">
</style>