import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-input-car-number__input-wrap" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = {
  key: 0,
  class: "c-input-car-number__fake-input"
}
const _hoisted_4 = ["textContent"]
const _hoisted_5 = {
  ref: "keyboardContainer",
  class: "c-input-car-number__keyboard-container"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass({
      'c-input-car-number': true,
      'c-input-car-number--valid': _ctx.isValid,
      'c-input-car-number--colored': _ctx.colored,
      [`c-input-car-number--available-${_ctx.availableInput}`]: !!_ctx.availableInput
    })
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        ref: "input",
        readonly: "",
        class: "c-input-car-number__input",
        type: "text",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.carNumber) = $event)),
        name: "car_number",
        placeholder: _ctx.carNumberPlaceholder
      }, null, 8, _hoisted_2), [
        [_vModelText, _ctx.carNumber]
      ]),
      (_ctx.colored)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.carNumberColored, (charInfo, charIndex) => {
              return (_openBlock(), _createElementBlock("span", {
                key: `${charIndex}_${charInfo.char}`,
                class: _normalizeClass({ 'car-number-char-type-number': charInfo.isNumber }),
                textContent: _toDisplayString(charInfo.char)
              }, null, 10, _hoisted_4))
            }), 128))
          ]))
        : _createCommentVNode("", true),
      _createElementVNode("button", {
        class: "c-input-car-number__backspace-btn",
        onClick: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.backspaceAct && _ctx.backspaceAct(...args)))
      }, [
        _createVNode(_component_ion_icon, { name: "backspace-outline" })
      ])
    ]),
    _createElementVNode("div", _hoisted_5, null, 512)
  ], 2))
}