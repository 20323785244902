<template>
  <div class="c-choice-list-item">
    <div class="c-choice-list-item__title" v-if="$slots.default">
      <slot />
    </div>

    <div class="c-choice-list-item__footer">
      <ion-buttons>
        <slot name="buttons" /> 
      </ion-buttons>

      <div class="c-choice-list-item__view-selected">
        <slot name="view-selected" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.c-choice-list-item {
  padding: var(--core-spacer-small) 0;
  border-bottom: var(--core-divider-width) solid var(--core-divider-color);

  &:last-child {
    border-bottom: none;
  }

  &__title {
    font-size: var(--core-font-size);
    margin-bottom: 6px;
  }

  &__view-selected {
    font-size: var(--core-font-size);
    color: var(--ion-text-color);
  }

  &__footer {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
  }

  ion-buttons {
    padding-left: 0;
    padding-right: 0;
    margin-bottom: -6px;
    margin-top: -4px;

    ion-button {
      --padding-start: 0;
      --padding-end: 0;
      margin-left: 0;
      margin-right: var(--core-spacer-small);
      font-size: var(--core-font-size-medium, 0.9rem);
    }
  }
}
</style>