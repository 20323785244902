import { computed, Ref, ref, toValue, isRef } from 'vue';
import { useApp } from '@/composables/useApp';
import carNoImage from '@/img/car-no-image.svg';
import { computedAsync } from '@vueuse/core';
import { VisitGalleryItem, VisitGallery } from '@/database/Tables/VisitGallery';
import { generateUuid } from '@/utils/identifier';
import { base64ToBlob } from '@/helpers/image';
import { useToast } from '@/composables/toast';
import { onIonViewDidEnter } from '@ionic/vue';
import md5 from 'blueimp-md5';
import { chunk } from 'lodash';
import { errorToMessageData } from '@/helpers/error';

export interface UseVisitFramesOptions {
  /** Идентификатор визита, для нового (создаваемого) визита он будет равняться '0' */
  visitId: string|Ref<string>;

  viewDidEnterAutosync?: boolean;

  /**
   * Автоматически обновлять visitId, если он изменился.
   * В этом случае visitId лучше передавать, как Ref значение.
   */
  autoupdateVisitId?: boolean; 
}

export interface VisitImagesUploadAllOptions {
  autoremove?: boolean;
  showError?: boolean;
  chunkCount?: number;
}

const VISIT_IMAGES_UPLOAD_ALL_OPTIONS: Required<VisitImagesUploadAllOptions> = Object.freeze({
  autoremove: false,
  showError: true,
  chunkCount: 1,
});

/**
 * Работа с изображениями визита.
 * Данные храняться в IndexedDB.
 * 
 * @param options 
 * @returns 
 */
export function useVisitImages(options: UseVisitFramesOptions) {
  const o: Required<UseVisitFramesOptions> = Object.assign({
    viewDidEnterAutosync: true,
    autoupdateVisitId: true,
  }, options);

  const { getDb, repositories } = useApp();
  const toast = useToast();
  const db = getDb();

  const galleryTriggerSync = ref(0);

  const visitId = computed(() => toValue(options.visitId));
  const gallery = computedAsync<VisitGallery|null>(
    async () => {
      galleryTriggerSync.value; // bind trigger

      try {
        return (await db.visitGallery.get(visitId.value)) || null;
      } catch {
        return null;
      }
    },
    null,
    { lazy: true }
  );

  const galleryItems = computed<VisitGalleryItem[]>(() => gallery.value?.items || []);
  const imagesItems = computed<string[]>(() => galleryItems.value.map(item => item.imageBase64));
  const cover = computed<string>(() => galleryItems.value?.[0].imageBase64 || carNoImage);

  /**
   * Провоцирует обновление галереи 
   * (подтягивет данные из БД)
   */
  function gallerySync() {
    galleryTriggerSync.value++;
  }

  /**
   * Добавить изображение к галерее
   * 
   * NOTE: Фото добавляется в начало списка!
   * 
   * @param image 
   * @param createdAt 
   * @param id 
   * @returns 
   */
  async function addImage(image: string, createdAt?: Date, id?: string): Promise<void> {
    let updatedGallery = await db.visitGallery.get(visitId.value);

    if (!updatedGallery) {
      updatedGallery = {
        visitId: visitId.value,
        items: [],
      };
    }

    updatedGallery.items = [
      ...updatedGallery.items,

      { // last
        id: id || generateUuid(),
        imageBase64: image,
        md5: md5(image),
        createdAt: createdAt || (new Date())
      },
    ];

    db.visitGallery.put(updatedGallery, visitId.value);

    gallerySync();
  }

  /**
   * Удаляет элемент (изображение) из галереи
   * 
   * @param itemId идентификатор удаляемого элемента
   */
  async function removeImage(itemId: string): Promise<void> {
    let updatedGallery = await db.visitGallery.get(visitId.value);
    if (!updatedGallery) {
      throw new Error('Не найдена галерея с изображением');
    }

    const removeIndex = updatedGallery.items.findIndex(item => item.id === itemId);
    updatedGallery.items.splice(removeIndex, 1);

    db.visitGallery.put(updatedGallery, visitId.value);

    gallerySync();
  }

  const uploadLoading = ref(false);

  /**
   * Загрузить все изображения на сервер
   * 
   * @param upoloadVisitId Идентификтаор заказа по которому мы будем выполнять загрузку фотографий, т.к. visitId может быть временным идентификатором.
   * @param autoremove Автоматически удалять галерею после успешной загрузки изщображений
   * @returns 
   */
  async function uploadAll(upoloadVisitId?: string, options: VisitImagesUploadAllOptions = {}): Promise<void> {
    const uploadOptions = { ...VISIT_IMAGES_UPLOAD_ALL_OPTIONS, ...options };

    if (uploadLoading.value) {
      return;
    }

    uploadLoading.value = true;

    const errorsList: any[] = [];
    const galleryItemsIsNotUploaded: VisitGalleryItem[] = [];

    try {
      let updatedGallery = await db.visitGallery.get(visitId.value);
      if (!updatedGallery) {
        throw new Error('Не найдена галерея с изображением');
      }

      const finalVisitId = upoloadVisitId || visitId.value;

      if (updatedGallery.items.length > 0) {
        // При необходимости разбиваем массив элементов на чанки.
        // Это необходимо для избежания ошибок связанных с превышением тела http запроса при отправке
        const galleryItemsChunks = (uploadOptions.chunkCount > 1)
          ? chunk(updatedGallery.items, uploadOptions.chunkCount)
          : [ updatedGallery.items ]
        ;

        for (const itemsChunk of galleryItemsChunks) {
          try {
            // NOTE: base64ToFileObject не работает на Capacitor Android
            // из-за какого-то бага. Он неправильно создает объект типа File.
            const imagesFiles = itemsChunk.map(item => base64ToBlob(item.imageBase64)!);
            await repositories.visit.addPhoto(finalVisitId, imagesFiles);
          } catch (errorUploadChunk: any) {
            galleryItemsIsNotUploaded.push(...itemsChunk);

            // Сохраняем ошибки, чтобы выбросить исключение позже, когда загрузим остальные фото и обновим инфомацию о галереи
            errorsList.push(errorUploadChunk);
          }
        }
      }

      if (galleryItemsIsNotUploaded.length > 0) {
        updatedGallery.items = galleryItemsIsNotUploaded;
        db.visitGallery.put(updatedGallery, visitId.value);
        updateVisitId(finalVisitId);
      } else if (uploadOptions.autoremove) {
        await removeGallery();
      }

      gallerySync();

      if (errorsList.length > 0) {
        const lastError = errorToMessageData(errorsList[errorsList.length - 1]);
        const finalErrorObject = new Error('Не удалось загрузить часть изображений, ошибка: ' + lastError.message);
        Object.assign(finalErrorObject, { errorsList });

        throw finalErrorObject;
      }
    } catch (e: any) {
      if (uploadOptions.showError) {
        toast.error(e);
      }

      throw e;
    } finally {
      uploadLoading.value = false;
    }
  }

  /**
   * Обновить идентификатор визита
   * 
   * @param newVisitId 
   */
  async function updateVisitId(newVisitId: string) {
    if (visitId.value === newVisitId) {
      return; // ignore update is not changed
    }

    await db.visitGallery.update(visitId.value, {
      visitId: newVisitId,
    });

    if (o.autoupdateVisitId) {
      if (isRef(options.visitId)) {
        options.visitId.value = newVisitId;
      } else {
        options.visitId = newVisitId;
      }
    }
  }

  /**
   * Очистить текущую галерею с изображениями
   */
  async function removeGallery() {
    await db.visitGallery.delete(visitId.value);
    gallerySync();
  }

  if (options.viewDidEnterAutosync || options.viewDidEnterAutosync === undefined) {
    // После возврата на данную страницу, обновить данные в галереи,
    // т.к. они могли измениться изменились.
    // NOTE: Автоматического live обновления пока нет =(
    onIonViewDidEnter(() => gallerySync());
  }

  return {
    visitId,
    gallery,
    galleryItems,
    cover,
    carNoImage,
    addImage,
    removeImage,
    imagesItems,
    uploadAll,
    uploadLoading,
    gallerySync,
    removeGallery,
    updateVisitId,
  };
}