<template>
  <div class="core-spinner">
    <div class="core-spinner__spinner">
      <slot name="spinner">
        <ion-spinner name="lines" />
      </slot>
    </div>

    <div class="core-spinner__text" v-if="loadingText || loadingText === undefined">
      <slot name="text">
        {{ loadingText || $t('components.spinner.loading') }}
      </slot>
    </div>

    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  components: {},
  props: {
    loadingText: {
      type: String,
    },
  },
});
</script>

<style lang="scss">
.core-spinner {
  text-align: center;

  &__text {
    color: var(--oh-dop-color-text-gray);
    font-size: var(--oh-font-size-sm);
  }

  &__spinner {
    color: var(--ion-color-primary);

    ion-icon {
      font-size: 80px;
    }
  }

  ion-infinite-scroll & {
    display: none;
  }
  ion-infinite-scroll.infinite-scroll-loading & {
    display: block;
  }
}
</style>

