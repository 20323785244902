<template>
  <ion-page>
    <app-header-new-visit
      :title="$t('views.visits_new_preentry_datetime.title')"
      :progress="0.825"
    >
      <ion-toolbar class="page-preentry-datetime-toolbar">
        <ion-chip v-show="customDate" outline color="primary" @click="currentTab = 'selectDate'">
          <ion-label>{{ customDateFormat }}</ion-label>
          <ion-icon icon="close-outline" @click.stop="clearCustomDate"></ion-icon>
        </ion-chip>

        <ion-segment v-show="!customDate" class="core-segment" v-model="currentTab">
          <ion-segment-button value="currentDate" layout="icon-hide">
            <ion-label>{{ $t('views.visits_new_preentry_datetime.tab_current_day') }}</ion-label>
          </ion-segment-button>

          <ion-segment-button value="nextDate" layout="icon-hide">
            <ion-label>{{ $t('views.visits_new_preentry_datetime.tab_next_day') }}</ion-label>
          </ion-segment-button>

          <ion-segment-button value="selectDate" layout="icon-hide">
            <ion-label>{{ $t('views.visits_new_preentry_datetime.tab_select_date') }}</ion-label>
          </ion-segment-button>
        </ion-segment>
      </ion-toolbar>

      <c-divider class="my-0" />
    </app-header-new-visit>

    <ion-content :scroll-y="false">
      <keep-alive>
        <template v-if="isSelectDate">
          <c-infinite-calendar
            @update:modelValue="setCustomDate"
            :modelValue="customDate"
            class="visits-new-preentry-calendar"
          />
        </template>

        <template v-else>
          <visits-preentry-shedule-box
            class="visit-new-preentry-shedule-box"
            :select-enable="true"
            :date="viewSheduleDate"
            :normative="normativeTimeMinutes"
            :exclude-visit-ids="excludeVisitIds"
            v-model="preentryValue"
            @pointer-info="setPointerInfo"
            @pointer-rect="updatePointerRect"
          >
            <template #default>
              <!-- Modal (only tablet) -->
              <transition name="fade">
                <c-modal-float
                  v-if="showTabletPreviewModal"
                  :container-styles="floatModalStyles"
                  @click-backgroup="onClickBackgroupFloatModal"
                >
                  <!-- NOTE:
                    На данный момент код продублирован как на мобильной версии, но в будущем
                    возможно содержимое будет немного отличаться от мобильной версии.
                    Так что выносить в отдельный компоент не стоит.
                  -->
                  <c-modal-action-header @dismiss="closeFloatModal">
                    {{ $t('views.visits_new_preentry_datetime.modal_confirm_title') }}
                  </c-modal-action-header>

                  <c-modal-content class="ion-padding-horizontal">
                    <ion-item class="list-info-item"
                      v-for="(info, index) in previewInfo"
                      :key="index"
                    >
                      {{ info.label }}

                      <!-- eslint-disable-next-line -->
                      <div slot="end" class="color-default mr-0">
                        {{ info.value }}
                      </div>
                    </ion-item>

                    <div class="ion-padding-vertical">
                      <ion-button color="primary" expand="block" shape="round" @click="confirm" :disabled="nextButtonDisabled">
                        {{ nextButtonText }}
                      </ion-button>
                    </div>
                  </c-modal-content>
                </c-modal-float>
              </transition>
            </template>
          </visits-preentry-shedule-box>
        </template>
      </keep-alive>

      <!-- Modal (only phone) -->
      <c-modal-step
        v-if="false === isTabletAndUp"
        :preview-height="previewHeight"
        v-model:state="previewState"
        v-slot="{ close, open }"
        @closed="preentryValue = null"
        @click-backgroup="onClickBackgroupPreviewModal"
      >
        <c-modal-action-header @click-title="open" @dismiss="close">
          {{ $t('views.visits_new_preentry_datetime.modal_confirm_title') }}
        </c-modal-action-header>

        <c-modal-content class="ion-padding-horizontal">
          <ion-item class="list-info-item"
            v-for="(info, index) in previewInfo"
            :key="index"
          >
            {{ info.label }}

            <!-- eslint-disable-next-line -->
            <div slot="end" class="color-default mr-0">
              {{ info.value }}
            </div>
          </ion-item>

          <div class="ion-padding-vertical">
            <ion-button color="primary" expand="block" shape="round" @click="confirm" :disabled="nextButtonDisabled">
              {{ nextButtonText }}
            </ion-button>
          </div>
        </c-modal-content>
      </c-modal-step>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import CInfiniteCalendar from '@/components/core/InfiniteCalendar/CInfiniteCalendar.vue';
import { usePreenryDatetimePage } from '@/composables/usePreentry';
import { useStore } from '@/composables/useApp';
import { asyncComputed } from '@/helpers/vue';
import { useCreateVisitStep, useVisitPreentryChangeDatetime } from '@/composables/visit';
import CModalStep, { StateValue } from '@/components/core/CModalStep.vue';
import CModalFloat from '@/components/core/CModalFloat.vue';
import { isTabletAndUp } from '@/helpers/adaptive';
import { useToast } from '@/composables/toast';
import { useRouter } from 'vue-router';
import { get } from 'lodash';
import { useI18n } from 'vue-i18n';
import VisitsPreentrySheduleBox, {
  PreentryValue,
  PointerInfo,
  PointerRect,
} from '@/components/visit/VisitsPreentrySheduleBox.vue';
import {
  formatDate,
  MINUTES_DAY_NUMBER,
  timeNumberToHM,
  dateToServerDatetimeString,
  parseToDate
} from '@/helpers/datetime';
import { useNavManager } from '@/composables/useNavManager';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    VisitsPreentrySheduleBox,
    CInfiniteCalendar,
    CModalStep,
    CModalFloat,
  },

  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const navManager = useNavManager();
    const { t } = useI18n();
    const {
      visitState,
      isPreentry,
      visitId,
      isChangePreentryDatetime,
      clearVisitState
    } = useCreateVisitStep();
    
    const {
      currentTab,
      customDate,
      customDateFormat,
      setCustomDate,
      clearCustomDate,
      viewSheduleDate,
      isSelectDate,
    } = usePreenryDatetimePage();

    const excludeVisitIds: string[] = [];
    const { updatePreentryDatetime: updatePreentryDatetimeRaw } = useVisitPreentryChangeDatetime();
    const updateLoading = ref(false);

    const nextButtonDisabled = computed(() => updateLoading.value);
    const nextButtonText = computed(() => {
      return (updateLoading.value)
        ? 'Подождите...'
        : t('views.visits_new_preentry_datetime.modal_confirm_btn')
      ;
    });

    // Если изменяем дату и время у существующего визита,
    // то нужно исключить его отображение из расписания.
    if (isChangePreentryDatetime && visitState.body.id) {
      excludeVisitIds.push(visitState.body.id);
    }

    async function update() {
      updateLoading.value = true;

      try {
        await updatePreentryDatetimeRaw();
        clearVisitState();
      } catch (e: any) {
        toast.error(e);
      } finally {
        updateLoading.value = false;
      }
    }

    function getSaveDate(): Date|null {
      const date = get(visitState.meta, 'saveDate', null)
      return (date instanceof Date) ? (new Date(date)) : null;
    }

    const normativeTimeMinutes = asyncComputed(async () => {
      return store.service.calcNormativesProvideService(
        visitState.body.providedServices || []
      );
    }, 0);

    /** @private */
    function getInitValue(): PreentryValue|null {
      if (visitState.body.creationDate && visitState.body.box) {
        const datetime = parseToDate(visitState.body.creationDate);

        setCustomDate(new Date(datetime));

        return {
          datetime,
          boxId: visitState.body.box.id,
        };
      }

      return null;
    }

    /** @private */
    function timeMinutesToText(minutes: number) {
      minutes = minutes % MINUTES_DAY_NUMBER;
      const [h, m] = timeNumberToHM(minutes);
      const mm = m > 9 ? `${m}` : `0${m}`;
      return `${h}:${mm}`;
    }

    const preentryValue = ref<PreentryValue|null>(getInitValue());
    const previewState = ref<StateValue>(preentryValue.value ? 'opened' : 'closed');
    const pointerInfo = ref<PointerInfo|null>(null);
    function setPointerInfo(info: PointerInfo|null) {
      pointerInfo.value = info;
      previewState.value = info ? 'preview' : 'closed';
    }

    if (!preentryValue.value) {
      // Отобразить заранее сохраненную дату
      setCustomDate(getSaveDate());
    }

    function onClickBackgroupPreviewModal() {
      if (previewState.value === 'opened') {
        previewState.value = 'preview';
      }
    }
    
    const BOX_REPLACE_PREVIEW_REGV = new RegExp(t('views.visits_new_preentry_datetime.modal_info_label_box_name') + ' ', 'i');
    const previewHeight = computed(() => isTabletAndUp.value ? 120 : 145);
    const previewInfo = computed(() => {
      let dateText = '—';
      let timeText = '—';
      let boxText = '—';
      let isBoxNumber = false;

      if (pointerInfo.value && preentryValue.value) {
        dateText = formatDate(preentryValue.value.datetime);
        timeText = timeMinutesToText(pointerInfo.value.time)
          + ' — '
          + timeMinutesToText(pointerInfo.value.time + normativeTimeMinutes.value);
        
        boxText = String(pointerInfo.value.box.name);
        isBoxNumber = BOX_REPLACE_PREVIEW_REGV.test(boxText)
        if (isBoxNumber) {
          boxText = boxText.replace(BOX_REPLACE_PREVIEW_REGV, '');
        }
      }

      const boxLabel = isBoxNumber
        ? t('views.visits_new_preentry_datetime.modal_info_label_box_number')
        : t('views.visits_new_preentry_datetime.modal_info_label_box_name');

      return [
        {
          label: t('views.visits_new_preentry_datetime.modal_info_label_time'),
          value: timeText
        },
        {
          label: t('views.visits_new_preentry_datetime.modal_info_label_date'),
          value: dateText
        },
        {
          label: boxLabel,
          value: boxText
        },
      ];
    });

    async function confirm() {
      if (!preentryValue.value) {
        toast.error(t('views.visits_new_preentry_datetime.error_empty_datetime'));
        return;
      }

      visitState.body.creationDate = dateToServerDatetimeString(preentryValue.value.datetime);
      visitState.body.box = { id: preentryValue.value.boxId };

      if (isChangePreentryDatetime) {
        await update();

        navManager.navigate({
          routerLink: { name: 'visits-preentry' },
          routerDirection: 'root',
        });
      }
      else {
        router.push({
          name: isPreentry ? 'preentry-doers' : 'visit-new-doers',
          params: { visitId },
        });
      }
    }

    // При выборе даты в календаре, лучше сбросить ранее выбранное значение
    watch(viewSheduleDate, () => {
      preentryValue.value = null;
    });

    const showTabletPreviewModal = computed(() => !!preentryValue.value && isTabletAndUp.value);
    const floatModalStyles = ref<Record<string, any>>({});

    function updatePointerRect(rect: PointerRect|null) {
      if (!rect) return;

      const edgeRight = rect.container.right - rect.right;
      const edgeLeft = rect.left - rect.container.left;
      const edgeBottom = rect.container.scrollHeight - rect.offsetTop - rect.height;

      const GAP = 10;
      const MIN_WINDOW_WIDTH = 200;

      let top = 'auto';
      let bottom = 'auto';
      let left = 'auto';
      let right = 'auto';

      let inset = false; // Когда, окно не помещается по бокам, то оно помещается во внутрь
      let maxWidthNumber = 0;

      // Отобразить окно внутри слева
      if (edgeRight < MIN_WINDOW_WIDTH && edgeLeft < MIN_WINDOW_WIDTH) {
        left = '0px';
        inset = true;

        maxWidthNumber = edgeRight - GAP * 2;
      }
      // Отобразить окно справа
      else if (edgeRight >= edgeLeft || rect.offsetLeft < MIN_WINDOW_WIDTH) {
        maxWidthNumber = edgeRight - GAP * 2;
        left = (rect.offsetLeft + rect.width + GAP) + 'px';
      }
      // Отобразить окно слева
      else {
        maxWidthNumber = edgeLeft - GAP * 2;
        right = (rect.container.width - rect.offsetLeft + GAP) + 'px';
      }

      // Выровнить по верхнему краю курсора
      if (edgeBottom > 500) {
        top = (inset ? rect.offsetTop + rect.height : rect.offsetTop) + 'px';
      }
      // Выровнить по нижнему краю курсора
      else {
        bottom = (inset ? edgeBottom + rect.height : edgeBottom) + 'px';
      }

      const maxWidth = Math.min(300, maxWidthNumber) + 'px';

      floatModalStyles.value = { top, left, right, bottom, maxWidth };
    }

    function onClickBackgroupFloatModal() {
      preentryValue.value = null;
    }

    function closeFloatModal() {
      preentryValue.value = null;
    }

    return {
      currentTab,
      viewSheduleDate,
      isSelectDate,
      customDate,
      customDateFormat,
      setCustomDate,
      clearCustomDate,
      normativeTimeMinutes,
      preentryValue,
      previewHeight,
      setPointerInfo,
      previewState,
      previewInfo,
      confirm,
      isTabletAndUp,
      onClickBackgroupPreviewModal,
      updatePointerRect,
      floatModalStyles,
      showTabletPreviewModal,
      onClickBackgroupFloatModal,
      closeFloatModal,
      excludeVisitIds,
      nextButtonDisabled,
      nextButtonText,
    };
  }
});
</script>

<style lang="scss">
.page-preentry-datetime-toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --min-height: 46px;

  ion-segment-button {
    min-width: auto !important;
  }
}

.visit-new-preentry-shedule-box {
  &.c-shedule-box {
    .c-shedule-box-pointer {
      position: relative;
      z-index: 11;
    }
  }

  .c-shedule-grid {
    &__header {
      z-index: 10;
    }

    &__grid {
      position: relative;
    }
  }

  .c-modal-float {
    position: absolute;
    z-index: 9;

    &__backgroup {
      opacity: 0.2;
      position: fixed;
    }

    &__modal-container {
      width: 380px;
    }
  }
}

.visits-new-preentry-calendar {
  .c-calendar-mounth {
    &__date-cell {
      &--before-now {
        opacity: 0.4;
      }
    }
  }
}

</style>