import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-group-partner-input-card" }
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_skeleton_text = _resolveComponent("ion-skeleton-text")!
  const _component_ion_note = _resolveComponent("ion-note")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_c_card_list = _resolveComponent("c-card-list")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_c_card_list, null, {
      default: _withCtx(() => [
        (_ctx.isSelected)
          ? (_openBlock(), _createBlock(_component_ion_item, { key: 0 }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_label, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('components.group_partner_input_card.label')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_note, {
                  slot: "end",
                  color: "light"
                }, {
                  default: _withCtx(() => [
                    (_ctx.loadingGroupInfo)
                      ? (_openBlock(), _createBlock(_component_ion_skeleton_text, {
                          key: 0,
                          animated: "",
                          style: {
            width: '100px',
            height: '1em',
          }
                        }))
                      : (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.selectedGroupInfo.name), 1))
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }))
          : _createCommentVNode("", true),
        _createVNode(_component_ion_item, {
          class: "item-link-button ion-text-center",
          onClick: _ctx.openSlectModal
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.isSelected
            ? _ctx.$t('components.group_partner_input_card.btn_edit')
            : _ctx.$t('components.group_partner_input_card.btn_select')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    })
  ]))
}