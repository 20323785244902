<template>
  <div :class="{
    'c-modal-step': true,
    [`c-modal-step--${lazyValue}`]: true,
  }">
    <div class="c-modal-step__backgroup" @click="$emit('click-backgroup', $event)" />

    <div
      class="c-modal-step__modal-container"
      ref="modalContainer"
      :style="{
        transform: `translateY(${translateYUnit})`,
        transition: isMove ? 'none' : null,
      }"
    >
      <slot v-bind="{
        setState,
        open,
        close,
        preview,
      }" />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, PropType, computed, watch } from 'vue';
import { createGesture, GestureDetail, Gesture } from '@ionic/vue';

export type StateValue = 'closed'|'preview'|'opened';

const EDGE_ACTION_OFFSET_PX = 60;

export default defineComponent({
  emits: ['update:state', 'closed', 'preview', 'opened', 'click-backgroup'],

  props: {
    previewHeight: {
      type: Number,
      required: true,
    },
    state: {
      type: String as PropType<StateValue>,
      validate: (v: string) => ['closed', 'preview', 'opened'].includes(v), 
    }
  },

  setup(props, { emit }) {
    const modalContainer = ref<HTMLDivElement|null>(null);
    const isMove = ref(false);
    let moveTranslateYStart = 0;
    const moveTranslateY = ref(0);
    const translateYUnit = computed<string>(() => {
      if (isMove.value) return `${moveTranslateY.value}px`;

      switch (lazyValue.value) {
        case 'closed': return '100%';
        case 'preview': return `calc(100% - ${props.previewHeight}px)`;
      }

      return '0';
    });

    function getTranslateYStart() {
      if (isMove.value) return moveTranslateY.value;
      if (!modalContainer.value) return 0;

      switch (lazyValue.value) {
        case 'closed': return modalContainer.value.clientHeight;
        case 'preview': return modalContainer.value.clientHeight - props.previewHeight;
      }

      return 0; // opened
    }

    const lazyValue = ref<StateValue>(props.state || 'closed');
    watch(() => props.state, value => lazyValue.value = value || 'closed');

    function setState(value: StateValue) {
      lazyValue.value = value;
      emit('update:state', value);
      emit(value);
    }

    function onStart() {
      moveTranslateYStart = getTranslateYStart();
      moveTranslateY.value = moveTranslateYStart;
      isMove.value = true;
    }

    function onMove(ev: GestureDetail) {
      if (!modalContainer.value) return;

      const modalHeight = modalContainer.value.clientHeight;
      const correctOffset = Math.max(0, Math.min(modalHeight, ev.deltaY + moveTranslateYStart));
      moveTranslateY.value = correctOffset;
    }

    function onEnd(ev: GestureDetail) {
      if (!modalContainer.value) return;

      const modalHeight = modalContainer.value.clientHeight;
      const correctOffset = Math.max(0, Math.min(modalHeight, ev.deltaY + moveTranslateYStart));

      let newValue: StateValue;

      if (correctOffset < EDGE_ACTION_OFFSET_PX) {
        newValue = 'opened';
      } else if (correctOffset + EDGE_ACTION_OFFSET_PX > modalHeight) {
        newValue = 'closed';
      } else {
        newValue = 'preview';
      }

      isMove.value = false;
      setState(newValue);
    }

    let gesturePointer: Gesture|null = null;
    onMounted(() => {
      if (!modalContainer.value) return;

      gesturePointer = createGesture({
        el: modalContainer.value,
        threshold: 15,
        direction: 'y',
        gestureName: 'modal-move',
        onStart: onStart,
        onMove: onMove,
        onEnd: onEnd,
      });

      gesturePointer.enable();
    });

    return {
      modalContainer,
      translateYUnit,
      setState,
      isMove,
      lazyValue,
      open: () => setState('opened'),
      close: () => setState('closed'),
      preview: () => setState('preview'),
    };
  },
});
</script>

<style lang="scss">
@import "@/scss/abstract";

.c-modal-step {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-end;
  z-index: 20001;
  pointer-events: none;
  touch-action: none;

  > * { pointer-events: all; touch-action: auto; }

  &__backgroup {
    background: var(--core-backgroup-color);
    opacity: 0.6;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    transition: opacity 0.5s ease;
  }

  &--preview &__backgroup,
  &--closed &__backgroup {
    pointer-events: none;
    opacity: 0;
  }

  &__modal-container {
    --ion-background-color: var(--core-toolbar-bg);
    --background: var(--core-toolbar-bg);
    overflow: hidden;

    background: var(--core-toolbar-bg);
    border-radius: var(--core-card-radius) var(--core-card-radius) 0 0;
    color: var(--core-light);
    position: relative;
    z-index: 2;
    transition: transform 0.3s ease;
  }

  @include ionic-tablet {
    align-items: center;
    padding: 0 var(--ion-padding);

    &__modal-container {
      width: 100%;
      max-width: 500px;
    }
  }
}
</style>