<template>
  <ion-page>
    <app-header :title="$t('views.settings.settings_modal_title')">
      <template #end>
        <c-transparent-button @click="close">
          <ion-icon name="close-outline" class="icon-medium" />
        </c-transparent-button>
      </template>
    </app-header>

    <ion-content class="ion-padding">
      <AppSettings
        @logout="close"
        @clear-cache="close"
        @close-settings="close"
      />
    </ion-content>

    <ion-footer></ion-footer>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import AppSettings from '@/components/app-service/AppSettings.vue'
import { modalController } from '@ionic/vue'

export default defineComponent({
  components: {
    AppSettings,
  },
  setup() {
    function close() {
      return modalController.dismiss();
    }

    return {
      close,
    };
  },
});
</script>

<style lang="scss">
</style>