<script lang="ts" setup>
import { PropType, computed, defineProps, inject } from 'vue';
import { CarVisitProvideService } from '@/repositories/Models/CarVisit';
import { useCurrency } from '@/composables/currency';

const props = defineProps({
  provideService: {
    type: Object as PropType<CarVisitProvideService>,
    required: true,
  },
  showDiscount: {
    type: Boolean,
    default: true,
  },
});

const { formatCurrency } = useCurrency();

const discountViewBadges = computed<string[]>(() => {
  let badges: string[] = [];

  let staticDiscountPercent = props.provideService.discountPercent || props.provideService.discountCampaign?.percent || 0;
  if (staticDiscountPercent) {
    badges.push(`-${staticDiscountPercent}%`);
  }

  if (props.provideService.discountSum) {
    const sumFormat = formatCurrency(props.provideService.discountSum);
    badges.push('-' + sumFormat);
  }

  return badges;
});

const doersChips = computed(() => {
  return (props.provideService.doers || [])
    .map(doer => doer.name)
    .filter(name => !!name);
});

/**
 * По другому прокинуть обработчик через
 * большое количество компонентов было сложно
 */
const emitClickDoers = inject<((payload: CarVisitProvideService) => void)|null>('actionClickDoers', null);
function onClickDoers() {
  emitClickDoers?.call(null, props.provideService);
}
</script>

<template>
  <ion-item class="service-view-item ion-align-items-start">
    {{ provideService.service?.name }}

    <!-- eslint-disable-next-line -->
    <div class="service-view-item__addition-info" slot="end">
      <div
        v-if="doersChips.length"
        class="service-view-item__doers"
        @click="onClickDoers"
      >
        <ion-chip
          v-for="(name, i) in doersChips"
          :key="i"
          color="secondary"
          class="service-view-item__doer-chip chip-filled"
        >
          {{ name }}
        </ion-chip>
      </div>

      <div class="service-view-item__price-info">
        <ion-chip
          v-for="badge in discountViewBadges"
          :key="badge"
          color="success"
          class="chip-size-small chip-filled"
        >
          {{ badge }}
        </ion-chip>

        <div class="text-muted" v-if="provideService.qty > 1">
          <cc :price="provideService.singleItemPrice" v-slot="{ priceFormatted }">
            {{ priceFormatted }} X{{ provideService.qty }}
          </cc>
        </div>

        <div><cc :price="provideService.totalPrice" /></div>
      </div>
    </div>
  </ion-item>
</template>

<style lang="scss">
</style>