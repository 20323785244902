import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = ["innerHTML"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_service_provide_view_item = _resolveComponent("service-provide-view-item")!
  const _component_ion_item_group = _resolveComponent("ion-item-group")!

  return (_openBlock(), _createBlock(_component_ion_item_group, null, {
    default: _withCtx(() => [
      _createVNode(_component_ion_item, { class: "color-default" }, {
        default: _withCtx(() => [
          _createElementVNode("span", { innerHTML: _ctx.categoryName }, null, 8, _hoisted_1)
        ]),
        _: 1
      }),
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group.providedServices, (provideService) => {
        return (_openBlock(), _createBlock(_component_service_provide_view_item, {
          key: provideService.id,
          "provide-service": provideService
        }, null, 8, ["provide-service"]))
      }), 128))
    ]),
    _: 1
  }))
}