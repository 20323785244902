import { computed, ref } from 'vue';
import { useToast } from './toast';
import { get } from 'lodash';

const flashlight: any = get(window, 'plugins.flashlight'); // NOTE: PhoneGap/Cordova Plugin

/**
 * Global Flashlight status
 */
const flashlightActive = ref(false);
const flashlightIsAvalible = ref<boolean|null>(null);
const flashlightSwitchWait = ref(false);

export function useFlashlight() {
  const toast = useToast();

  /** @private */
  function testAvalible() {
    if (!flashlight) return;

    flashlight.available((isAvailable: boolean) => {
      flashlightIsAvalible.value = !!isAvailable;
    });
  }
  
  /** @private */
  function updateState() {
    flashlightActive.value = !!flashlight && flashlight.isSwitchedOn() === true;
  }

  /** Включить фонарик */
  function switchOn() {
    if (flashlightSwitchWait.value) {
      return;
    }

    if (flashlight) {
      flashlightSwitchWait.value = true;

      flashlight.switchOn(() => {
        updateState();
        flashlightSwitchWait.value = false;
      }, () => {
        flashlightSwitchWait.value = false;
        toast.error('Не удалось включить фонарик');
      });
    }
  }

  /** Выключить фонарик */
  function switchOff() {
    if (flashlightSwitchWait.value) {
      return;
    }

    if (flashlight) {
      flashlightSwitchWait.value = true;

      flashlight.switchOff(() => {
        updateState();
        flashlightSwitchWait.value = false;
      }, () => {
        flashlightSwitchWait.value = false;
        toast.error('Не удалось выключить фонарик');
      });
    }
  }

  /** Переключить фонарик */
  function switchToggle() {
    if (flashlightIsAvalible.value !== true) {
      throw new Error('Вспышка недоступна');
    }

    if (flashlightActive.value) {
      switchOff();
    } else {
      switchOn();
    }
  }

  const active = computed(() => flashlightActive.value);
  const isAvailable = computed(() => flashlightIsAvalible.value === true);

  updateState();

  if (flashlightIsAvalible.value === null) {
    testAvalible();
  }

  return {
    switchToggle,
    switchOn,
    switchOff,
    active,
    isAvailable,
  };
}