import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createBlock as _createBlock } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = ["src"]
const _hoisted_3 = {
  key: 2,
  class: "text-muted ion-text-center ion-padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_c_transparent_button = _resolveComponent("c-transparent-button")!
  const _component_app_header = _resolveComponent("app-header")!
  const _component_c_spinner = _resolveComponent("c-spinner")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header, { title: _ctx.title }, {
        end: _withCtx(() => [
          _createVNode(_component_c_transparent_button, { onClick: _ctx.close }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                name: "close-outline",
                class: "icon-medium"
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          (_ctx.loadingDocument)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_c_spinner, {
                  class: "mt-4 mb-3",
                  "loading-text": _ctx.$t('views.report_modal.loading_document')
                }, null, 8, ["loading-text"])
              ]))
            : (_ctx.pdfObjectUrl)
              ? (_openBlock(), _createElementBlock("iframe", {
                  key: 1,
                  class: "report-modal-iframe",
                  src: _ctx.viewerFullUrl,
                  frameborder: "0"
                }, null, 8, _hoisted_2))
              : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('views.report_modal.document_undefined')), 1))
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}