import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, createBlock as _createBlock, mergeProps as _mergeProps, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-padding ion-text-center text-muted"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_modal_action_header = _resolveComponent("c-modal-action-header")!
  const _component_c_modal_select_option = _resolveComponent("c-modal-select-option")!
  const _component_c_modal_select = _resolveComponent("c-modal-select")!
  const _component_c_modal_content = _resolveComponent("c-modal-content")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_c_modal_action_header, { tag: "ion-header" }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.modalTitle), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_c_modal_content, null, {
      default: _withCtx(() => [
        _createVNode(_component_c_modal_select, null, {
          default: _withCtx(() => [
            (!_ctx.options.length)
              ? (_openBlock(), _createElementBlock("div", _hoisted_1, _toDisplayString('Список пуст')))
              : _createCommentVNode("", true),
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (item) => {
              return (_openBlock(), _createBlock(_component_c_modal_select_option, {
                key: item[_ctx.propValue],
                selected: _ctx.isSelected(item[_ctx.propValue]),
                onClick: ($event: any) => (_ctx.toggleOption(item))
              }, {
                default: _withCtx(() => [
                  (_ctx.innerItemComponent)
                    ? (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.innerItemComponent), _normalizeProps(_mergeProps({ key: 0 }, { text: item[_ctx.propText], item })), null, 16))
                    : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                        _createTextVNode(_toDisplayString(item[_ctx.propText]), 1)
                      ], 64))
                ]),
                _: 2
              }, 1032, ["selected", "onClick"]))
            }), 128)),
            _renderSlot(_ctx.$slots, "select-after")
          ]),
          _: 3
        })
      ]),
      _: 3
    })
  ]))
}