import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, vShow as _vShow, mergeProps as _mergeProps, withCtx as _withCtx, withDirectives as _withDirectives, createCommentVNode as _createCommentVNode, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "c-visit-archive-group__title" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!

  return (_openBlock(), _createBlock(_component_ion_grid, { class: "c-visit-archive-group" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, _toDisplayString(_ctx.group.title), 1),
      _createVNode(_component_ion_row, null, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.group.items, (visit, i) => {
            return _withDirectives((_openBlock(), _createBlock(_component_ion_col, _mergeProps(_ctx.colSizes, {
              size: "12",
              key: visit.id
            }), {
              default: _withCtx(() => [
                (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.viewComponentName), {
                  class: "ion-no-margin",
                  visit: visit,
                  onClick: ($event: any) => (_ctx.onClickCard(visit))
                }, null, 8, ["visit", "onClick"]))
              ]),
              _: 2
            }, 1040)), [
              [_vShow, _ctx.expand || _ctx.itemsViewCount > i]
            ])
          }), 128)),
          (_ctx.isShowMore)
            ? (_openBlock(), _createBlock(_component_ion_col, {
                key: 0,
                "size-xl": "3",
                "size-lg": "4",
                "size-sm": "6",
                size: "12"
              }, {
                default: _withCtx(() => [
                  _createElementVNode("div", {
                    class: "c-visit-archive-group__show-more",
                    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.expand = !_ctx.expand))
                  }, _toDisplayString(_ctx.showMoreBtnText), 1)
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}