import { StaticDiscountCampaign } from '@/repositories/Models/Discount';
import { find, get, isEmpty, keyBy } from 'lodash';
import { datetimeToTimeNumber, getDateCleanTime, parseToDate } from './datetime';

/**
 * Проверка статической дисконтной кампании на дату
 * Если критерий не задан, вернет true
 * 
 * @param campain 
 * @returns 
 */
export function checkStaticDates(campain: StaticDiscountCampaign): boolean {
  // Если даты не указаны => действует все время
  if (!campain.validity) return true;

  // Если дата отключена, значит действует
  // в любую дату в заданный промежуток времени
  if (!campain.validity.dateEnabled) return true;

  // Такого теоритически быть не должно
  if (!campain.validity.dateFrom || !campain.validity.dateTo) {
    console.error('Не указаны обязательные даты для скидки');
    return false;
  }

  const dateNow = getDateCleanTime(new Date());
  const dateFrom = getDateCleanTime(parseToDate(campain.validity.dateFrom));
  const dateTo = getDateCleanTime(parseToDate(campain.validity.dateTo));

  return (dateNow >= dateFrom && dateNow <= dateTo);
}

/**
 * Проверка статической дисконтной кампании на временной диапазон
 * Если критерий не задан, вернет true
 * 
 * @param campain 
 * @returns 
 */
export function checkStaticTimes(campain: StaticDiscountCampaign): boolean {
  // Если даты не указаны => действует все время
  if (!campain.validity) return true;

  // Если время отключено, значит действует 
  // все время за указанный промежуток дат
  if (!campain.validity.timeEnabled) return true;

  // Такого теоритически быть не должно
  if (!campain.validity.dateFrom || !campain.validity.dateTo) {
    console.error('Не указаны обязательные даты для скидки');
    return false;
  }

  let timeNow = datetimeToTimeNumber(new Date());
  let timeFrom = datetimeToTimeNumber(parseToDate(campain.validity.dateFrom));
  let timeTo = datetimeToTimeNumber(parseToDate(campain.validity.dateTo));

  // Для времени c 21:00 до 1:00
  // должна применяться другая логика сравнения
  if (timeFrom > timeTo) {
    return (timeNow >= timeFrom || timeNow <= timeTo)
  }

  return (timeNow >= timeFrom && timeNow <= timeTo);
}

/** Перчисление дней недели, для проверки */
const WEEKDAYS_MAP: Record<number, string> = {
  0: 'sunday',    // Воскресенье
  1: 'monday',    // Понедельник
  2: 'tuesday',   // Вторник
  3: 'wednesday', // Среда
  4: 'thursday',  // Четверг
  5: 'friday',    // Пятница
  6: 'saturday',  // Суббота
};

/**
 * Проверка статической дисконтной кампании на указанные дни недели
 * Если критерий не задан, вернет true
 * 
 * @param campain 
 * @returns 
 */
export function checkStaticWeekdays(campain: StaticDiscountCampaign): boolean {
  // Если даты не указаны => действует на все дни недели
  if (!campain.validity) return true;

  // Если время дни недели отключены, значит активна все дни недели
  if (!campain.validity.weekdayEnabled) return true;

  // Такого теоритически быть не должно
  if (!campain.validity.weekdays) {
    console.error('Не указаны обязательные дни недели для статической компании');
    return false;
  }

  const currDayNum = (new Date()).getDay();
  const weekDayKey = WEEKDAYS_MAP[currDayNum] || '';

  return !!get(campain.validity.weekdays, weekDayKey, false);
}

/**
 * Проверка статической дисконтной кампании на участников (клиентов)
 * Если конкретные клиенты не указаны, применяется ко всем
 * 
 * @param campain 
 * @param carId идентификатор клиента/авто
 * @returns 
 */
export function checkStaticForsedAccount(campain: StaticDiscountCampaign, carId: string): boolean {
  // Если аккаунты не указаны, применяется ко сем клиентам
  if (isEmpty(campain.forcedAccounts)) return true;
  
  return !!find(campain.forcedAccounts, accountInfo => {
    return accountInfo.id == carId;
  });
}

/**
 * Проверка компании на применение ко всем услугам
 * в не зависимости от категорий и номенклатуры
 * 
 * @param campain 
 */
export function checkStaticDiscountavAilabilityAllServices(campain: StaticDiscountCampaign) {
  return isEmpty(get(campain, 'applicability.nomenclatures'))
    && isEmpty(get(campain, 'applicability.categories'));
}

/**
 * Создает функцию для проверки компании на номенклатуру
 * 
 * @param campain 
 * @returns 
 */
export function createCheckNomenclatures(campain: StaticDiscountCampaign) {
  const nomenclatures = get(campain, 'applicability.nomenclatures', []);

  const nomenclatureIndex = keyBy(nomenclatures, 'id');

  return (nomenclatureId: string|number) => {
    return !!nomenclatureIndex[`${nomenclatureId}`];
  };
}

/**
 * Создает функцию для проверки компании на категорию номенклатуры
 * 
 * @param campain 
 * @returns 
 */
export function createCheckCategories(campain: StaticDiscountCampaign) {
  const categoriesIndex = keyBy(get(campain, 'applicability.categories', []), 'id');

  return (categoryId: string|number) => {
    return !!categoriesIndex[`${categoryId}`];
  };
}

/**
 * Выбирает из списка компаний, самую приоритетную
 * 
 * @param campaines 
 * @returns 
 */
export function getPriorityCampain(campaines: StaticDiscountCampaign[]) {
  let priorityCampain: StaticDiscountCampaign|null = null;

  // Из всех выбираем компанию с максимальной скидкой
  for (const compain of campaines) {
    if (!priorityCampain || priorityCampain.percent < compain.percent) {
      priorityCampain = compain;
    }
  }

  return priorityCampain;
}

export interface StaticDiscountCampainSuitable {
  campain: StaticDiscountCampaign;
  applicableAllServices: boolean;
  checkCategory: ReturnType<typeof createCheckCategories>;
  checkNomenclature: ReturnType<typeof createCheckNomenclatures>;
}

/**
 * Проверяет список компаний и оставляет только те,
 * которые действуют для данного клиента в данный момент времени.
 * 
 * NOTE: Для проверки категории или услуги на которые она распростроняется,
 * возвращаются специальные функции проверки.
 * 
 * @param campaines 
 * @param carId 
 * @param forsed применимые скидки
 * @param exclude исключаемые скидки
 * 
 * @returns 
 */
export function useSuitableСompanies(campaines: StaticDiscountCampaign[], carId: string, forsed: Array<number|string>, exclude: Array<number|string>) {
  let campainSuitable: StaticDiscountCampainSuitable[] = [];

  const forsedIndex = keyBy(forsed);
  const excludeIndex = keyBy(exclude);

  for (const campain of campaines) {
    // Кампания НЕ активна
    if (!campain.activated) continue;

    // Применима к текущему клиенту
    const checkAccount = !excludeIndex[campain.id]
      && (!!forsedIndex[campain.id] || checkStaticForsedAccount(campain, carId));

    if (!checkAccount) continue;

    const checkDatetimes =   
      checkStaticDates(campain)       // Удовлетворяет установленным датам
      && checkStaticTimes(campain)    // Удовлетворяет заданному времени
      && checkStaticWeekdays(campain) // Удовлетворяет дням недели
    ;

    if (!checkDatetimes) continue;

    campainSuitable.push({
      campain,
      applicableAllServices: checkStaticDiscountavAilabilityAllServices(campain),
      checkCategory: createCheckCategories(campain),
      checkNomenclature: createCheckNomenclatures(campain),
    });
  }

  const discountsApplicableToAll = campainSuitable
    .filter(c => c.applicableAllServices)
    .map(c => c.campain);

  const priorityDiscountApplicableToAll = getPriorityCampain(discountsApplicableToAll);

  /**
   * Получить компанию, для указонной категории
   * 
   * @param categoryId 
   * @returns 
   */
  const getForCategory = (categoryId: string|number, defaultCampain: StaticDiscountCampaign|null = null) => {
    const forCategoryCampangs = campainSuitable
      .filter(c => c.checkCategory(categoryId))
      .map(c => c.campain);

    const priorityCategoryCampain = getPriorityCampain(forCategoryCampangs);
    if (!priorityCategoryCampain) return defaultCampain;

    // Если у скидки по умолчанию % больше, то применяться будет она
    if (defaultCampain && defaultCampain.percent > priorityCategoryCampain.percent) {
      return defaultCampain;
    }

    return priorityCategoryCampain;
  };

  /**
   * Получить компанию, для указонной номенклатуры
   * 
   * NOTE: Если у переданной компании по умолчанию % скидки больше
   * чем у найденой, то вернется кампания по умолчанию
   * 
   * @param nomenclatureId 
   * @param defaultCampain 
   * @returns 
   */
  const getForNomenclature = (nomenclatureId: string|number, defaultCampain: StaticDiscountCampaign|null = null) => {
    const forNomenclatureCampangs = campainSuitable
      .filter(c => c.checkNomenclature(nomenclatureId))
      .map(c => c.campain);

    const priorityNomenclatureCampain = getPriorityCampain(forNomenclatureCampangs);
    if (!priorityNomenclatureCampain) return defaultCampain;

    // Если у скидки по умолчанию % больше, то применяться будет она
    if (defaultCampain && defaultCampain.percent > priorityNomenclatureCampain.percent) {
      return defaultCampain;
    }

    return priorityNomenclatureCampain;
  };

  return {
    campainSuitable,
    getForCategory,
    getForNomenclature,
    discountsApplicableToAll,
    priorityDiscountApplicableToAll,
  };
}
