<script lang="ts" setup>
/**
 * TODO: Логику в данном файле нужно разнести по composables
 */

import { computed, ref, shallowRef, watch, provide, reactive } from 'vue';
import { useStore } from '@/composables/useApp';
import { useRoute, useRouter } from 'vue-router';
import { includes, isEmpty } from 'lodash';
import VisitCard, { VisitCardShortInfo } from '@/components/visit/VisitCard.vue';
import ClientInfoCard from '@/components/car/ClientInfoCard.vue';
import ServicesProvidedViewCard from '@/components/service/provide-view/ServicesProvidedViewCard.vue';
import { getPaymentTypeTitle } from '@/helpers/visit';
import { PaymentTypeEnum } from '@/repositories/Models/Pos';
import { modalController, onIonViewWillLeave } from '@ionic/vue';
import { useToast } from '@/composables/toast';
import { awaitLoading } from '@/ext/loading';
import ServicesAddCategoryGroupModal from '@/components/service/add-category-modal/ServicesAddCategoryGroupModal.vue';
import { VisitTypeAndCategory } from '@/helpers/visit';
import { isTabletDown } from '@/helpers/adaptive';
import PaymentTypeModal from '@/components/pos/PaymentTypeModal.vue';
import { useNavManager } from '@/composables/useNavManager';
import { useMetrica } from '@/composables/useMetrica';
import { useAlert } from '@/composables/alert';
import { useI18n } from 'vue-i18n';
import { getEmptyNewVisitState } from '@/store/NewVisitState';
import { useVisitCounterparty, useVisitPreentryChangeDatetime } from '@/composables/visit';
import type { ModalServicesContext } from '@/composables/visit';
import { ChangeProvidedServicesAction } from '@/components/service/add-category-modal/modal';
import { useFlashlight } from '@/composables/flashlight';
import VisitAddPhotoButton from '@/components/visit/VisitAddPhotoButton.vue';

import {
  convertProvidedServicesToBodyItems,
  provideServicesBodyGroupToList,
  mapProvideServicesBodyItemsForGroup,
  prepareProvidedServicesBodyItems,
} from '@/helpers/visit';
import {
  CarVisitBodyRequest,
  CarVisitCheckupEnum,
  CarVisitResponse,
  CarVisitStatusEnum,
  ProvideServiceBodyItem
} from '@/repositories/Models/CarVisit';

// NOTE: Почему-то vetur неправильно определяет использование компонента:
// eslint-disable-next-line
import VisitShortInfo from '@/components/visit/VisitShortInfo.vue';

const store = useStore();
const route = useRoute();
const router = useRouter();
const toast = useToast();
const navManager = useNavManager();
const { emitEvent } = useMetrica();
const { createAlertConfirm } = useAlert();
const { t } = useI18n();

//#region Load Visit & other info
const visitId = route.params.visitId as string;
const visit = ref<CarVisitResponse|null>(null);
const isCahier = ref(false);
const isPreentry = computed(() => {
  return visit.value?.status === CarVisitStatusEnum.New
    && visit.value?.checkup === CarVisitCheckupEnum.PendingPreentry;
});
const eventPrefix = computed(() => isPreentry.value ? 'visit/preentry' : 'visit');

provide('visit', visit);

async function loadCahier() {
  isCahier.value = await store.user.isCahier();
}

async function loadVisit() {
  const { data } = await store.visit.getCarVisit(visitId);
  visit.value = data;
}

/** @private Обновить данные заказа с отображение полноэкранной загрузки */
async function updateVisit(body: CarVisitBodyRequest) {
  await awaitLoading(async () => {
    let { response } = await store.visit.update(visitId, body);

    if (response) {
      visit.value = response.data;
    } else {
      await loadVisit();
    }
  }, {});
}

// TODO: Переписать с использованием awaitLoading
/** Обновить информацию о визите в фоне */
function reloadVisitBackground() {
  return awaitLoading(() => loadVisit(), {
    message: t('views.visit_single.update_visit_info_backgroung'),
  });
}

function load() {
  return Promise.all([
    loadVisit(),
    loadCahier(),
  ]);
}

const visitsCounters = store.visit.getCountersRef();
let emptyInited = !!visitsCounters.value.emptyInited;
const syncReloadOff = watch(visitsCounters, async () => {
  // Чтобы при первом запуске не делать лишний повторный запрос
  if (emptyInited === true) {
    emptyInited = false;
    return;
  }

  // По сути обновление выполнется, только после того, как:
  // 1) заказ был создан в режиме оффлайн ->
  // 2) была открыта данная страница ->
  // 3) произошло соединение с сервером и синхронизация ->
  // 4) обновляем данные заказа получаемые с сервера а не из кэша.
  if (visit.value?.isOffline) {
    await reloadVisitBackground(); // async
    if (visit.value?.isOffline) return;
  }

  syncReloadOff();
}, { deep: true });
//#endregion

const paymentTypeTitle = computed(() => visit.value ? getPaymentTypeTitle(visit.value.paymentType || PaymentTypeEnum.Cash) : '');

//#region Завершить обслуживание
// Возможность закрыть заказ
const oppCloseVisit = computed(() => includes([
  CarVisitStatusEnum.Processed,
  CarVisitStatusEnum.Opened,
], visit.value?.status));

/** @click Действие закрытия заказа */
function closeVisitAction() {
  alertConfirmCloseVisit(); // async
}

/** Окно для подтверждения закрытия закза */
const alertConfirmCloseVisit = createAlertConfirm({
  header: t('views.visit_single.alert_close_visit_header'),
  message: t('views.visit_single.alert_close_visit_message'),
  async confirm() {
    emitEvent(`${eventPrefix.value}/finish`);
    await closeVisitQuery();
  }
});

const finishVisitLoading = ref(false);
/** Закрытие заказа */
async function closeVisitQuery() {
  finishVisitLoading.value = true;
  try {
    await store.visit.finish(visitId);

    if (visit.value?.isPayed) {
      navManager.navigate({
        routerDirection: 'root',
        routerLink: { name: 'visits' },
      });
    } else {
      reloadVisitBackground(); // async
    }
  } catch (e) {
    toast.error(e, 5000, {
      defaultHeader: t('views.visit_single.header_error_finish_visit')
    });
  } finally {
    finishVisitLoading.value = false;
  }
}
//#endregion

//#region Оплата заказа
// Возможность оплатить заказ
const oppPay = computed(() => isCahier.value && (visit.value?.isPayable || false) && !isPreentry.value);

async function payVisit() {
  if (false === isCahier.value) {
    return toast.warning(t('views.visit_single.pay_warning_not_cashier'));
  }

  const points = visit.value?.car?.discountAccount?.scores;
  const hasPoints = !!points && points > 0;
  const hasGroup = !!visit.value?.group;

  const modal = await modalController.create({
    cssClass: 'core-modal-actions',
    component: PaymentTypeModal,
    componentProps: {
      points,
      hasPoints,
      hasGroup,
      setValue(paymentType: PaymentTypeEnum, usePoints: boolean) {
        emitEvent(`${eventPrefix.value}/pay/open`, { paymentType, usePoints });

        router.push({
          name: 'visit-pay',
          params: {
            action: 'confirm',
            visitId,
            paymentType,
          },
          query: {
            sum: visit.value?.price,
            points: usePoints && hasPoints
              ? (points || 0) : undefined,
            status: visit.value?.status,
          }
        });
      }
    },
    swipeToClose: true,
  });

  modal.present(); // async

  // В новой версии ionic не работает, почему - неизвестно
  // await openPaymentTypeModal({
  //   points,
  //   hasPoints,
  //   hasGroup,
  //   setValue(paymentType: PaymentTypeEnum, usePoints: boolean) {
  //     emitEvent(`${eventPrefix.value}/pay/open`, { paymentType, usePoints });

  //     router.push({
  //       name: 'visit-pay',
  //       params: {
  //         action: 'confirm',
  //         visitId,
  //         paymentType,
  //       },
  //       query: {
  //         sum: visit.value?.price,
  //         points: usePoints && hasPoints
  //           ? (points || 0) : undefined,
  //         status: visit.value?.status,
  //       }
  //     });
  //   }
  // });
}
//#endregion

//#region Действия предварительной записи
const oppGetStarted = computed(() => isPreentry.value);
const oppCancelPreentryVisit = computed(() => isPreentry.value);

const oppChangeDatetimePreentryVisit = computed(() => isPreentry.value);

/**
 * Приступить к выполнению
 *
 * NOTE: В зависимости от того выбраны ли все исполнители для заказа или нет,
 * показать окно с подтверждением начала или показать модальное окно с выбором исполнителей.
 */
const preentryGetStarted = () => {
  if (checkMandatoryDoers()) {
    preentryGetStartedAlert();
  } else {
    preentryGetStartedСhoiceDoers();
  }
};

/**
 * Приступить к выполеннию заказа, только после выбора исполнителей
 */
const preentryGetStartedСhoiceDoers = () => {
  changeProvidedServices({
    action: ChangeProvidedServicesAction.UpdateDoers,
    userCloseModal: true,
    componentProps: {
      doerViewNextButtonText: t('views.visit_single.action_preentry_apply_doers_and_get_started'),
    },
    async updateHandler(providedServices) {
      emitEvent('visit/preentry/get-started');

      await updateVisit({
        providedServices: prepareProvidedServicesBodyItems(providedServices),
        status: CarVisitStatusEnum.Processed,
        checkup: CarVisitCheckupEnum.Verified,
      });
    },
  });
};

/**
 * Показать alert окно с подтвержением и приступить к выполнению
 */
const preentryGetStartedAlert = createAlertConfirm({
  header: t('views.visit_single.alert_preentry_get_started_header'),
  message: t('views.visit_single.alert_preentry_get_started_message'),
  async confirm() {
    emitEvent('visit/preentry/get-started');

    await updateVisit({
      status: CarVisitStatusEnum.Processed,
      checkup: CarVisitCheckupEnum.Verified,
    });
  }
});

/**
 * Проверяем выбраны ли исполнители, для услуг.
 * - Товары на назначенных исполнителей не проверяем.
 */
function checkMandatoryDoers(): boolean {
  if (!visit.value) {
    return false;
  }

  for (const provideService of visit.value.providedServices || []) {
    if (isEmpty(provideService.doers) && !provideService.service.isProduct) {
      return false;
    }
  }

  return true;
}

const preentryCancelVisit = createAlertConfirm({
  header: t('views.visit_single.alert_preentry_cancel_header'),
  message: t('views.visit_single.alert_preentry_cancel_message'),
  async confirm() {
    emitEvent('visit/preentry/delete');
    await updateVisit({
      checkup: CarVisitCheckupEnum.Rejected,
    });

    navManager.navigate({
      routerDirection: 'root',
      routerLink: {
        name: 'visits-preentry',
      }
    });
  }
});
//#endregion Приступить к выполнению

//#region Возврат в работу (открытие заказа)
// Возможность вернуть в работу
const oppReturnWork = computed(() => includes([
  CarVisitStatusEnum.Finished,
  CarVisitStatusEnum.Canceled,
  CarVisitStatusEnum.Archive
], visit.value?.status));

/** @click Действие открытия заказа */
function reopenVisitAction() {
  alertConfirmReopenVisit(); // async
}

/** Окно для подтверждения открытия закза */
const alertConfirmReopenVisit = createAlertConfirm({
  header: t('views.visit_single.alert_reopen_visit_header'),
  message: t('views.visit_single.alert_reopen_visit_message'),
  async confirm() {
    emitEvent(`${eventPrefix.value}/reopen`);
    await reopenVisitQuery();
  }
});

const reopenVisitLoading = ref(false);
/** Вернуть заказ в работу */
async function reopenVisitQuery() {
  reopenVisitLoading.value = true;
  try {
    await store.visit.rollback(visitId);
    reloadVisitBackground(); // async
  } catch (e) {
    toast.error(e, 5000, {
      defaultHeader: t('views.visit_single.header_error_process_visit')
    });
  } finally {
    reopenVisitLoading.value = false;
  }
}
//#endregion

//#region Возврат средств
const oppReturnPay = computed(() => !!visit.value?.isPayed);

/** @click Действие возврата средств */
function returnPayAction() {
  emitEvent(`${eventPrefix.value}/pay/return/open`);

  router.push({
    name: 'visit-pay',
    params: {
      action: 'return',
      visitId,
      paymentType: visit.value?.paymentType || PaymentTypeEnum.Cash,
    },
    query: {
      sum: visit.value?.payed,
      points: visit.value?.payedByScores || undefined,
    }
  });
}
//#endregion

//#region Чеки (пречек, фискальный)
const oppInvoice = computed(() => {
  if (oppCloseVisit.value) {
    return true;
  }

  if (visit.value?.status === CarVisitStatusEnum.New) {
    return visit.value?.checkup === CarVisitCheckupEnum.Verified;
  }

  return false;
});

const alertConfirmInvoice = createAlertConfirm({
  header: t('views.visit_single.alert_invoice_header'),
  message: t('views.visit_single.alert_invoice_message'),
  confirmBtnText: t('views.visit_single.alert_invoice_btn_confirm'),
  async confirm() {
    emitEvent(`${eventPrefix.value}/invoice/print`);

    await awaitLoading(() => store.visit.invoice(visitId), {
      message: t('views.visit_single.invoice_processing_wait')
    });
  },
});

const oppInvoiceFiscal = computed(() => !!visit.value?.isPayed);
const alertConfirmInvoiceFiscal = createAlertConfirm({
  header: t('views.visit_single.alert_invoice_fiscal_header'),
  message: t('views.visit_single.alert_invoice_fiscal_message'),
  confirmBtnText: t('views.visit_single.alert_invoice_fiscal_btn_confirm'),
  async confirm() {
    emitEvent(`${eventPrefix.value}/invoice/fiscal`);

    await awaitLoading(() => store.visit.invoiceFiscal(visitId), {
      message: t('views.visit_single.invoice_fiscal_processing_wait'),
    });
  }
});
//#endregion

//#region Изменение деталей заказа (выбор доп услуг)
const oppChangeProvidedServices = computed(() => {
  return !visit.value?.isPayed && includes(
    [
      CarVisitStatusEnum.New,
      CarVisitStatusEnum.Processed,
      CarVisitStatusEnum.Opened
    ],
    visit.value?.status
  );
});

interface ChangeProvidedServicesOptions {
  action?: ChangeProvidedServicesAction;
  userCloseModal?: boolean;
  componentProps?: { [prop: string]: any; };
  updateHandler?: (providedServices: ProvideServiceBodyItem[]) => Promise<void>;
}

async function changeProvidedServices(options: ChangeProvidedServicesOptions = {}) {
  if (!visit.value) return;
  emitEvent(`${eventPrefix.value}/edit/services`);

  const visitState = reactive(getEmptyNewVisitState());
  if (visit.value.group) {
    visitState.body.group = { id: visit.value.group.id };
  }

  // Оказываемые услуги, разбитые по группам (тип_категория)
  const providedServicesGroupsValues = ref(
    mapProvideServicesBodyItemsForGroup(
      convertProvidedServicesToBodyItems(visit.value.providedServices || [])
    )
  );

  // При изменении значений в группе, необходимо обновить данные состояния
  watch(providedServicesGroupsValues, servicesGroups => {
    visitState.body.providedServices = provideServicesBodyGroupToList(servicesGroups);
  }, { immediate: true, deep: true });

  const selectedTypeAndCategoriesCRef = computed<VisitTypeAndCategory[]>(() => {
    if (!visitState.body.providedServices) return [];
    return visitState.body.providedServices.map(ps => ({
      typeId: ps.pointType.id,
      catId: ps.carCategory.id,
    }));
  });

  function useModalServicesContext(): ModalServicesContext {
    return {
      providedServicesGroupsValues,
      providedServicesGroupsReplaced: ref({}),
      visitState,
    };
  }

  const {
    userCloseModal = true,
    action = ChangeProvidedServicesAction.Update,
    componentProps = {},
    updateHandler = async (providedServices: ProvideServiceBodyItem[]) => {
      await updateVisit({
        providedServices: prepareProvidedServicesBodyItems(providedServices)
      });
    }
  } = options;

  const modal = await modalController.create({
    cssClass: 'core-modal-actions',
    component: ServicesAddCategoryGroupModal,
    swipeToClose: (userCloseModal === true),
    keyboardClose: (userCloseModal === true),
    backdropDismiss: (userCloseModal === true),
    componentProps: {
      ...componentProps,
      groupId: visit.value.group?.id,
      carId: visit.value.car?.id,
      action,
      selectedTypeAndCategoriesCRef,
      providedServicesGroupsValues,
      useModalServicesContext,
      userCloseModal,
      setProvidedServices(providedServices: ProvideServiceBodyItem[]) {
        emitEvent(`${eventPrefix.value}/edit/provide-services/update`);

        updateHandler(providedServices); // async

        modal.dismiss();
      },
    },
  });

  modal.present();
  return modal;
}

/**
 * Менять исполнителей можно даже в том случае, если заказ оплачен!
 * NOTE: Увы но сервер в оплаченном заказе не дает менять исполнителей.
 */
// const oppChangeDoers = computed(() => includes(
//   [
//     CarVisitStatusEnum.New,
//     CarVisitStatusEnum.Processed,
//     CarVisitStatusEnum.Opened
//   ],
//   visit.value?.status
// ));

/**
 * NOTE: По другому прокинуть событие было сложно
 */
provide('actionClickDoers', () => {
  if (!oppChangeProvidedServices.value) {
    return; // Нельзя сменить исполнителя в закрытом заказе (в оплаченном можно, но сервер не дает этого сделать)
  }

  changeProvidedServices({
    action: ChangeProvidedServicesAction.UpdateDoers,
  });
});
//#endregion

//#region Краткая информация о заказе в шапке при скролле на смартфоне
const visitShortInfo = shallowRef<VisitCardShortInfo|null>(null);
function setShortInfo(info: VisitCardShortInfo) {
  visitShortInfo.value = info;
}

const showInfoHeaderAfterBlock = ref<HTMLDivElement|null>(null);
const belowInformationBlock = ref(false);
const showShortInformationHeader = computed(() => !!visitShortInfo.value && belowInformationBlock.value && isTabletDown.value);
function onScroll(event: CustomEvent) {
  if (!showInfoHeaderAfterBlock.value || !event.detail.scrollTop) return;

  const dScrollY = showInfoHeaderAfterBlock.value.offsetTop - event.detail.scrollTop as number;
  belowInformationBlock.value = dScrollY < 0;
}
//#endregion

//#region Выбрать группу
const { startChangeCounterparty } = useVisitCounterparty({ visit });

function changeCounterparty() {
  if (!oppChangeProvidedServices.value) {
    return; // Нельзя сменить контрагента в закрытом или оплаченном заказе
  }

  startChangeCounterparty();
}
//#endregion

//#region
const { preparePreentryVisitState, toChangePreentryDatetime } = useVisitPreentryChangeDatetime();

function changePreentryDatetime() {
  if (!visit.value) return;

  preparePreentryVisitState(visit.value);
  toChangePreentryDatetime();
}
//#endregion

//#region Flashlight
const {
  active: flashlightActive,
  switchToggle: flashlightToggle,
  isAvailable: flashlightIsAvailable,
  switchOff: flashligSwitchOff,
} = useFlashlight();

const isShowFlashlightButton = computed(() => {
  return flashlightIsAvailable.value && store.config.useFlashlight.value;
});

function flashlightToggleAction() {
  if (flashlightActive.value === false) {
    emitEvent('flashlight/on');
  }

  flashlightToggle();
}

onIonViewWillLeave(() => {
  if (flashlightActive.value) {
    flashligSwitchOff();
  }
});
//#endregion END Flashlight

//#region Upload Photo
const oppUploadPhotoVisit = computed(() => includes([
  CarVisitStatusEnum.New,
  CarVisitStatusEnum.Processed,
  CarVisitStatusEnum.Opened,
], visit.value?.status));
//#endregion
</script>

<template>
  <ion-page>
    <app-header :title="t('views.visit_single.title')" :default-href="{ name: 'visits' }">
      <keep-alive>
        <visit-short-info
          v-if="showShortInformationHeader && visitShortInfo"
          class="visit-header-information"
          v-bind="{
            images: visitShortInfo.images.value[0],
            totalPrice: visitShortInfo.totalPrice.value,
            progressText: visitShortInfo.progressText.value,
            formatCarNumber: visitShortInfo.formatCarNumber.value,
          }"
        />
      </keep-alive>
    </app-header>

    <ion-content class="visit-single" :scroll-events="true" @ionScroll="onScroll($event)">
      <tablet-side-layout>
        <template #side>
          <div v-if="visit" class="ion-padding-horizontal ion-padding-top">
            <visit-card
              :visit="visit"
              @short-info="setShortInfo"
              :view-box="true"
              scroll="card"
            >
              <template #header-buttons>
                <visit-add-photo-button
                  v-if="oppUploadPhotoVisit"
                  :visitId="visitId"
                  :metrica-event-name="`${eventPrefix}/photo/add`"
                />
              </template>
            </visit-card>

            <div ref="showInfoHeaderAfterBlock"></div>

            <c-card-list>
              <ion-item class="color-default" v-if="visit.isPayed">
                {{ t('views.visit_single.card_info_pay') }}

                <div slot="end">
                  <span v-if="paymentTypeTitle">({{ paymentTypeTitle }})</span>
                  <cc :price="visit.payed" />
                </div>
              </ion-item>

              <ion-item class="color-default" v-if="visit.isPayed && visit.payedByScores">
                {{ t('views.visit_single.card_info_pay_points') }}
                <div slot="end">{{ visit.payedByScores }}</div>
              </ion-item>

              <ion-item
                button :detail="false" v-if="oppCloseVisit" @click="closeVisitAction"
                class="item-link-button ion-text-center item-no-detail-icon"
              >
                <ion-label>{{ t('views.visit_single.card_action_close_visit') }}</ion-label>
              </ion-item>

              <ion-item
                button :detail="false" v-else-if="oppReturnWork" @click="reopenVisitAction"
                class="item-link-button ion-text-center item-no-detail-icon"
              >
                <ion-label>{{ t('views.visit_single.card_action_return_work') }}</ion-label>
              </ion-item>

              <ion-item
                button :detail="false" v-if="oppReturnPay" @click="returnPayAction"
                class="item-link-button ion-text-center item-no-detail-icon"
              >
                <ion-label>{{ t('views.visit_single.card_action_return_pay') }}</ion-label>
              </ion-item>

              <ion-item
                button :detail="false" v-if="oppInvoiceFiscal" @click="alertConfirmInvoiceFiscal"
                class="item-link-button ion-text-center item-no-detail-icon"
              >
                <ion-label>{{ t('views.visit_single.card_action_invoice_fiscal') }}</ion-label>
              </ion-item>

              <ion-item
                button :detail="false" v-if="oppInvoice" @click="alertConfirmInvoice"
                class="item-link-button ion-text-center item-no-detail-icon"
              >
                <ion-label>{{ t('views.visit_single.card_action_invoice') }}</ion-label>
              </ion-item>

              <ion-item
                button :detail="false" v-if="oppChangeDatetimePreentryVisit" @click="changePreentryDatetime"
                class="item-link-button ion-text-center item-no-detail-icon"
              >
                <ion-label>{{ t('views.visit_single.card_action_сhange_datetime_preentry_visit') }}</ion-label>
              </ion-item>

              <ion-item
                button :detail="false" v-if="oppCancelPreentryVisit" @click="preentryCancelVisit"
                class="item-link-button ion-text-center item-no-detail-icon"
              >
                <ion-label>{{ t('views.visit_single.card_action_cancel_preentry_visit') }}</ion-label>
              </ion-item>
            </c-card-list>
          </div>
        </template>

        <template #side-after v-if="visit && (oppPay || oppGetStarted)">
          <div class="visit-single-complete-btn-wrap">
            <ion-button v-if="oppPay" @click="payVisit" color="primary" expand="block" shape="round">
              <cc :price="visit?.price" v-slot="{ priceFormatted }">
                {{ t('views.visit_single.action_pay', { price: priceFormatted }) }}
              </cc>
            </ion-button>

            <ion-button v-if="oppGetStarted" @click="preentryGetStarted" color="primary" expand="block" shape="round">
              {{ t('views.visit_single.action_preentry_get_started') }}
            </ion-button>
          </div>
        </template>

        <template #default>
          <c-content-loading :action="load">
            <div v-if="visit" class="ion-padding">
              <c-card-header class="ion-hide-md-down">
                {{ t('views.visit_single.provide_services_title') }}
              </c-card-header>

              <services-provided-view-card
                v-if="visit.providedServices"
                :provided-services="visit.providedServices"
                :total="visit.price"
              >
                <template #after>
                  <!-- Изменить детали заказа -->
                  <ion-item
                    v-if="oppChangeProvidedServices" @click="changeProvidedServices"
                    class="item-link-button ion-text-center item-no-detail-icon"
                  >
                    <ion-label>{{ t('views.visit_single.provide_services_action_edit') }}</ion-label>
                  </ion-item>
                </template>
              </services-provided-view-card>

              <c-card-header class="ion-hide-md-down mt-3">
                {{ t('views.visit_single.client_card_title') }}
              </c-card-header>

              <client-info-card
                class="padding-x-adaptive"
                :car-data="visit.car"
                :show-group="true"
                :group="visit.group"
                @choice-group="changeCounterparty"
                :has-choise-group="oppChangeProvidedServices"
              />
            </div>
          </c-content-loading>
        </template>
      </tablet-side-layout>

      <div class="visits-fixed-toolbar" slot="fixed">
        <div class="visits-fixed-actions">
          <ion-fab-button
            v-if="isShowFlashlightButton"
            :class="{
              'flashlight-button': true,
              'flashlight-button--active': flashlightActive
            }"
            @click="flashlightToggleAction"
            color="light"
          >
            <ion-icon name="core-flashlight" class="icon-large" />
          </ion-fab-button>
        </div>
      </div>

      <div
        v-if="isShowFlashlightButton"
        class="fixed-actions-spacer"
      ></div>
    </ion-content>

    <app-footer></app-footer>
  </ion-page>
</template>

<style lang="scss">
@import '@/scss/abstract';

.visit-single {
  @include ionic-tablet {
    .c-tablet-side-layout {
      &__side {
        // Чтобы тень не отбрасывалась в правой части
        overflow: hidden;
      }
    }
  }
}

.visit-header-information {
  position: absolute;
  top: var(--ion-safe-area-top, 0);
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: var(--ion-toolbar-background);
}

.flashlight-button {
  --transition: transform 0.2s cubic-bezier(0.25, 1.11, 0.78, 1.59),
    box-shadow 0.3s cubic-bezier(0.25, 1.11, 0.78, 1.59);

  &--active {
    --box-shadow: 0 0 0 4px rgba(var(--ion-color-light-rgb, 255, 255, 255), 0.2);
  }
}
</style>
