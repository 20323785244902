import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "text-muted" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_divider = _resolveComponent("c-divider")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", {
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.toDevPage && _ctx.toDevPage(...args)))
    }, _toDisplayString(_ctx.$t('components.user_settings.fullname_label')), 1),
    _createElementVNode("p", _hoisted_1, _toDisplayString(_ctx.fullName), 1),
    _createVNode(_component_c_divider, { class: "my-0" }),
    _createVNode(_component_ion_button, {
      fill: "clear",
      color: "transparent",
      class: "ion-no-padding",
      onClick: _ctx.confirmLogout
    }, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.user_settings.btn_exit')), 1)
      ]),
      _: 1
    }, 8, ["onClick"])
  ]))
}