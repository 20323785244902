<template>
  <ion-header>
    <ion-toolbar class="g-ion-header-toolbar-main">
      <!-- eslint-disable-next-line -->
      <ion-buttons class="header-back-buttons" slot="start" v-if="hideBackButton == false">
        <slot name="start">
          <ion-back-button :default-href="defaultHref" />
        </slot>
      </ion-buttons>
      
      <ion-title>
        <slot name="title">
          {{ finalTitle }}
        </slot>
      </ion-title>

      <!-- eslint-disable-next-line -->
      <ion-buttons slot="end" v-if="$slots.end">
        <slot name="end" />
      </ion-buttons>

      <slot name="toolbar-after" />
    </ion-toolbar>

    <slot />
  </ion-header>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { IonBackButton } from '@ionic/vue';
import { RouteLocationRaw } from 'vue-router';

export default defineComponent({
  components: {
    IonBackButton,
  },
  props: {
    title: String as PropType<string>,
    defaultTitle: String as PropType<string>,

    hideBackButton: {
      type: Boolean as PropType<boolean>,
      default: false
    },

    defaultHref: {
      type: [String, Object] as PropType<RouteLocationRaw>, 
    }
  },

  data() {
    const queryTitle = this.$route.query.title as string|undefined;
    return {
      queryTitle,
    };
  },

  computed: {
    finalTitle(): string|undefined {
      return this.title || this.queryTitle || this.defaultTitle;
    }
  }
});
</script>

<style lang="scss">
@import '@/scss/abstract';

.header-back-buttons {
  ion-back-button {
    min-height: var(--core-header-height);

    @include ionic-tablet {
      min-height: var(--core-tablet-header-height);
    }

    // Для увелечения области нажатия
    margin: -2px -60px -2px 0;
    padding-right: 60px;
  }
}
</style>