import * as IonicComponents from './ionic'
import { App } from 'vue'

import CCurrency from './core/CCurrency.vue'
import AppHeader from './layout/AppHeader.vue'
import AppHeaderBig from './layout/AppHeaderBig.vue'
import AppFooter from './layout/AppFooter.vue'
import TabletSideLayout from './layout/TabletSideLayout.vue'
import PosSlimLayout from './layout/PosSlimLayout.vue'

import CCardList from './core/CCardList.vue'
import CCardHeader from './core/CCardHeader.vue'
import CDivider from './core/CDivider.vue'
import CTransparentButton from './core/CTransparentButton.vue'
import CProgressBar from './core/CProgressBar.vue'
import CTabsContentainer from './core/CTabsContentainer.vue'
import CTabContent from './core/CTabContent.vue'
import CModalActionHeader from './core/CModalActionHeader.vue'
import CImageBg from './core/CImageBg.vue'
import CModalSelect from './core/CModalSelect.vue'
import CModalSelectOption from './core/CModalSelectOption.vue'
import CSpinner from './core/CSpinner.vue'
import CErrorBlock from './core/CErrorBlock.vue'
import CContentLoading from './core/CContentLoading.vue'
import CNumberCounter from './core/CNumberCounter.vue'
import CChoiceListItem from './core/CChoiceListItem.vue'
import CInfiniteLoading from './core/CInfiniteLoading.vue'
import CNavCardsList from './core/CNavCardsList.vue'
import CNavCardsListItem from './core/CNavCardsListItem.vue'
import CModalContent from './core/CModalContent.vue'
import CSpoiler from './core/CSpoiler.vue'

export const AppGlobalComponents = {
  CCurrency,
  cc: CCurrency, // alias

  AppHeader,
  AppHeaderBig,
  AppFooter,
  TabletSideLayout,
  PosSlimLayout,

  CCardList,
  CCardHeader,
  CDivider,
  CTransparentButton,
  CProgressBar,
  CTabsContentainer,
  CTabContent,
  CModalActionHeader,
  CImageBg,
  CModalSelect,
  CModalSelectOption,
  CSpinner,
  CErrorBlock,
  CContentLoading,
  CNumberCounter,
  CChoiceListItem,
  CInfiniteLoading,
  CNavCardsList,
  CNavCardsListItem,
  CModalContent,
  CSpoiler,
};

export default {
  install(app: App) {
    // Ionic
    for (let [IonComponentName, IonComponent] of Object.entries(IonicComponents)) {
      app.component(IonComponentName, IonComponent);
    }

    // App
    for (let [AppComponentName, AppComponent] of Object.entries(AppGlobalComponents)) {
      app.component(AppComponentName, AppComponent);
    }
  }
}
