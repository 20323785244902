import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, vShow as _vShow, withDirectives as _withDirectives, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = {
  key: 0,
  slot: "fixed",
  class: "view-toggle-container-tablet",
  ref: "viewToggleContainer"
}
const _hoisted_2 = {
  class: "visits-fixed-toolbar",
  slot: "fixed"
}
const _hoisted_3 = { class: "visits-fixed-actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_segment_button = _resolveComponent("ion-segment-button")!
  const _component_ion_segment = _resolveComponent("ion-segment")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_c_transparent_button = _resolveComponent("c-transparent-button")!
  const _component_app_header_big = _resolveComponent("app-header-big")!
  const _component_VisitsTabProcessing = _resolveComponent("VisitsTabProcessing")!
  const _component_c_tab_content = _resolveComponent("c-tab-content")!
  const _component_VisitsTabArchive = _resolveComponent("VisitsTabArchive")!
  const _component_c_tabs_contentainer = _resolveComponent("c-tabs-contentainer")!
  const _component_ion_fab_button = _resolveComponent("ion-fab-button")!
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _directive_scroll_hide = _resolveDirective("scroll-hide")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header_big, {
        title: _ctx.$t('views.visits.title')
      }, {
        "buttons-before": _withCtx(() => [
          _withDirectives(_createVNode(_component_c_transparent_button, { onClick: _ctx.toggleSearchbar }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                name: "core-search",
                class: "icon-medium"
              })
            ]),
            _: 1
          }, 8, ["onClick"]), [
            [_vShow, _ctx.isArchive && _ctx.isTabletAndUp]
          ])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_ion_toolbar, { class: "core-header-segment-toolbar" }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_segment, {
                class: "core-segment",
                modelValue: _ctx.currentTab,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.currentTab) = $event))
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_segment_button, {
                    value: "processing",
                    layout: "icon-hide"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.visits.tab_processing')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }),
                  _createVNode(_component_ion_segment_button, {
                    value: "archive",
                    layout: "icon-hide"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_label, null, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.visits.tab_archive')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }, 8, ["modelValue"]),
              (_openBlock(), _createBlock(_Teleport, {
                to: _ctx.viewToggleContainer,
                disabled: !_ctx.viewToggleContainer || !_ctx.isTabletAndUp
              }, [
                _createVNode(_component_ion_segment, {
                  slot: "end",
                  class: "core-segment-light",
                  modelValue: _ctx.viewType,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.viewType) = $event))
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_ion_segment_button, {
                      value: "normal",
                      layout: "label-hide"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { name: "core-visits-view-card" })
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_ion_segment_button, {
                      value: "mini",
                      layout: "label-hide"
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_icon, { name: "core-visits-view-list" })
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ], 8, ["to", "disabled"]))
            ]),
            _: 1
          }),
          (_ctx.showHeaderSearchbar)
            ? (_openBlock(), _createBlock(_component_ion_toolbar, {
                key: 0,
                class: "core-header-searchbar"
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { lines: "none" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_input, {
                        placeholder: _ctx.$t('views.visits.search_input'),
                        inputmode: "search",
                        ref: "searchInputRef",
                        modelValue: _ctx.searchWords,
                        "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.searchWords) = $event)),
                        debounce: 700
                      }, null, 8, ["placeholder", "modelValue"]),
                      _createVNode(_component_ion_button, {
                        slot: "end",
                        fill: "clear",
                        color: "light",
                        onClick: _ctx.closeSearchbar
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_icon, {
                            name: "close-outline",
                            class: "icon-medium"
                          })
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              }))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_c_tabs_contentainer, {
            modelValue: _ctx.currentTab,
            "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.currentTab) = $event))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_c_tab_content, { name: "processing" }, {
                default: _withCtx(() => [
                  _createVNode(_component_VisitsTabProcessing, { "view-type": _ctx.processingViewType }, null, 8, ["view-type"])
                ]),
                _: 1
              }),
              _createVNode(_component_c_tab_content, { name: "archive" }, {
                default: _withCtx(() => [
                  _createVNode(_component_VisitsTabArchive, {
                    search: _ctx.searchWords,
                    "view-type": _ctx.archiveViewType
                  }, null, 8, ["search", "view-type"])
                ]),
                _: 1
              }),
              _createVNode(_component_c_tab_content, null, {
                default: _withCtx(() => [
                  _createTextVNode("What?!")
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["modelValue"]),
          (_ctx.isTabletAndUp)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, null, 512))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _withDirectives(_createElementVNode("div", _hoisted_3, [
              (_ctx.configUsePreentry)
                ? (_openBlock(), _createBlock(_component_ion_fab_button, {
                    key: 0,
                    color: "light",
                    onClick: _ctx.toPreentry
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_icon, {
                        name: "core-calendar",
                        class: "icon-large"
                      })
                    ]),
                    _: 1
                  }, 8, ["onClick"]))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_button, {
                color: "primary",
                shape: "round",
                onClick: _ctx.newVisit
              }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_icon, {
                    name: "add-outline",
                    class: "mr-1"
                  }),
                  _createTextVNode(" " + _toDisplayString(_ctx.$t('views.visits.add_visit')), 1)
                ]),
                _: 1
              }, 8, ["onClick"])
            ], 512), [
              [_vShow, _ctx.currentTab === 'processing']
            ]),
            _withDirectives((_openBlock(), _createElementBlock("div", {
              class: _normalizeClass(["core-searchbar-mobile ion-padding", { 'scroll-hide--show-force': _ctx.searchWords }])
            }, [
              _createVNode(_component_ion_searchbar, {
                class: "core-searchbar",
                inputmode: "search",
                placeholder: _ctx.$t('views.visits.search_input'),
                "clear-icon": "close-outline",
                debounce: 700,
                modelValue: _ctx.searchWords,
                "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.searchWords) = $event))
              }, null, 8, ["placeholder", "modelValue"])
            ], 2)), [
              [_directive_scroll_hide, {}],
              [_vShow, _ctx.showFooterSearchbar]
            ])
          ]),
          _createElementVNode("div", {
            class: "fixed-actions-spacer",
            style: _normalizeStyle({
        height: _ctx.showFooterSearchbar ? '100px' : undefined,
      })
          }, null, 4)
        ]),
        _: 1
      }),
      _createVNode(_component_app_footer)
    ]),
    _: 1
  }))
}