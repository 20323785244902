import axios, { AxiosError, AxiosInstance, AxiosResponse } from 'axios'
import camelCaseKeys from 'camelcase-keys'
import snakeCaseKeys from 'snakecase-keys'
import { isObject, set } from 'lodash'
import qs from 'qs'

export type AsyncResponse<T = any> = Promise<AxiosResponse<T>>;
export type Response<T = any> = AxiosResponse<T>;
export type ClientInstance = AxiosInstance;
export type ClientError<T = any> = AxiosError<T>;

export function createClient(baseURL: string): ClientInstance {
  const client = axios.create({
    baseURL,
    paramsSerializer(params): string {
      // Преобразуем все ключи из camelCase в snake_case
      if (isObject(params)) {
        params = snakeCaseKeys(params);
      }
  
      return qs.stringify(params, {
        arrayFormat: 'brackets',
      });
    },
  });
  
  client.interceptors.request.use(
    function (config) {
      // TODO: Временно, в будущем данные должны храниться в другом месте
      const token = localStorage.getItem('token');
  
      if (token) {
        set(config.headers as any, 'Authorization', `Basic ${token}`);
      }
  
      // WARNING: На данный момент, если данные отсылаются в FormData,
      // то преобразования ключей в snake_case не будет.
      const isFormDataObject = (config.data instanceof FormData);

      // Преобразуем все ключи из тела запроса из camelCase в snake_case
      if (isObject(config.data) && !isFormDataObject) {
        config.data = snakeCaseKeys(config.data);
      }
  
      return config;
    },
    function (error) {
      return Promise.reject(error);
    }
  );
  
  client.interceptors.response.use(
    function(response) {
      let data = response.data;
  
      // Для коллекций сокращаем путь с `data._embedded.items` до `data.items`
      if (data?._embedded?.items) {
        data.items = data._embedded.items;
        delete data._embedded;
      }
  
      // Преобразуем все ключи объектов из snake_case в camelCase
      if (isObject(response.data)) {
        response.data = camelCaseKeys(data, { deep: true });
      }
  
      // FIXME: if HTTP == 200 OK && success == false => throw Error
  
      return response;
    },
    function(error) {
      return Promise.reject(error);
    }
  );

  return client;
}

/**
 * Создаст ошибку типа AxiosError
 * 
 * @param response 
 * @param message 
 * @returns 
 */
export function createError(response: Response, message?: string): ClientError {
  let error: Error & Record<string, any> = new Error(message || `Request failed with status code ${response.status}`);

  error.config = response.config;
  error.request = response.request;
  error.response = response;
  error.isAxiosError = true;

  error.toJSON = function toJSON() {
    return {
      // Standard
      message: this.message,
      name: this.name,
      // Microsoft
      description: this.description,
      number: this.number,
      // Mozilla
      fileName: this.fileName,
      lineNumber: this.lineNumber,
      columnNumber: this.columnNumber,
      stack: this.stack,
      // Axios
      config: this.config,
      code: this?.code
    };
  };

  return error as AxiosError;
}

/**
 * Проверит является ли ошибка типа AxiosError
 * 
 * @param payload 
 * @returns 
 */
export function isClientError(payload: any): payload is AxiosError {
  return axios.isAxiosError(payload);
}

/**
 * Проверяет является ли ошибка ошибкой сети
 * 
 * @param e объект ошибки запроса
 * @returns 
 */
export function isNetworkError(e: any) {
  // Увы по другому не узнать
  return e?.message === 'Network Error';
}

export default createClient;
