import moment from 'moment';
import { upperFirst } from 'lodash';
import { shallowRef } from 'vue';

/**
 * Число минут в одном дне
 */
export const MINUTES_DAY_NUMBER = 60 * 24;

// global
export const currentTimeMoment = shallowRef(moment());

// Обновление времени каждые 10 секунд (чтобы слишком часто не пересчитывать)
setInterval(() => { currentTimeMoment.value = moment(); }, 10000);

/**
 * Преобразует объект даты в число минут, пройденных с начала дня
 * 
 * @param datetime 
 * @returns 
 */
export function datetimeToTimeNumber(datetime: Date) {
  return datetime.getHours() * 60 + datetime.getMinutes();
}

/**
 * Сконвертирует число в часы и минуты
 * @param time 
 * @returns [h, m] часы и минуты
 */
export function timeNumberToHM(time: number) {
  const h = Math.floor(time / 60);
  const m = Math.floor(time - h * 60);

  return [h, m];
}

/**
 * Парсит строку в объект Date
 * @param datetime 
 */
export function parseToDate(datetime: string): Date {
  return moment(datetime).toDate();
}

/**
 * Сразнение дат (время игнорируется)
 * 
 * @param aDate 
 * @param bDate 
 * @returns 
 */
export function isSameDateValue(aDate: any, bDate: any) {
  return moment(aDate).isSame(moment(bDate), 'date');
}

/**
 * Сконвертирует значение в объект Date
 * 
 * @param datetime 
 * @returns 
 */
export function convertToDate(datetime: any): Date {
  if (datetime instanceof Date) {
    return datetime;
  }

  return moment(datetime).toDate();
}

/**
 * Вернет новый объект Date c очищенным временем
 * @param datetime 
 * @returns 
 */
export function getDateCleanTime(datetime: Date): Date {
  return moment({
    date: datetime.getDate(),
    month: datetime.getMonth(),
    year: datetime.getFullYear(),
  }).toDate();
}

/**
 * Сконвертирует и вернет дату с очищенным (нулевым временем)
 * 
 * @param datetime 
 * @returns 
 */
export function convertToDateAndCleanTime(datetime: any): Date {
  return getDateCleanTime(convertToDate(datetime));
}

/**
 * Преобразует значение даты времени в троку даты, для отправки на сервер
 * NOTE: Сервером поддерживает только конкретный формат
 * 
 * @param datetime 
 * @returns 
 */
export function dateToServerDatetimeString(datetime?: any): string {
  return moment(datetime).format(moment.defaultFormat);
}

/**
 * Форматирование даты
 * 
 * @param date 
 * @param hideYearIsCurrent стоит ли прятать год если он текущий (по умолчанию да)
 * @returns 
 */
export function formatDate(date: Date, hideYearIsCurrent = true): string {
  const md = moment(date);

  if (hideYearIsCurrent) {
    const now = moment();
    if (md.isSame(now, 'year')) {
      return md.format('D MMMM');
    }
  }

  return md.format('D MMMM YYYY');
}

//#region Calendar data generate
export interface CalendarDayInfo {
  year: number;
  month: number;
  day: number;
  date: number;
  dateObject: Date;
  column: number;
  row: number;
  beforeNow: boolean;
  today: boolean;
  dayOff: boolean;
  timestamp: number;
}

export interface CalendarMounthInfo {
  textMounth: string;
  textYear: string;
  startDate: Date;
  endDate: Date;
  daysCount: number;
  calendar: CalendarDayInfo[],
}

/**
 * Сгенерирует данные дней месяца для удобного отображения в виде календаря
 * 
 * @param month месяц 0 - 11 (0 - январь, 11 - декабрь)
 * @param year год в формате YYYY (пример: 2021)
 * @returns 
 */
export function getCalendarGrid(month: number, year: number): CalendarMounthInfo {
  const start = moment({ month, year, date: 1 });
  const end = start.clone().endOf('month');
  const now = moment();

  const textMounth = upperFirst(start.format('MMMM'));
  const textYear = start.format('YYYY');

  let calendar: CalendarDayInfo[] = [];

  for (let curr = start.clone(), row = 1; curr.isSameOrBefore(end); curr.add(1, 'day')) {
    const column = (curr.day() + 6) % 7 + 1;
    const dateObject = curr.toDate();
    
    calendar.push({
      year: curr.year(),
      month: curr.month(),
      day: curr.day(),
      date: curr.date(),
      row,
      column,
      dateObject,
      beforeNow: curr.isBefore(now, 'date'),
      today: curr.isSame(now, 'date'),
      dayOff: (column === 6 || column === 7),
      timestamp: dateObject.getTime(),
    });

    if (column === 7) ++row;
  }
  
  return {
    textMounth,
    textYear,
    startDate: start.toDate(),
    endDate: end.toDate(),
    daysCount: start.daysInMonth(),
    calendar,
  };
}
//#endregion

/**
 * Переводит минуты в миллисекунды
 * 
 * @param m минуты
 * @returns m * 60_000
 */
 export function minutes(m: number) {
  return m * 60_000;
}