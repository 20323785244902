<script lang="ts" setup>
import { defineProps } from 'vue';
import { VisitBadgeItem } from '@/composables/visit';
import { IonIcon, IonBadge } from '@ionic/vue';

interface VisitLabelProps {
  labels: VisitBadgeItem[];
}

const props = defineProps<VisitLabelProps>();

</script>

<template>
  <div class="c-visit-labels">
    <slot name="before"></slot>

    <ion-badge
      class="c-visit-labels__badge"
      v-for="(label, index) in props.labels"
      :key="index"
      :color="label.color"
    >
      <ion-icon class="c-visit-labels__badge-icon" v-if="label.icon" :name="label.icon" />
      
      {{ label.text }}
    </ion-badge>

    <slot name="after"></slot>
  </div>
</template>

<style lang="scss">
.c-visit-labels {
  padding: var(--core-spacer-small);

  &__badge {
    --padding-top: 5px;
    --padding-bottom: 5px;

    margin-right: var(--core-spacer-small);
    font-size: var(--core-font-size-xsmall);
    text-transform: uppercase;
    font-weight: 700;
    max-width: 100%;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__badge-icon {
    font-size: 1.2em;

    margin: -0.2em 0;
  }
}
</style>