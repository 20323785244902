import {
  toRef,
  ref,
  watch,
  computed,
  getCurrentInstance,
  ComponentInternalInstance,
} from 'vue';

export function useModelSync<T extends Record<string, unknown>, K extends string>(props: T, modelPropName: K) {
  const { emit } = getCurrentInstance() as ComponentInternalInstance;

  const value = toRef(props, modelPropName);
  const lazyValue = ref(value.value);
  watch(value, v => lazyValue.value = v);

  const innerValue = computed<T[K]>({
    get() {
      return lazyValue.value;
    },
    set(v) {
      lazyValue.value = v;
      emit(`update:${modelPropName}`, v);
    },
  });

  return {
    value,
    lazyValue,
    innerValue,
  };
}