<template>
  <ion-page>
    <app-header :title="pageTitle" />

    <ion-content>
      <services-categories
        :type-id="typeId"
        @type-loaded="setType"
        @select-category="selectCategory"
      />
    </ion-content>

    <app-footer />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, DeepReadonly } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import { ThisClientPointTypeItem, PointTypeCategoryItem } from '@/repositories/Models/Point';
import ServicesCategories from '@/components/service/ServicesCategories.vue';
import { useMetrica } from '@/composables/useMetrica';
import { pick } from 'lodash';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    ServicesCategories,
  },

  setup() {
    const route = useRoute();
    const router = useRouter();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    const typeId = route.params.typeId as string;

    const pageTitle = ref(t('views.pricelist_cat.default_title'));
    function setType(pointType: DeepReadonly<ThisClientPointTypeItem>) {
      pageTitle.value = pointType.name;
    }

    function selectCategory(category: DeepReadonly<PointTypeCategoryItem>) {
      emitEvent('pricelist/category', pick(category, ['id', 'name']));
      
      router.push({
        name: 'pricelist-services',
        params: {
          typeId,
          catId: category.id,
        }
      });
    }

    return {
      pageTitle,
      typeId,
      setType,
      selectCategory,
    }
  }
});
</script>

<style lang="scss">
</style>