<script lang="ts" setup>
import { ref } from 'vue';
import uuid from 'uuid'
import { usePush } from '@/composables/push';
import { useAppCheckUpdate } from '@/composables/appCheckUpdate';
import { NotificationData } from '@/composables/notifications';
import { useToast } from '@/composables/toast';

const toast = useToast();

const push = usePush();
const pushToken = push.getToken() || '-';
const pushTestSaveHistory = ref(false);

/**
 * Отображает тестовое сообщение (не сохраняется в истории)
 */
function testPushMessage() {
  const id = uuid.v1();
  const notification: NotificationData = {
    id: id,
    title: `Уведомление ID:${id} пример`,
    body: `Короткий текст уведомления ID:${id} новое https://core12.ru/ \n текст на новой строке`,
    meta: {},
    createAt: new Date(),
  };

  push.eventBus.emit('notification', notification);

  if (pushTestSaveHistory.value) {
    push.eventBus.emit('mergeNotification', notification);
  }
}

const appUpdate = useAppCheckUpdate();
async function showCheckAppUpdate() {
  const isUpd = await appUpdate.isNeedsUpdating();
  toast.message(isUpd ? 'Требуется обновление!' : 'Обновление НЕ требуется.');
}

</script>

<template>
  <!-- <c-divider /> -->

  <ion-page>
    <app-header title="Development" />

    <ion-content class="ion-padding">
      <!-- PUSH -->
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Работа с PUSH</ion-card-subtitle>
        </ion-card-header>

        <ion-card-content>
          <ion-item>
            <ion-label position="floating">Push токен</ion-label>
            <ion-input :value="pushToken" :readonly="true"></ion-input>
          </ion-item>

          <ion-button class="ion-margin-top" expand="block" @click="testPushMessage">
            Показать тестовое уведомление
          </ion-button>

          <ion-item lines="none">
            <ion-checkbox slot="start" v-model="pushTestSaveHistory"></ion-checkbox>
            <ion-label>Сохранить в истории</ion-label>
          </ion-item>
        </ion-card-content>
      </ion-card>

      <!-- APP UPDATE -->
      <ion-card>
        <ion-card-header>
          <ion-card-subtitle>Запрос на обновление</ion-card-subtitle>
        </ion-card-header>
        
        <ion-card-content>
          <ion-button fill="clear" expand="block" @click="showCheckAppUpdate">
            Требуется ли обновление
          </ion-button>

          <ion-button expand="block" @click="appUpdate.showModalInformation">
            Открыть окно
          </ion-button>
        </ion-card-content>
      </ion-card>
    </ion-content>

    <app-footer />
  </ion-page>
</template>

<style lang="scss">

</style>