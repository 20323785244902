<template>
  <popover-layout
    class="c-settings-popover"
    :title="$t('views.settings.user_popover_title')"
  >
    <UserSettings @logout="close" />
  </popover-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { popoverController } from '@ionic/vue';
import UserSettings from '@/components/app-service/UserSettings.vue';
import PopoverLayout from '@/components/layout/PopoverLayout.vue';

export default defineComponent({
  components: {
    UserSettings,
    PopoverLayout,
  },

  setup() {
    function close() {
      popoverController.dismiss();
    }

    return {
      close,
    };
  }
});
</script>

<style lang="scss">
</style>