import privateConfig from './private-config';

export default {
  ...privateConfig,
  defaultBaseDomain: '',
  apiPath: '/api',
  supportChatUrl: 'https://t.me/Core12tech_bot',
  privacyPolicyLink: 'https://core12.ru/privacy-policy-mobile.pdf',
  serviceAppName: 'manager',
  communityLink: 'https://t.me/+3EKnZv1N4ltkZmEy',
}