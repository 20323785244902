<script lang="ts" setup>
import { IonApp, modalController } from '@ionic/vue';
import { onMounted } from 'vue';
import { VueIonicOutlet } from '@/ext/ionic/outlet/VueIonicOutlet';
import { useMetrica } from './composables/useMetrica';
import { RouteLocationNormalized, useRouter } from 'vue-router';
import { get, debounce } from 'lodash';
import { usePush, onNotification } from '@/composables/push';
import { useStore } from './composables/useApp';
import { useNotificationUnreadCount } from './composables/notifications';
import { useMessage } from './composables/messages';
import { useAppCheckUpdate } from '@/composables/appCheckUpdate';
import { useToast } from './composables/toast';

const { emitEvent, emitError } = useMetrica();
const store = useStore();
const router = useRouter();
const push = usePush();
const toast = useToast()
const { openMessage } = useMessage();
const { actualizeUnread: actualizeUnreadRaw } = useNotificationUnreadCount();
const appUpdate = useAppCheckUpdate();

const actualizeUnreadDebounce = debounce(actualizeUnreadRaw, 500);

function getRouteInfoForMetric(route: RouteLocationNormalized) {
  const path = String(get(route.matched, '0.path', 'unknown'));
  const name: string = route.name ? String(route.name) : path;

  return {
    name,
    path,
    query: route.query,
  }
}

router.afterEach((to, from) => {
  const toInfo = getRouteInfoForMetric(to);
  const fromInfo = getRouteInfoForMetric(from);
  
  emitEvent('page/' + toInfo.name, {
    ...toInfo,
    prev: fromInfo,
  });
});

onMounted(async () => {
  // Проверка доступного обновления
  appUpdate.startCheck(); // async
  appUpdate.repeatChecking(6 * 60); // Запускаем проверку каждые 6 часов

  // Инициализация push
  const isSupported = await push.isSupported();
  if (isSupported) {
    try {
      // Запрос на получение пушей и авторегистрация токена
      await push.requestPermissionsAndRegister(true);
    } catch (error) {
      // NOTE: На некоторых устроствах (например huawei) нельзя достучатся
      // до серверов gms, поэтому просто прячем данную ошибку.
      if (get(error, 'error') === 'SERVICE_NOT_AVAILABLE') {
        emitError('push_not_init', 'Не удалось получить доступ к gms серверам')
      } else {
        toast.error(JSON.stringify(error), 5000, {
          header: 'Push-token error on registration'
        });
      }
    }
  }
});

onNotification(notification => {
  openMessage({
    header: notification.title,
    message: notification.body,
    buttons: [
      {
        text: 'Прочитано',
        async handler() {
          notification.meta.read === true;
          await store.notification.notificationHistoryTable.update(notification.id, {
            'meta.read': true,
          });

          actualizeUnreadDebounce(); // async
          modalController.dismiss(); // async
        },
      }
    ],
  });
});

/**
 * Обработчик, который добавляет пришедшее сообщение в БД уведомлений
 */
push.eventBus.on('mergeNotification', notification => {
  store.notification.mergeNotification(notification, false);
  actualizeUnreadDebounce(); // debounce - предотвратит многократный вызов в случае если в истории несколько уведомлений
});

/**
 * Приложение инициализаровано и готово
 * работать с историей пушей и их отображением.
 */
push.eventBus.emit('appInit');
</script>

<template>
  <ion-app>
    <vue-ionic-outlet ref="outlet" />
  </ion-app>
</template>