<template>
  <div class="visit-store-checkout">
    <c-card-header>
      {{ $t('components.visit_store_checkout.title') }}
    </c-card-header>

    <visit-store-products-view
      :total="total"
      :discount-percent="discountPercent"
    />

    <c-card-list>
      <ion-item
        button :detail="false" @click="alertConfirmInvoice"
        class="item-link-button ion-text-center item-no-detail-icon"
      >
        <ion-label>
          {{ $t('components.visit_store_checkout.item_invoice') }}
        </ion-label>
      </ion-item>

      <ion-item
        button :detail="false" @click="choiceDiscountAction"
        class="item-link-button ion-text-center item-no-detail-icon"
      >
        <ion-label>
          {{ $t('components.visit_store_checkout.item_choice_discount') }}
        </ion-label>
      </ion-item>

      <ion-item
        button :detail="false" @click="clearStoreOrder"
        class="item-link-button ion-text-center item-no-detail-icon"
      >
        <ion-label>
          {{ $t('components.visit_store_checkout.item_clear_order') }}
        </ion-label>
      </ion-item>
    </c-card-list>

    <teleport :to="completeBtnContainer" :disabled="!completeBtnContainer">
      <ion-button
        @click="payAction" :disabled="btnPayDisabled"
        color="primary" expand="block" shape="round"
      >
        {{ btnPayText }}
      </ion-button>
    </teleport>
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, PropType, ref, computed, onMounted } from 'vue';
import VisitStoreProductsView from './VisitStoreProductsView.vue';
import { NewVisitSatate } from '@/store/NewVisitState';
import { clearNewVisitState } from '@/store/NewVisitState';
import { useStore } from '@/composables/useApp';
import { useToast } from '@/composables/toast';
import { isEmpty, pick } from 'lodash';
import { useDiscounts } from '@/composables/useDiscounts';
import PaymentTypeModal from '@/components/pos/PaymentTypeModal.vue';
import { useRouter } from 'vue-router';
import { PaymentTypeEnum } from '@/repositories/Models/Pos';
import { useMetrica } from '@/composables/useMetrica';
import { CarVisitBodyRequest, CarVisitStatusEnum } from '@/repositories/Models/CarVisit';
import { useI18n } from 'vue-i18n';
import { useAlert } from '@/composables/alert';
import { useCurrency } from '@/composables/currency';
import { modalController } from '@ionic/vue';
import uuid from 'uuid';

export default defineComponent({
  components: {
    VisitStoreProductsView,
  },

  props: {
    completeBtnContainer: {
      type: Object as PropType<HTMLDivElement>,
      required: false
    }
  },

  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();
    const { createAlertConfirm } = useAlert();
    const { loadDiscounts, openModalDiscount } = useDiscounts();
    const { ccc } = useCurrency();

    const visitState = inject<NewVisitSatate|null>('visitState', null);
    if (!visitState) throw new Error('Mandatory state of a new store visit is not passed');

    const providedServices = computed(() => visitState.body?.providedServices || []);
    const discountPercent = computed(() => visitState.totalOrderDiscount?.percent || 0);

    const total = computed(() => {
      const productsTotal = providedServices.value
        .reduce((s, item) => item.totalPrice + s, 0);

      const discountCoof = discountPercent.value ? (1 - discountPercent.value / 100) : 1;

      return productsTotal * discountCoof;
    });

    //#region Create store order
    const createLoading = ref(false);
    const oppCreate = computed(() => providedServices.value.length > 0);
    const btnPayDisabled = computed(() => !oppCreate.value || createLoading.value);

    /**
     * Создает или обновляет заказ на сервере
     * @private
     * @throws {ClientError}
     */
    async function createOrUpdateOrder(body: CarVisitBodyRequest) {
      const visitId = store.visit.newStoreVisitState.body.id;

      // Если заказ еще не был создан, то создадим
      if (!visitId) {
        if (!body.idempotenceId) {
          const idempotenceId = uuid.v1();

          body.idempotenceId = idempotenceId;
          store.visit.newStoreVisitState.body.idempotenceId = idempotenceId;
        }

        return await store.visit.create(body, { createOffline: false });
      }

      // CRUTCH: FIX: Т.к. заказы со статусом finish обновлять нельзя
      // предварительно перед обновлением списка товаров поставим статус processed
      await store.visit.update(visitId, { status: CarVisitStatusEnum.Processed }, { createOffline: false });

      return await store.visit.update(visitId, body, { createOffline: false })
    }

    async function saveStoreOrder() {
      createLoading.value = true;

      try {
        const bodyStoreVisit = await store.visit.generateBodyNewStoreVisit();

        if (isEmpty(bodyStoreVisit.providedServices)) {
          throw new Error('Can\'t place an empty order');
        }

        const res = await createOrUpdateOrder(bodyStoreVisit);
        store.visit.newStoreVisitState.body.id = res.entityId;
        delete store.visit.newStoreVisitState.body.idempotenceId;

        // if (res.sendingDelayed) {
        //   toast.warning('Интернет недоступен, данные сохранены, и синхрнизируются при поялении интернета');
        // }

        // Очистка стора будет происходить после оплаты
        // store.visit.clearNewStoreVisitState();
      } catch (e) {
        toast.error(e, 5000, {
          defaultHeader: t('components.visit_store_checkout.header_error_save_order')
        });
      } finally {
        createLoading.value = false;
      }

      return store.visit.newStoreVisitState.body.id;
    }

    async function payAction() {
      const visitId = await saveStoreOrder();

      const modal = await modalController.create({
        cssClass: 'core-modal-actions',
        component: PaymentTypeModal,
        componentProps: {
          hasPoints: false,
          hasGroup: false,

          setValue(paymentType: PaymentTypeEnum) {
            emitEvent('visit/market/next-pay', { paymentType });
            router.push({
              name: 'visit-store-pay',
              params: {
                visitId: visitId || 0,
                paymentType,
              },
              query: {
                sum: total.value,
              }
            });
          }
        },
        swipeToClose: true,
      });

      modal.present(); // async

      // openPaymentTypeModal({
      //   hasPoints: false,
      //   hasGroup: false,

      //   setValue(paymentType: PaymentTypeEnum) {
      //     emitEvent('visit/market/next-pay', { paymentType });
      //     router.push({
      //       name: 'visit-store-pay',
      //       params: {
      //         visitId: visitId || 0,
      //         paymentType,
      //       },
      //       query: {
      //         sum: total.value,
      //       }
      //     });
      //   }
      // });
    }
    //#endregion

    //#region Select discount
    onMounted(loadDiscounts); // TODO: Возможно стоит сделать получше

    async function choiceDiscountAction() {
      if (!visitState) return;

      await openModalDiscount({
        value: visitState.totalOrderDiscount?.id || undefined,
        setValue(discountId, discount) {
          visitState.totalOrderDiscount = discount || null;

          const discountAct = discount ? 'set' : 'remove';
          const discountData = discount ? pick(discount, [
            'id', 'name', 'percent', 'type',
            'impactsDoerComission', 'impactsManagerComission'
          ]) : undefined;
          emitEvent(`visit/market/discount/${discountAct}`, discountData);
        }
      });
    }
    //#endregion

    //#region Пречек & Очистка заказа
    const invoiceLoading = ref(false);

    async function saveOrderAndSendInvoice() {
      const id = await saveStoreOrder();

      try {
        if (!id) {
          throw new Error('Unable to create guest invoice, order not completed');
        }

        await store.visit.invoice(id);
        toast.success(t('components.visit_store_checkout.message_invoice_success'));
      } catch (e) {
        toast.error(e, 4000, {
          defaultHeader: t('components.visit_store_checkout.header_invoice_error')
        });
      } finally {
        invoiceLoading.value = false;
      }
    }

    const alertConfirmInvoice = createAlertConfirm({
      header: t('components.visit_store_checkout.alert_invoice_header'),
      message: t('components.visit_store_checkout.alert_invoice_message'),
      confirmBtnText: t('components.visit_store_checkout.alert_invoice_btn_confirm'),
      async confirm() {
        emitEvent('visit/market/invoice/print');
        await saveOrderAndSendInvoice();
      }
    });

    const alertConfirmIssetOrder = createAlertConfirm({
      header: t('components.visit_store_checkout.alert_clear_order_header'),
      message: t('components.visit_store_checkout.alert_clear_order_message'),
      async confirm() {
        clearStoreOrder(false);
      }
    });

    /**
     * Очистить данные заказа
     * @param confirmIfCreated спрашивать подтверждение, если заказ уже создан
     */
    function clearStoreOrder(confirmIfCreated = true) {
      const isCreated = !!store.visit.newStoreVisitState.body.id;
      if (isCreated && confirmIfCreated) {
        alertConfirmIssetOrder();
        return;
      }

      if (visitState) {
        clearNewVisitState(visitState);
      }

      emitEvent('visit/market/clear');
    }
    //#endregion

    const totalPriceFormatted = ccc(total);
    const btnPayText = computed(() => {
      return (createLoading.value)
        ? t('components.visit_store_checkout.btn_pay_loading')
        : t('components.visit_store_checkout.btn_pay', { total: totalPriceFormatted.value });
    });

    return {
      clearStoreOrder,
      total,
      btnPayDisabled,
      payAction,
      createLoading,
      alertConfirmInvoice,
      choiceDiscountAction,
      discountPercent,
      btnPayText,
    };
  },
});
</script>

<style lang="scss">
</style>