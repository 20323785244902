import { resolveDynamicComponent as _resolveDynamicComponent, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.currentView.component), _normalizeProps(_guardReactiveProps({
        ..._ctx.$attrs,
        ..._ctx.currentView.params,
        action: _ctx.action
      })), null, 16))
  ]))
}