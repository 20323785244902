import { modalController, ModalOptions } from '@ionic/vue';
import MessageModal from '@/components/app-service/MessageModal.vue';
import type { Animation } from '@ionic/core';
import { createAnimation } from '@ionic/core';

export interface ModalMessageButtonItem {
  text?: string;
  handler?: () => void;
  cssClass?: string|string[]|{[className: string]: any;};
}

export interface ModalMessageProps {
  header?: string;
  message?: string;
  closeButton?: boolean;
  buttons?: ModalMessageButtonItem[];
}

export type ModalMessageOptions = Omit<ModalOptions, "component"|"componentProps">;

export function useMessage() {
  /**
   * Открывает модальное окно с сообщением
   * 
   * @param options 
   * @returns 
   */
  async function openMessage(props: ModalMessageProps, options: ModalMessageOptions = {}) {
    const modal = await modalController.create({
      component: MessageModal as any,
      componentProps: props,
      cssClass: 'c-modal-message',
      showBackdrop: false,
      backdropDismiss: false,
      enterAnimation: iosEnterAnimation,
      leaveAnimation: iosLeaveAnimation,
      ...options,
    });

    modal.present(); // async

    return modal;
  }

  return {
    openMessage,
  };
}


// --- Импорт анимации из Ionic недоступен, поэтому пришлось продублировать ---

/**
 * Gets the root context of a shadow dom element
 * On newer browsers this will be the shadowRoot,
 * but for older browser this may just be the
 * element itself.
 *
 * Useful for whenever you need to explicitly
 * do "myElement.shadowRoot!.querySelector(...)".
 */
export const getElementRoot = (el: HTMLElement, fallback: HTMLElement = el) => {
  return el.shadowRoot || fallback;
};

/**
 * iOS Toast Enter Animation
 */
export const iosEnterAnimation = (baseEl: HTMLElement, position: string = 'top'): Animation => {
  const edgeOffset = 0;

  const baseAnimation = createAnimation();
  const wrapperAnimation = createAnimation();

  const root = getElementRoot(baseEl);
  const wrapperEl = root.querySelector('.modal-wrapper') as HTMLElement;

  const bottom = `calc(-${edgeOffset}px - var(--ion-safe-area-bottom, 0px))`;
  const top = `calc(${edgeOffset}px + var(--ion-safe-area-top, 0px))`;

  wrapperAnimation.addElement(wrapperEl);

  switch (position) {
    case 'top':
      wrapperAnimation.fromTo('transform', 'translateY(-100%)', `translateY(${top})`);
      break;
    case 'middle': {
        const topPosition = Math.floor(baseEl.clientHeight / 2.0 - wrapperEl.clientHeight / 2.0);
        wrapperEl.style.top = `${topPosition}px`;
        wrapperAnimation.fromTo('opacity', 0.01, 1);
        break;
      }
    default:
      wrapperAnimation.fromTo('transform', 'translateY(100%)', `translateY(${bottom})`);
      break;
  }
  return baseAnimation.easing('cubic-bezier(.155,1.105,.295,1.12)').duration(400).addAnimation(wrapperAnimation);
};

/**
 * iOS Toast Leave Animation
 */
export const iosLeaveAnimation = (baseEl: HTMLElement, position: string = 'top'): Animation => {
  const edgeOffset = 0;

  const baseAnimation = createAnimation();
  const wrapperAnimation = createAnimation();

  const root = getElementRoot(baseEl);
  const wrapperEl = root.querySelector('.modal-wrapper') as HTMLElement;

  const bottom = `calc(-${edgeOffset}px - var(--ion-safe-area-bottom, 0px))`;
  const top = `calc(${edgeOffset}px + var(--ion-safe-area-top, 0px))`;

  wrapperAnimation.addElement(wrapperEl);

  switch (position) {
    case 'top':
      wrapperAnimation.fromTo('transform', `translateY(${top})`, 'translateY(-100%)');
      break;
    case 'middle':
      wrapperAnimation.fromTo('opacity', 0.99, 0);
      break;
    default:
      wrapperAnimation.fromTo('transform', `translateY(${bottom})`, 'translateY(100%)');
      break;
  }
  return baseAnimation.easing('cubic-bezier(.36,.66,.04,1)').duration(300).addAnimation(wrapperAnimation);
};