// PROD: 85693440   |   8a75a185-5322-4639-ad96-3b8892ebf9bf
// TEST: 85744157   |   20067316-f372-44a1-9177-a10283b11fbb

export default {
  /** Ключ от App Метрики (яндекс) */
  appMetricaApiKey: '8a75a185-5322-4639-ad96-3b8892ebf9bf',

  /** Идентификатор Яндекс.Метрики */
  yandexMetricaId: 0,

  /** Данные для инициализации firebase application */
  firebaseConfig: {
    apiKey: "AIzaSyCS_1ptqsUuyJimj6CCKKgMFC77rR9V-z4",
    authDomain: "core12-admin.firebaseapp.com",
    projectId: "core12-admin",
    storageBucket: "core12-admin.appspot.com",
    messagingSenderId: "187585542264",
    appId: "1:187585542264:web:a4d3496c91afaeb247958d",
    measurementId: "G-2DZPVLR9WH"
  },
  firebaseVapidKey: 'BMcf6cmZ-8y5wNC_hLajZ3BX_Mu76OBu_1KLDp92wgRGkWi6KA4yaINlnTQjpHD6jvaQswrfK9L2GEw9MghNHdg',
}