import { AppDB } from "@/database/AppDB"
import { isNetworkError } from "@/repositories"
import { isOnline } from "./network"
import { sortBy, entries } from 'lodash'

export type CacheQueryReturn<T> = {
  /** Значение взято из кэша */
  fromСache: boolean;

  /** Запрашиваемые данные */
  data: T;

  /** Значение просрочено (существует, только когда значение берется из кэша) */
  expired?: boolean;

  /** Дополнительная информация об ошибке */
  error?: any;
};

export type AsyncCache<T> = Promise<CacheQueryReturn<T>>;

/**
 * Получает актуальные данные из кэша или кэширует новые
 * данные в случае их просрочки или отсутствия
 * 
 * @private
 * 
 * @param db кземпляр локальной БД в которой хранится кэш
 * @param keyCache ключ кэша
 * @param getActialData функция для получения данных не из кэша
 * @param relevantTime срок релевантности кэша (в зависимости от скорости интернета можно менять значение)
 * @param offlineAndNotCacheHandler обработчик вызываемый в режиме оффлайн в случае отсутствия кэша
 * @returns 
 */
async function cacheQueryBase<T = any>(
  db: AppDB,
  keyCache: string,
  getActialData: () => Promise<T>,
  relevantTime: number = 0,
  offlineAndNotCacheHandler?: () => Promise<T|undefined>
): Promise<CacheQueryReturn<T>> {
  const cache = await db.cacheQuery.get(keyCache);

  /** @throws {Error} */
  async function updateCache(): Promise<CacheQueryReturn<T>> {
    try {
      const data = await getActialData.call(null);

      await db.cacheQuery.put({
        key: keyCache,
        updated: Date.now(),
        data: data,
      }, keyCache);
  
      return {
        fromСache: false, 
        data
      };
    } catch (e) {
      // Если это ошибка сети и в кэше есть данные, то вернем значение из кэша.
      // В теории эта ошибка редко когда должна возникать, т.к. офлайн режим ообрабатывается в отдельной ветке кода.
      // Ошибка может возникнуть, когда подключение к сети очень слабое и во время запроса происходит разрыв соединения
      if (isNetworkError(e)) {
        if (cache) {
          return {
            fromСache: true,
            data: cache.data,
            error: e
          };
        }
        
        // Обработчик, для попытки получить данные из другого места
        if (offlineAndNotCacheHandler) {
          const offlineData = await offlineAndNotCacheHandler();

          if (offlineData !== undefined && offlineData !== null) {
            return {
              fromСache: false,
              data: offlineData,
              error: e
            };
          }
        }
      }

      // Любая другая ошибка HTTP_CODE >= 400
      // или ошибка сохранения в БД, что очень маловероятно
      throw e;
    }
  }

  // В кэше есть значение
  if (cache) {
    const expired: boolean = (cache.updated + relevantTime) < Date.now();

    // Значение просрочено
    if (expired) {
      // И есть сеть
      if (isOnline()) {
        return await updateCache();
      }
    }

    return {
      fromСache: true,
      data: cache.data as T,
      expired,
    };
  }

  // Значения в кэше нет и приложение оффлайн
  if (false === isOnline()) {
    // Обработчик, для попытки получить данные из другого места
    if (offlineAndNotCacheHandler) {
      const offlineData = await offlineAndNotCacheHandler();

      if (offlineData !== undefined && offlineData !== null) {
        return {
          fromСache: false,
          data: offlineData,
        };
      }
    }

    throw new Error('Отсутсвует подключение к интернету и нет закэшированных данных. \nПодключитесь к интернету и повторите попытку.');
  }

  return await updateCache();
}

/**
 * Получает актуальные данные из кэша или кэширует новые
 * данные в случае их просрочки или отсутствия
 * 
 * @param db экземпляр локальной БД в которой хранится кэш
 * @param partsKey состовные части ключа (уникальное значение для формирования идентификатора данных)
 * @param getActialData функция для получения данных не из кэша
 * @param relevantTime срок релевантности кэша (в зависимости от скорости интернета можно менять значение)
 * @param offlineAndNotCacheHandler обработчик вызываемый в режиме оффлайн в случае отсутствия кэша
 * @returns 
 */
export function cacheQuery<T = any>(
  db: AppDB,
  partsKey: any[],
  getActialData: () => Promise<T>,
  relevantTime: number = 0,
  offlineAndNotCacheHandler?: () => Promise<T|undefined>
): Promise<CacheQueryReturn<T>> {
  const keyCache = db.generateCacheKey(...partsKey);
  return cacheQueryBase(db, keyCache, getActialData, relevantTime, offlineAndNotCacheHandler);
}

const cacheQueriesPromises = new Map<AppDB, Record<string, Promise<any>>>();
/**
 * Получает актуальные данные из кэша или кэширует новые
 * данные в случае их просрочки или отсутствия, блокируя
 * одновременные операции по получению данных.
 * 
 * @param db экземпляр локальной БД в которой хранится кэш
 * @param partsKey состовные части ключа (уникальное значение для формирования идентификатора данных)
 * @param getActialData функция для получения данных не из кэша
 * @param relevantTime срок релевантности кэша (в зависимости от скорости интернета можно менять значение)
 * @returns 
 */
export async function cacheQueryWithBloking<T = any>(
  db: AppDB,
  partsKey: any[],
  getActialData: () => Promise<T>,
  relevantTime: number = 0,
  offlineAndNotCacheHandler?: () => Promise<T|undefined>
): Promise<CacheQueryReturn<T>> {
  const keyCache = db.generateCacheKey(...partsKey);
  let dbQueriesAssoc = cacheQueriesPromises.get(db);
  if (!dbQueriesAssoc) {
    dbQueriesAssoc = {};
    cacheQueriesPromises.set(db, dbQueriesAssoc);
  }

  let query = dbQueriesAssoc[keyCache];
  let isCreate = false;
  if (!query) {
    query = cacheQueryBase(db, keyCache, getActialData, relevantTime, offlineAndNotCacheHandler);
    dbQueriesAssoc[keyCache] = query;
    isCreate = true;
  }

  const data = await query;

  if (isCreate) { // Кто создал тот и удаляет
    delete dbQueriesAssoc[keyCache];
  }

  return data;
}

/**
 * Переводит минуты в миллисекунды
 * 
 * @param m минуты
 * @returns m * 60_000
 */
export function minutes(m: number) {
  return m * 60_000;
}

/**
 * Переводит часы в миллисекунды
 * 
 * @param h минуты
 * @returns h * 60 * 60_000
 */
export function hours(h: number) {
  return minutes(h * 60);
}

/**
 * Порядок ключей объектов не гарантируется =>
 * их преобразовываем в массив и сортируем.
 * 
 * Это позволит избежать ситуаций когда при одинаковых запросах
 * с одинаковыми параметрами могут возникать разные хеши ключей для кэша
 * 
 * @param params парметры для обработки
 * @returns 
 */
export function prepareParams(params: any): any[] {
  return sortBy(entries(params), 0);
}
