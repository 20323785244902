import { CarGroup } from './CarGroup'

export type PricePolicyCounterpartyItem = Pick<CarGroup, "id"|"name">;

export interface PricePolicyRule {
  id: string;
  type: string;
}

export interface PricePolicyRuleTypeCounterparty extends PricePolicyRule {
  counterparties: PricePolicyCounterpartyItem[];
  type: 'counterparty';
}

export interface PricePolicy {
  id: string;
  name: string;
  updatedAt: string; // format: datetime
  createdAt: string; // format: datetime
  rules: PricePolicyRule[];
}

export type PricePolicyListItemRule = PricePolicyRule|PricePolicyRuleTypeCounterparty;

export interface PricePolicyListItem extends PricePolicy {
  rules: PricePolicyListItemRule[];
}

export type PricePolicyListQuery = { [param: string]: any; };
export type PricePolicyListResponse = Array<PricePolicyListItem>;

export function isRuleTypeCounterparty(rule: PricePolicyRule): rule is PricePolicyRuleTypeCounterparty {
  return rule.type === 'counterparty';
}