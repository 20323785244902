<template>
  <ion-page>
    <app-header-new-visit
      :title="$t('views.visit_new_client.title')"
      :progress="0.25"
    />

    <ion-content>
      <tablet-side-layout>
        <template #side>
          <div class="ion-padding-top ion-padding-horizontal">
            <c-card-header>
              {{ car.number || carId }}
            </c-card-header>

            <visit-preview-images
              class="ion-margin-bottom"
              :visit-id="visitId"
              :is-new-preentry="isPreentry"
            />

            <teleport
              :to="$refs.servicesPopularMobileContainer"
              :disabled="isTabletAndUp || !$refs.servicesPopularMobileContainer"
            >
              <c-card-header v-show="car.info.id" class="mt-4">
                {{ $t('views.visit_new_client.popular_services_title') }}
              </c-card-header>
              
              <services-popular-card
                v-show="car.info.id"
                :car-id="car.info.id"
              />
            </teleport>
          </div>
        </template>

        <div class="ion-padding">
          <c-card-header>
            {{ $t('views.visit_new_client.client_form_title') }}
          </c-card-header>

          <c-content-loading :action="loadCar">
            <div class="c-form">
              <div class="c-form-group">
                <ion-input
                  class="core-input"
                  :placeholder="$t('views.visit_new_client.client_input_name')"
                  autocapitalize="words"
                  v-model="car.info.ownerName"
                />
              </div>

              <div class="c-form-group">
                <ion-input
                  class="core-input"
                  :placeholder="$t('views.visit_new_client.client_input_phone')"
                  v-model="car.info.ownerPhone"
                  type="tel"
                  inputmode="tel"
                />
              </div>

              <div class="c-form-group">
                <ion-textarea
                  class="core-input"
                  :placeholder="$t('views.visit_new_client.client_input_comment')"
                  autocapitalize="sentences"
                  :rows="3"
                  v-model="car.info.comment"
                />
              </div>
            </div>

            <div ref="servicesPopularMobileContainer" />

            <group-partner-input-card
              class="mb-3"
              v-model="groupPartnerId"
            />

            <ion-item class="c-item-checkbox" v-if="false == isActEdit">
              <!-- eslint-disable-next-line -->
              <ion-checkbox color="light" slot="start" checked></ion-checkbox>
              <div class="text-muted py-1">
                {{ $t('views.visit_new_client.client_agrees_personal_data') }}
              </div>
            </ion-item>
          </c-content-loading>
        </div>

        <div class="fixed-actions-spacer" />
      </tablet-side-layout>

      <!-- eslint-disable-next-line -->
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-button color="primary" shape="round" @click="next">
          {{ $t('views.visit_new_client.btn_next') }}
        </ion-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, watch } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import { useStore } from '@/composables/useApp';
import { useRoute, useRouter } from 'vue-router';
import { get, pick } from 'lodash';
import { CarBodyRequest } from '@/repositories/Models/Car';
import { isClientError } from '@/repositories';
import ServicesPopularCard from '@/components/service/ServicesPopularCard.vue';
import GroupPartnerInputCard from '@/components/group-partner/GroupPartnerInputCard.vue';
import { isTabletAndUp } from '@/helpers/adaptive';
import { useCreateVisitStep } from '@/composables/visit';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
import { preparePhoneCore12LegacyFormat } from '@/utils/string';
import { useToast } from '@/composables/toast';
import VisitPreviewImages from '@/components/visit/VisitPreviewImages.vue';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    ServicesPopularCard,
    GroupPartnerInputCard,
    VisitPreviewImages,
  },

  setup() {
    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { emitEvent } = useMetrica();
    const toast = useToast();
    const { t } = useI18n();
    const { isPreentry, visitState, visitType } = useCreateVisitStep();

    const carId = get(route.params, 'carId', '') as string;
    const visitId = '0'; // NOTE: т.к. пока черновиков у нас нет, то новый заказ создается с идентификатором 0

    const groupPartnerId = ref<number|string>(get(visitState.body, 'group.id', ''));
    watch(groupPartnerId, (groupId, prevGroupId) => {
      const action = (groupId ? (prevGroupId ? 'replace' : 'select') : 'unselect');
      emitEvent(`visit/${visitType}/client/partner/${action}`);
    });

    // Означает что страница открыта повторно для редактирования
    // (можно открыть в последующих шагах)
    const isActEdit = route.query.act === 'edit';

    const car = reactive({
      loadingInfo: false,
      loadingSave: false,
      fromСacheInfo: false,
      info: {
        // Специально не указывать, станет доступен после
        // загрузки информации об автомобиле с сервера
        // id: carId,

        ownerName: '',
        ownerPhone: '',
        comment: '',
      } as CarBodyRequest,
    });

    /** Загрузка информации об автомобиле и владельце */
    async function loadCar() {
      car.loadingInfo = true;

      try {
        const data = await store.car.getCar(carId);
        car.fromСacheInfo = data.fromСache;

        if (!groupPartnerId.value && !isActEdit) {
          groupPartnerId.value = get(data.data, 'group.id', '');
        }

        Object.assign(car.info, pick<CarBodyRequest>(data.data, [
          'id', 'comment',
          'ownerName', 'ownerPhone',
          'number',
        ]));
      } catch(e) {
        if (isClientError(e) && e.response?.status === 404) {
          toast.error(t('views.visit_new_client.error_load_car_not_exist'), 4000, {
            defaultHeader: t('views.visit_new_client.header_error_car_not_exists')
          });
        }

        throw e;
      } finally {
        car.loadingInfo = false;
      }
    }

    /** Обновление информации об автомобиле */
    async function updateCar() {
      car.loadingSave = true;

      try {
        if (!car.info.id) {
          throw new Error('No vehicle found to update');
        }

        await store.car.update(car.info.id, {
          ...car.info,
          ownerPhone: preparePhoneCore12LegacyFormat(car.info.ownerPhone),
        });

        // const updateInfo = ...
        // if (updateInfo.sendingDelayed) {
        //   toast.warning('Интернет недоступен, данные сохранены, и синхрнизируются после пояления интернета');
        // }
      } catch (e) {
        toast.error(e, 4000, {
          defaultHeader: t('views.visit_new_client.header_error_update')
        });

        throw e;
      } finally {
        car.loadingSave = false;
      }
    }

    async function next() {
      await updateCar();

      // fix: Чтобы правильно перекэшировать информацию
      await store.car.getCar(carId);

      visitState.body.car = {
        id: carId,
        number: car.info.number,
      };

      const oldGroupId = visitState.body.group?.id;

      visitState.body.group = groupPartnerId.value
        ? { id: +groupPartnerId.value } : undefined;

      if (isActEdit) {
        // Если при редактировании информации был изменен конрагент,
        // то стоимость услуг могла измениться, и чтобы оформить заказ
        // с новыми ценами, ранее выбранные услуги необходимо сбросить
        if (groupPartnerId.value != oldGroupId) {
          visitState.body.providedServices = [];
        }

        router.back();
        return;
      }

      emitEvent(`visit/${visitType}/client/next`);

      router.push({
        // В предварительной записи страницу выбора бокса пропускаем,
        // т.к. бокс быдет выбран на странице календаря
        name: isPreentry ? 'preentry-service-type' : 'visit-new-box',
        params: { visitId },
      });
    }

    return {
      carId,
      car,
      next,
      groupPartnerId,
      isActEdit,
      loadCar,
      isTabletAndUp,
      isPreentry,
      visitId,
    };
  },
});
</script>

<style lang="scss">
</style>