import { MetricaPluginAdapter } from "@/composables/useMetrica";

export const DevLogMetricaAdapter: MetricaPluginAdapter = {
  init({ onEvent }) {
    console.log('METRICA DEV INIT');
    
    onEvent(ev => {
      console.log('METRICA LOG: ', ev.name, ev.params);
    });
  }
};