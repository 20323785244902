<template>
  <visit-archive-group
    v-for="group in visitsArchiveDatesGroups"
    :key="group.title"
    :group="group"
    :col-sizes="colSizes"
  />
</template>

<script lang="ts">
import { defineComponent, PropType, computed } from 'vue'
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit'
import { visitsGroupByDate } from '@/helpers/visit'
import VisitArchiveGroup from './VisitArchiveGroup.vue'

export default defineComponent({
  components: {
    VisitArchiveGroup,
  },

  props: {
    visits: {
      type: Array as PropType<CarVisitCollectionItem[]>,
      required: true
    },
    colSizes: {
      type: Object as PropType<Record<string, string|number>>,
      required: false,
    }
  },
  
  setup(props) {
    const visitsArchiveDatesGroups = computed(() => visitsGroupByDate(props.visits));

    return {
      visitsArchiveDatesGroups,
    };
  }
});
</script>

<style lang="scss">
</style>