<script lang="ts" setup>
import { useStore } from '@/composables/useApp';
import { defineProps, inject, ref, computed, defineEmits } from 'vue';
import { ProvidedServicesInformation } from '@/store/ServiceStore';
import ServiceProvideDoersItem from './ServiceProvideDoersItem.vue';
import { useDoers } from '@/composables/useDoers';
import { intersectionWith, isEqual, isEmpty } from 'lodash';
import { NewVisitSatate } from '@/store/NewVisitState';
import { useMetrica } from '@/composables/useMetrica';

const props = defineProps({
  stateInjectKey: {
    type: String,
    required: true,
  },
  isMandatoryDoers: {
    type: null,
  }
});

const emit = defineEmits<{
  (e: 'update:isMandatoryDoers', value: boolean): void;
}>();

const store = useStore();
const { doers, loadDoers } = useDoers();
const { emitEvent } = useMetrica();

const visitAction = inject<string>('visitAction', 'new');
const visitState = inject<NewVisitSatate>(props.stateInjectKey, null as unknown as NewVisitSatate);
if (!visitState) {
  throw new Error('Не найдено состояние с данными заказа');
}

const servicesAndProvideService = ref<ProvidedServicesInformation[]>([]);

/**
 * Выбраны ли все необходимые исполнители
 * - для услуги обязательно должен быть выбран исполнитель
 * - для товара значение не обязательно
 */
const isMandatoryDoers = computed<boolean>(() => {
  for (const { service, provide } of servicesAndProvideService.value) {
    if (isEmpty(provide.doers) && !service?.isProduct) {
      return false;
    }
  }

  return true;
});

async function loadServicesByProvidedItems() {
  servicesAndProvideService.value = await store.service.getProvidedServicesInformation(
    visitState.body.providedServices || [],
    visitState.body.group?.id,
    visitState.body.car?.id,
    false
  );

  emit('update:isMandatoryDoers', isMandatoryDoers.value);
}

async function load() {
  await Promise.all([
    loadServicesByProvidedItems(),
    loadDoers(),
  ]);
}

function setDefaultDoers(doers: Array<{ id: number; }>) {
  emitEvent(`visit/${visitAction}/doers/set-default`, { doers: [ ...doers ] });
  visitState.defaultDoers = doers;

  // При выборе исполнителя по умолчанию, устанавливаем
  // всех исполнителей, согдасно этому правилу
  servicesAndProvideService.value.forEach(({ provide, service }) => {
    if (!service?.isProduct) { // Назначаем исполнителей только, для нетоваров
      provide.doers = [ ...doers ];
    }
  });

  emit('update:isMandatoryDoers', isMandatoryDoers.value);
}

function setItemDoers(item: ProvidedServicesInformation, doers: Array<{ id: number; }>) {
  emitEvent(`visit/${visitAction}/doers/set-service`, { doers: [ ...doers ] });
  item.provide.doers = doers;

  // При выборе исполнителей для элемента правильно корректируем
  // значение по-умолчанию, для выбранных исполнителей
  const doersItems = servicesAndProvideService.value.map(item => item.provide.doers);
  const doersExistsAll = intersectionWith(...doersItems, isEqual);

  visitState.defaultDoers = doersExistsAll;

  emit('update:isMandatoryDoers', isMandatoryDoers.value);
}
</script>

<template>
  <c-content-loading :action="load">
    <service-provide-doers-item
      select-btn-text="Выбрать для всего заказа"
      :doers-collection="doers"
      :multiselect="true"
      :doers-value="visitState.defaultDoers"
      @update:doers-value="setDefaultDoers"
    />

    <template v-for="(item, index) in servicesAndProvideService">
      <service-provide-doers-item
        v-if="item.service"
        :key="item.service.id"
        :service="item.service"
        :doers-collection="doers"
        :doers-value="item.provide.doers"
        @update:doers-value="value => setItemDoers(item, value)"
      />

      <ion-item v-else :key="`service_index_${index}`">
        <ion-label>
          Не найдена услуга с идентификатором ID:{{ item.provide.service.id || 'null' }}
        </ion-label>
      </ion-item>
    </template>
  </c-content-loading>
</template>
