<template>
  <div class="c-image-bg" :style="{
    backgroundImage: src ? `url(${src})` : null,
    paddingTop: paddingY,
    paddingBottom: paddingY,
  }">
    <div class="c-image-bg__content">
      <slot />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    ratio: {
      type: Number as PropType<number>,
      default: 1,
    },
    src: {
      type: String as PropType<string>,
      required: false,
    }
  },
  setup(props) {
    const paddingY = computed(() => {
      return Math.abs(Math.round(props.ratio * 100 / 2)) + '%';
    });

    return {
      paddingY,
    };
  }
});
</script>

<style lang="scss">
.c-image-bg {
  position: relative;
  background-color: rgba(255, 255, 255, 0.02);
  background-size: cover;
  background-position: center center;
  border-radius: var(--core-card-radius);

  &__content {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
  }
}
</style>