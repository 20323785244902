<template>
  <div class="c-calendar-mounth">
    <div class="c-calendar-mounth__header">
      {{ mounthGridInfo.textMounth }}, {{ mounthGridInfo.textYear }}
    </div>

    <div class="c-calendar-mounth__weeks-grid">
      <div
        v-for="dateInfo in mounthGridInfo.calendar"
        :key="dateInfo.date"
        @click="onClickDate(dateInfo)"
        :style="{
          gridColumn: dateInfo.column,
          gridRow: dateInfo.row,
        }"
        :class="{
          'c-calendar-mounth__date-cell': true,
          'c-calendar-mounth__date-cell--day-off': dateInfo.dayOff,
          'c-calendar-mounth__date-cell--today': dateInfo.today,
          'c-calendar-mounth__date-cell--before-now': dateInfo.beforeNow,
          'c-calendar-mounth__date-cell--selected': (dateInfo.timestamp === innerValueTimeshtamp),
        }"
      >
        <div class="c-calendar-mounth__inner-date-number">
          {{ dateInfo.date }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, computed, ref, watch } from 'vue';
import {
  getCalendarGrid,
  convertToDateAndCleanTime,
  CalendarDayInfo
} from '@/helpers/datetime';

export default defineComponent({
  emits: ['update:modelValue'],

  props: {
    mounth: {
      type: Number,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    modelValue: {
      type: null,
    },
  },

  setup(props, { emit }) {
    function prepareValue(value: any): Date|null {
      if (!value) return null;

      const dateObject = convertToDateAndCleanTime(value);

      // Optimaze (если дата изначально не попадает в заданный диапазон, то далее
      // проверка на конкретные даты производится не будет и внутренее значение будет == null)
      const { startDate, endDate } = mounthGridInfo.value;
      if (dateObject >= startDate && dateObject <= endDate) {
        return dateObject;
      }

      return null;
    }

    const innerValue = computed<null|Date>({
      get() {
        return lazyValue.value;
      },
      set(value) {
        lazyValue.value = prepareValue(value);
        emit('update:modelValue', innerValue.value);
      },
    });
    const innerValueTimeshtamp = computed(() => innerValue.value?.getTime() || null);
    const mounthGridInfo = computed(() => {
      return getCalendarGrid(props.mounth, props.year);
    });

    function onClickDate(dateInfo: CalendarDayInfo) {
      innerValue.value = dateInfo.dateObject;
    }

    const lazyValue = ref<null|Date>(prepareValue(props.modelValue));
    watch(() => props.modelValue, value => lazyValue.value = prepareValue(value));

    return {
      mounthGridInfo,
      innerValueTimeshtamp,
      onClickDate,
    };
  }
});
</script>

<style lang="scss">
.c-calendar-mounth {
  background-color: var(--ion-card-background);
  border-radius: var(--core-card-radius);
  padding: var(--ion-padding);
  color: var(--core-light);
  user-select: none;

  &__header {
    font-size: var(--core-font-size-heading);
    font-weight: 500;
    margin-bottom: var(--ion-padding);
  }

  &__weeks-grid {
    display: grid;
    grid-template-columns: repeat(7, 1fr);
    grid-auto-rows: auto;
    grid-gap: 2px;
  }

  &__date-cell {
    &--day-off {
      color: var(--ion-color-primary);
    }
  }

  &__inner-date-number {
    font-size: var(--core-font-size-large);
    width: 100%;
    padding: calc(50% - var(--core-font-size-large) / 2) 3px;
    line-height: 1em;
    margin: 0 auto;
    border-radius: 1000px;
    box-sizing: border-box;
    text-align: center;

    @media (min-width: 400px) {
      height: 46px;
      width: 46px;
      padding: 0;
      margin-top: 5px;
      margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    @media (min-width: 550px) {
      margin-top: 2.4vw;
      margin-bottom: 2.4vw;
    }
  }

  &__date-cell--today &__inner-date-number {
    box-shadow: 0 0 0 1px var(--ion-color-primary) !important;
  }

  &__date-cell:hover &__inner-date-number {
    box-shadow: 0 0 0 1px var(--core-divider-color);
  }

  &__date-cell--selected &__inner-date-number {
    background-color: var(--ion-color-primary) !important;
    color: var(--ion-color-primary-contrast) !important;
  }
}
</style>