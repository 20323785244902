import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "core-searchbar-mobile ion-padding" }
const _hoisted_2 = { class: "padding-x-adaptive pb-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_services_by_category_group = _resolveComponent("services-by-category-group")!
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_cc = _resolveComponent("cc")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_Teleport, {
        to: _ctx.searchbarContainer,
        disabled: _ctx.searchbarTeleportDisabled
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_searchbar, {
            class: "core-searchbar",
            inputmode: "search",
            placeholder: _ctx.$t('components.services_provide_visit_state.input_search'),
            "clear-icon": "close-outline",
            "search-icon": "core-search",
            debounce: 300,
            modelValue: _ctx.searchWords,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchWords) = $event))
          }, null, 8, ["placeholder", "modelValue"])
        ])
      ], 8, ["to", "disabled"])),
      _createVNode(_component_c_content_loading, { action: _ctx.contentLoading }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_2, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.servicesGroups, (servicesGroup, i) => {
              return (_openBlock(), _createBlock(_component_services_by_category_group, {
                key: _ctx.generateKeyProvideService(servicesGroup.typeId, servicesGroup.categoryId),
                context: servicesGroup,
                "search-words": _ctx.searchWords,
                "short-title": _ctx.shortGroupName,
                "is-collapsed-content": _ctx.isCollapsedGroup,
                "show-content": (i < 1),
                onToggleServicesGroup: _ctx.toggleGroup,
                provided: _ctx.providedServicesGroupValue[
            _ctx.generateKeyProvideService(servicesGroup.typeId, servicesGroup.categoryId)
          ],
                "onUpdate:provided": ($event: any) => ((_ctx.providedServicesGroupValue[
            _ctx.generateKeyProvideService(servicesGroup.typeId, servicesGroup.categoryId)
          ]) = $event)
              }, null, 8, ["context", "search-words", "short-title", "is-collapsed-content", "show-content", "onToggleServicesGroup", "provided", "onUpdate:provided"]))
            }), 128)),
            _createVNode(_component_ion_row, { class: "color-default size-default" }, {
              default: _withCtx(() => [
                _createVNode(_component_ion_col, { class: "px-0 pb-0" }, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('components.services_provide_visit_state.label_total')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_ion_col, {
                  class: "px-0 pb-0",
                  size: "auto"
                }, {
                  default: _withCtx(() => [
                    _createVNode(_component_cc, { price: _ctx.total }, null, 8, ["price"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            })
          ])
        ]),
        _: 1
      }, 8, ["action"])
    ]),
    _: 1
  }))
}