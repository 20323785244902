import { BaseCollectionResponse, BaseQueryPage } from './Base';
import { ServiceNomenclature } from './Service';

export interface DiscountAccount {
  id: string;

  /** Бонусный счет */
  scores: number;

  /** Исключаемые компании */
  excludedCampaigns?: Discount;

  /** Применяемые компании */
  forcedCampaigns?: Discount;
}

export enum DiscountTypeEnum {
  /** Скидки, которые выставляет администратор руками */
  ManualDiscount = 'manual_discount',

  /** Автоматическая скидка со статическим процентом скидки */
  StaticDiscount = 'static_discount',

  /** ... */
  BonusAccounting = 'bonus_accounting',

  /** Скидочная программа со скидкой (серебряный, золотой, платиновый и т.д.) */
  LeveledDiscount = 'leveled_discount',

  /** Бонусная программа, для выдачи подарков */
  Gift = 'gift',
}

//#region Простые акции (назначаются администраторами)
export interface Discount {
  id: number;

  /** Наименование скидки */
  name: string;

  /** Влияет на коммисию менеджера (да/нет) */
  impactsManagerComission: boolean;

  /** Влияет на коммисию исполнителя (да/нет) */
  impactsDoerComission: boolean;

  /** Скидка активна? (да/нет) */
  activated: boolean;

  /** Процент скидки */
  percent: number; // int32

  /** Тип скидки */
  type: DiscountTypeEnum;
}

export interface Weekdays {
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
  sunday: boolean;
}

export interface DateTimeValidity {
  dateEnabled: boolean;
  timeEnabled: boolean;
  weekdayEnabled: boolean;
  weekdays?: Weekdays;
  dateFrom?: string;
  dateTo?: string;
}

export interface CategoryNomenclatureCampain {
  id: number;
  name: string;
  level: number;
}

export type DiscountCollectionQuery = BaseQueryPage & { [param: string]: any };
export type DiscountCollectionResponse = BaseCollectionResponse<Discount>;
//#endregion Простые акции

//#region Статические акции (по неделям, дням, времени)
export interface StaticDiscountCampaignApplicability {
  nomenclatures?: ServiceNomenclature[];
  categories?: CategoryNomenclatureCampain[];
}

export interface StaticDiscountCampaignTargetAccounts {
  /**
   * Идентификатор автомобиля
   */
  id: string;

  /**
   * Бонусный счет
   */
  scores: number;
}

export interface StaticDiscountCampaign extends Discount {
  validity: DateTimeValidity;
  applicability: StaticDiscountCampaignApplicability;
  forcedAccounts: StaticDiscountCampaignTargetAccounts[];
}

export type StaticDiscountCampaignCollectionQuery = BaseQueryPage & { [param: string]: any };
export type StaticDiscountCampaignCollectionResponse = BaseCollectionResponse<StaticDiscountCampaign>;
//#endregion Статические акции

//#region Подарочные акции
export interface GiftCampaignNomenclature {
  id: number;
  name: string;
}

export interface GiftCampaign {
  /** Идентификатор акции (компании) */
  id: number;

  /** Наименование акции */
  name: string;

  /** Идентификатор автомобиля/клиента */
  carId: string;

  /** Текущее кол-во уже купленного товара/услуги */
  qty: number;

  /** Кол-во покупок, котороого нужно достич, для акцтивации акции */
  targetQty: number;

  /** Идентификтаор номенклатуры "к приобритению" */
  nomenclatureId: number;

  /** Наименование номенклатуры "к приобритению" */
  nomenclatureName: string;

  /** Идентификаторы услуг, которые можно подарить */
  gifts: GiftCampaignNomenclature[];
}

export type GiftCampaignCollectionQuery = { carId: string; };
export type GiftCampaignCollectionResponse = Array<GiftCampaign>;
//#endregion Подарочные акции

//#region Накопительные кампании
export interface LeveledCampaignNomenclature {
  id: number;
  name: string;
}

export interface LeveledCampaign {
  id: number;              // TODO: Этого идентификаора пока нет
  counterpartyId?: number; // TODO: не понятно зачем идентифкатор контрагента
  percent: number;
  target: number;
  name: string;
  carId: string;
  nomenclatures: LeveledCampaignNomenclature[];

  // categoriesNome: any[]; // Не понятно зачем и почему это есть в API
}

export type LeveledCampaignCollectionQuery = { carId: string; };
export type LeveledCampaignCollectionResponse = Array<LeveledCampaign>;
//#endregion Накопительные кампании