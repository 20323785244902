<template>
  <c-card-list>
    <ion-item class="c-card-list-header">
      <ion-label>
        {{ $t('components.services_popular_card.col_label_name') }}
      </ion-label>

      <!-- eslint-disable-next-line -->
      <ion-note slot="end" color="light">
        {{ $t('components.services_popular_card.col_label_count') }}
      </ion-note>
    </ion-item>

    <template v-if="loading">
      <ion-item
        v-for="skeletIndex in skeletLoadingRows"
        :key="skeletIndex"
      >
        <ion-label>
          <ion-skeleton-text
            animated
            :style="{
              width: randWidth(20, 70),
              height: '1em', 
            }"
          />
        </ion-label>

        <!-- eslint-disable-next-line -->
        <ion-note slot="end" color="light">
          <ion-skeleton-text
            animated
            :style="{
              width: '20px',
              height: '1em', 
            }"
          />
        </ion-note>
      </ion-item>
    </template>

    <ion-item v-else-if="!hasServices">
      <ion-label class="ion-text-center">
        {{ $t('components.services_popular_card.services_list_empty') }}
      </ion-label>
    </ion-item>

    <template v-else>
      <ion-item
        v-for="item in viewList"
        :key="item.id"
      >
        <ion-label>{{ item.name }}</ion-label>
        <!-- eslint-disable-next-line -->
        <ion-note slot="end" color="light">
          {{ item.countPurchases }}
        </ion-note>
      </ion-item>
    </template>
  </c-card-list>
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, PropType, watch, ref } from 'vue';
import { CarServicesPopularItem } from '@/repositories/Models/Car';
import { useStore } from '@/composables/useApp';
import { uniqueId, Dictionary, get } from 'lodash';
import { randWidth } from '@/utils/rand';

const SERVICES_REPLACE_DICTIONARY: Dictionary<string> = {
  // Данное значение не обязательно локализовывать,
  // т.к. оно приходит с сервера, должно быть локализованно именно там
  'Клиент приезжал': 'Общее количество визитов'
};

export default defineComponent({
  props: {
    carId: {
      type: String as PropType<string>,
      required: false,
    },
    skeletLoadingRows: {
      type: Number as PropType<number>,
      default: 3,
    }
  },
  setup(props) {
    const store = useStore();

    const loading = ref(false);
    const list = ref<CarServicesPopularItem[]>([]);
    const hasServices = computed(() => !!list.value.length);
    const viewList = computed(() => list.value.map(item => {
      return {
        id: item.service.id || uniqueId('service_'),
        name: get(SERVICES_REPLACE_DICTIONARY, item.service.name, item.service.name),
        countPurchases: item.countPurchases,
      };
    }));

    /** Загрузка популярных услуг для автомобиля */
    async function loadPopularServices() {
      if (!props.carId) return;

      loading.value = true;
      try {
        const { data } = await store.car.getPopularServices(props.carId);
        list.value = data;
      } catch (e) {
        // TODO: Ошибки пока никак не выводятся,
        // в будущем предусмотреть возможность игнорирования 404
        console.warn(e);
      } finally {
        loading.value = false;
      }
    }

    watch(() => props.carId, () => loadPopularServices());
    onMounted(() => loadPopularServices());

    return {
      list,
      loading,
      viewList,
      loadPopularServices,
      hasServices,
      randWidth,
    }
  },
});
</script>

<style lang="scss">
</style>