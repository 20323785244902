<template>
  <div :class="{
    'c-nav-cards-list': true,
    'c-nav-cards-list--tablet-adaptive': tabletAdaptive,
  }">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  props: {
    tabletAdaptive: {
      type: Boolean,
      default: true,
    },
  }
});
</script>

<style lang="scss">
</style>