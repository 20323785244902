<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from '@/composables/useApp';
import { usePreenryDatetimePage } from '@/composables/usePreentry';
import { useNavManager } from '@/composables/useNavManager';
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit';
import { get } from 'lodash';
// import CInfiniteCalendar from '@/components/core/InfiniteCalendar/CInfiniteCalendar.vue';
import VisitsPreentrySheduleBox from '@/components/visit/VisitsPreentrySheduleBox.vue';
import VisitsPreentryCalendarMonth from '@/components/visit/VisitsPreentryCalendarMonth.vue';
import VisitsPreentryChoiceMonth from '@/components/visit/VisitsPreentryChoiceMonth.vue';
import { useI18n } from 'vue-i18n';
import { DateTime } from 'luxon';

const router = useRouter();
const navManager = useNavManager();
const store = useStore();
const { t } = useI18n();
const {
  currentTab,
  customDate,
  customDateFormat,
  setCustomDate,
  // clearCustomDate,
  viewSheduleDate,
} = usePreenryDatetimePage();

//#region Custom Mounth
const customMounth = ref<Date|null>(null);
const customMounthFormat = computed<string|null>(() => {
  if (!customMounth.value) {
    return null;
  }

  return DateTime.fromJSDate(customMounth.value)
    .toFormat('LLLL, yyyy', { locale: 'ru' });
});

function clearCustomMounth() {
  customMounth.value = null;
  currentTab.value = 'selectMounth';
}

function clearCustomDateAndOpenCalendar() {
  customDate.value = null;
  currentTab.value = 'viewCalendar';
}

function clearCustomDate() {
  customDate.value = null;
}

function setCustomMounth(date: Date) {
  customMounth.value = date;
  currentTab.value = 'viewCalendar';
}

function onClickDayCalendar(date: Date) {
  setCustomDate(date);
  customMounth.value = null;
}
//#endregion

const showAddButton = computed(() => {
  return ['currentDate', 'nextDate', 'customDate'].includes(currentTab.value);
});

function newVisitPreentry() {
  store.visit.clearPreentryVisitState();

  // Предварительно сохраним выбранную дату, чтобы в последующем шаге 
  // при выборе времени отобразить календарь с этой датой
  store.visit.preentryVisitState.meta.saveDate = new Date(viewSheduleDate.value);
  
  router.push({ name: 'preentry-car-number' });
}

function toVisits() {
  if (get(router.options, 'history.state.back') === '/visits') {
    router.back();
  } else {
    navManager.navigate({
      routerDirection: 'root',
      routerLink: { name: 'visits' },
    });
  }
}

function toVisitSingle(visit: CarVisitCollectionItem) {
  router.push({
    name: 'visit-single',
    params: { visitId: visit.id }
  });
}
</script>

<template>
  <ion-page>
    <ion-header class="c-visits-preenty-header">
      <ion-toolbar class="g-ion-header-toolbar-main">
        <!-- Chip выбранного дня -->
        <ion-chip v-show="customDate" outline color="primary" @click="clearCustomDateAndOpenCalendar">
          <ion-label>{{ customDateFormat }}</ion-label>
          <ion-icon icon="close-outline" @click.stop="clearCustomDate"></ion-icon>
        </ion-chip>

        <!-- Chip выбранного месяца -->
        <ion-chip v-show="customMounth" outline color="primary" @click="clearCustomMounth">
          <ion-label>{{ customMounthFormat }}</ion-label>
          <ion-icon icon="close-outline" @click.stop="clearCustomMounth"></ion-icon>
        </ion-chip>

        <!-- Вкладки, для выбора дня, или выбора месяца -->
        <ion-segment v-show="!customDate && !customMounth" class="core-segment" v-model="currentTab">
          <ion-segment-button value="currentDate" layout="icon-hide">
            <ion-label>{{ t('views.visits_preentry.tab_current_day') }}</ion-label>
          </ion-segment-button>

          <ion-segment-button value="nextDate" layout="icon-hide">
            <ion-label>{{ t('views.visits_preentry.tab_next_day') }}</ion-label>
          </ion-segment-button>

          <!-- <ion-segment-button value="selectDate" layout="icon-hide">
            <ion-label>{{ t('views.visits_preentry.tab_select_date') }}</ion-label>
          </ion-segment-button> -->

          <ion-segment-button value="selectMounth" layout="icon-hide">
            <ion-label>{{ t('views.visits_preentry.tab_choice_month') }}</ion-label>
          </ion-segment-button>
        </ion-segment>

        <ion-buttons slot="end">
          <c-transparent-button @click="toVisits">
            <ion-icon name="close-outline" class="icon-medium" />
          </c-transparent-button>
        </ion-buttons>
      </ion-toolbar>
    </ion-header>

    <ion-content :scroll-y="false">
      <!-- <template v-if="currentTab === 'selectDate'">
        <c-infinite-calendar @update:modelValue="setCustomDate" :modelValue="customDate" />
      </template> -->

      <keep-alive>
        <template v-if="currentTab === 'selectMounth'">
          <visits-preentry-choice-month @choice-month="setCustomMounth" />
        </template>

        <template v-else-if="currentTab === 'viewCalendar'">
          <visits-preentry-calendar-month
            :month="customMounth || undefined"
            @click-date="onClickDayCalendar"
          />
        </template>

        <template v-else>
          <visits-preentry-shedule-box
            :date="viewSheduleDate"
            @click-visit="toVisitSingle"
            :visits-auto-sync="true"
          />
        </template>
      </keep-alive>

      <div slot="fixed" class="visits-fixed-toolbar">
        <div class="visits-fixed-actions" v-show="showAddButton">
          <ion-button color="primary" shape="round" @click="newVisitPreentry">
            <ion-icon name="add-outline" class="mr-1" />
            {{ t('views.visits_preentry.btn_add_preentry') }}
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<style lang="scss">
.c-visits-preenty-header {
  ion-segment-button {
    min-width: auto !important;
  }

  .g-ion-header-toolbar-main {
    --padding-top: 0;
    --padding-bottom: 0;

    ion-segment {
      height: var(--min-height);
    }
  }
}
</style>