<template>
  <popover-layout
    class="c-settings-popover"
    :title="$t('views.settings.settings_popover_title')"
  >
    <AppSettings
      @logout="close"
      @clear-cache="close"
      @close-settings="close"
    />
  </popover-layout>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { popoverController } from '@ionic/vue';
import AppSettings from '@/components/app-service/AppSettings.vue';
import PopoverLayout from '@/components/layout/PopoverLayout.vue';

export default defineComponent({
  components: {
    AppSettings,
    PopoverLayout,
  },

  setup() {
    function close() {
      popoverController.dismiss();
    }

    return {
      close,
    };
  }
});
</script>

<style lang="scss">
</style>