<template>
  <ion-page>
    <app-header-big :title="$t('views.market.title')" />

    <ion-content>
      <tablet-side-layout>
        <template #side v-if="isTabletAndUp">
          <visit-store-checkout
            class="ion-padding"
            :complete-btn-container="sideAfterRef"
          />
        </template>

        <template #side-after v-if="isTabletAndUp">
          <div class="visit-single-complete-btn-wrap" ref="sideAfterRef" />
        </template>

        <div class="ion-padding">
          <c-card-header>
            {{ $t('views.market.provide_services_card_title') }}
          </c-card-header>

          <services-provide-visit-state
            :restore-filter-by-selected="false"
            :short-group-name="true"
            :mobile-searchbar-container="mobileSearchbarContainer"
            :services-groups="categoryServicesGroups"
            :is-collapsed-group="false"
            @input-search="w => searchWords = w"
            :content-loading="contentLoading"
          />
        </div>

        <div class="fixed-actions-spacer" v-if="!isTabletAndUp" />
      </tablet-side-layout>

      <!-- eslint-disable-next-line -->
      <div slot="fixed"
        v-if="!isTabletAndUp"
        v-scroll-hide="{}"
        class="visits-fixed-toolbar"
        :class="{'scroll-hide--show-force': searchWords }"
      >
        <div class="visits-fixed-actions" v-if="!isTabletAndUp">
          <ion-button
            color="primary"
            shape="round"
            @click="next"
          >
            {{ $t('views.market.btn_next') }}
          </ion-button>
        </div>

        <div ref="mobileSearchbarContainer"></div>
      </div>
    </ion-content>

    <app-footer />
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useStore } from '@/composables/useApp';
import ServicesProvideVisitState from '@/components/service/provide-form/ServicesProvideVisitState.vue';
import VisitStoreCheckout from '@/components/store/VisitStoreCheckout.vue';
import { isTabletAndUp } from '@/helpers/adaptive';
import { isEmpty, values, flatten, get, debounce } from 'lodash';
import { useRouter } from 'vue-router';
import { scrollHide } from '@/directives/scrollHide';
import { ServiceByContextQuery } from '@/repositories/Models/Service';
import { useCreateVisitStep } from '@/composables/visit';
import { usePointTypes } from '@/composables/usePointTypes';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
import { useToast } from '@/composables/toast';

export default defineComponent({
  components: {
    VisitStoreCheckout,
    ServicesProvideVisitState,
  },
  directives: { scrollHide },
  setup() {
    const store = useStore();
    const toast = useToast();
    const router = useRouter();
    const { t } = useI18n();
    const { emitEvent } = useMetrica();
    const { loadPointTypes, storeAllTypes } = usePointTypes();
    const { visitState, visitType } = useCreateVisitStep({
      stateProvideKey: 'visitState',
    });

    /**
     * В данной версии приложения скореевсего всегда будет undefined,
     * т.к. у магазиина врядли есть и будут партнеры.
     */
    const groupId = computed<number|undefined>(() => {
      return get(visitState, 'body.group.id', undefined);
    });

    async function contentLoading() {
      await loadPointTypes();
    }

    const categoryServicesGroups = computed<ServiceByContextQuery[]>(() => {
      return flatten(
        storeAllTypes.value.map(pointType => {
          return values(pointType.carCategories).map(category => ({
            typeId: pointType.id,
            categoryId: category.id,
            groupId: groupId.value || undefined,
            discountAccountId: get(visitState, 'body.car.id', undefined),
          }));
        })
      );
    });

    const sideAfterRef = ref<HTMLDivElement|null>(null);
    const isNextDisabled = computed(() => isEmpty(store.visit.newStoreVisitState.body.providedServices));

    function next() {
      if (isNextDisabled.value) {
        return toast.error(t('views.market.error_empty_products'), 3000, {
          defaultHeader: t('views.market.header_error_empty_products')
        });
      }

      router.push({
        name: 'visit-store-checkout'
      });
    }

    const searchWords = ref('');
    const mobileSearchbarContainer = ref<HTMLDivElement|null>(null);
    const emitEventSearchDebounce = debounce(() => {
      if (searchWords.value) {
        emitEvent(`visit/${visitType}/services/search`, {
          text: searchWords.value
        });
      }
    }, 1400);
    watch(searchWords, emitEventSearchDebounce);

    return {
      sideAfterRef,
      isTabletAndUp,
      isNextDisabled,
      searchWords,
      mobileSearchbarContainer,
      categoryServicesGroups,
      next,
      contentLoading,
    };
  },
});
</script>

<style lang="scss">
</style>