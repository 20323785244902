import { ObjectDirective /*, DirectiveBinding */ } from 'vue'

/**
 * Найдет все необходимые контейнеры страницы
 * 
 * @param el элемент который необходимо скрывать/показывать
 */
export async function getContextElements(el: HTMLElement) {
  const target = el;
  const ionPage = target.closest('.ion-page');
  const ionContent = ionPage?.querySelector('ion-content') || null;
  const innerScroll = await ionContent?.getScrollElement() || null;

  return { target, ionPage, ionContent, innerScroll };
}

export type HideScrollElement = HTMLElement & {
  _hideScroll?: HideScrollData;
};

// Какое расстояние необходимо проскролить, чтобы
// сработало переключение показа/скрытия (значение в px)
const SCROLL_TOGGLE_DELTA_DOWN = 50;
const SCROLL_TOGGLE_DELTA_UP = 2;

export interface HideScrollData {
  target: HTMLElement;
  innerScroll: HTMLElement;
  pointScrollY: number;
  dirDown: boolean;
  onScroll: (ev: Event) => void;
}

export async function bindPageScrollActions(target: HideScrollElement) {
  const { innerScroll } = await getContextElements(target);
  if (!innerScroll) return;

  let params: HideScrollData = {
    target,
    innerScroll,
    pointScrollY: 0,
    dirDown: false,
    onScroll: () => { /** Empty */ }
  };

  let options = {
    initHide: true,
    dirHide: 'up' as 'up'|'down',
  };

  const resetTransition = () => {
    if (target.style.transition != 'transform 0.3s ease') {
      target.style.transition = 'transform 0.3s ease';
    }
  }

  const transformReset = () => {
    resetTransition();
    target.style.transform = 'translateY(0px)';
  }

  const transformHide = () => {
    resetTransition();
    target.style.transform = `translateY(100%)`;
  }

  params.onScroll = (ev: Event) => {
    const scrollContainer = ev.target as HTMLElement;
    const cs = scrollContainer.scrollTop;

    // fix: эффект перепрокрутки сверху
    if (cs <= 0) {
      if (params.dirDown) {
        params.dirDown = false;
        transformHide();
      }

      return;
    }
    
    // В самом низу прокрутки панель будет отображена.
    // Так-же содеержит и справления для эффекта перепрокрутки (iOS).
    const scrollBottom = scrollContainer.scrollHeight - scrollContainer.clientHeight - cs;
    if (scrollBottom < 4) {
      if (!params.dirDown) {
        params.dirDown = true;
        transformReset();
      }

      return;
    }

    const ps = params.pointScrollY;
    const d = cs - ps;

    // Scroll DOWN
    if (d > 0) {
      if (!params.dirDown) {
        if (d >= SCROLL_TOGGLE_DELTA_DOWN) {
          params.dirDown = true;
          transformReset();
        }
      } else {
        params.pointScrollY = cs;
      }
    }
    // SCROLL UP
    else {
      if (params.dirDown) {
        if (-d >= SCROLL_TOGGLE_DELTA_UP) {
          params.dirDown = false;
          transformHide();
        }
      } else {
        params.pointScrollY = cs;
      }
    }
  };

  if (options.initHide) {
    target.style.transform = `translateY(100%)`;
  }

  innerScroll.addEventListener('scroll', params.onScroll);
  target._hideScroll = params;
}

export function unbindPageScrollActions(el: HideScrollElement) {
  if (!el._hideScroll) return;

  const { innerScroll, target, onScroll } = el?._hideScroll as HideScrollData;
  innerScroll.removeEventListener('scroll', onScroll);
  target.style.transition = '';
  target.style.transform = '';

  delete el._hideScroll;
}

export const scrollHide: ObjectDirective = {
  beforeUpdate(el: HideScrollElement /*, binding: DirectiveBinding */ ) {
    unbindPageScrollActions(el);
    bindPageScrollActions(el); // async
  },

  beforeUnmount(el: HideScrollElement) {
    unbindPageScrollActions(el);
  }
};