<template>
  <ion-page>
    <app-header-big :title="$t('views.reports.title')" />

    <ion-content>
      <c-nav-cards-list>
        <c-nav-cards-list-item @click="reportCurrent">
          {{ $t('views.reports.item_report_current') }}
        </c-nav-cards-list-item>

        <c-nav-cards-list-item @click="reportPrevious">
          {{ $t('views.reports.item_report_previous') }}
        </c-nav-cards-list-item>
      </c-nav-cards-list>
    </ion-content>

    <app-footer />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useApp } from '@/composables/useApp';
import { modalController } from '@ionic/core';
import ReportModal from './ReportModal.vue';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';

export default defineComponent({
  setup() {
    const { repositories, store } = useApp();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();
    
    async function reportCurrent() {
      emitEvent('reports/view/current');
      const client = await store.point.getThisClient();
      const reportUrl = repositories.report.shiftCurrent(client.id, new Date());
      openReportModal(reportUrl, t('views.reports.midal_report_current_title'));
    }

    async function reportPrevious() {
      emitEvent('reports/view/previous');
      const client = await store.point.getThisClient();
      const reportUrl = repositories.report.shiftPrevious(client.id, new Date());
      openReportModal(reportUrl, t('views.reports.midal_report_previous_title'));
    }

    async function openReportModal(url: string, title: string) {
      const modal = await modalController.create({
        component: ReportModal,
        componentProps: { url, title },
        cssClass: 'report-modal-table-large'
      });

      modal.present();
    }

    return {
      reportCurrent,
      reportPrevious,
    };
  },
});
</script>

<style lang="scss">
@import '@/scss/abstract';

.report-modal-table-large {
  @include ionic-tablet {
    --width: 90vw;
    --height: 80vh;
  }
}
</style>