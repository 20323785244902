export const LS_CACHE_PREFIX = 'cache/';

/**
 * Сохранит данные в localStorage
 * 
 * @param key ключ
 * @param data данные для сохранения
 */
export function saveValue(key: string, data: any) {
  let jsonData = JSON.stringify(data);
  localStorage.setItem(`${LS_CACHE_PREFIX}${key}`, jsonData);
  return data;
}

/**
 * Вернет данные из localStorage
 * 
 * @param key ключ
 * @param def значеине по умолчанию
 */
export function getValue(key: string, def: any = null) {
  let data = def;

  try {
    let jsonData = localStorage.getItem(`${LS_CACHE_PREFIX}${key}`);
    if (jsonData === null) return data;

    data = JSON.parse(jsonData);
  }
  catch (e) {
    console.error((e as any).message);
    data = def;
  }

  return data;
}

/**
 * Удалит данные из localStorage
 * 
 * @param key ключ
 */
export function removeValue(key: string) {
  localStorage.removeItem(`${LS_CACHE_PREFIX}${key}`);
}

export default {
  saveValue,
  getValue,
  removeValue,
};