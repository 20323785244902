import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "visit-card-mini__image-container" }
const _hoisted_2 = { class: "visit-card-mini__image" }
const _hoisted_3 = { class: "visit-card-mini__image-scroll" }
const _hoisted_4 = { class: "visit-card-mini__right-container" }
const _hoisted_5 = { class: "visit-card-mini__header" }
const _hoisted_6 = { class: "visit-card-mini__title" }
const _hoisted_7 = { key: 0 }
const _hoisted_8 = {
  key: 1,
  class: "text-muted"
}
const _hoisted_9 = {
  key: 0,
  class: "visit-card-mini__total"
}
const _hoisted_10 = {
  key: 0,
  class: "visit-card-mini__footer-progress"
}
const _hoisted_11 = {
  key: 1,
  class: "visit-card-mini__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_visit_labels = _resolveComponent("visit-labels")!
  const _component_cc = _resolveComponent("cc")!
  const _component_c_progress_bar = _resolveComponent("c-progress-bar")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, { class: "visit-card-mini" }, {
    default: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_visit_labels, {
            class: "visit-card-mini__badge-container visit-card-badge-container",
            labels: _ctx.badgeList
          }, null, 8, ["labels"]),
          _createElementVNode("div", _hoisted_3, [
            (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.images, (image) => {
              return (_openBlock(), _createElementBlock("div", {
                class: "visit-card-mini__image",
                key: image,
                style: _normalizeStyle({ backgroundImage: `url('${image}')` })
              }, null, 4))
            }), 128))
          ])
        ])
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("div", _hoisted_6, [
            (_ctx.hasCarNumber)
              ? (_openBlock(), _createElementBlock("span", _hoisted_7, _toDisplayString(_ctx.formatCarNumber), 1))
              : (_openBlock(), _createElementBlock("span", _hoisted_8, _toDisplayString(_ctx.$t('components.visit_card_mini.car_no_number')), 1))
          ]),
          (_ctx.visit.price)
            ? (_openBlock(), _createElementBlock("div", _hoisted_9, [
                _createVNode(_component_cc, { price: _ctx.totalPrice }, null, 8, ["price"])
              ]))
            : _createCommentVNode("", true)
        ]),
        (_ctx.isViewProgress)
          ? (_openBlock(), _createElementBlock("div", _hoisted_10, [
              _createVNode(_component_c_progress_bar, {
                color: _ctx.progressColor,
                value: _ctx.progressValue,
                small: true
              }, null, 8, ["color", "value"])
            ]))
          : (_openBlock(), _createElementBlock("div", _hoisted_11, [
              (_ctx.isPreentry)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('components.visit_card_mini.preentry_datetime', {
            date: _ctx.preentryDateFormat,
            time: _ctx.preentryTimeFormat
          })), 1)
                  ], 64))
                : (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createTextVNode(_toDisplayString(_ctx.$t('components.visit_card_mini.visit_finish', { datetime: _ctx.finishTime })), 1)
                  ], 64))
            ]))
      ])
    ]),
    _: 1
  }))
}