import { MetricaPluginAdapter } from '@/composables/useMetrica';
import config from '@/config';
import { isOffline } from '@/helpers/network';
import { isPlatform } from '@ionic/vue';
import { AppMetrica } from 'capacitor-appmetrica-plugin';

/**
 * Проверяет подключена ли App Метрика
 * @returns 
 */
export function isSupportAppMetrica(): boolean {
  return isPlatform('hybrid')
    && isPlatform('capacitor')
    && isPlatform('android')
    && isPlatform('ios')
  ;
}

export const AppMetricaAdapter: MetricaPluginAdapter = {
  async init({ onEvent, onError }) {
    if (!isSupportAppMetrica()) {
      console.log('AppMetrica не поддерживается на данном устройстве/платформе');
      return;
    }

    if (!config.appMetricaApiKey) {
      console.error('Не установлен ключ для App Метрики');
      return;
    }

    await AppMetrica.activate({
      apiKey: config.appMetricaApiKey,
    });

    onEvent(ev => {
      if (isOffline()) return;

      AppMetrica.reportEvent({
        name: ev.name,
        params: ev.params,
      });
    });

    onError(ev => {
      if (isOffline()) return;

      AppMetrica.reportError({
        group: ev.name,
        message: ev.message,
      });
    });
  }
};