let isDeviceready = false;
let readyPromise: Promise<void>;

if ('cordova' in window) {
  let resolveReady: () => void;
  readyPromise = new Promise((resolve) => { resolveReady = resolve; });

  document.addEventListener("deviceready", () => {
    isDeviceready = true;
    resolveReady();
  }, false);
} else {
  isDeviceready = true;
  readyPromise = Promise.resolve();
}

export function isReady() {
  return isDeviceready;
}

export function deviceReady(onReady?: () => void): Promise<void> {
  if (isDeviceready) {
    onReady?.call(null);
    return Promise.resolve();
  }

  return new Promise<void>((resolve) => {
    readyPromise.then(() => {
      onReady?.call(null);
      resolve();
    })
  });
}