import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_c_transparent_button = _resolveComponent("c-transparent-button")!
  const _component_app_header = _resolveComponent("app-header")!
  const _component_AppSettings = _resolveComponent("AppSettings")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_footer = _resolveComponent("ion-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header, {
        title: _ctx.$t('views.settings.settings_modal_title')
      }, {
        end: _withCtx(() => [
          _createVNode(_component_c_transparent_button, { onClick: _ctx.close }, {
            default: _withCtx(() => [
              _createVNode(_component_ion_icon, {
                name: "close-outline",
                class: "icon-medium"
              })
            ]),
            _: 1
          }, 8, ["onClick"])
        ]),
        _: 1
      }, 8, ["title"]),
      _createVNode(_component_ion_content, { class: "ion-padding" }, {
        default: _withCtx(() => [
          _createVNode(_component_AppSettings, {
            onLogout: _ctx.close,
            onClearCache: _ctx.close,
            onCloseSettings: _ctx.close
          }, null, 8, ["onLogout", "onClearCache", "onCloseSettings"])
        ]),
        _: 1
      }),
      _createVNode(_component_ion_footer)
    ]),
    _: 1
  }))
}