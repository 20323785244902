import md5 from 'blueimp-md5';
import type { MessagePayload } from 'firebase/messaging';
import type { PushNotificationSchema } from '@capacitor/push-notifications';
import type { NotificationData } from '@/composables/notifications';

/**
 * Конвертирует данные push-уведомления то Capacitor плагина в общий формат
 * 
 * @param notification
 */
export function capPushNotificationSchemaToNotificationData(notification: PushNotificationSchema): NotificationData {
  const data = notification.data || {};

  let id: string|0 = notification.id;
  const title = notification.title || data.title; // fix: для открываемых уведомлений по тапу
  const body = notification.body   || data.body;  // fix: для открываемых уведомлений по тапу

  // fix: приложение когда свернуто возвращает идентификатор 0
  // для исправления возмем хэш от заголовка и текста уведомления.
  if (!id) {
    id = md5(JSON.stringify([title || '', body || '']));
  }
  
  return {
    id,
    title,
    subtitle: notification.subtitle,
    body,
    image:    undefined, // TODO: добавить изображение
    badge:    notification.badge, // По сути только на iOS
    data:     data,
    meta:     { /** Empty */ },
    createAt: new Date(),
  };
}

/**
 * Конвертирует уведомление от Firebase в общий формат уведомления
 * 
 * @param payload 
 * @returns 
 */
export function webFirebaseMessagePayloadToNotificationData(payload: MessagePayload): NotificationData {
  const notification = payload.notification || {};

  return {
    id:       payload.messageId,
    title:    notification.title,
    subtitle: undefined, // В вебе такого нет
    body:     notification.body,
    image:    notification.title,
    badge:    undefined,
    data:     payload.data,
    meta:     { /** Empty */ },
    createAt: new Date(),
  };
}