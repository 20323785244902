<script lang="ts" setup>
import { onMounted, ref, computed } from 'vue';
import { useApp } from '@/composables/useApp';
import { useToast } from '@/composables/toast';
import { alertController } from '@ionic/vue';
import { PosInfoResponse } from '@/repositories/Models/Pos';
import { useRouter } from 'vue-router';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
// import PosAcquiringModal from './PosAcquiringModal.vue';

const toast = useToast();
const router = useRouter();
const { repositories } = useApp();
const { emitEvent } = useMetrica();
const { t } = useI18n();

/** @private */
function createConfirmAction(header: string, message: string, action: () => Promise<void>) {
  return async () => {
    const alert = await alertController.create({
      header,
      message,
      buttons: [
        {
          text: t('views.pos.alert_confirm_btn_cancel'),
          role: 'cancel',
        },
        {
          text: t('views.pos.alert_confirm_btn_confirm'),
          async handler() {
            try {
              await action.call(null);
            } catch (e) {
              toast.error(e, 4000, {
                defaultHeader: t('views.pos.header_error_pos')
              });
            }

            alert.dismiss(); // async
          },
        }
      ],
    });

    alert.present(); // async
  }
}

const posInfo = ref<PosInfoResponse|null>(null);
const sessionOpened = computed(() => !!posInfo.value?.sessionOpened);

async function loadInfo() {
  try {
    const { data } = await repositories.pos.getInfo();
    posInfo.value = data;
  } catch (e) {
    toast.error(e, 4000, {
      defaultHeader: t('views.pos.header_error_pos')
    });
  }
}

onMounted(() => loadInfo());

/** Открыть касовую смену */
const openSession = createConfirmAction(
  t('views.pos.alert_confirm_open_session_header'),
  t('views.pos.alert_confirm_open_session_message'),
  async () => {
    if (sessionOpened.value) return;
    await repositories.pos.openDrawer();
    emitEvent('pos/session/open');
  }
);

function toCashAction(action: 'in'|'out') {
  emitEvent(`pos/cach/open-page/${action}`);
  router.push({
    name: 'pos-cash-action',
    params: { action }
  });
}

/** Распечатать X-отчет */
const reportX = createConfirmAction(
  t('views.pos.alert_confirm_report_x_header'),
  t('views.pos.alert_confirm_report_x_message'),
  async () => {
    emitEvent('pos/report/print/x');
    await repositories.pos.reportX();
  },
);

/** Распечатать Z-отчет */
const reportZ = createConfirmAction(
  t('views.pos.alert_confirm_report_z_header'),
  t('views.pos.alert_confirm_report_z_message'),
  async () => {
    emitEvent('pos/report/print/z');
    await repositories.pos.reportZ();
  },
);

/** Сверка итогов (эквайринг) */
async function acquiringRevise() {
  emitEvent('pos/acquiring-revise');

  try {
    await repositories.pos.acquiringRevise();
  } catch (e) {
    toast.error(e, 4000, {
      defaultHeader: t('views.pos.header_error_pos')
    });
  }
}

// NOTE: Делалось 3 попытки на отправку завтроса, оказалось, что не требуется.
// Все написанное решено было не удалять, т.к. может пригодится в другом месте,
// для выполнения нескольких попыток запросов.

/** Сверка итогов (эквайринг) */
// async function acquiringRevise() {
//   emitEvent('pos/acquiring-revise');

//   const props = {
//     title: 'Выполнение запроса',
//     attemptCount: 3,
//     attemptsIntervalBetween: 800, // ms
//     async attemptAction() {
//       await repositories.pos.acquiringRevise();
//     },
//     onFail(e: any) {
//       toast.error(e, 4000, {
//         defaultHeader: t('views.pos.header_error_pos')
//       });
//     },
//     onFinally() {
//       modal.dismiss();
//     }
//   };

//   const modal = await modalController.create({
//     cssClass: 'core-modal-actions',
//     component: PosAcquiringModal,
//     componentProps: props,
//     swipeToClose: true,
//     keyboardClose: true,
//     backdropDismiss: false,
//   });

//   modal.present(); // async
// }
</script>

<template>
  <ion-page>
    <app-header-big :title="$t('views.pos.title')" />

    <ion-content>
      <c-nav-cards-list>
        <c-nav-cards-list-item
          :disabled="sessionOpened"
          @click="openSession"
        >
          {{ $t('views.pos.item_open_pos_session') }}
        </c-nav-cards-list-item>

        <c-nav-cards-list-item @click="toCashAction('in')">
          {{ $t('views.pos.item_cash_in') }}
        </c-nav-cards-list-item>

        <c-nav-cards-list-item @click="toCashAction('out')">
          {{ $t('views.pos.item_cash_out') }}
        </c-nav-cards-list-item>

        <c-nav-cards-list-item @click="reportX">
          {{ $t('views.pos.item_report_x') }}
        </c-nav-cards-list-item>

        <c-nav-cards-list-item @click="reportZ">
          {{ $t('views.pos.item_report_z') }}
        </c-nav-cards-list-item>

        <c-nav-cards-list-item @click="acquiringRevise">
          {{ $t('views.pos.item_acquiring_revise') }}
        </c-nav-cards-list-item>
      </c-nav-cards-list>
    </ion-content>

    <app-footer />
  </ion-page>
</template>