<template>
  <ion-card class="visit-card">
    <visit-image-container>
      <template #header-content>
        <visit-labels :labels="badgeList" />
      </template>

      <template #header-side>
        <slot name="header-buttons"></slot>
      </template>

      <template #default>
        <visit-images-slider :images="images" />
      </template>
    </visit-image-container>

    <div class="c-visit-card__header">
      <div class="c-visit-card__title">
        <span v-if="hasCarNumber">{{ formatCarNumber }}</span>
        <span v-else class="text-muted">{{ $t('components.visit_card.car_no_number') }}</span>
      </div>

      <div class="c-visit-card__total" v-if="visit.price">
        <cc :price="totalPrice" />
      </div>
    </div>

    <div class="c-visit-card__content">
      <!--
        Пробел, чтобы выровнить высоту карточки с
        карточкой в процессе (с полосой прогресса)
      -->
      <div v-if="isPreentry" :style="{ height: '6px' }"></div>

      <template v-if="!isClosed && !isPreentry">
        <c-progress-bar :color="progressColor" :value="progressValue" />

        <ion-row>
          <ion-col class="px-0">
            {{ $t('components.visit_card.start_time', { time: startTimeFormat || '–' }) }}
          </ion-col>

          <ion-col class="px-0 ion-text-end" size="auto">
            {{ $t('components.visit_card.remaining_work_time', { time: predictedEndTimeFormat || '∞' }) }}
          </ion-col>
        </ion-row>
      </template>
    </div>

    <div class="c-visit-card__footer">
      <ion-item v-if="viewBox && boxInfo">
        {{ boxInfo.label }}

        <ion-note class="color-default" slot="end">
          {{ boxInfo.name }}
        </ion-note>
      </ion-item>

      <template v-if="isPreentry">
        <ion-item @click="$emit('click-preentry-date')">
          {{ $t('components.visit_card.item_label_preentry_date') }}

          <ion-note class="color-default" slot="end">
            {{ preentryDateFormat }}
          </ion-note>
        </ion-item>

        <ion-item @click="$emit('click-preentry-time')">
          {{ $t('components.visit_card.item_label_preentry_time') }}

          <ion-note class="color-default" slot="end">
            {{ preentryTimeFormat }}
          </ion-note>
        </ion-item>
      </template>

      <template v-else-if="isClosed">
        <ion-item v-if="createDateFormat">
          {{ $t('components.visit_card.item_label_visit_datetime') }}

          <ion-note class="color-default" slot="end">
            {{ createDateFormat }}
          </ion-note>
        </ion-item>

        <ion-item v-if="startTimeFormat">
          {{ $t('components.visit_card.item_label_start_datetime') }}

          <ion-note class="color-default" slot="end">
            {{ startTimeFormat }}
          </ion-note>
        </ion-item>

        <ion-item v-if="endTimeFormat">
          {{ $t('components.visit_card.item_label_end_datetime') }}

          <ion-note class="color-default" slot="end">
            {{ endTimeFormat }}
          </ion-note>
        </ion-item>
      </template>

      <ion-item v-else class="color-default">
        <ion-icon slot="start" name="core-timer" class="mr-2 size-default" />

        {{ progressText }}
      </ion-item>
    </div>
  </ion-card>
</template>

<script lang="ts">
import { CarVisitItem } from '@/repositories/Models/CarVisit';
import { computed, ComputedRef, defineComponent, onMounted, PropType, toRef, ref } from 'vue';
import { useVisit, useVisitTimings, useVisitBadges } from '@/composables/visit';
import moment from 'moment';
import { useI18n } from 'vue-i18n';
import VisitLabels from '@/components/visit/VisitLabels.vue';
import VisitImagesSlider from '@/components/visit/VisitImagesSlider.vue';
import VisitImageContainer from '@/components/visit/VisitImageContainer.vue';

export interface VisitBadgeItem {
  text: string;
  color: string;
}

export interface VisitCardShortInfo {
  images: ComputedRef<string[]>;
  totalPrice: ComputedRef<number>;
  progressText: ComputedRef<string>;
  formatCarNumber: ComputedRef<string>;
  badgeList: ComputedRef<VisitBadgeItem[]>;
}

export default defineComponent({
  components: {
    VisitLabels,
    VisitImagesSlider,
    VisitImageContainer,
  },
  emits: [
    'click-preentry-date',
    'click-preentry-time',
    'short-info',
  ],
  props: {
    visit: {
      type: Object as PropType<CarVisitItem>,
      required: true,
    },
    viewBox: {
      type: Boolean,
      default: false,
    },
    scroll: {
      type: String,
      default: 'list'
    }
  },
  setup(props, { emit }) {
    const { t } = useI18n();
    const BOX_REPLACE_PREVIEW_REGV = new RegExp(t('components.visit_card.item_label_box_name') + ' ', 'i');
    const visitRef = toRef(props, 'visit');

    const {
      totalPrice,
      formatCarNumber,
      hasCarNumber,
      images,
      isPreentry,
      preentryDateFormat,
      preentryTimeFormat,
    } = useVisit(visitRef);

    const {
      progressText,
      progressValue,
      progressColor,
      predictedFinishDate,
      processedDate,
      normativMinutes,
    } = useVisitTimings(visitRef);

    const { badgeList, isClosed } = useVisitBadges(visitRef);

    onMounted(() => {
      emit('short-info', {
        images,
        totalPrice,
        progressText,
        formatCarNumber,
        badgeList,
      } as VisitCardShortInfo);

    });
    const activeIndexSlide = ref<number>(0)
      const refScrollImage = ref<any>(null)
      const initSwiper = (swiper: any) => {
        refScrollImage.value = swiper
      }

    /** Дата создания заказа */
    const createDateFormat = computed(() => processedDate.value?.format('D MMMM YYYY') || null);

    /** Фактическое время начала работ */
    const startTimeFormat = computed(() => processedDate.value?.format('DD.MM.YY, H:mm') || null);

    /** Рассчетное время окончания работ */
    const predictedEndTimeFormat = computed(() => {
      return (normativMinutes.value && predictedFinishDate.value)
        ? predictedFinishDate.value.format('H:mm') : null;
    });

    /** Фактическое время окончания работ */
    const endTimeFormat = computed(() => {
      return props.visit.exitDate ? moment(props.visit.exitDate).format('H:mm') : null;
    });

    const boxInfo = computed(() => {
      if (!props.visit.box) return null;

      const boxName = String(props.visit.box.name);
      if (BOX_REPLACE_PREVIEW_REGV.test(boxName)) {
        return {
          label: t('components.visit_card.item_label_box_number'),
          name: boxName.replace(BOX_REPLACE_PREVIEW_REGV, ''),
        };
      }

      return {
        label: t('components.visit_card.item_label_box_name'),
        name: boxName,
      };
    });

    return {
      totalPrice,
      formatCarNumber,
      hasCarNumber,
      images,
      badgeList,
      isClosed,
      progressValue,
      progressText,
      progressColor,
      createDateFormat,
      predictedEndTimeFormat,
      startTimeFormat,
      endTimeFormat,
      isPreentry,
      preentryDateFormat,
      preentryTimeFormat,
      boxInfo,
      activeIndexSlide,
      refScrollImage,
      initSwiper,
    };
  },
});
</script>

<style lang="scss">
@import '@/scss/abstract';

.c-visit-card {
  &__header {
    color: var(--core-text);
    padding: var(--core-spacer-small);
    font-size: 1.55em;
    display: flex;
    width: 100%;
  }

  &__title {
    flex-grow: 1;
  }

  &__total {
    padding-left: var(--core-spacer-small);
  }

  &__content {
    padding: 0 var(--core-spacer-small);
  }

  &__footer {
    margin: 0 var(--core-spacer-small);
    color: var(--core-text);
    border-top: $border-divider;

    ion-item {
      --padding-start: 0;
      --padding-end: 0;
      --inner-padding-end: 0;
    }
  }
}
</style>
