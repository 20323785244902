<script lang="ts" setup>
/** @version 1.0.1 */

import { StyleValue, computed, shallowRef, defineEmits } from 'vue';
import { ScrollEventCtx, getScrollInfiniteContext } from './define';

const SCROLL_INERTIA_MIN_TIME = 140;
const SCROLL_INERTIA_TIME_CONSTANT = 325; // ms

const emit = defineEmits<{
  (e: 'scroll', payload: ScrollEventCtx ): void;
}>();

const {
  scrollTop,
  wheelTransition,
  containerEl,
  scrollContentEl,
  moveStartScrollTop
} = getScrollInfiniteContext();

function setScrollTop(value: number) {
  scrollTop.value = value;

  emit('scroll', {
    scrollTop: value,
    containerEl: containerEl.value as HTMLDivElement,
    scrollContentEl: scrollContentEl.value as HTMLDivElement,
  });
}

//#region Touch events
let startTouch = shallowRef<Touch|null>(null);
let startTime = 0;
let scrollVelocity = shallowRef<number>(0);

function getCurrentTouch(list: TouchList): Touch|null {
  if (!startTouch.value) return null;

  for (let i = 0; i < list.length; ++i) {
    const t = list.item(i)!;
    if (t.identifier === startTouch.value.identifier) {
      return t;
    }
  }

  return null;
}

function onTouchstart(ev: TouchEvent) {
  if (startTouch.value) return;

  if (requestId) {
    cancelAnimationFrame(requestId);
  }

  startTouch.value = ev.changedTouches[0] || null;
  moveStartScrollTop.value = scrollTop.value;
  startTime = Date.now();
  scrollVelocity.value = 0;
}

function onTouchmove(ev: TouchEvent) {
  ev.preventDefault();
  ev.stopPropagation();

  const moveTouch = getCurrentTouch(ev.changedTouches);
  if (!moveTouch || !startTouch.value) return;

  const dY = moveTouch.clientY - startTouch.value.clientY;
  setScrollTop(moveStartScrollTop.value - dY);
}

function normalizeBeetween(value: number, min: number, max: number) {
  return Math.max(min, Math.min(max, value));
}

function onTouchend(ev: TouchEvent) {
  const endTouch = getCurrentTouch(ev.changedTouches);
  if (!endTouch || !startTouch.value) return;

  const endTime = Date.now();
  const dY = endTouch.clientY - startTouch.value.clientY;
  const dTime = endTime - startTime;

  setScrollTop(moveStartScrollTop.value - dY);
  startTouch.value = null;
  moveStartScrollTop.value = 0;
  startTime = 0;

  if (dTime <= SCROLL_INERTIA_MIN_TIME && Math.abs(dY) >= 4) {
    const velocity = normalizeBeetween(dY * 0.8, -100, 100) * (1 - dTime / SCROLL_INERTIA_MIN_TIME);
    scrollVelocity.value = velocity;

    startInertiaAnimation();
  }
}

let requestId: number|null = null;
function startInertiaAnimation() {
  if (requestId) {
    cancelAnimationFrame(requestId);
  }

  const timestamp = Date.now();
  const amplitude = scrollVelocity.value;

  requestId = requestAnimationFrame(function frame() {
    const elapsed = Date.now() - timestamp;
    const delta = -amplitude * Math.exp(-elapsed / SCROLL_INERTIA_TIME_CONSTANT);

    setScrollTop(Math.round(scrollTop.value + delta));

    if (Math.abs(delta) < 1) {
      requestId = null;
      scrollVelocity.value = 0;
    } else {
      requestId = requestAnimationFrame(frame);
    }
  });
}
//#endregion END Touch events

//#region Mouse events
function onWheel(ev: WheelEvent) {
  wheelTransition.value = true
  setScrollTop(scrollTop.value + ev.deltaY);
}

function onTransitionend(/*ev: Event*/) {
  wheelTransition.value = false;
}
//#endregion END Mouse events

const scrollContentStyle = computed<StyleValue>(() => {
  return {
    transform: `translateY(${-scrollTop.value}px)`,
    transition: wheelTransition.value ? 'transform 0.15s ease' : undefined,
  };
});
</script>

<template>
  <div
    class="v-scroll-infinite"
    ref="containerEl"
    @wheel="onWheel"
    @touchstart="onTouchstart"
    @touchmove="onTouchmove"
    @touchend="onTouchend"
  >
    <div
      class="v-scroll-infinite__scroll-content"
      ref="scrollContentEl"
      :style="scrollContentStyle"
      @transitionend="onTransitionend"
    >
      <slot></slot>
    </div>
  </div>
</template>

<style lang="scss">
.v-scroll-infinite {
  overflow: hidden;
  padding-left: var(--c-virtual-scroll-block-padding-left, 0);
  padding-right: var(--c-virtual-scroll-block-padding-right, 0);
}
</style>