import Dexie from 'dexie';
import { KeyValue } from './Tables/KeyValue';
import { NotificationHistory } from './Tables/NotificationHistory';

export const DB_SHARE_VERSION = 5;

export class ShareDB extends Dexie {  
  public keyValue: Dexie.Table<KeyValue, string>;
  public notificationHistory: Dexie.Table<NotificationHistory, string>;
  
  constructor () {
    super('share');

    this.version(DB_SHARE_VERSION).stores({
      keyValue: 'key',
      notificationHistory: 'id, createAt',
    });

    this.keyValue = this.table("keyValue");
    this.notificationHistory = this.table("notificationHistory");
  }

  async setStorageVar<T = any>(key: string, value: T): Promise<void> {
    await this.keyValue.put({ key, value });
  }

  async getStorageValue<T = any, D = T>(key: string, def?: D): Promise<T|D|undefined> {
    const value = await this.keyValue.get(key);
    return value?.value || def as T|D|undefined;
  }
}
