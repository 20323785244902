<template>
  <button
    type="button"
    tabindex="-1"
    class="c-modal-select-option"
  >
    <div class="c-modal-select-option__label">
      <slot />
    </div>

    <div class="c-modal-select-option__icon-col">
      <slot name="icon">
        <ion-icon
          v-if="selected"
          name="checkmark-outline"
        />
      </slot>
    </div>
  </button>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  props: {
    selected: {
      type: Boolean as PropType<boolean>,
      default: false,
    }
  }
});
</script>

<style lang="scss">
@import '@/scss/abstract';

.c-modal-select-option {
  @extend %btn-reset-style;

  display: flex;
  align-items: stretch;
  width: 100%;
  padding: 0 var(--ion-padding);

  &:hover {
    background-color: var(--ion-color-dark-tint);
  }

  &:active {
    background-color: var(--ion-color-dark-shade);
  }

  &__label, &__icon-col {
    display: flex;
    align-items: center;
  }

  &__label {
    flex: 1 1 0%;
    padding: 1.1rem 0;
  }

  &__icon-col {
    font-size: 22px;
  }
}
</style>