import { BaseStore, BaseStoreContext } from './BaseStore';
import ls from '@/helpers/localStorage';
import { Ref, ref, watch, isRef, unref, computed, ComputedRef } from 'vue';
import { toSoftBoolean } from '@/utils/convert';
import { CIDConfig, LocaleItem, CarNumberTranslitDictionary } from '@/repositories/Models/Config';
import { LocalizationDictionary } from '@/localization/types';
import { LS_KEY_LANGUAGE, LS_KEY_PREFIX_LANGUAGE_MESSAGES, SUPPORT_LOCAL_LOCALES } from '@/localization';
import { has, map, invert, includes } from 'lodash';
// import { hours } from '@/helpers/cache';

// NOTE: Требуется, чтобы загружать файл локализации без всяких преобразований
import axios from 'axios';

// NOTE: Возможно неправильно использовать глобальный экземпляр,
// но так получить доступ к нему намного проще.
import { i18n } from '@/localization';
import { CarNumberTypeEnum } from '@/repositories/Models/Car';
import { CarNumberTypeRecognition } from '@/utils/car';
import { LS_KEY_BASE_DOMAIN } from '@/composables/useApp';
import moment from 'moment';

/**
 * Вернет базовую конфгурацию Core12
 * @returns 
 */
export function getDefaultKeyboardAndCurrencyConfig(): CIDConfig {
  return {
    inputCarNumber: {
      keyboardLayout: [
        "1 2 3 4 5 6 7 8 9 0",
        "А В Е К М Н",
        "О Р С Т У Х",
      ],

      keyboardLayoutStyle: {
        small: '1 2 3 4 5 6 7 8 9 0',
        numeric: '1 2 3 4 5 6 7 8 9 0',
      },

      // Данное значение по мимо отображение на полк ввода так-же играет роль в том,
      // какой символ на виртуальной клавиатуре необходимо подсвечивать (делать доступным для нажатия)
      placeholder: 'А123ХЕ177',
      // Регулярное выражение, по которому проверяется валидность введеного номера
      regexp: "^[A-ZА-Я]{1}[\\d]{3}[A-ZА-Я]{2}[\\d]{2,3}$",

      // Регулярные выражения, для распознавания типа номеров,
      // доступные типы: citizen, military, police, taxi, diplomatical
      typesRecognition: [
        {
          regexp: "^[abekmhopctyx]{1}[\\d]{3}[abekmhopctyx]{2}[\\d]{2,3}$",
          type: 'citizen',
        },
        {
          regexp: "^[\\d]{4}[abekmhopctyx]{2}[\\d]{2,3}$",
          type: 'military',
        },
        {
          regexp: "^[abekmhopctyx]{1}[\\d]{4}[\\d]{2,3}$",
          type: 'police',
        },
        {
          regexp: "^[abekmhopctyx]{2}[\\d]{3}[\\d]{2,3}$",
          type: 'taxi',
        },
        {
          regexp: "^[\\d]{3}cd[\\d][\\d]{2,3}$",
          type: 'diplomatical',
        },
        {
          regexp: "^[\\d]{3}d[\\d]{3}[\\d]{2,3}$",
          type: 'diplomatical',
        },
        {
          regexp: "^[\\d]{3}t[\\d]{3}[\\d]{2,3}$",
          type: 'diplomatical',
        },
      ],

      // Словарь, для транслитерации символов в номере
      // <язык>: <соответствующий латинский символ>
      translitChars: {
        'а': 'a',
        'в': 'b',
        'е': 'e',
        'к': 'k',
        'м': 'm',
        'н': 'h',
        'о': 'o',
        'р': 'p',
        'с': 'c',
        'т': 't',
        'у': 'y',
        'х': 'x',
      },
    },
    currency: {
      symbol: '₽',
      name: 'Рублей',
      displayBefore: false,
    },
  };
}

export const LS_KEY_USE_NUMBER_RECOGNITION = 'use_number_recognition';

/**
 * NOTE: В будущем пригодится для получения настроек с сервера
 */
export class ConfigStore extends BaseStore {
  readonly useNumberRecognition: Ref<boolean>;
  readonly usePreentry: Ref<boolean>;
  readonly useFlashlight: Ref<boolean>;


  private locales: LocaleItem[]|null;
  readonly cidConfig: Ref<CIDConfig|null>;
  readonly cidConfigPrepared: ComputedRef<{
    inputCarNumber: {
        keyboardLayout: string[];
        keyboardLayoutStyle: {
          small: string;
          numeric: string;
        };
        placeholder: string;
        regexp: RegExp;
        typesRecognition: CarNumberTypeRecognition[];
        translitChars: CarNumberTranslitDictionary;
        translitCharsInvert: CarNumberTranslitDictionary;
    };
    currencyMessages: {
      symbol: string;
      name: string;
      displayBefore: boolean;
    };
  }>

  constructor(ctx: BaseStoreContext) {
    super(ctx);

    // TODO: Лучше перейти на pinia + VueUse в будущем
    this.useNumberRecognition = this.createSyncLocalStorageValue<boolean>('use_number_recognition', true, toSoftBoolean);
    this.usePreentry = this.createSyncLocalStorageValue<boolean>('use_preentry_function', true, toSoftBoolean);
    this.useFlashlight = this.createSyncLocalStorageValue<boolean>('use_flashlight_function', true, toSoftBoolean);
    
    this.locales = null;
    this.setupI18nLanguage();

    this.cidConfig = ref(null);
    this.cidConfigPrepared = computed(() => {
      const config = this.cidConfig.value || getDefaultKeyboardAndCurrencyConfig();

      return {
        inputCarNumber: {
          keyboardLayout: config.inputCarNumber.keyboardLayout,
          keyboardLayoutStyle: config.inputCarNumber.keyboardLayoutStyle,
          placeholder: config.inputCarNumber.placeholder,
          regexp: new RegExp(config.inputCarNumber.regexp),
          typesRecognition: map(config.inputCarNumber.typesRecognition, item => {
            return {
              regexp: new RegExp(item.regexp),
              type: item.type as CarNumberTypeEnum,
            };
          }),
          translitChars: config.inputCarNumber.translitChars,
          translitCharsInvert: invert(config.inputCarNumber.translitChars),
        },
        currencyMessages: {
          ...config.currency,
        },
      };
    });
    this.loadCIDConfig(); // async
  }

  //#region Localization
  async getLocales(): Promise<LocaleItem[]> {
    if (!this.locales) {
      // TODO: В будущем здесь будет запрос на получение списка с сервера
      this.locales = [
        { key: 'ru', label: 'Русский' },
        { key: 'en', label: 'Английский' },
        { key: 'en2', label: 'Английский загрузить' },
        { key: 'unknown', label: 'Не существующий' },
      ];
    }

    return this.locales;
  }

  private setupI18nLanguage() {
    const locale = unref(i18n.global.locale);
    this.setLocaleEnvironment(locale);
  }

  /**
   * Установка локали для остального окружения
   * - Язык документа в теге HTML
   * - Заголовок Accept-Language для http клиента
   * - Локализация дат (moment)
   * 
   * @param locale 
   */
  private setLocaleEnvironment(locale: string) {
    document.querySelector('html')?.setAttribute('lang', locale);
    this.ctx.http.defaults.headers.common['Accept-Language'] = locale;
    moment.locale(includes(moment.locales(), locale) ? locale : 'ru');
  }

  /**
   * Установит указанную локаль
   * - Устанавливает в глобальную переменную i18n
   * - устанавливает <html lang="...">
   * - установит в заголовок для http клиента
   * 
   * @param locale 
   */
  setI18nLanguage(locale: string) {
    if (i18n.mode === 'legacy') {
      i18n.global.locale = locale
    } else if (isRef(i18n.global.locale)) {
      i18n.global.locale.value = locale
    } else {
      throw new Error('Something went wrong when installing the language, contact your administrator')
    }

    localStorage.setItem(LS_KEY_LANGUAGE, locale);
    this.setLocaleEnvironment(locale);
  }

  /**
   * Загружает и устанавливает указаные переводы для языка
   * ВНИМАНИЕ! не меняет саму установленную локаль, для ее установки
   * необходимо воспользоваться setI18nLanguage.
   * 
   * @param locale 
   * @param skipIfExists 
   */
  async loadAndSetI18nMessages(locale: string, skipIfExists = true) {
    // Локальные переводы обновлять не требуется
    if (SUPPORT_LOCAL_LOCALES.includes(locale)) return;

    // Существующие задавать так-же не требуется
    if (has(i18n.global.messages, locale) && skipIfExists) return;

    const messages = await this.loadLocaleMessages(locale);
    i18n.global.setLocaleMessage(locale, messages);
  }

  /**
   * Загружает указынный перевод с сервера или из кэша
   * @param locale если не указать, то будет загружена текущая локаль
   * @returns 
   */
  async loadLocaleMessages(locale: string): Promise<LocalizationDictionary> {
    // TODO: В будущем адрес запроса измениться
    const { data } = await axios.get<LocalizationDictionary>(`https://core12.inaliv.ru/__locales/${locale}.json`);

    ls.saveValue(LS_KEY_PREFIX_LANGUAGE_MESSAGES + locale, data);
    return data;
  }

  get currentLocale(): string {
    return unref(i18n.global.locale);
  }

  /**
   * Скачаем последние переводы с сервера и установим их
   */
  async reloadCurrentLocale() {
    await this.loadAndSetI18nMessages(this.currentLocale, false);
  }
  //#endregion Localization

  //#region CID config
  async loadCIDConfig(): Promise<CIDConfig> {
    if (!this.cidConfig.value) {
      // При первом запуске приложения конфиг грузить не надо,
      // т.к. CID в настройках еще не указан.
      if (!localStorage.getItem(LS_KEY_BASE_DOMAIN)) {
        this.cidConfig.value = getDefaultKeyboardAndCurrencyConfig();
      }

      // TODO: Временно отключено для клиентской версии
      this.cidConfig.value = getDefaultKeyboardAndCurrencyConfig();
      
      // const cache = await this.cacheQueryWithBloking(['ap_cid_config'], async () => {
      //   // TMP: Временная загрузка тестового файла
      //   // const { data } = await this.ctx.http.get<CIDConfig>('https://core12.inaliv.ru/__locales/sid-config.json');
      //   // return data;
      // }, hours(24), async () => {
      //   // Возможно это старый бэкенд и у него нет API для получения конфигурации
      //   return getDefaultKeyboardAndCurrencyConfig();
      // });
  
      // this.cidConfig.value = cache.data;
    }

    return this.cloneData(this.cidConfig.value);
  }
  //#endregion CID config

  /**
   * Создает новое реактивное значение, которое синхронизируется с локальным хранилищем
   * 
   * @param key ключ, по которому значение можно получить в локальном хранилище
   * @param def значение по умолчанию (если в локальном хранилище оно отсутствует)
   * @param validate функция для обработки значения (выполняется после получения значения из локального хранилища)
   * 
   * @returns реактивное значение
   */
  protected createSyncLocalStorageValue<T>(key: string, def: T, validate?: (val: any) => T): Ref<T> {
    let value = ls.getValue(key, def);
    if (validate) {
      value = validate.call(null, value);
    }

    const valueRef = ref(value);
    watch(valueRef, v => ls.saveValue(key, v));

    return valueRef;
  }
}


