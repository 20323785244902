import { reactive, readonly, UnwrapRef, DeepReadonly } from "vue"
import { BaseStore, BaseStoreContext } from "./BaseStore"
import { Box } from "@/repositories/Models/Box"

export interface BoxStoreState {
  items: Box[]|null;
}

export class BoxStore extends BaseStore {
  protected state: UnwrapRef<BoxStoreState>;

  constructor(ctx: BaseStoreContext) {
    super(ctx);

    this.state = reactive({
      items: null,
    });
  }

  /**
   * Получить информацию о боксах
   * 
   * CACHE: Данные автоматически кэшируются, и запрашиваются по
   * требованию только первый раз после запуска приложения
   * 
   * @returns 
   */
  async getAll(): Promise<DeepReadonly<Box[]>> {
    if (this.state.items) {
      return readonly(this.state.items);
    }

    // Больше 1000 боксов точно не должно быть
    const cache = await this.cacheQuery(['box_collection', 1000], async () => {
      const { data } = await this.repositories.box.getCollection({ limit: 1000 });
      return data.items;
    }, 0);

    this.state.items = cache.data;

    return readonly(this.state.items);
  }
}
