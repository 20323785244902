<template>
  <div class="c-group-partner-input-card">
    <c-card-list>
      <ion-item v-if="isSelected">
        <ion-label>
          {{ $t('components.group_partner_input_card.label') }}
        </ion-label>

        <!-- eslint-disable-next-line -->
        <ion-note slot="end" color="light">
          <ion-skeleton-text v-if="loadingGroupInfo" animated :style="{
            width: '100px',
            height: '1em',
          }" />
          <span v-else>
            {{ selectedGroupInfo.name }}
          </span>
        </ion-note>
      </ion-item>

      <ion-item class="item-link-button ion-text-center" @click="openSlectModal">
        <ion-label>
          {{ isSelected
            ? $t('components.group_partner_input_card.btn_edit')
            : $t('components.group_partner_input_card.btn_select')
          }}
        </ion-label>
      </ion-item>
    </c-card-list>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue'
import { modalController } from '@ionic/vue'
import GroupPartnerSelectBox from './GroupPartnerSelectBox.vue'
import { CarGroup } from '@/repositories/Models/CarGroup'
import { useStore } from '@/composables/useApp';

export type SetGroupValueCallback = (groupId: string|number, group?: CarGroup) => void;

export default defineComponent({
  props: {
    modelValue: {
      type: [String, Number] as PropType<string|number>,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { group } = useStore();

    const selectedGroupId = ref(props.modelValue);
    watch(() => props.modelValue, newValue => selectedGroupId.value = newValue);
    const isSelected = computed(() => !!selectedGroupId.value);

    const loadingGroupInfo = ref(false);
    const selectedGroupInfo = ref<CarGroup|null>(null);
    watch(selectedGroupId, async groupId => {
      if (!groupId) {
        selectedGroupInfo.value = null;
        return;
      }

      loadingGroupInfo.value = true;
      try {
        selectedGroupInfo.value = await group.filterById(+groupId);
      } finally {
        loadingGroupInfo.value = false;
      }
    }, { immediate: true });

    /**
     * Выбор происходит после выбора пункта в списке
     * @protected
     */
    function selectValue(groupId: string|number, group?: CarGroup) {
      selectedGroupId.value = groupId;
      emit('update:modelValue', groupId, group);
    }

    async function openSlectModal() {
      const modal = await modalController.create({
        component: GroupPartnerSelectBox,
        cssClass: 'core-modal-actions',
        swipeToClose: true,
        componentProps: {
          selectValue,
          value: selectedGroupId.value
        },
      });

      await modal.present();
      return modal;
    }

    return {
      openSlectModal,
      isSelected,
      loadingGroupInfo,
      selectedGroupInfo,
    };
  },
});
</script>

<style lang="scss">
</style>