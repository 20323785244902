<script lang="ts" setup>
import { ref, computed, defineProps, withDefaults, nextTick, defineEmits, onMounted } from 'vue';
import { DateTime } from 'luxon';
import { upperFirst, throttle } from 'lodash';
import VirtualScrollBlock from '@/components/core/VirtualScrollBlock/VirtualScrollBlock.vue';
import { useScrollInfinite, ScrollEventCtx } from '@/components/core/VirtualScrollBlock/define';

const EDGE_OFFSET_Y = 200;
const MONTH_BUFFER_EDGE = 20;

interface Props {
  startDate?: Date;
}

interface DateTimeItem {
  date: Date;
  text: string;
  key: string;
  isNow: boolean;
  isPoint: boolean;
}

const props = withDefaults(defineProps<Props>(), {});
const emit = defineEmits<{
  (e: 'choice-month', value: Date): void;
}>();

const {
  scrollTop,
  wheelTransition,
  updateSilentlyScrollTop,
  scrollContentEl,
  containerEl,
} = useScrollInfinite();

const pointerDate = ref<Date>(getStartPointer());
const initedScrollContent = ref(false);
const beetweenMonth = computed<DateTimeItem[]>(() => {
  const start = DateTime.fromJSDate(pointerDate.value).plus({ month: -MONTH_BUFFER_EDGE });
  const now = new Date();
  const items: DateTimeItem[] = [];

  for (let i = 0; i < (MONTH_BUFFER_EDGE * 2 + 1); ++i) {
    const datetime = start.plus({ month: i });

    items.push({
      date: datetime.toJSDate(),
      text: upperFirst(datetime.toFormat('LLLL, yyyy', { locale: 'ru' })),
      key: datetime.toISODate() || String(i),
      isNow: compareYearAndMonth(now, datetime),
      isPoint: compareYearAndMonth(pointerDate.value, datetime),
    });
  }

  return items;
});

onMounted(() => {
  setTimeout(() => {
    currentPointCenterAlign();
    initedScrollContent.value = true;
  }, 150); // fix: без задержки не успевает построить DOM элементы
});

function getStartPointer(): Date {
  const startDate = props.startDate || new Date();

  return DateTime.fromObject({
    year: startDate.getFullYear(),
    month: startDate.getMonth() + 1,
  }).toJSDate();
}

function compareYearAndMonth(jsDate: Date, datetime: DateTime) {
  return datetime.year === jsDate.getFullYear() && datetime.month === (jsDate.getMonth() + 1);
}

//#region Scroll Handler
/**
 * Элемент относительно которого определяем смещения новго контента.
 * Так-же является lock значением (чтобы событие scroll не срабатывало в момент генерации новых элементов)
 */
let watchChangeOffsetTopEl: HTMLDivElement|null = null;

function onScroll(ctx: ScrollEventCtx) {
  if (watchChangeOffsetTopEl) return;

  const containerBounding = ctx.containerEl.getBoundingClientRect();
  const scrollContentBounding = ctx.scrollContentEl.getBoundingClientRect();

  const offsetTop = -(scrollContentBounding.top - containerBounding.top);
  const offsetBottom = (scrollContentBounding.bottom - containerBounding.bottom);

  if (offsetTop < EDGE_OFFSET_Y) {
    pointerDate.value = DateTime.fromJSDate(pointerDate.value).plus({ month: -MONTH_BUFFER_EDGE}).toJSDate();
    watchChangeOffsetTopEl = ctx.scrollContentEl.querySelector('.c-visits-preentry-choice-month__item:first-child') as HTMLDivElement;
  } else if (offsetBottom < EDGE_OFFSET_Y) {
    pointerDate.value = DateTime.fromJSDate(pointerDate.value).plus({ month: MONTH_BUFFER_EDGE}).toJSDate();
    watchChangeOffsetTopEl = ctx.scrollContentEl.querySelector('.c-visits-preentry-choice-month__item:last-child')  as HTMLDivElement;
  }

  let lastOffsetTop = 0;
  let dOffsetTop = 0;

  if (watchChangeOffsetTopEl) {
    lastOffsetTop = watchChangeOffsetTopEl.getBoundingClientRect().top;

    /**
     * NOTE: На десктопе, при прокрутке колесиком может возникнуть эффект резкого перемещения.
     * Это возникает из-за того, что перед большим смещением холста (для бесконечной прокрутки),
     * анимация отключается.
     * 
     * Как сместить холст и не нарушить transition анимацию, решения не нашел.
     */
    wheelTransition.value = false;
  }

  nextTick(() => {
    if (watchChangeOffsetTopEl) {
      const newOffsetTop = watchChangeOffsetTopEl?.getBoundingClientRect().top || 0;
      dOffsetTop = (newOffsetTop - lastOffsetTop);

      updateSilentlyScrollTop(scrollTop.value + dOffsetTop);
    }

    watchChangeOffsetTopEl = null;
  });
}

/**
 * Прокрутить до месяца-указателя
 */
function currentPointCenterAlign() {
  if (!scrollContentEl.value || !containerEl.value) return;

  const currentCalendarEl: HTMLDivElement|null = scrollContentEl.value.querySelector('.c-visits-preentry-choice-month__item--point');
  if (!currentCalendarEl) return;

  const calendarBounding = currentCalendarEl.getBoundingClientRect();
  const containerBounding = containerEl.value.getBoundingClientRect();

  const newScrollTop = calendarBounding.top - (-scrollTop.value) - containerBounding.top - 12; // 12px - margin-top

  scrollTop.value = newScrollTop;
}

const onScrollTrottle = throttle(onScroll, 100);
//#endregion
</script>

<template>
  <virtual-scroll-block
    class="c-visits-preentry-choice-month"
    @scroll="onScrollTrottle"
    :style="{ opacity: initedScrollContent ? 1 : 0 }"
  >
    <div
      v-for="month in beetweenMonth"
      :key="month.key"
      :class="{
        'c-visits-preentry-choice-month__item': true,
        'c-visits-preentry-choice-month__item--now': month.isNow,
        'c-visits-preentry-choice-month__item--point': month.isPoint,
      }"
      @click="() => emit('choice-month', month.date)"
    >
      {{ month.text }}
    </div>
  </virtual-scroll-block>
</template>

<style lang="scss">
.c-visits-preentry-choice-month {
  height: 100%;
  transition: opacity 0.3 ease;

  &__item {
    margin: var(--core-spacer-small);
    background-color: var(--ion-card-background);
    border-radius: var(--core-card-radius);
    padding: var(--ion-padding);
    color: var(--core-light);

    &--now {
      color: var(--ion-color-primary);
    }
  }
}
</style>