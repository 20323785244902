import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_chip = _resolveComponent("ion-chip")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createTextVNode(_toDisplayString(_ctx.item.name) + " ", 1),
    _createVNode(_component_ion_chip, {
      color: "success",
      class: "chip-size-small chip-filled my-0 mr-0 ml-2"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" -" + _toDisplayString(_ctx.item.percent) + "% ", 1)
      ]),
      _: 1
    })
  ], 64))
}