import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createSlots as _createSlots, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, withDirectives as _withDirectives, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  class: "visit-single-complete-btn-wrap",
  ref: "sideAfterRef"
}
const _hoisted_2 = { class: "ion-padding" }
const _hoisted_3 = {
  key: 0,
  class: "fixed-actions-spacer"
}
const _hoisted_4 = {
  key: 0,
  class: "visits-fixed-actions"
}
const _hoisted_5 = { ref: "mobileSearchbarContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_big = _resolveComponent("app-header-big")!
  const _component_visit_store_checkout = _resolveComponent("visit-store-checkout")!
  const _component_c_card_header = _resolveComponent("c-card-header")!
  const _component_services_provide_visit_state = _resolveComponent("services-provide-visit-state")!
  const _component_tablet_side_layout = _resolveComponent("tablet-side-layout")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!
  const _directive_scroll_hide = _resolveDirective("scroll-hide")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header_big, {
        title: _ctx.$t('views.market.title')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_tablet_side_layout, null, _createSlots({
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_c_card_header, null, {
                  default: _withCtx(() => [
                    _createTextVNode(_toDisplayString(_ctx.$t('views.market.provide_services_card_title')), 1)
                  ]),
                  _: 1
                }),
                _createVNode(_component_services_provide_visit_state, {
                  "restore-filter-by-selected": false,
                  "short-group-name": true,
                  "mobile-searchbar-container": _ctx.mobileSearchbarContainer,
                  "services-groups": _ctx.categoryServicesGroups,
                  "is-collapsed-group": false,
                  onInputSearch: _cache[0] || (_cache[0] = w => _ctx.searchWords = w),
                  "content-loading": _ctx.contentLoading
                }, null, 8, ["mobile-searchbar-container", "services-groups", "content-loading"])
              ]),
              (!_ctx.isTabletAndUp)
                ? (_openBlock(), _createElementBlock("div", _hoisted_3))
                : _createCommentVNode("", true)
            ]),
            _: 2
          }, [
            (_ctx.isTabletAndUp)
              ? {
                  name: "side",
                  fn: _withCtx(() => [
                    _createVNode(_component_visit_store_checkout, {
                      class: "ion-padding",
                      "complete-btn-container": _ctx.sideAfterRef
                    }, null, 8, ["complete-btn-container"])
                  ]),
                  key: "0"
                }
              : undefined,
            (_ctx.isTabletAndUp)
              ? {
                  name: "side-after",
                  fn: _withCtx(() => [
                    _createElementVNode("div", _hoisted_1, null, 512)
                  ]),
                  key: "1"
                }
              : undefined
          ]), 1024),
          (!_ctx.isTabletAndUp)
            ? _withDirectives((_openBlock(), _createElementBlock("div", {
                key: 0,
                slot: "fixed",
                class: _normalizeClass(["visits-fixed-toolbar", {'scroll-hide--show-force': _ctx.searchWords }])
              }, [
                (!_ctx.isTabletAndUp)
                  ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
                      _createVNode(_component_ion_button, {
                        color: "primary",
                        shape: "round",
                        onClick: _ctx.next
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('views.market.btn_next')), 1)
                        ]),
                        _: 1
                      }, 8, ["onClick"])
                    ]))
                  : _createCommentVNode("", true),
                _createElementVNode("div", _hoisted_5, null, 512)
              ], 2)), [
                [_directive_scroll_hide, {}]
              ])
            : _createCommentVNode("", true)
        ]),
        _: 1
      }),
      _createVNode(_component_app_footer)
    ]),
    _: 1
  }))
}