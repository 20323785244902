<script lang="ts" setup>
import { PropType, defineProps, ref, computed, onMounted } from 'vue';
import CSpinner from './CSpinner.vue';
import CErrorBlock from './CErrorBlock.vue';
import { isOnline } from '@/helpers/network';
import { isClientError } from '@/repositories';
import { ActionFunction, getContentLoadingInnerContext } from '@/composables/contentLoading';
import {
  errorToMessageData,
  MessageData as ErrorMessageData,
  stringToMessageData,
  UNKNOW_ERROR_MESAGE
} from '@/helpers/error';

const props = defineProps({
  loadingText: {
    type: String as PropType<string>,
  },
  firstLoad: {
    type: Boolean as PropType<boolean>,
    default: true,
  },
  loadindHideContent: {
    type: Boolean as PropType<boolean>,
    default: true
  },
  action: {
    type: Function as PropType<ActionFunction>,
    required: true,
  },
});

const ctx = getContentLoadingInnerContext();

const loading = ref(false);
const errorData = ref<ErrorMessageData|null>(null);

const hasErrors = computed<boolean>(() => !!errorData.value);
const contentHide = computed<boolean>(() => (props.loadindHideContent && loading.value) || hasErrors.value);

async function load() {
  let qp: any = null;

  try {
    errorData.value = null;
    loading.value = true;

    qp = props.action();
    if (!(qp instanceof Promise)) {
      throw new Error('Функция action для CContentLoading должна быть асинхронной');
    }

    await qp; // await query
  } catch (e) {
    const errorPayload = isClientError(e) ? e.response?.data : e;

    if (!errorPayload) {
      const message = isOnline()
        ? UNKNOW_ERROR_MESAGE
        : 'Возникла ошибка сети. <br> Проверьте подключение к интрнету и повторите попытку.'
      ;
      
      errorData.value = stringToMessageData(message);
    } else {
      errorData.value = errorToMessageData(errorPayload);
    }
  } finally {
    loading.value = false;
  }

  return qp;
}

function reload() {
  return load();
}

// NOTE: Export context
ctx.load = load;
ctx.reload = reload;

onMounted(() => {
  if (props.firstLoad) {
    load();
  }
});
</script>

<template>
  <div class="core-content-loading">
    <div class="core-content-loading__content" v-show="!contentHide">
      <slot v-bind="{ loading, load }"></slot>
    </div>

    <div class="core-content-loading__error" v-if="errorData">
      <slot name="error" v-bind="{
        reload,
        errorMessage: errorData.message, /** @depraceted */
        errorData
      }">
        <c-error-block
          :message="errorData.message"
          :title="errorData.title"
          @reload="reload()"
        />
      </slot>
    </div>

    <slot name="spinner" v-bind="{ loading, loadingText }">
      <c-spinner
        v-show="loading"
        class="mt-4 mb-3"
        :loading-text="loadingText"
      />
    </slot>
  </div>
</template>

<style lang="scss">
.core-content-loading {
  position: relative;
}
</style>

