<script lang="ts" setup>
import { defineEmits, defineProps, computed, inject, onMounted, PropType, Ref, ref, watch } from 'vue';
import { CarResponse } from '@/repositories/Models/Car';
import { useStore } from '@/composables/useApp';
import { minutes } from '@/helpers/cache';
import { cloneDeep, get } from 'lodash';
import { softPhonePrepare } from '@/utils/string';
import { useRoute, useRouter } from 'vue-router';
import { CarVisitResponse } from '@/repositories/Models/CarVisit';
import { getLastFrameImage } from '@/helpers/visit';
import { CarGroup } from '@/repositories/Models/CarGroup';

const props = defineProps({
  carId: {
    type: String as PropType<string>,
    required: false,
  },
  cacheRelevantMinutes: {
    type: Number as PropType<number>,
    default: 60
  },
  carData: {
    type: Object as PropType<CarResponse>,
    required: false,
  },
  group: {
    type: Object as PropType<CarGroup>,
    required: false
  },
  showGroup: {
    type: Boolean,
    default: false,
  },
  hasChoiseGroup: {
    type: Boolean,
    default: true,
  }
});

const emit = defineEmits<{
  (e: 'car-loaded', car: CarResponse): void;
  (e: 'choice-group'): void;
}>();

const store = useStore();
const route = useRoute();
const router = useRouter();

const car = ref<CarResponse|null>(null);
const loading = ref(true);
const error: Ref<any> = ref(null);

const phonePrepared = computed(() => softPhonePrepare(get(car.value, 'ownerPhone'), null));

const phoneLink = computed(() => {
  return phonePrepared.value ? `tel:${phonePrepared.value}` : null;
});

const phoneFormat = computed<string>(() => {
  const phoneRaw = get(car.value, 'ownerPhone', '');
  const preparedPhone = softPhonePrepare(phoneRaw, null);

  if (preparedPhone?.length !== 12) return phoneRaw;

  // NOTE: Для подобных вещей лучше использовать imask
  // link: https://github.com/uNmAnNeR/imaskjs
  // Но из-за одного форматирования не хоетлось тащить целую библиотеку
  return preparedPhone.substr(0, 2)            // +7
    + ' (' + preparedPhone.substr(2, 3) + ') ' // _(900)_
    + preparedPhone.substr(5, 3) + '-'         // 111-
    + preparedPhone.substr(8, 2) + '-'         // 22-
    + preparedPhone.substr(10, 2)              // 33
  ;
});

const showGroupItem = computed<boolean>(() => props.showGroup && (props.hasChoiseGroup || !!props.group));

async function loadCarInfo() {
  if (props.carData) {
    car.value = cloneDeep(props.carData);
    loading.value = false;
    return;
  }

  if (!props.carId) return;

  loading.value = true;
  error.value = null;

  try {
    const data = await store.car.getCar(props.carId, {
      // Данный бокс служит для информационного вывода,
      // во время оформления заказа и после того, как были обновлены данные о владельце.
      // Чтобы постоянно не запрашивать данные с сервера, они будут подтягиваться
      // с кэша на протяжении заданного времени (по умолчанию 1-н час)
      relevantTime: minutes(props.cacheRelevantMinutes),
    });

    car.value = data.data;
    emit('car-loaded', car.value);
  } catch (e) {
    error.value = e;
  } finally {
    loading.value = false;
  }
}

watch(() => props.carId, loadCarInfo);
onMounted(loadCarInfo);

function toCarHistory() {
  router.push({
    name: 'visits-car-history',
    params: { carId: car.value?.id || 0 }
  });
}

const visit = inject<Ref<CarVisitResponse|null>>('visit', ref(null));
function toUserEdit() {
  // Находимся на странице создания нового заказа
  if (route.meta.visit === 'new') {
    router.push({
      name: 'visit-new-client',
      params: { carId: car.value?.id || 0 },
      query: { act: 'edit' }
    });
  }

  // На других страницах
  else {
    const visitImage = visit.value ? getLastFrameImage(visit.value) : undefined;

    router.push({
      name: 'client-info-edit',
      params: { carId: car.value?.id || 0 },
      query: { dir: 'back', visit_image: visitImage },
    });
  }
}
</script>

<template>
  <c-card-list>
    <c-error-block
      v-if="error"
      :message="error"
      @reload="loadCarInfo"
    />

    <template v-else>
      <ion-item button class="color-default" @click="toUserEdit">
        <ion-skeleton-text v-if="loading" animated :style="{
          width: '130px',
          height: '1em',
        }" />
        
        <template v-else>
          {{ car?.ownerName || $t('components.client_info_card.client_name_empty') }}
        </template>
      </ion-item>

      <ion-item button class="color-default" @click="toCarHistory">
        {{ $t('components.client_info_card.item_car_history') }}
      </ion-item>

      <ion-item>
        {{ $t('components.client_info_card.item_client_phone') }}
        
        <!-- eslint-disable-next-line -->
        <ion-note slot="end" color="light">
          <ion-skeleton-text v-if="loading" animated :style="{
            width: '100px',
            height: '1em',
          }" />
          <a v-else-if="phoneLink" class="cursor-pointer reset-link" :href="phoneLink">
            {{ phoneFormat }}
          </a>
          <span v-else class="cursor-pointer user-select-none"  @click="toUserEdit">
            {{ $t('components.client_info_card.client_phone_empty') }}
          </span>
        </ion-note>
      </ion-item>

      <ion-item v-if="showGroupItem">
        {{ $t('components.client_info_card.item_group') }}
        
        <!-- eslint-disable-next-line -->
        <ion-note
          class="cursor-pointer user-select-none"
          slot="end"
          color="light"
          @click="$emit('choice-group')"
        >
          {{ group ? group.name : 'Добавить' }}
        </ion-note>
      </ion-item>

      <ion-item v-if="loading">
        <ion-skeleton-text animated :style="{
          width: '150px',
          height: '1em',
        }" />
      </ion-item>

      <ion-item v-else-if="car?.comment">
        {{ car?.comment }}
      </ion-item>
    </template>
  </c-card-list>
</template>