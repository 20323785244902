import { resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, createVNode as _createVNode, withDirectives as _withDirectives, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-login-form" }
const _hoisted_2 = { class: "c-form-group" }
const _hoisted_3 = { class: "c-form-group" }
const _hoisted_4 = {
  class: "c-login-form__keyboard",
  ref: "keyboardContainer"
}
const _hoisted_5 = { class: "c-form-footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_input = _resolveComponent("ion-input")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _directive_virtual_keyboard = _resolveDirective("virtual-keyboard")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_ion_input, {
        class: "core-input",
        placeholder: _ctx.$t('components.login_form.input_login'),
        modelValue: _ctx.form.data.username,
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.data.username) = $event)),
        inputmode: "none",
        readonly: _ctx.inputmodeNoneNotSupported
      }, null, 8, ["placeholder", "modelValue", "readonly"]), [
        [_directive_virtual_keyboard, _ctx.keyboard]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createVNode(_component_ion_input, {
        class: "core-input",
        placeholder: _ctx.$t('components.login_form.input_password'),
        modelValue: _ctx.form.data.password,
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.data.password) = $event)),
        inputmode: "none",
        readonly: _ctx.inputmodeNoneNotSupported
      }, null, 8, ["placeholder", "modelValue", "readonly"]), [
        [_directive_virtual_keyboard, _ctx.keyboard]
      ])
    ]),
    _createElementVNode("div", _hoisted_4, null, 512),
    _createElementVNode("div", _hoisted_5, [
      _createVNode(_component_ion_button, {
        color: "primary",
        expand: "block",
        shape: "round",
        onClick: _ctx.login,
        disabled: _ctx.loginButtonDisabled
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.loginButtonText), 1)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ])
  ]))
}