<template>
  <div
    v-if="isCreated"
    v-show="isActive"
    class="c-tab-content c-tab-content--show-animation"
  >
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, inject, ComputedRef, PropType, computed, ref, watch } from 'vue'

export default defineComponent({
  props: {
    name: {
      type: String as PropType<string>,
      default: 'default',
    }
  },
  setup(props) {
    const selectedTab = inject('seletedTab') as ComputedRef<string|undefined>;
    const isActive = computed(() =>
      selectedTab.value === props.name
      || (!selectedTab.value && props.name === 'default')
    );

    // Внутренние компоненты будут создаваться только в момент первого отображения,
    // в последующем они будут скрываться/показываться
    const isCreated = ref(isActive.value);
    if (false === isCreated.value) {
      const stopWatchCreated = watch(isActive, active => {
        if (active) {
          isCreated.value = true;
          stopWatchCreated();
        }
      });
    }

    return {
      selectedTab,
      isActive,
      isCreated,
    }
  }
});
</script>

<style lang="scss">
// Подтормаживает при большом кол-ве контента
// @keyframes tabContentShowAnim {
//   0% { opacity: 0; transform: translateX(50px); }
//   40% { transform: translateX(0); }
//   100% { opacity: 1; }
// }

@keyframes tabContentShowAnim {
  0% { opacity: 0; }
  100% { opacity: 1; }
}

// .c-tab-content {
  // &--show-animation {
    // FIXME: Анимация подтормаживает (хотя не должна),
    // разобраться с этим и потом активировать
    // animation: tabContentShowAnim 0.5s ease;
  // }
// }
</style>