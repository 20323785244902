<script lang="ts" setup>
import { useStore } from '@/composables/useApp';
import { defineProps, PropType, watch, toRef, computed } from 'vue';
import { useServicesCategoriesContext } from '@/composables/useServicesCategoriesContext';
import ServicesViewCategoryItem from './ServicesViewCategoryItem.vue';
import { useContentLoading } from '@/composables/contentLoading';

const props = defineProps({
  catId: {
    type: [String, Number] as PropType<string|number>,
    required: true,
  },
  searchWords: {
    type: String,
    required: false,
  },
});

const store = useStore();
const contentLoadingCtx = useContentLoading();

const searchWords = toRef(props, 'searchWords');
const titleGroup = store.point.getCategoryBreadcrumbsComputed(toRef(props, 'catId'));

const {
  servicesCategoriesFiltered,
  loadServicesCategoriesByContext,
} = useServicesCategoriesContext(searchWords);

const loadAction = () => loadServicesCategoriesByContext({ categoryId: props.catId });
const isSearchStarted = computed<boolean>(() => !!searchWords.value?.trim());

watch(() => props.catId, () => contentLoadingCtx.load());
</script>

<template>
  <c-content-loading
    :action="loadAction"
  >
    <ion-item class="color-default">
      <span v-html="titleGroup"></span>
    </ion-item>

    <services-view-category-item
      v-for="category in servicesCategoriesFiltered"
      :key="category.id"
      :category="category"
      :is-search-started="isSearchStarted"
    ></services-view-category-item>
  </c-content-loading>
</template>
