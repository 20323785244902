import { ref, DeepReadonly, Ref, computed, UnwrapRef } from 'vue';
import { useStore } from './useApp';
import CModalViewSelect from '@/components/core/CModalViewSelect.vue';
import { modalController } from '@ionic/vue';
import { isEmpty, get, max, cloneDeep } from 'lodash';
import { useI18n } from 'vue-i18n';
import { ServiceForForm } from '@/store/ServiceStore';
import { Discount, DiscountTypeEnum, GiftCampaign, LeveledCampaign, StaticDiscountCampaign } from '@/repositories/Models/Discount';
import { NewVisitSatate } from '@/store/NewVisitState';
import { percentToUnit } from '@/utils/price';
import { useCurrency } from './currency';
import { ProvideServiceItemPart } from '@/composables/provideServices';

export type ModalDiscountSetValueCallback = (discountId: number|null, discount?: Discount) => void;
export interface ModalDiscountOptions {
  setValue: ModalDiscountSetValueCallback;
  discountsOptions?: Discount[];
  value?: number;
  modalTitle?: string;
}

export function useDiscounts() {
  const store = useStore();
  const { t } = useI18n();

  const discounts = ref<DeepReadonly<Discount[]>>([]);
  async function loadDiscounts() {
    discounts.value = await store.discount.getAll();
  }

  async function openModalDiscount(options: ModalDiscountOptions) {
    const discountOptions = isEmpty(options.discountsOptions)
      ? discounts.value : options.discountsOptions;

    const modal = await modalController.create({
      cssClass: 'core-modal-actions',
      component: CModalViewSelect,
      swipeToClose: true,
      componentProps: {
        options: discountOptions,
        propValue: 'id',
        propText: 'name',
        multiselect: false,
        value: options.value,
        modalTitle: options.modalTitle || t('composables.discount.modal_choice_title_default'),
        setValue: options.setValue,
      }
    });

    modal.present(); // async

    return modal;
  }

  return {
    discounts,
    loadDiscounts,
    openModalDiscount,
  };
}


export interface UseDiscountServiceItemOptions {
  service: Ref<ServiceForForm>;
  priceItem: Ref<number>;
  visitState?: UnwrapRef<NewVisitSatate>;
}

/**
 * Часть позволяющая корректно рассчитать и применить скидку у конкретной оказываемой услуги или товару (provideService)
 * 
 * @param options 
 * @returns 
 */
export function useDiscountServiceItem(options: UseDiscountServiceItemOptions) {
  const { formatCurrency } = useCurrency();

  /** Статическая компания по часам/дням/неделям */
  const discountStaticCampaign = computed<StaticDiscountCampaign|null>(() => options.service.value.staticDiscount || null);
  /** Накопительная компания (скидки) */
  const discountLeveledCampaign = computed<LeveledCampaign|null>(() => options.service.value.leveledDiscount || null);

  /** Подарочные акции, при покупке N штук что-то одно в подарок (на 1-н заказ) */
  const discountGiftCampaign = computed<GiftCampaign|null>(() => {
    if (!options.visitState || isEmpty(options.service.value.giftCampaignSelf)) return null;

    // Если подарочная акция еще не задана или заданна на текущую услугу
    if (isEmpty(options.visitState.giftCampaign) || options.visitState.giftCampaign[options.service.value.nomenclature.id]) {
      // Берем самую первую, учитывая, что скидка у всех одинковая (100%), без разницы какую брать
      return get(options.service.value.giftCampaignSelf, '0', null);
    }

    // NOTE: Если уже используется другая подарочная акция,
    // то еще одну назначать нельзя: 1-н заказ - 1-н подарок.
    return null;
  });

  /** Применяемый по итогу тип скидки (без подарочной акции) */
  const appliedDiscountPercentCampaign = computed<Pick<Discount, "id" | "type">|null>(() => {
    // Если применимы 2 кампании (по часам/дням/неделям) и накопительная акция, смотрим у кого % скидки больше
    if (discountStaticCampaign.value && discountLeveledCampaign.value) {
      if (discountStaticCampaign.value.percent > discountLeveledCampaign.value.percent) {
        return {
          id: discountStaticCampaign.value.id,
          type: DiscountTypeEnum.StaticDiscount
        }
      }

      return {
        id: discountLeveledCampaign.value.id,
        type: DiscountTypeEnum.LeveledDiscount
      };
    }

    // Статическая компания по часам/дням/неделям
    if (discountStaticCampaign.value) {
      return {
        id: discountStaticCampaign.value.id,
        type: DiscountTypeEnum.StaticDiscount
      }
    }

    // Накопительная компания (скидки)
    if (discountLeveledCampaign.value) {
      return {
        id: discountLeveledCampaign.value.id,
        type: DiscountTypeEnum.LeveledDiscount
      };
    }

    // Увы, скидок нет
    return null;
  });

  /**
   * Вернет итоговый процент скидки (ИМЕННО %).
   * 
   * WARNING: Выберет между статической скидкой и накопительной, 
   * подарочная компания здесь не учитывается.
   */
  const discountPercent = computed<number>(() => {
    return max([
      get(discountStaticCampaign.value, 'percent', 0) as number,
      get(discountLeveledCampaign.value, 'percent', 0) as number,
    ]) || 0;
  });

  /**
   * Стоимость одной единицы услуги, с учетом процентной скидки (статическая или накопительная кампания)
   * WARNING: Не учитывает подарочныю скидку.
   */
  const priceItemPercentDiscount = computed(() => {
    return options.priceItem.value * (1 - percentToUnit(discountPercent.value));
  });

  /**
   * Метки со скидками, которые будут отображаться пользователю
   * 
   * Здесь отображается макс % скидки из статической или накопительная кампании.
   * Также отображается - сумма в случае, если применима подарочная акция, но отображается
   * не исходная минус стоимость, а с учетом процентной скидки, для наглядности.
   * (Так админимтратору будет понятнее, на какую сумму реально сделан кодарок для конкретного клиента).
   * 
   * Пример:
   * Исходная стоимость услуги: 1000 руб
   * Статическая скидка - 10%
   * Накопительная скидка - 15% (применяется этот процент т.к. он больше)
   * Применима подарочная акция (товар в подарок)
   * 
   * По итогу получаться метки: ["-15%", "-850руб"]
   */
  const discountViewBadges = computed<string[]>(() => {
    const list: string[] = [];

    if (discountPercent.value) {
      list.push(`-${discountPercent.value}%`);
    }

    if (discountGiftCampaign.value) {
      list.push('-' + formatCurrency(priceItemPercentDiscount.value));
    }

    return list;
  });

  /**
   * Применить скидку к переденному значению
   * 
   * @param value данное значение будет изменено
   */
  function applyDiscount(value: ProvideServiceItemPart) {
    if (appliedDiscountPercentCampaign.value) {
      // (||) По часам/дням/неделям или накопительная
      switch (appliedDiscountPercentCampaign.value.type) {
        case DiscountTypeEnum.StaticDiscount: {
          value.discountPercent = discountStaticCampaign.value!.percent;
          value.discountCampaign = appliedDiscountPercentCampaign.value;

          value.meta = {
            ...value.meta,
            staticDiscount: cloneDeep(discountStaticCampaign.value!)
          };

          break;
        }

        case DiscountTypeEnum.LeveledDiscount: {
          value.discountPercent = discountLeveledCampaign.value!.percent;
          value.discountCampaign = appliedDiscountPercentCampaign.value;

          value.meta = {
            ...value.meta,
            leveledDiscount: cloneDeep(discountLeveledCampaign.value!)
          };

          break;
        }
      }
    }

    // (&&) И возможно подарочная
    if (discountGiftCampaign.value) {
      // Вычитываем полную стоимость товара, с учетом процентной скидки.
      // Это необходимо, т.к. на бэкенде алгортм, который сначала посчитает процент от единицы товара,
      // а затем от общей суммы (с учетом количесва) отнимает данное значение.
      value.discountSum = priceItemPercentDiscount.value;

      // Обязательно перезатираем %-ю кампанию, т.к. иначе подарок не спишется и счетчик не обнулиться
      value.discountCampaign = {
        id: discountGiftCampaign.value.id,
        type: DiscountTypeEnum.Gift
      };

      value.meta = {
        ...value.meta,
        giftDiscount: cloneDeep(discountGiftCampaign.value)
      };
    }
  }

  return {
    discountStaticCampaign,
    discountLeveledCampaign,
    discountGiftCampaign,
    appliedDiscountPercentCampaign,
    discountPercent,
    priceItemPercentDiscount,
    discountViewBadges,
    applyDiscount,
  };
}