import { createRouter, createWebHistory } from '@ionic/vue-router'
import { RouteRecordRaw } from 'vue-router'

import Login from '@/views/Login.vue'

import Visits from '@/views/visits/Visits.vue'
import VisitSingle from '@/views/visits/VisitSingle.vue'
import VisitPay from '@/views/visits/VisitPay.vue'
import VisitNewPhotoScanner from '@/views/visits/VisitNewPhotoScanner.vue'
import VisitNewCarNumber from '@/views/visits/VisitNewCarNumber.vue'
import VisitNewClient from '@/views/visits/VisitNewClient.vue'
import VisitNewSelectBox from '@/views/visits/VisitNewSelectBox.vue'
import VisitNewSelectServiceType from '@/views/visits/VisitNewSelectServiceType.vue'
import VisitNewSelectServiceCat from '@/views/visits/VisitNewSelectServiceCat.vue'
import VisitNewServices from '@/views/visits/VisitNewServices.vue'
import VisitNewSelectDoers from '@/views/visits/VisitNewSelectDoers.vue'
import VisitNewSelectDiscounts from '@/views/visits/VisitNewSelectDiscounts.vue'
import VisitNewPreentryDatetime from '@/views/visits/VisitNewPreentryDatetime.vue'
import VisitsCarHistory from '@/views/visits/VisitsCarHistory.vue'
import ClientEdit from '@/views/client/Edit.vue'

import VisitsPreentry from '@/views/visits/VisitsPreentry.vue'

import Pos from '@/views/pos/Pos.vue'
import PosCashInOrOut from '@/views/pos/PosCashInOrOut.vue'

import Pricelist from '@/views/pricelist/Pricelist.vue'
import PricelistCat from '@/views/pricelist/PricelistCat.vue'
import PricelistServices from '@/views/pricelist/PricelistServices.vue'
import PricelistCatAndServices from '@/views/pricelist/PricelistCatAndServices.vue'

import Market from '@/views/market/Market.vue'
import VisitStoreCheckout from '@/views/market/VisitStoreCheckout.vue'
import Reports from '@/views/reports/Reports.vue'

import Dev from '@/views/dev/Dev.vue'
// import DemoComponents from '@/views/dev/DemoComponents.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'login',
    component: Login,
  },

  //#region Visits
  {
    path: '/visits',
    name: 'visits',
    component: Visits,
    meta: { tab: 'visits' },
  },
  {
    path: '/visits/:visitId',
    name: 'visit-single',
    component: VisitSingle,
    meta: { tab: 'visits' },
  },
  {
    path: '/visits/:visitId/pay/:paymentType/:action',
    name: 'visit-pay',
    component: VisitPay,
    meta: { tab: 'visits' },
  },
  {
    path: '/visits/new/photo-scanner',
    name: 'visit-new-photo-scanner',
    component: VisitNewPhotoScanner,
    meta: { visit: 'new' },
  },
  {
    path: '/visits/new/car-number',
    name: 'visit-new-car-number',
    component: VisitNewCarNumber,
    meta: { visit: 'new' },
  },
  {
    path: '/visits/new/client/car/:carId',
    name: 'visit-new-client',
    component: VisitNewClient,
    meta: { visit: 'new' },
  },
  {
    path: '/visits/new/:visitId/photo/create',
    name: 'visit-new-create-photo',
    component: () => import('@/views/visits/VisitCreatePhoto.vue'),
    meta: {
      visit: 'new',
      nextRouteName: 'visit-new-photo-gallery',
    },
  },
  {
    path: '/visits/new/:visitId/photo/gallery',
    name: 'visit-new-photo-gallery',
    component: () => import('@/views/visits/VisitPhotoGallery.vue'),
    meta: {
      visit: 'new',
      nextRouteName: 'visit-new-car-number',
      addRouteName: 'visit-new-create-photo',
    },
  },
  {
    path: '/visits/new/:visitId/box',
    name: 'visit-new-box',
    component: VisitNewSelectBox,
    meta: { visit: 'new' },
  },
  {
    path: '/visits/new/:visitId/type',
    name: 'visit-new-service-type',
    component: VisitNewSelectServiceType,
    meta: { visit: 'new' },
  },
  {
    path: '/visits/new/:visitId/type/:typeId/cat',
    name: 'visit-new-service-cat',
    component: VisitNewSelectServiceCat,
    meta: { visit: 'new' },
  },
  {
    path: '/visits/new/:visitId/services',
    name: 'visit-new-services',
    component: VisitNewServices,
    meta: { visit: 'new' },
  },
  {
    path: '/visits/new/:visitId/doers',
    name: 'visit-new-doers',
    component: VisitNewSelectDoers,
    meta: { visit: 'new' },
  },
  {
    path: '/visits/new/:visitId/discounts',
    name: 'visit-new-discounts',
    component: VisitNewSelectDiscounts,
    meta: { visit: 'new' },
  },
  {
    path: '/visits/car/:carId/history',
    name: 'visits-car-history',
    component: VisitsCarHistory,
  },
  {
    path: '/client/car/:carId/edit',
    name: 'client-info-edit',
    component: ClientEdit,
  },
  {
    path: '/visits/:visitId/photo/create',
    name: 'visit-create-photo',
    component: () => import('@/views/visits/VisitCreatePhoto.vue'),
    meta: {
      visit: 'change-photos',
      nextRouteName: 'visit-photo-gallery',
    },
  },
  {
    path: '/visits/:visitId/photo/gallery',
    name: 'visit-photo-gallery',
    component: () => import('@/views/visits/VisitPhotoGallery.vue'),
    meta: {
      visit: 'change-photos',
      nextRouteName: 'visit-single',
      addRouteName: 'visit-create-photo',
    },
  },
  //#endregion Visits

  //#region Upload photo for visit
  // {
  //   path: '/visits/:visitId/photo/add',
  //   name: 'visit-add-photo',
  //   component: () => import('@/views/visits/VisitAddPhoto.vue'),
  //   meta: { },
  // },
  // {
  //   path: '/visits/:visitId/photo/confirm',
  //   name: 'visit-add-photo-confirm',
  //   component: () => import('@/views/visits/VisitAddPhotoConfirm.vue'),
  //   meta: { },
  // },
  //#endregion

  //#region Change Counterparty
  {
    path: '/visits/change-counterparty/:visitId/type',
    name: 'change-counterparty-service-type',
    component: VisitNewSelectServiceType,
    meta: { visit: 'change-counterparty' },
  },
  {
    path: '/visits/change-counterparty/:visitId/type/:typeId/cat',
    name: 'change-counterparty-service-cat',
    component: VisitNewSelectServiceCat,
    meta: { visit: 'change-counterparty' },
  },
  {
    path: '/visits/change-counterparty/:visitId/services',
    name: 'change-counterparty-services',
    component: VisitNewServices,
    meta: { visit: 'change-counterparty' },
  },
  {
    path: '/visits/change-counterparty/:visitId/doers',
    name: 'change-counterparty-doers',
    component: VisitNewSelectDoers,
    meta: { visit: 'change-counterparty' },
  },
  {
    path: '/visits/change-counterparty/:visitId/discounts',
    name: 'change-counterparty-discounts',
    component: VisitNewSelectDiscounts,
    meta: { visit: 'change-counterparty' },
  },
  //#endregion Change Counterparty

  //#region Preentry
  {
    path: '/visits/preentry',
    name: 'visits-preentry',
    component: VisitsPreentry,
  },
  {
    path: '/visits/preentry/car-number',
    name: 'preentry-car-number',
    component: VisitNewCarNumber,
    meta: { visit: 'preentry' },
  },
  {
    path: '/visits/preentry/client/car/:carId',
    name: 'preentry-client',
    component: VisitNewClient,
    meta: { visit: 'preentry' },
  },
  { // skip preentry
    path: '/visits/preentry/:visitId/box',
    name: 'preentry-box',
    component: VisitNewSelectBox,
    meta: { visit: 'preentry' },
  },
  {
    path: '/visits/preentry/:visitId/type',
    name: 'preentry-service-type',
    component: VisitNewSelectServiceType,
    meta: { visit: 'preentry' },
  },
  {
    path: '/visits/preentry/:visitId/type/:typeId/cat',
    name: 'preentry-service-cat',
    component: VisitNewSelectServiceCat,
    meta: { visit: 'preentry' },
  },
  {
    path: '/visits/preentry/:visitId/services',
    name: 'preentry-services',
    component: VisitNewServices,
    meta: { visit: 'preentry' },
  },
  { // only preenry
    path: '/visits/preentry/:visitId/datetime',
    name: 'preentry-datetime',
    component: VisitNewPreentryDatetime,
    meta: { visit: 'preentry' },
  },
  {
    path: '/visits/preentry/:visitId/doers',
    name: 'preentry-doers',
    component: VisitNewSelectDoers,
    meta: { visit: 'preentry' },
  },
  {
    path: '/visits/preentry/:visitId/discounts',
    name: 'preentry-discounts',
    component: VisitNewSelectDiscounts,
    meta: { visit: 'preentry' },
  },

  {
    path: '/visits/preentry/:visitId/datetime/change',
    name: 'change-preentry-datetime',
    component: VisitNewPreentryDatetime,
    meta: { visit: 'change-preentry-datetime' },
  },
  //#endregion

  //#region Pos
  {
    path: '/pos',
    name: 'pos',
    component: Pos,
    meta: { tab: 'pos' },
  },
  {
    path: '/pos/cash/:action',
    name: 'pos-cash-action',
    component: PosCashInOrOut,
    meta: { tab: 'pos' },
  },
  //#endregion

  //#region Pricelist
  {
    path: '/pricelist',
    name: 'pricelist',
    component: Pricelist,
    meta: { tab: 'pricelist' },
  },
  { // only phone
    path: '/pricelist/:typeId/cat',
    name: 'pricelist-category',
    component: PricelistCat,
    meta: { tab: 'pricelist' },
  },
  { // only phone
    path: '/pricelist/:typeId/cat/:catId/',
    name: 'pricelist-services',
    component: PricelistServices,
    meta: { tab: 'pricelist' },
  },
  { // only tablet
    path: '/pricelist/:typeId',
    name: 'pricelist-category-and-services',
    component: PricelistCatAndServices,
    meta: { tab: 'pricelist' },
  },
  //#endregion

  //#region Market
  {
    path: '/market',
    name: 'market',
    component: Market,
    meta: { tab: 'market', visit: 'market' },
  },
  { // only phone
    path: '/market/checkout',
    name: 'visit-store-checkout',
    component: VisitStoreCheckout,
    meta: { tab: 'market', visit: 'market' },
  },
  {
    path: '/market/visit/:visitId/pay/:paymentType',
    name: 'visit-store-pay',
    component: VisitPay,
    meta: {
      tab: 'visits',
      visitType: 'market', // FIXME: Переделать на `visit: 'market'`
      visit: 'market'
    },
  },
  //#endregion

  //#region Reports
  {
    path: '/reports',
    name: 'reports',
    component: Reports,
    meta: { tab: 'reports' },
  },
  //#endregion

  //#region Development
  {
    path: '/dev',
    name: 'dev',
    component: Dev,
    meta: {}
  },
  // {
  //   path: '/demo/components',
  //   name: 'demo-components',
  //   component: DemoComponents,
  //   meta: {}
  // },
  //#endregion

  // {
  //   path: '/:catchAll(.*)',
  //   component: Page404,
  // },
  // {
  //   path: '/404',
  //   name: 'page404',
  //   component: Page404,
  // },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
