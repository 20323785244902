<script lang="ts" setup>
import { provide, ref } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import CarSideInfo from '@/components/car/CarSideInfo.vue';
import ServicesProvideChoiceDoers from '@/components/service/provide-form/ServicesProvideChoiceDoers.vue';
import { useRouter } from 'vue-router';
import { useCreateVisitStep } from '@/composables/visit';
import { useMetrica } from '@/composables/useMetrica';
import { useToast } from '@/composables/toast';
import { useI18n } from 'vue-i18n';
import VisitPreviewImages from '@/components/visit/VisitPreviewImages.vue';

const router = useRouter();
const toast = useToast();
const { t } = useI18n();
const { emitEvent } = useMetrica();
const { isPreentry, visitId, carId, visitType, prefixNamePage } = useCreateVisitStep({
  stateProvideKey: 'visitState'
});
const isMandatoryDoers = ref(false);

provide('visitAction', visitType);

function next() {
  if (visitType !== 'preentry' && !isMandatoryDoers.value) {
    return toast.error(t('views.visit_new_doers.error_doer_all_required'));
  }

  emitEvent(`visit/${visitType}/doers/next`);
  router.push({
    name: prefixNamePage('discounts'),
    params: { visitId },
  });
}
</script>

<template>
  <ion-page>
    <app-header-new-visit
      :title="t('views.visit_new_doers.title')"
      :progress="0.875"
    />

    <ion-content>
      <tablet-side-layout>
        <template #side>
          <car-side-info
            class="ion-padding"
            :car-id="carId"
          >
            <template #preview-image>
              <visit-preview-images :visit-id="visitId" :is-new-preentry="isPreentry" />
            </template>
          </car-side-info>
        </template>

        <div class="ion-padding">
          <c-card-header class="ion-hide-md-down">
            {{ t('views.visit_new_doers.doers_card_title') }}
          </c-card-header>

          <ion-card class="padding-x-adaptive">
            <services-provide-choice-doers
              state-inject-key="visitState"
              v-model:is-mandatory-doers="isMandatoryDoers"
            />
          </ion-card>
        </div>

        <div class="fixed-actions-spacer"></div>
      </tablet-side-layout>

      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <div class="visits-fixed-actions">
          <ion-button
            color="primary"
            shape="round"
            class="m-0"
            @click="next"
          >
            {{ t('views.visit_new_doers.btn_next') }}
          </ion-button>
        </div>
      </ion-fab>
    </ion-content>

    <ion-footer />
  </ion-page>
</template>