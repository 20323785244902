import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeProps as _normalizeProps, guardReactiveProps as _guardReactiveProps, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeStyle as _normalizeStyle } from "vue"

const _hoisted_1 = { class: "c-shedule-grid__header" }
const _hoisted_2 = { class: "c-shedule-grid__header-first-col" }
const _hoisted_3 = {
  class: "c-shedule-grid__header-cols-container",
  ref: "headerScrollRef"
}
const _hoisted_4 = { class: "c-shedule-grid__content" }
const _hoisted_5 = { class: "c-shedule-grid__time" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "c-shedule-grid",
    style: _normalizeStyle(_ctx.sheduleStyles)
  }, [
    _renderSlot(_ctx.$slots, "root-before"),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _renderSlot(_ctx.$slots, "header-first-cell")
      ]),
      _createElementVNode("div", _hoisted_3, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cols, (col, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "c-shedule-grid__header-col",
            key: i
          }, [
            _renderSlot(_ctx.$slots, "header-cell", _normalizeProps(_guardReactiveProps({ col })), () => [
              _createTextVNode(_toDisplayString(col), 1)
            ])
          ]))
        }), 128))
      ], 512)
    ]),
    _createElementVNode("div", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _renderSlot(_ctx.$slots, "time-before"),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.rows, (row, i) => {
          return (_openBlock(), _createElementBlock("div", {
            class: "c-shedule-grid__time-row",
            key: i
          }, [
            _renderSlot(_ctx.$slots, "time-row", _normalizeProps(_guardReactiveProps({ row })), () => [
              _createTextVNode(_toDisplayString(row), 1)
            ])
          ]))
        }), 128))
      ]),
      _createElementVNode("div", {
        class: "c-shedule-grid__grid",
        onScroll: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onGridScroll && _ctx.onGridScroll(...args))),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('click-grid', $event)))
      }, [
        _renderSlot(_ctx.$slots, "default")
      ], 32),
      _renderSlot(_ctx.$slots, "content-after")
    ])
  ], 4))
}