/**
 * NOTE: На бэкенде происходит работа с сущьностью CarVisit,
 * но функционально она используется и для визита в магазин,
 * в данном проекте принято решение назвать просто Visit 
 * дабы не вводить в заблуждение
 */

import { BaseRepository, AsyncResponse} from './BaseRepository'
import { BaseSuccessResponse, NoContent } from './Models/Base'
import { serialize as formDataSerialize } from 'object-to-formdata'
import {
  CarVisitCollectionQuery, CarVisitCollectionResponse,
  CarVisitResponse, CarVisitCountersResponse,
  CarVisitProcessingResponse, CarVisitCheckupEnum,
  CarVisitBodyRequest, VisitArchiveQuery,
} from './Models/CarVisit'
import assertBodyStatus from './util/assertBodyStatus';

export class VisitRepository extends BaseRepository {
  /**
   * Получить список визитов в автосервис
   *
   * @param params 
   * @returns 
   */
  getCarVisitCollection(params: CarVisitCollectionQuery = {}): AsyncResponse<CarVisitCollectionResponse> {
    return this.client.get(`car-visits`, { params });
  }

  /**
   * Получить полную информацию о визите
   * 
   * @param id идентификатор визита
   * @returns 
   */
  getCarVisit(id: string): AsyncResponse<CarVisitResponse> {
    return this.client.get(`car-visits/${id}`);
  }

  /**
   * Получить список визитов в магазин
   * 
   * @param params 
   * @returns 
   */
  getShopVisitCollection(params: CarVisitCollectionQuery = {}): AsyncResponse<CarVisitCollectionResponse> {
    return this.client.get('shop-visits', { params });
  }

  /**
   * Получить полную информацию о визите в магазин
   * 
   * @param id идентификатор визита в магазин
   * @returns 
   */
  getShopVisit(id: string): AsyncResponse<CarVisitResponse> {
    return this.client.get(`shop-visits/${id}`);
  }

  /**
   * Поиск визитов по архиву
   * 
   * @param params 
   * @returns 
   */
  getArchive(params: VisitArchiveQuery = {}): AsyncResponse<CarVisitCollectionResponse> {
    return this.client.get('archive/search', { params });
  }

  /**
   * Получить подробную информацию о визите который находится в процессе
   * 
   * NOTE: Не до конца понятно, для чего данный запрос, если getCarVisit,
   * возвращает тоже самое, только с большем количеством информации
   * 
   * @param id идентификатор визита
   * @returns 
   */
  getCarVisitProcessing(id: string): AsyncResponse<CarVisitProcessingResponse> {
    return this.client.get(`car-visits/${id}/processing`);
  }

  /**
   * Краткая информация о количестве текущих визитов
   * 
   * @returns 
   */
  getCounters(): AsyncResponse<CarVisitCountersResponse> {
    return this.client.get('car-visit/counters');
  }

  /**
   * Объединит несколько визитов
   * 
   * @param target ID визита с которым происходит объединение
   * @param list Массив ID визитов, которые будут объединятся
   * @returns 
   */
  merge(target: string, list: string[]): AsyncResponse<BaseSuccessResponse> {
    return this.client.post('car-visit/merge', {
      target,
      list: list.join(',')
    });
  }

  /**
   * Загрузить одну или несколько фотографий
   * 
   * NOTE: Данный метод не может работает в оффлайн режиме
   * 
   * @param id идентификатор визита
   * @param photos массив с загружаемыми файлами
   * @returns 
   */
  addPhoto(id: string, photos: Array<File|Blob>): AsyncResponse<BaseSuccessResponse> {
    let data: Record<string, File|Blob> = {};
    photos.forEach((photo, i) => data[`photo_${i}`] = photo);

    const body = formDataSerialize(data);

    return this.client.post(`car-visits/${id}/add-photo`, body, {
      headers: { 'Content-Type': 'multipart/form-data' }
    });
  }

  /**
   * Вернуть в работу
   * 
   * @param id идентификатор визита
   * @returns 
   */
  rollback(id: string): AsyncResponse<BaseSuccessResponse> {
    return this.client.post(`car-visits/${id}/rollback`)
  }

  /**
   * Удаление визита из системы
   * 
   * @param id идентификатор визита
   * @returns 
   */
  removeHard(id: string): AsyncResponse<NoContent> {
    return this.client.delete(`car-visits/${id}`);
  }

  /**
   * Отклонить визит
   * 
   * @param id идентификатор визита
   * @returns 
   */
  rejected(id: string): AsyncResponse<CarVisitResponse> {
    return this.client.put(`car-visits/${id}`, {
      checkup: CarVisitCheckupEnum.Rejected
    });
  }

  /**
   * Создание нового визита 
   * 
   * NOTE: Если автомобиль еще не создан, то перед созданием
   * визита, необходимо добавить автомобиль
   * 
   * @param body данные для создания
   */
  create(body: CarVisitBodyRequest): AsyncResponse<CarVisitResponse> {
    return this.client.post('car-visits', body);
  }

  /**
   * Обновление информации о визите
   * 
   * @param id идентификатор визита
   * @param body обновляемая информация
   * @returns 
   */
  update(id: string, body: CarVisitBodyRequest): AsyncResponse<CarVisitResponse> {
    return this.client.put(`car-visits/${id}`, {
      ...body,
      id, // fix: По непонятной причине он трубует идентификатор еще и в теле
    });
  }

  /**
   * Пометить визит, как завершенный
   * 
   * @param id идентификатор визита
   * @returns 
   */
  finish(id: string): AsyncResponse<BaseSuccessResponse> {
    return this.client.post(`car-visits/${id}/finish`, {});
  }

  /**
   * Выставить счет
   * 
   * @param id идентификатор визита
   * @returns 
   */
  invoice(id: string): AsyncResponse<BaseSuccessResponse> {
    return assertBodyStatus(
      this.client.post(`car-visits/${id}/invoice`, {}),
      'Ошибка при отправке пречека'
    );
  }

  /**
   * Выставить фискальный счет
   * 
   * @param id идентификатор визита
   * @returns 
   */
  invoiceFiscal(id: string): AsyncResponse<BaseSuccessResponse> {
    return assertBodyStatus(
      this.client.post(`car-visits/${id}/invoice/fiscal`, {}),
      'Ошибка при выставлении фискального счета'
    );
  }
}
