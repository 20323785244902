import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, vShow as _vShow, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "visit-store-products-view-total" }
const _hoisted_2 = { class: "visit-store-products-view-total__price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_chip = _resolveComponent("ion-chip")!
  const _component_cc = _resolveComponent("cc")!
  const _component_services_provided_view_card = _resolveComponent("services-provided-view-card")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!

  return _withDirectives((_openBlock(), _createBlock(_component_c_content_loading, { action: _ctx.loadServices }, {
    default: _withCtx(() => [
      _createVNode(_component_services_provided_view_card, {
        "provided-services": _ctx.providedServices,
        total: _ctx.total,
        "short-group-name": true
      }, {
        "total-price": _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            (_ctx.discountPercent)
              ? (_openBlock(), _createBlock(_component_ion_chip, {
                  key: 0,
                  color: "success",
                  class: "chip-size-small chip-filled"
                }, {
                  default: _withCtx(() => [
                    _createTextVNode(" -" + _toDisplayString(_ctx.discountPercent) + "% ", 1)
                  ]),
                  _: 1
                }))
              : _createCommentVNode("", true),
            _createElementVNode("span", _hoisted_2, [
              _createVNode(_component_cc, { price: _ctx.total }, null, 8, ["price"])
            ])
          ])
        ]),
        _: 1
      }, 8, ["provided-services", "total"])
    ]),
    _: 1
  }, 8, ["action"])), [
    [_vShow, _ctx.hasProvidedServices]
  ])
}