/**
 * NOTE: В приложении не исользуется форматирование валют от vue-i18n (Intl.NumberFormat),
 * т.к. требуется особое ворматирование валют, которое нельзя реализовать через данные функции.
 * 
 * Используется собственный алгоритм форматирования, который целые суммы отображает без копеек
 * 1000 => "10 000 Unit", а дробные с округлением до 2-х символов:
 * 1000.1 => "10 000.00 Unit"
 * 123.4567 => "123.46 Unit"
 */

import { preparePrice, PriceFormatOptions as BasePriceFormatOptions } from '@/utils/price';
import { useStore } from './useApp';
import { computed, Ref } from 'vue';

export interface PriceFormatOptions extends BasePriceFormatOptions {
  currencyDisplay?: 'symbol'|'name';
} 

/**
 * Предназначен для работы с основной валютой в приложении
 */
export function useCurrency() {
  const store = useStore();

  const currencyConfig = computed(() => store.config.cidConfigPrepared.value.currencyMessages);

  function formatCurrency(price: any, options: PriceFormatOptions = {}) {
    return preparePrice(price, {
      currency: (options.currencyDisplay === 'name')
        ? currencyConfig.value.name
        : currencyConfig.value.symbol,
      currencyDisplayBefore: currencyConfig.value.displayBefore,
      ...options,
    });
  }

  function createComputedCurrency(price: Ref<any>, options: PriceFormatOptions = {}) {
    return computed(() => formatCurrency(price.value, options));
  }

  return {
    currencyConfig,
    createComputedCurrency,
    ccc: createComputedCurrency,
    formatCurrency,
    fc: formatCurrency,
  };
}