<template>
  <div
    :class="{
      'c-input-price': true,
    }"
  >
    <div class="c-input-price__input-wrap">
      <input
        ref="input"
        class="c-input-price__input"
        type="text"
        v-model.trim="price"
        v-bind="$attrs"
        inputmode="numeric"
        @blur="onBlur"
        @focus="onFocus"
        @input="onInput"
      />

      <div class="c-input-price__fake-input" v-if="isViewValute">
        <span class="c-input-price__fake-hide-text">{{ price }}</span>
        <span v-show="price !== ''">&nbsp;{{ currencyUnit }}</span>
      </div>

      <button class="c-input-price__backspace-btn" @click="backspaceAct">
        <ion-icon name="backspace-outline" />
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue'

export default defineComponent({
  inheritAttrs: false,

  props: {
    modelValue: {
      type: [String, Number] as PropType<string|number>,
      default: 0,
    },
    currencyUnit: {
      type: String as PropType<string>,
      default: '',
    },
  },

  setup(props, { emit }) {
    const isViewValute = computed(() => !!props.currencyUnit);
    const price = ref(props.modelValue || '0');
    const input = ref<HTMLInputElement|null>(null);

    watch(() => props.modelValue, value => {
      if (price.value != value) {
        const inputValue = String(value);
        const preparedValue = prepareValue(inputValue);

        price.value = preparedValue;
        
        if (inputValue !== preparedValue) {
          emit('update:modelValue', preparedValue);
        }
      }
    });

    /** Обработает значение и оставит только допустимые символы */
    function prepareValue(priceValue: string) {
      return priceValue
        .replace(',', '.')
        .replace(/[^\d.]/g, '')
        .replace(/^0?(\d+\.?\d{0,2}).*$/, '$1');
    }

    function backspaceAct(event: Event) {
      event.preventDefault();
      input.value?.focus();

      const currentValue = String(price.value);
      if (currentValue.length > 0) {
        price.value = currentValue.substr(0, currentValue.length - 1);
        emit('update:modelValue', price.value);
      }
    }

    function onBlur() {
      if (!price.value) {
        price.value = 0;
      }
    }

    function onFocus() {
      if (price.value == '0') {
        price.value = '';
      }
    }

    function onInput() {
      const currentValue = String(price.value);
      const preparedValue = prepareValue(currentValue);

      if (currentValue !== preparedValue) {
        price.value = preparedValue;
      }

      emit('update:modelValue', preparedValue);
    }

    return {
      backspaceAct,
      isViewValute,
      price,
      onBlur,
      onFocus,
      onInput,
      input,
    };
  }
});
</script>

<style lang="scss">
@import "@/scss/abstract";

.c-input-price {
  @include core-biginput-component;

  &__fake-input {
    @include base-core-biginput;
    padding-right: 0;
    pointer-events: none;
    touch-action: none;
    position: absolute;
    top: 0;
    left: 0;
    right: 46px;
    bottom: 0;
    height: 100%;
    box-sizing: border-box;
    overflow: hidden;
    white-space: nowrap;
    color: inherit;
    z-index: 1;
  }

  &__fake-hide-text {
    color: rgba(255, 255, 255, 0);
  }
}
</style>