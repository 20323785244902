import axios from "axios"
import { get, has } from "lodash"
import Compressor from "compressorjs"
import uuid from 'uuid'

export type URLObject = typeof window.URL;

export const URLObject = getURLObject();
/**
 * Вернет объект URL, для работы с ресурсами
 */
export function getURLObject(): URLObject|undefined {
  if (window.URL && has(window.URL, 'createObjectURL')) {
    return window.URL;
  }

  if (window.webkitURL && has(window.webkitURL, 'createObjectURL')) {
    return window.webkitURL;
  }

  return undefined;
}

/**
 * Загрузит изобраение и вернет Blob объект
 * 
 * @param fullUrl Полная ссылка на изображение
 * @returns 
 */
export async function downloadImage(fullUrl: string): Promise<Blob> {
  const response = await axios.get(fullUrl, {
    responseType: 'blob'
  });

  return response.data;
}

export type CompressImageOptions = Omit<Compressor.Options, "error"|"success">;

/**
 * Компрессия изображения
 * 
 * @param image входное изображение
 * @param options параметры обработки и сжатия
 * @returns сжатый файл
 */
export function compressImage(image: Blob, options: CompressImageOptions = {}): Promise<Blob> {
  return new Promise((resolve, reject) => {
    new Compressor(image, {
      checkOrientation: false,
      mimeType: 'image/jpeg',
      ...options,
      success: resolve,
      error: reject,
    });
  });
}

/**
 * Ввернет ссылку на изображение (object url)
 * 
 * NOTE: Лучше не использовать, т.к. при использовании требуется
 * освобождать ресурс с помощью URL.revokeObjectURL(url)
 * 
 * @param image 
 * @returns 
 */
export function blobToObjectURL(image: Blob): string|undefined {
  return URLObject?.createObjectURL(image);
}

/**
 * Преобразует Blob изображения в base64
 * 
 * @param image изображение
 * @returns 
 */
export function blobToImageBase64(image: Blob): Promise<string|undefined> {
  const fr = new FileReader();
  fr.readAsDataURL(image);

  return new Promise((resolve, reject) => {
    fr.onload = () => resolve(fr.result?.toString());
    fr.onerror = reject;
  });
}

/**
 * Перекодирует изображение из base64 в объект типа File
 * 
 * @param imageBase64 
 * @param filename 
 * @returns 
 */
export function base64ToFileObject(imageBase64: string, filename?: string): File|undefined {
  const data = base64ToArrayBufferData(imageBase64);

  if (!filename) {
    const ext = getExtensionFromMimiType(data.mimeType);
    filename = uuid.v1() + '.' + ext;
  }

  return new File([data.u8arr], filename, { type: data.mimeType });
}

/**
 * Получает расширение из MIME
 * @param mimeType 
 * @returns 
 */
export function getExtensionFromMimiType(mimeType: string) {
  return get(mimeType.match(/image\/([A-Za-z\d]+)/), '1', 'data');
}

/**
 * Перекодирует изображение из base64 в объект типа Blob
 * 
 * @param imageBase64
 * @returns 
 */
export function base64ToBlob(imageBase64: string): Blob|undefined {
  const data = base64ToArrayBufferData(imageBase64);

  return data
    ? new Blob([data.u8arr], { type: data.mimeType })
    : undefined;
}

export function base64ToArrayBufferData(imageBase64: string) {
  const chunks = imageBase64.split(',');

  if (chunks.length !== 2) undefined;

  const mimeType: string = get(chunks[0].match(/:(.*?);/), '1', 'image/png');
  const binaryString = atob(chunks[1]);

  let n = binaryString.length;
  const u8arr = new Uint8Array(n);
      
  while(n--) {
    u8arr[n] = binaryString.charCodeAt(n);
  }

  return {
    u8arr,
    mimeType,
  };
}

export interface PrepareImageOptions {
  /** @default 400 */
  maxWidth?: number;
  /** @default 400 */
  maxHeight?: number;
  /** @default 0.6 */
  quality?: number;
}

/**
 * Загружает, сжимает, и преобразует изображение в base64
 * 
 * @param fullUrl полная ссылка на изображение
 * @param options дополнительные параметры
 * @param throwException нужноли выбрасывать исключения в случае ошибки (по умолчанию нет - тихий режим)
 * @returns 
 */
export async function getCompressedImageBase64(
  fullUrl: string,
  options: PrepareImageOptions = {},
  throwException = false
): Promise<string|undefined> {
  options = {
    maxWidth: 400,
    maxHeight: 400,
    quality: 0.6,
    ...options
  };

  try {
    const originalImage = await downloadImage(fullUrl);
    const compressedImage = await compressImage(originalImage, options);
    const imageBase64 = await blobToImageBase64(compressedImage);

    if (!imageBase64) {
      throw new Error('Не удалось преобразовать изображение в base64');
    }

    return imageBase64;
  } catch (e) {
    if (throwException) {
      throw e;
    }

    return undefined;
  }
}