<template>
  <ion-card>
    <teleport :to="searchbarContainer" :disabled="searchbarTeleportDisabled">
      <div class="core-searchbar-mobile ion-padding">
        <ion-searchbar
          class="core-searchbar"
          inputmode="search"
          :placeholder="$t('components.services_view.input_search')"
          clear-icon="close-outline"
          search-icon="core-search"
          :debounce="300"
          v-model="searchWords"
        />
      </div>
    </teleport>
    
    <div class="padding-x-adaptive">
      <!-- 
        На данный момент дизайн приложения подразумивает
        отображение всего одного элемента группы, но
        в будущем планы могут измениться
      -->
      <services-view-category-group
        v-if="catId"
        :cat-id="catId"
        :search-words="searchWords"
      />

      <div v-else class="ion-text-center ion-padding">
        {{ $t('components.services_view.text_category_empty_selected') }}
      </div>
    </div>
  </ion-card>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue';
import ServicesViewCategoryGroup from '@/components/service/price-view/ServicesViewCategoryGroup.vue';
import { isTabletDown } from '@/helpers/adaptive';

export default defineComponent({
  components: { ServicesViewCategoryGroup },
  emits: ['input-search'],

  props: {
    catId: {
      type: [String, Number] as PropType<string|number>,
      required: false,
    },
    mobileSearchbarContainer: {
      type: Object as PropType<HTMLDivElement|null>,
      required: false,
    }
  },

  setup(props, { emit }) {
    const searchWords = ref('');
    watch(searchWords, text => emit('input-search', text));

    const searchbarTeleportDisabled = computed(() => !(isTabletDown.value && props.mobileSearchbarContainer));
    const searchbarContainer = computed(() => searchbarTeleportDisabled.value ? null : props.mobileSearchbarContainer);

    return {
      searchWords,
      searchbarContainer,
      searchbarTeleportDisabled,
    };
  }
});
</script>

<style lang="scss">
</style>