import { loadingController } from '@ionic/vue';
import { Ref } from '@vue/reactivity';
import toast from '../composables/toast';

export type SpinnerTypes = "bubbles" | "circles" | "circular" | "crescent" | "dots" | "lines" | "lines-small";

export interface AwaitLoadingOptions {
  spinner?: SpinnerTypes | null;
  message?: string;
  cssClass?: string | string[];
  showBackdrop?: boolean;
  duration?: number;
  translucent?: boolean;
  animated?: boolean;
  backdropDismiss?: boolean;
  mode?: "ios" | "md";
  keyboardClose?: boolean;
  id?: string;

  /** Ссылка на значение в которую заносится текущее состояние загрузки */
  loadingStatusRef?: Ref<boolean>;
  showToastError?: boolean;
}

export const AWAIT_LOADING_DEFAULT_OPTIONS: AwaitLoadingOptions = {
  message: 'Подождите...',
  showToastError: true,
};

/**
 * Отображает полноэкранный индикатор загрузки,
 * во время выполнения асинхронной функции
 * 
 * @throws {Error|ClientError}
 * 
 * @param handler обработчик
 * @param options дополнительные параметры
 * @returns значение возвращаемое обработчиком
 */
export async function awaitLoading<T = any>(handler: () => Promise<T>, options: AwaitLoadingOptions = {}): Promise<T> {
  options = { ...AWAIT_LOADING_DEFAULT_OPTIONS, ...options };
  let loadingUpdate = await loadingController.create(options);

  loadingUpdate.present(); // async
  let data: T|null = null;

  if (options.loadingStatusRef) {
    options.loadingStatusRef.value = true;
  }
  
  try {
    data = await handler();
  } catch(e) {
    if (options.showToastError) {
      toast.error(e);
    }
    
    throw e;
  } finally {
    loadingUpdate.dismiss(); // async

    if (options.loadingStatusRef) {
      options.loadingStatusRef.value = false;
    }
  }

  return data;
}