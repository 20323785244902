import { alertController } from '@ionic/vue';
import { useI18n } from 'vue-i18n';

export function useAlert() {
  const { t } = useI18n();

  /** @private создать функцию действия с окном подтверждения */
  function createAlertConfirm(options: {
    header?: string;
    message?: string;
    cancelBtnText?: string;
    confirmBtnText?: string;
    confirm: () => Promise<void>;
    dismissWait?: boolean;
  }) {
    return async () => {
      const alert = await alertController.create({
        header: options.header,
        message: options.message,
        buttons: [
          {
            text: options.cancelBtnText || t('composables.alert.confirm_default_btn_cancel'),
            role: 'cancel'
          },
          {
            text: options.confirmBtnText || t('composables.alert.confirm_default_btn_confirm'),
            async handler() {
              const dismissWait = options.dismissWait === true;

              if (false === dismissWait) {
                alert.dismiss(); // async
              }

              await options.confirm();

              if (true === dismissWait) {
                alert.dismiss();
              }
            }
          }
        ]
      });

      alert.present(); // async
      return alert;
    };
  }

  return {
    createAlertConfirm,
  };
}