<template>
  <ion-page>
    <app-header :title="title">
      <template #end>
        <c-transparent-button @click="close">
          <ion-icon name="close-outline" class="icon-medium" />
        </c-transparent-button>
      </template>
    </app-header>

    <ion-content>
      <div v-if="loadingDocument">
        <c-spinner class="mt-4 mb-3" :loading-text="$t('views.report_modal.loading_document')" />
      </div>
      <iframe
        v-else-if="pdfObjectUrl"
        class="report-modal-iframe"
        :src="viewerFullUrl"
        frameborder="0" 
      />
      <div v-else class="text-muted ion-text-center ion-padding">
        {{ $t('views.report_modal.document_undefined') }}
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent, onBeforeUnmount, onMounted, ref } from 'vue'
import { useApp } from '@/composables/useApp'
import { useToast } from '@/composables/toast'
import { URLObject } from '@/helpers/image'
import { modalController } from '@ionic/core'

const VIEWER_URI = (process.env.BASE_URL || '/') + 'pdfjs/web/viewer.html'

export default defineComponent({
  props: {
    title: {
      type: String,
    },
    url: {
      type: String,
      required: true
    }
  },
  setup(props) {
    const toast = useToast();
    const { http } = useApp();

    const loadingDocument = ref(false);
    const pdfObjectUrl = ref<string|null>(null);
    const viewerFullUrl = computed(() => {
      const fileEncodeParam = pdfObjectUrl.value ? encodeURIComponent(pdfObjectUrl.value) : '';
      return `${VIEWER_URI}?file=${fileEncodeParam}`;
    });

    async function loadPdf() {
      revokePdf();
      loadingDocument.value = true;

      try {
        const res = await http.get(props.url, {
          responseType: 'blob',
        });

        const pdfBlob = res.request.response as Blob|undefined;
        if (!(pdfBlob instanceof Blob)) throw new Error('Invalid response format');

        pdfObjectUrl.value = URLObject?.createObjectURL(pdfBlob) || null;
      } catch (e) {
        toast.error(e);
      } finally {
        loadingDocument.value = false;
      }
    }

    function revokePdf() {
      if (pdfObjectUrl.value) {
        URLObject?.revokeObjectURL(pdfObjectUrl.value);
        pdfObjectUrl.value = null;
      }
    }

    function close() {
      modalController.dismiss();
    }

    onMounted(() => loadPdf());
    onBeforeUnmount(() => revokePdf());

    return {
      close,
      pdfObjectUrl,
      loadingDocument,
      viewerFullUrl,
    };
  }
});
</script>

<style lang="scss">
.report-modal-iframe {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
}
</style>