import { NoContent } from './Models/Base'
import { BaseRepository, AsyncResponse} from './BaseRepository'
import { serialize as formDataSerialize } from 'object-to-formdata'
import {
  CarCollectionResponse, CarResponse,
  CarCollectionQuery, CarBodyRequest,
  CarHistoryResponse, CarServicesPopularResponse,
  RecognizeNumberResponse,
} from './Models/Car'
import { isString } from 'lodash';
import { createError } from './Clients/AxiosClient';

export class CarRepository extends BaseRepository {
  /**
   * Получить список автомобилей
   * 
   * @param params 
   * @returns 
   */
  getCollection(params: CarCollectionQuery = {}): AsyncResponse<CarCollectionResponse> {
    return this.client.get('cars', { params });
  }

  /**
   * Получение информации о конкретном автомобиле
   * 
   * @param id идентификатор автомобиля
   * @returns 
   */
  getCar(id: string): AsyncResponse<CarResponse> {
    return this.client.get(`cars/${id}`);
  }

  /**
   * Количество обращений
   * 
   * @param id идентификатор автомобиля
   * @returns 
   */
  getHistory(id: string): AsyncResponse<CarHistoryResponse>  {
    return this.client.get(`cars/${id}/history`);
  }

  /**
   * Популярные услуги для автомобиля
   * 
   * @param id идентификатор автомобиля
   * @returns 
   */
  getPopularServices(id: string): AsyncResponse<CarServicesPopularResponse> {
    // car/ за место cars/
    return this.client.get(`car/${id}/services/popular`)
  }

  /**
   * Добавление нового автомобиля
   * 
   * @param body информация о новом автомобиле
   * @returns 
   */
  create(body: CarBodyRequest): AsyncResponse<CarResponse> {
    return this.client.post('cars', body);
  }

  /**
   * Обновление информации об автомобиле
   * 
   * @param id идентификатор автомобиля
   * @param body обновляемые значения
   * @returns 
   */
  update(id: string, body: CarBodyRequest): AsyncResponse<CarResponse> {
    return this.client.put(`cars/${id}`, body);
  }

  /**
   * Удаление автомобиля
   * @param id идентификатор автомобиля
   * @returns 
   */
  remove(id: string): AsyncResponse<NoContent> {
    return this.client.delete(`cars/${id}`);
  }

  /**
   * Запрос на распознавания номера
   * 
   * @param data фото автомобиля, где видно его номер
   * @returns 
   */
  async recognizeNumber(data: File|Blob|FormData): AsyncResponse<RecognizeNumberResponse> {
    const body = (data instanceof FormData) ? data : formDataSerialize({ file: data });
    const baseURL = String(this.client.defaults.baseURL).replace(/api\/?$/, '');

    const res = await this.client.post('recognize', body, {
      baseURL,
      headers: { 'Content-Type': 'multipart/form-data' }
    });

    if (isString(res.data)) {
      if (/^\s*<!doctype/i.test(res.data)) { // HTML страница с формой
        throw createError(res, 'Сервер вернул неверный формат ответа (HTML)');
      }

      if (res.data.length < 200) { // Скореевсего текст ошибки
        throw createError(res, res.data);
      }

      throw createError(res, 'Сервер вернул неверный формат ответа');
    }

    return res;
  }
}
