import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_big = _resolveComponent("app-header-big")!
  const _component_c_nav_cards_list_item = _resolveComponent("c-nav-cards-list-item")!
  const _component_c_nav_cards_list = _resolveComponent("c-nav-cards-list")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header_big, {
        title: _ctx.$t('views.pricelist.title')
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_c_content_loading, { action: _ctx.loadPointTypes }, {
            default: _withCtx(() => [
              _createVNode(_component_c_nav_cards_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pointTypes, (pointType) => {
                    return (_openBlock(), _createBlock(_component_c_nav_cards_list_item, {
                      key: pointType.id,
                      onClick: ($event: any) => (_ctx.selectPointType(pointType))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(pointType.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          }, 8, ["action"])
        ]),
        _: 1
      }),
      _createVNode(_component_app_footer)
    ]),
    _: 1
  }))
}