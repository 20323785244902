<script lang="ts" setup>
import { ref, watch, defineEmits, defineProps } from 'vue';
import CollapseTransition from './CollapseTransition.vue';

const props = defineProps({
  expand: {
    type: Boolean,
    default: true,
  },
  title: {
    type: String,
    default: 'Развернуть область'
  },
});

const emit = defineEmits<{
  (e: 'update:expand', expand: boolean): void;
}>();

const expanded = ref(props.expand);
watch(() => props.expand, v => expanded.value = v);

function setExpand(val: boolean) {
  expanded.value = val;
  emit('update:expand', val);
}

const toggle = () => setExpand(!expanded.value);
const toExpand = () => setExpand(true);
const squeeze = () => setExpand(false);
</script>

<template>
  <div :class="{
    'c-spoiler': true,
    'c-spoiler--expanded': expanded,
  }">
    <slot name="head" v-bind="{
      expanded,
      toggle,
      setExpand,
      toExpand,
      squeeze,
    }">
      <div class="c-spoiler__head" @click="toggle">
        <div class="c-spoiler__head-arrow-wrap">
          <ion-icon name="core-collapse-arrow" />
        </div>

        {{ title }}
      </div>
    </slot>
    
    <collapse-transition>
      <!-- 
        NOTE: Убрал v-if т.к. при изменении услуг необходимо,
        чтобы стоимость во вложенных пунктах пересчитывалась,
        т.е. они должны инициализироваться сразу после создания спойлера.

        TODO: В будущем лучше вернуть v-if
      -->
      <div class="c-spoiler__content" v-show="expanded">
        <slot v-bind="{
          expanded,
          toggle,
          setExpand,
          toExpand,
          squeeze,
        }"></slot>
      </div>
    </collapse-transition>
  </div>
</template>

<style lang="scss">
@import '@/scss/abstract';

.c-spoiler {
  &__head {
    @extend %card-head-title;
    padding-left: 22px;
    border-bottom: $border-divider;
    position: relative;
    user-select: none;
    cursor: pointer;
  }

  &__head-arrow-wrap {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    left: 0;
    bottom: 0;
    font-size: 13px;

    ion-icon {
      transition: transform 0.25s ease;
      transform: rotate(-90deg);
      opacity: 0.35;
    }

    .c-spoiler--expanded & {
      ion-icon {
        transform: rotate(0deg);
      }
    }
  }
}
</style>