<script lang="ts" setup>
import { computed, defineProps, defineEmits, ref } from 'vue';
import { isString } from 'lodash';
import { errorToMessageData } from '@/helpers/error';
import { useI18n } from 'vue-i18n';
import errorMessageIcon from '@/icons/error-message-icon.svg';

const emit = defineEmits<{
  (e: 'reload'): void;
}>();

const props = defineProps({
  title: {
    type: String,
    default: ''
  },
  /**
   * Может быть string, объектом MessageData
   */
  message: {
    type: null,
  },
  hasButton: {
    type: Boolean,
    default: false,
  },
  reloadButtonText: {
    type: String,
    required: false,
  },
  valign: {
    type: Boolean,
    default: true,
  },
});

const { t } = useI18n();

/**
 * Внутренний заголовок, будет задействован,
 * если в message передали объект MessageData.
 */
const innerTitle = ref<string>('');

const errorTitle = computed<string>(() => props.title || innerTitle.value);
const errorTextMessage = computed<string>(() => {
  if (isString(props.message)) {
    return props.message;
  }

  // fix: Если передали сложный объект
  const { message, title } = errorToMessageData(props.message);
  innerTitle.value = title || '';

  return message;
});

const isMessageTypeString = computed<boolean>(() => {
  return typeof props.message === 'string'
    || isString(props.message?.message) // fix
  ;
});

function onClinkReload() {
  emit('reload');
}
</script>

<template>
  <div :class="{
    'с-error-block': true,
    'с-error-block--valign': valign,
  }">
    <div class="с-error-block__message" :class="{ 'с-error-block__message--string': isMessageTypeString }">
      <slot v-bind="{
        isMessageTypeString,
        errorTextMessage,
        errorTitle,
      }">
        <div class="с-error-block__message-header">
          <ion-icon :src="errorMessageIcon" />

          <div
            v-if="errorTitle"
            v-html="errorTitle"
            class="с-error-block__message-title"
          ></div>
        </div>

        <div
          class="с-error-block__message-html"
          v-html="errorTextMessage"
        ></div>
      </slot>
    </div>

    <div class="с-error-block__actions">
      <slot name="actions.before"></slot>
      
      <slot name="actions" v-bind="{
        errorTextMessage,
        reloadButtonText: props.reloadButtonText,
      }">
        <ion-button
          @click="onClinkReload"
          color="primary"
          expand="block"
          shape="round"
        >
          {{ props.reloadButtonText || t('components.error_block.btn_reload') }}
        </ion-button>
      </slot>

      <slot name="actions.after"></slot>
    </div>
  </div>
</template>

<style lang="scss">
.с-error-block {
  padding: var(--ion-padding);

  &--valign {
    // CRUTCH: FIXME:
    padding-top: calc(40vh - 160px);
  }

  &__message {
    color: var(--core-text-muted);
    padding: 0 0 var(--ion-padding);
    font-size: var(--core-font-size-medium);

    &--string {
      text-align: center;
    }

    p {
      margin: 0 0 5px;
      padding: 0;
    }

    ul {
      margin: 0;
      padding: 0 0 0 20px;
    }

    li {
      margin-bottom: 2px;
    }

    pre {
      padding: 8px;
      margin: 0;
      border-radius: var(--core-card-radius, 0);
      background-color: rgba(var(--ion-color-danger-rgb), 0.1);
      font-size: 0.85rem;
      line-height: 1.2em;
      max-width: 100%;
      max-height: 300px;
      overflow: auto;
    }
  }

  &__actions {
    text-align: center;
  }

  &__message-header {
    text-align: center;
    padding-bottom: var(--ion-padding);

    ion-icon {
      font-size: 40px;
    }
  }

  &__message-title {
    font-weight: bold;
    font-size: 1.4rem;
    color: var(--core-text);
    padding-top: 4px;
  }
}
</style>

