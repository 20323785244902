<template>
  <ion-page>
    <app-header :title="pageTitle" />

    <ion-content>
      <tablet-side-layout>
        <template #side>
          <div class="ion-padding-horizontal ion-padding-top" v-if="carId">
            <c-card-header>
              {{ $t('views.visits_car_history.popular_services_card_title') }}
            </c-card-header>
            <services-popular-card :car-id="carId" />
          </div>
        </template>

        <c-card-header class="ion-padding-top ion-padding-horizontal">
          {{ $t('views.visits_car_history.section_visits_title') }}
        </c-card-header>
        
        <c-content-loading :action="loadVisitsHistory" v-slot="{ loading }">
          <div v-if="!loading && visits.length === 0" class="ion-text-center text-muted">
            {{ $t('views.visits_car_history.visits_not_found') }}
          </div>

          <visits-archive-groups-by-date
            :visits="visits"
            :col-sizes="{
              sizeXl: 4,
              sizeLg: 6,
              sizeSm: 12,
            }"
          />
        </c-content-loading>
      </tablet-side-layout>
    </ion-content>

    <app-footer />
  </ion-page>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'
import { toString } from 'lodash'
import { useStore } from '@/composables/useApp';
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit'
import VisitsArchiveGroupsByDate from '@/components/visit/VisitsArchiveGroupsByDate.vue'
import ServicesPopularCard from '@/components/service/ServicesPopularCard.vue'
import { useI18n } from 'vue-i18n';

export default defineComponent({
  components: {
    VisitsArchiveGroupsByDate,
    ServicesPopularCard,
  },
  setup() {
    const route = useRoute();
    const store = useStore();
    const { t } = useI18n();

    const carId = toString(route.params.carId);

    // carId - по сути номер автомобиля, поэтому его можно отображать в заголовке
    const pageTitle = computed(() => t('views.visits_car_history.title', { carNumber: carId }));

    const visits = ref<CarVisitCollectionItem[]>([]);
    async function loadVisitsHistory() {
      const { data } = await store.visit.getCarVisitCollection({
        limit: 80,
        car: carId,
      });

      visits.value = data.items;
    }

    return {
      visits,
      pageTitle,
      loadVisitsHistory,
      carId,
    };
  }
});
</script>

<style lang="scss">
</style>