<script lang="ts" setup>
import { defineProps, PropType, computed } from 'vue';
import { modalController } from '@ionic/vue';
import { ModalMessageButtonItem } from '@/composables/messages';
import { textLinkToHtmlLink, nl2br } from '@/utils/string';

const props = defineProps({
  header: {
    type: String,
    required: false,
  },
  message: {
    type: String,
    required: false,
  },
  closeButton: {
    type: Boolean,
    default: true,
  },
  buttons: {
    type: Array as PropType<ModalMessageButtonItem[]>,
    default: () => [],
  }
});

function close() {
  modalController.dismiss();
}

const preparedMessage = computed(() => textLinkToHtmlLink(nl2br(props.message || '')));

</script>

<template>
  <div class="c-modal-message-card">
    <ion-button fill="default" class="c-modal-message-card__close-button" @click="close">
      <ion-icon name="core-close"></ion-icon>
    </ion-button>

    <div class="c-modal-message-card__content">
      <div class="c-modal-message-card__header" v-if="props.header">
        {{ props.header }}
      </div>

      <div class="c-modal-message-card__message" v-if="props.message" v-html="preparedMessage"></div>
    </div>

    <div class="c-modal-message-buttons">
      <ion-item
        v-for="(button, index) in buttons"
        :key="index"
        button :detail="false" @click="button.handler"
        class="c-modal-message-buttons__button item-link-button ion-text-center item-no-detail-icon"
        :class="button.cssClass"
      >
        <ion-label>{{ button.text }}</ion-label>
      </ion-item>
    </div>
  </div>
</template>

<style lang="scss">
@import '@/scss/abstract';

.c-modal-message {
  --height: auto;
  --ion-item-background: transparent;
  --ion-item-border-color: rgba(160, 160, 160, 0.3);
  --ion-item-color: var(--ion-color-primary);

  align-items: flex-start !important;

  .modal-wrapper {
    --background: transparent;
    --max-width: 700px;

    height: auto;
    margin-top: var(--ion-safe-area-top, 0);

    @include ionic-tablet {
      --width: var(--max-width);
      --height: auto;
    }
  }

  .ion-page {
    contain: inherit;
    position: relative;
    top: auto;
    right: auto;
    left: auto;
    bottom: auto;
  }
}

.c-modal-message-card {
  margin: calc(var(--ion-margin) / 2) calc(var(--ion-margin) / 2) 0;
  background: var(--c-modal-message-background, var(--ion-color-light));
  color: var(--c-modal-message-color, var(--ion-color-light-contrast));
  border-radius: var(--core-messages-radius, 0);
  position: relative;

  @include ionic-tablet {
    margin-left: 0;
    margin-right: 0;
  }

  &__content {
    padding: var(--ion-padding) 0;
  }

  &__header {
    padding: 0 var(--ion-padding);
    font-size: var(--core-font-size-heading);
    font-weight: 600;
  }

  &__message {
    padding: 0 var(--ion-padding);
    margin-top: var(--ion-padding);
  }

  &__close-button {
    position: absolute;
    top: 0;
    right: 0;
    height: 2.5em;

    ion-icon {
      font-size: 12px;
    }
  }
}

.c-modal-message-buttons {
  border-top: var(--core-divider-width) solid var(--ion-item-border-color);
}
</style>