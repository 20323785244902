import { sleep } from '@/utils/wait';
import { Ref } from 'vue';

export interface RepeatRequestOptions {
  repeatCount?: number;
  timeout?: number;
  maxTime?: number;
  counter?: Ref<number>|null;
}

export type ReturnTypePromise<T extends (...args: any) => Promise<any>>
  = T extends (...args: any) => Promise<infer R> ? R : any;

const REPEAT_REQUEST_OPTIONS_DEFAULT: Required<RepeatRequestOptions> = {
  repeatCount: 3,  // 3 попытки
  timeout: 200,    // 200 мс.
  maxTime: 10_000, // 10 сек.
  counter: null,
};

/**
 * Повторяемый запрос, в случае возникновения ошибки
 * 
 * @param reqHandler 
 * @param options 
 * @returns 
 */
export async function repeatRequest<F extends () => Promise<any>>(
  reqHandler: F,
  options: RepeatRequestOptions = {}
): Promise<ReturnTypePromise<F>> {
  const o = { ...REPEAT_REQUEST_OPTIONS_DEFAULT, ...options };

  const startTime = Date.now();
  let error: any = null;

  if (o.counter) {
    o.counter.value = 0;
  }

  for (let counter = 1; counter <= o.repeatCount; ++counter) {
    if (Date.now() - startTime > o.maxTime) {
      break; // Время на запросы закончено
    }

    if (o.counter) {
      o.counter.value = counter;
    }

    if (counter > 1 && o.timeout > 0) {
      await sleep(o.timeout);
    }

    try {
      const response: ReturnTypePromise<F> = await reqHandler();
      error = null;

      return response;
    } catch(e: any) {
      error = e;
    }
  }

  if (!error) {
    error = new Error('Неизвестная ошибка');
  }

  throw error;
}
