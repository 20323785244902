<template>
  <ion-item-group>
    <ion-item class="color-default">
      <span v-html="categoryName"></span>
    </ion-item>

    <service-provide-view-item
      v-for="provideService in group.providedServices"
      :key="provideService.id"
      :provide-service="provideService"
    />
  </ion-item-group>
</template>

<script lang="ts">
import { useStore } from '@/composables/useApp'
import { CarVisitProvideService } from '@/repositories/Models/CarVisit'
import { defineComponent, PropType } from 'vue'
import ServiceProvideViewItem from './ServiceProvideViewItem.vue'

export interface ViewGroup {
  catId: number;
  providedServices: CarVisitProvideService[];
}

export default defineComponent({
  components: {
    ServiceProvideViewItem,
  },

  props: {
    group: {
      type: Object as PropType<ViewGroup>,
      required: true,
    },
    shortTitle: {
      type: Boolean,
      default: false,
    }
  },

  setup(props) {
    const store = useStore();

    const categoryName = store.point.getCategoryBreadcrumbsComputed(props.group.catId, props.shortTitle);

    return {
      categoryName,
    };
  }
});
</script>

<style lang="scss">
</style>