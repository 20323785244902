<template>
  <div class="c-visit-shortinfo">
    <div class="c-visit-shortinfo__inner-wrap">
      <div class="c-visit-shortinfo__image-col">
        <div
          class="c-visit-shortinfo__image"
          :style="{ backgroundImage: `url('${images}')` }"
        >
          <visit-labels class="visit-card-badge-container" :labels="badgeList" />
        </div>
      </div>

      <div class="c-visit-shortinfo__content">
        <div class="c-visit-shortinfo__header">
          <div class="c-visit-shortinfo__car-number">
            {{ formatCarNumber }}
          </div>

          <div size="auto" class="c-visit-shortinfo__total">
            <cc :price="totalPrice" />
          </div>
        </div>

        <div class="c-visit-shortinfo__footer">
          {{ progressText }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';
import { VisitBadgeItem } from '@/composables/visit';
import VisitLabels from '@/components/visit/VisitLabels.vue';

export default defineComponent({
  components: {
    VisitLabels,
  },
  props: {
    images: Array,
    totalPrice: [Number, String],
    progressText: String,
    formatCarNumber: String,
    badgeList: {
      type: Array as PropType<VisitBadgeItem[]>,
      default: () => [],
    }
  }
});
</script>

<style lang="scss">
@import '@/scss/abstract';

.c-visit-shortinfo {
  $padding-y: 9px;

  &__inner-wrap {
    display: flex;
    align-items: stretch;
    min-height: 100%;
    padding: $padding-y var(--ion-padding);
    box-sizing: border-box;
  }

  &__image-col {
    width: 33%;
    max-width: 115px;
  }

  &__image {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    border-radius: var(--core-button-border-radius);
    position: relative;
    background-color: rgba(255, 255, 255, 0.02);
  }

  &__content {
    padding-left: var(--ion-padding);
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
  }

  &__header {
    color: var(--ion-text-color);
    display: flex;
    padding-bottom: $padding-y;
  }

  &__car-number {
    flex: 1 1 0%;
  }

  &__footer {
    border-top: $border-divider;
    padding-top: $padding-y;
    color: var(--core-text-muted);
    font-size: 14px;
  }

  .visit-card-badge-container {
    padding: $padding-y;
  }
}
</style>
