<template>
  <ion-page>
    <app-header-new-visit
      :title="$t('views.visit_new_services_type.title')"
      :progress="0.5"
    />

    <ion-content>
      <c-content-loading :action="loadPointTypes">
        <div v-if="isTabletDown" class="ion-padding-horizontal ion-padding-top">
          <visit-preview-images
            class="ion-margin-bottom"
            :visit-id="visitId"
            :is-new-preentry="isPreentry"
          />
        </div>

        <c-nav-cards-list>
          <c-nav-cards-list-item
            v-for="pointType in pointTypes"
            :key="pointType.id"
            :class="{ '--success-color': lastTypeCategoryId == pointType.id }"
            @click="selectPointType(pointType)"
          >
            {{ pointType.name }}
          </c-nav-cards-list-item>
        </c-nav-cards-list>

        <div v-if="isTabletDown" class="ion-padding-horizontal ion-padding-bottom">
          <c-card-header>{{ $t('views.visit_new_services_type.client_card_info_title') }}</c-card-header>
          <client-info-card :car-id="carId" />
        </div>
      </c-content-loading>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, DeepReadonly } from 'vue';
import { useRouter } from 'vue-router';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import { ThisClientPointTypeItem } from '@/repositories/Models/Point';
import ClientInfoCard from '@/components/car/ClientInfoCard.vue';
import { isTabletDown } from '@/helpers/adaptive';
import { usePointTypes } from '@/composables/usePointTypes';
import { useCreateVisitStep } from '@/composables/visit';
import { useMetrica } from '@/composables/useMetrica';
import { pick } from 'lodash';
import VisitPreviewImages from '@/components/visit/VisitPreviewImages.vue';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    ClientInfoCard,
    VisitPreviewImages,
  },

  setup() {
    const router = useRouter();
    const { pointTypes, loadPointTypes } = usePointTypes();
    const { isPreentry, visitId, carId, visitType, visitState, prefixNamePage } = useCreateVisitStep();
    const { emitEvent } = useMetrica();

    function selectPointType(type: DeepReadonly<ThisClientPointTypeItem>) {
      emitEvent(`visit/${visitType}/category-type`, pick(type, ['id', 'name', 'style']));

      router.push({
        name: prefixNamePage('service-cat'),
        params: {
          typeId: type.id,
          visitId
        },
        query: {
          title: type.name,
        }
      });
    }

    const lastTypeCategoryId = visitState.meta.lastTypeCategoryId;

    return {
      loadPointTypes,
      pointTypes,
      selectPointType,
      carId,
      isTabletDown,
      lastTypeCategoryId,
      visitId,
      isPreentry,
    };
  }
});
</script>

<style lang="scss">
</style>