import { BaseCollectionResponse, BaseQueryPage } from './Base'
import { DiscountAccount } from './Discount'
import { Service } from './Service'
import { Category } from './Category'
import { PointType } from './Point'
import { CarGroup } from './CarGroup'

export enum CarNumberTypeEnum {
  Citizen = 'citizen',
  Military = 'military',
  Police = 'police',
  Taxi = 'taxi',
  Other = 'other',
  Diplomatical = 'diplomatical',
  Unknown = 'unknown'
}

export interface Car {
  id: string;
  numberType: CarNumberTypeEnum;
  subscribe: boolean;
  number?: string;
  comment?: string;
  ownerName?: string;
  ownerPhone?: string;
}

export interface CarBodyRequest extends Partial<Car> {
  discountAccount?: Pick<DiscountAccount, "id">;
  priceCategories?: Array<Pick<Category, "id">>;
}

export interface CarResponse extends Car {
  discountAccount?: DiscountAccount;
  priceCategories: CarPriceCategory[];
  group?: CarGroup;
}

export interface CarHistoryResponse {
  /** FIXME: В теории должно иметь тип number, но по факту возвращается строка */
  overallCount: string; // int32
}

export interface CarServicesPopularItem {
  /** Не во всех возвращаемых услугах присутствует `id` */
  service: Pick<Service, "id" | "name">;
  countPurchases: number;
  lastPurchase?: string;
}

export type CarPriceCategory = Pick<Category, "id" | "name"> & {
  pointType: PointType;
};
export type CarServicesPopularResponse = Array<CarServicesPopularItem>;
export type CarCollectionResponse = BaseCollectionResponse<CarResponse>;
export type CarCollectionQuery = BaseQueryPage & { [param: string]: any; };
export type CarQuery = {
  /** TODO: ??? */
  softdelForal: boolean;
  [param: string]: any;
};

export interface RecognizeNumberResponse {
  number: string[];
  time: string;
}