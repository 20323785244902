import { isClientError } from "@/repositories";
import { nl2br } from "@/utils/string"
import { isString, get, has, isEmpty } from "lodash"

export const UNKNOW_ERROR_MESAGE = 'Возникла неизвестная ошибка,<br> обратитесь к администратору';

/**
 * Преобразует значение в форматированный JSON (с отступами и переносами)
 * 
 * @param value значение для конвертирования
 * @param space кол-во пробелов или сами пробелы в виде строки
 */
export function stringToFormatJSON(value: any, space: number|string = 2): string {
  return JSON.stringify(value, null, space);
}

/**
 * Преобразует информацию о возникшей ошибки в текст c HTML разметкой
 * 
 * @deprecated Использовать функцию errorToMessageData()
 * 
 * @param message сообщение об ошибке
 * @param newLineTag тег который будет использован в качестве переноса
 */
export function errorToMessageString(message: any, newLineTag = '<br>'): string {
  if (typeof message === 'string') {
    return nl2br(message.trim(), newLineTag);
  }

  if (message instanceof Error) {
    if (isClientError(message)) {
      if (has(message.response, 'data.error')) {
        return get(message.response, 'data.error');
      }
    }

    return message.message;
  }

  if (Array.isArray(message)) {
    const listItems = message
      .map(item => `<li>${errorToMessageString(item)}</li>`)
      .join('\n');
    
    return `<ul>${listItems}</ul>`;
  }

  if (typeof message === 'object' && isString(get(message, 'message', null))) {
    return message.message;
  }

  return `<pre>${stringToFormatJSON(message)}</pre>`;
}

export interface MessageData {
  message: string;
  title?: string;
  meta: {
    [prop: string]: any;
  };
}

export interface MessageDataOptions {
  /** который будет использован в качестве переноса */
  newLineSymbol: string|false;

  /**
   * Сообщение об ошибке по умолчанию
   */
  defaultMessage: string,

  /**
   * Преобразовать ошибку в списке ошибок
   * @param preparedMessageList 
   * @param options 
   */
  prepareListItem(singleMessageData: MessageData, options: MessageDataOptions): string;

  /**
   * Преобразует итоговый спиок с ошибками
   * @param preparedMessageList 
   * @param options 
   */
  prepareListWrap(preparedMessageList: string, messagesData: MessageData[], messagePayload: any,  options: MessageDataOptions): MessageData;

  /** Функция преобразования, для ошибки, которую не удалось обработать остальными способами */
  prepareOther(messagePayload: any, options: MessageDataOptions): MessageData;

  /**
   * Функция для проверки и обработки специальных серверных ошибок,
   * которые могут содержать заголовок и дополнительную мета-информацию.
   */
  prepareSpecialErrors(messagePayload: any, options: MessageDataOptions): MessageData|null;

  /**
   * Дополнительная обработка сообщения
   * 
   * @param messageData 
   * @param options 
   */
  prepareMessageData(messageData: MessageData, options: MessageDataOptions): MessageData;

  /**
   * Дополнительная обработка строковых значений.
   * NOTE: используется для обработки заголовка и текста ошибки (обработать переносы строк и т.п.)
   * 
   * @param value 
   * @param options 
   */
  prepareStringValue(value: string, options: MessageDataOptions): string;
}

/**
 * Проверка и преобразование ошибок сервера в специальном формате (с заголовком ошибки и мета-полями)
 * @param messagePayload 
 * @returns 
 */
export function testSpecialErrorAndToMessageData(messagePayload: any): MessageData|null {
  if (messagePayload === null || typeof messagePayload !== 'object') {
    return null; // Не подходит формат
  }

  const message: any = messagePayload.message || messagePayload.error;
  if (false === isString(message) || isEmpty(message)) {
    return null; // Не подходит структура
  }

  let messageData: MessageData = {
    message: message as string,
    meta: {
      type: 'special',
    },
  };
  
  if (isString(messagePayload.title)) {
    messageData.title = messagePayload.title as string;
  }

  if (messagePayload.meta !== null && typeof messagePayload.meta === 'object') {
    Object.assign(messageData.meta, messagePayload.meta);
  }

  return messageData;
}

/**
 * Helper: Сконвертировать сообщение в объект
 * 
 * @param message 
 * @returns 
 */
export function stringToMessageData(message: string, type = 'string'): MessageData {
  return {
    message,
    meta: { type },
  };
}

const MESSAGE_DATA_OPTIONS_DEFAULT: MessageDataOptions = {
  newLineSymbol: '<br>',
  defaultMessage: UNKNOW_ERROR_MESAGE,
  prepareListItem: (msg) => `<li>${[msg.title, msg.message].join(': ')}</li>`,
  prepareListWrap(list) {
    return {
      message: `<ul>${list}</ul>`,
      meta: { type: 'array' }
    };
  },
  prepareSpecialErrors: testSpecialErrorAndToMessageData,
  prepareStringValue: (value, options) => options.newLineSymbol ? nl2br(value, options.newLineSymbol) : value,
  prepareMessageData(messageData, options) {
    let preparedMessageData = { ...messageData };

    preparedMessageData.message = options.prepareStringValue(messageData.message, options);

    if (messageData.title) {
      preparedMessageData.title = options.prepareStringValue(messageData.title, options);
    }

    return preparedMessageData;
  },
  prepareOther(messagePayload) {
    const message = `<pre>${stringToFormatJSON(messagePayload)}</pre>`;
    return {
      message,
      meta: {
        type: 'other'
      }
    };
  },
};

/**
 * Преобразовать данные ошибки (смешанные) в данные MessageData
 * 
 * @param messagePayload 
 * @param options 
 * @returns 
 */
export function errorToMessageData(messagePayload: any, options?: Partial<MessageDataOptions>): MessageData {
  const o = { ...MESSAGE_DATA_OPTIONS_DEFAULT, options };

  if (typeof messagePayload === 'string') {
    return stringToMessageData(
      o.prepareStringValue(messagePayload || o.defaultMessage, o)
    );
  }

  if (typeof messagePayload === 'object' && messagePayload !== null) {
    if (Array.isArray(messagePayload)) {
      if (messagePayload.length === 0) {
        return stringToMessageData(
          o.prepareStringValue(o.defaultMessage, o),
          'empty_array'
        );
      }

      const messagePayloadList = messagePayload.map(singleMessagePayload => errorToMessageData(singleMessagePayload, o));
      const messageListString = messagePayloadList.map(singleMessageData => o.prepareListItem(singleMessageData, o)).join();
      
      return o.prepareListWrap(messageListString, messagePayloadList, messagePayload, o);
    }

    const messageData = isClientError(messagePayload)
      ? o.prepareSpecialErrors(messagePayload.response?.data, o) // Ошибка от API слиента (axios)
      : o.prepareSpecialErrors(messagePayload, o)                // В функции по умолчанию, Error тоже обработается с проверкой заголовков
    ;

    if (messageData) {
      return o.prepareMessageData(messageData, o);
    }

    if (messagePayload instanceof Error) {
      return stringToMessageData(
        o.prepareStringValue(messagePayload.message || o.defaultMessage, o),
        'error'
      );
    }
  }

  return o.prepareOther(messagePayload, o);
}