<template>
  <component :is="tag" class="c-modal-action-header">
    <div class="c-modal-action-header__swipe-indicator" />

    <div class="c-modal-action-header__inline-wrap">
      <div class="c-modal-action-header__start">
        <slot name="start" />
      </div>

      <div class="c-modal-action-header__title" @click="$emit('click-title')">
        <slot />
      </div>

      <div class="c-modal-action-header__end">
        <slot name="end" v-bind="{ emitClose }">
          <c-transparent-button
            v-if="showClose"
            @click="emitClose"
            class="c-modal-action-header__close-btn"
          >
            <ion-icon name="close-outline" />
          </c-transparent-button>
        </slot>
      </div>
    </div>
  </component>
</template>

<script lang="ts">
import { modalController } from '@ionic/vue';
import { defineComponent, PropType } from 'vue';

export default defineComponent({
  emits: ['dismiss', 'click-title'],

  props: {
    emitModalDismiss: {
      type: Boolean as PropType<boolean>,
      default: true,
    },
    tag: {
      type: null,
      default: 'div'
    },
    showClose: {
      type: Boolean,
      default: true,
    }
  },

  setup(props, { emit }) {
    function emitClose(event: Event) {
      event.preventDefault();

      if (props.emitModalDismiss) {
        modalController.dismiss();
      }

      emit('dismiss');
    }

    return {
      emitClose,
    };
  }
});
</script>

<style lang="scss">
@import "@/scss/abstract";

.c-modal-action-header {
  color: var(--ion-text-color);
  background-color: var(--background);

  @include ionic-tablet {
    border-bottom: 1px solid var(--core-divider-color);
  }

  &__inline-wrap {
    display: flex;
    width: 100%;
  }

  &__swipe-indicator {
    padding: 12px 0 8px;

    &::before {
      content: '';
      display: block;
      width: 48px;
      height: 6px;
      border-radius: 1000px;
      background-color: rgba(170, 170, 170, 0.07);
      margin: 0 auto;
    }

    @include ionic-tablet {
      display: none;
    }
  }

  &__start {
    max-width: 20%;
  }

  &__end {
    max-width: 20%;
    display: flex;
    align-items: center;
  }

  &__title {
    flex: 1 1 0%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    padding: var(--ion-padding);
    font-size: var(--core-font-size-heading-large, 20px);
    font-weight: 300;
    color: inherit;
  }

  &__close-btn {
    margin-right: 8px;
    display: none;

    @include ionic-tablet {
      display: inline-block;
    }

    ion-icon {
      font-size: 30px;
    }
  }

  .c-modal-header-back-button {
    height: 100%;
    margin: 0 -16px 0 0;
  }

  .core-modal-actions & {
    background-color: var(--core-toolbar-bg);
  }

  @media (max-device-width: 340px) {
    &__title {
      font-size: 16px;
    }
  }
}
</style>