import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, normalizeClass as _normalizeClass, withCtx as _withCtx, createBlock as _createBlock } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "ion-padding-horizontal ion-padding-top"
}
const _hoisted_2 = {
  key: 1,
  class: "ion-padding-horizontal ion-padding-bottom"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header_new_visit = _resolveComponent("app-header-new-visit")!
  const _component_visit_preview_images = _resolveComponent("visit-preview-images")!
  const _component_c_nav_cards_list_item = _resolveComponent("c-nav-cards-list-item")!
  const _component_c_nav_cards_list = _resolveComponent("c-nav-cards-list")!
  const _component_c_card_header = _resolveComponent("c-card-header")!
  const _component_client_info_card = _resolveComponent("client-info-card")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header_new_visit, {
        title: _ctx.$t('views.visit_new_services_type.title'),
        progress: 0.5
      }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_c_content_loading, { action: _ctx.loadPointTypes }, {
            default: _withCtx(() => [
              (_ctx.isTabletDown)
                ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                    _createVNode(_component_visit_preview_images, {
                      class: "ion-margin-bottom",
                      "visit-id": _ctx.visitId,
                      "is-new-preentry": _ctx.isPreentry
                    }, null, 8, ["visit-id", "is-new-preentry"])
                  ]))
                : _createCommentVNode("", true),
              _createVNode(_component_c_nav_cards_list, null, {
                default: _withCtx(() => [
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.pointTypes, (pointType) => {
                    return (_openBlock(), _createBlock(_component_c_nav_cards_list_item, {
                      key: pointType.id,
                      class: _normalizeClass({ '--success-color': _ctx.lastTypeCategoryId == pointType.id }),
                      onClick: ($event: any) => (_ctx.selectPointType(pointType))
                    }, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(pointType.name), 1)
                      ]),
                      _: 2
                    }, 1032, ["class", "onClick"]))
                  }), 128))
                ]),
                _: 1
              }),
              (_ctx.isTabletDown)
                ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                    _createVNode(_component_c_card_header, null, {
                      default: _withCtx(() => [
                        _createTextVNode(_toDisplayString(_ctx.$t('views.visit_new_services_type.client_card_info_title')), 1)
                      ]),
                      _: 1
                    }),
                    _createVNode(_component_client_info_card, { "car-id": _ctx.carId }, null, 8, ["car-id"])
                  ]))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["action"])
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}