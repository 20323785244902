<template>
  <ion-page>
    <app-header-big :title="$t('views.visits.title')">
      <ion-toolbar class="core-header-segment-toolbar">
        <ion-segment class="core-segment" v-model="currentTab">
          <ion-segment-button value="processing" layout="icon-hide">
            <ion-label>{{ $t('views.visits.tab_processing') }}</ion-label>
          </ion-segment-button>
          <ion-segment-button value="archive" layout="icon-hide">
            <ion-label>{{ $t('views.visits.tab_archive') }}</ion-label>
          </ion-segment-button>
        </ion-segment>

        <teleport :to="viewToggleContainer" :disabled="!viewToggleContainer || !isTabletAndUp">
          <!-- eslint-disable-next-line -->
          <ion-segment slot="end" class="core-segment-light" v-model="viewType">
            <ion-segment-button value="normal" layout="label-hide">
              <ion-icon name="core-visits-view-card" />
            </ion-segment-button>

            <ion-segment-button value="mini" layout="label-hide">
              <ion-icon name="core-visits-view-list" />
            </ion-segment-button>
          </ion-segment>
        </teleport>
      </ion-toolbar>

      <ion-toolbar class="core-header-searchbar" v-if="showHeaderSearchbar">
        <ion-item lines="none">
          <ion-input
            :placeholder="$t('views.visits.search_input')"
            inputmode="search"
            ref="searchInputRef"
            v-model="searchWords"
            :debounce="700"
          />

          <!-- eslint-disable-next-line -->
          <ion-button slot="end" fill="clear" color="light" @click="closeSearchbar">
            <ion-icon name="close-outline" class="icon-medium" />
          </ion-button>
        </ion-item>
      </ion-toolbar>

      <template #buttons-before>
        <c-transparent-button @click="toggleSearchbar" v-show="isArchive && isTabletAndUp">
          <ion-icon name="core-search" class="icon-medium" />
        </c-transparent-button>
      </template>
    </app-header-big>

    <ion-content>
      <c-tabs-contentainer v-model="currentTab">
        <c-tab-content name="processing">
          <VisitsTabProcessing :view-type="processingViewType" />
        </c-tab-content>

        <c-tab-content name="archive">
          <VisitsTabArchive :search="searchWords" :view-type="archiveViewType" />
        </c-tab-content>

        <c-tab-content>What?!</c-tab-content>
      </c-tabs-contentainer>

      <!-- eslint-disable-next-line -->
      <div slot="fixed"
        v-if="isTabletAndUp"
        class="view-toggle-container-tablet"
        ref="viewToggleContainer"
      ></div>

      <div class="visits-fixed-toolbar" slot="fixed">
        <div class="visits-fixed-actions" v-show="currentTab === 'processing'">
          <ion-fab-button v-if="configUsePreentry" color="light" @click="toPreentry">
            <ion-icon name="core-calendar" class="icon-large" />
          </ion-fab-button>

          <ion-button color="primary" shape="round" @click="newVisit">
            <ion-icon name="add-outline" class="mr-1" />
            {{ $t('views.visits.add_visit') }}
          </ion-button>
        </div>

        <div
          v-scroll-hide="{}"
          v-show="showFooterSearchbar"
          class="core-searchbar-mobile ion-padding"
          :class="{ 'scroll-hide--show-force': searchWords }"
        >
          <ion-searchbar
            class="core-searchbar"
            inputmode="search"
            :placeholder="$t('views.visits.search_input')"
            clear-icon="close-outline"
            :debounce="700"
            v-model="searchWords"
          />
        </div>
      </div>

      <div class="fixed-actions-spacer" :style="{
        height: showFooterSearchbar ? '100px' : undefined,
      }"></div>
    </ion-content>

    <app-footer>

    </app-footer>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import { useNavManager } from '@/composables/useNavManager';
// import { mdTransitionAnimation } from '@ionic/core';
import VisitsTabProcessing from './VisitsTabProcessing.vue';
import VisitsTabArchive from './VisitsTabArchive.vue';
import { isTabletAndUp } from '@/helpers/adaptive';
import { useStore, useApp } from '@/composables/useApp';
import { scrollHide } from '@/directives/scrollHide';
import router from '@/router';
import { useMetrica } from '@/composables/useMetrica';
import ls from '@/helpers/localStorage';
import { useI18n } from 'vue-i18n';

const LS_VIEW_TYPE_KEY = 'visits-view-type';
const LS_VIEW_ARCHIVE_TYPE_KEY = 'visits-archive-view-type';

export default defineComponent({
  components: {
    VisitsTabProcessing,
    VisitsTabArchive,
  },
  directives: { scrollHide },
  setup() {
    const store = useStore();
    const { getDb } = useApp();
    const navManager = useNavManager();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    if (!store.user.isAuth) {
      navManager.navigate({
        routerDirection: 'root',
        routerLink: {
          name: 'login',
        }
      });
    }

    const configUsePreentry = store.config.usePreentry;
    const db = getDb();

    //#region Tabs and View type
    const currentTab = ref('processing');
    watch(() => currentTab.value, tabName => emitEvent(`visits/tab/${tabName}`));

    const viewToggleContainer = ref<HTMLDivElement|null>(null);

    const processingViewType = ref<string>(ls.getValue(LS_VIEW_TYPE_KEY, 'normal'));
    watch(processingViewType, type => ls.saveValue(LS_VIEW_TYPE_KEY, type));

    const archiveViewType = ref<string>(ls.getValue(LS_VIEW_ARCHIVE_TYPE_KEY, 'mini'));
    watch(archiveViewType, type => ls.saveValue(LS_VIEW_ARCHIVE_TYPE_KEY, type));

    const viewType = computed({
      get() {
        return (currentTab.value === 'processing')
          ? processingViewType.value
          : archiveViewType.value;
      },
      set(value: string) {
        if (currentTab.value === 'processing') {
          processingViewType.value = value;
        } else {
          archiveViewType.value = value;
        }
      }
    });

    const isArchive = computed(() => currentTab.value === 'archive');
    //#endregion

    function newVisit() {
      emitEvent('visit/new');

      store.visit.clearNewVisitState();
      db.visitGallery.delete('0');

      router.push({ name: 'visit-new-photo-scanner' });

      // navManager.navigate({
      //   routerDirection: 'forward',
      //   routerLink: { name: 'visit-new-photo-scanner' },
      //   routerAnimation: mdTransitionAnimation,
      // });
    }

    function toPreentry() {
      router.push({ name: 'visits-preentry' });
    }

    //#region Search bar
    const showSearchbar = ref(false);
    const showHeaderSearchbar = computed(() => isArchive.value && isTabletAndUp.value && showSearchbar.value);  // for tablet & desktop
    const showFooterSearchbar = computed(() => isArchive.value && !isTabletAndUp.value); // for mobile
    const searchWords = ref('');
    const searchInputRef = ref<any|null>(null);
    watch(searchInputRef, ionInputComponent => {
      // crutch
      setTimeout(() => ionInputComponent?.$el?.setFocus(), 200);
    }, { flush: 'post' });

    function openSearchbar() {
      showSearchbar.value = true;
    }

    function closeSearchbar() {
      showSearchbar.value = false;
      searchWords.value = '';
    }

    function toggleSearchbar() {
      if (showSearchbar.value) {
        closeSearchbar();
      } else {
        openSearchbar();
      }
    }
    //#endregion

    return {
      currentTab,
      newVisit,
      openSearchbar,
      showHeaderSearchbar,
      showFooterSearchbar,
      searchWords,
      toggleSearchbar,
      closeSearchbar,
      searchInputRef,
      isArchive,
      isTabletAndUp,
      toPreentry,
      viewType,
      archiveViewType,
      processingViewType,
      viewToggleContainer,
      configUsePreentry,
      t,
    };
  },
});
</script>

<style lang="scss">
@import '@/scss/abstract';

.header-ios .core-header-segment-toolbar {
  --padding-top: 0;
  --padding-bottom: 0;
  --padding-start: 10px;
  --padding-end: 10px;

  border-top: $border-divider;

  @include ionic-tablet {
    --background: transparent;

    position: absolute;
    left: 200px;
    right: 200px;
    bottom: 0;
    width: auto;
    border-top: none;

    .core-segment {
      margin: 0 auto;
    }

    ion-segment-button {
      min-height: var(--core-tablet-header-height);
    }
  }
}

.core-header-searchbar {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  display: flex;

  ion-item {
    --padding-start: var(--ion-padding);
  }
}

.view-toggle-container-tablet {
  position: absolute;
  top: var(--core-spacer-small);
  right: var(--core-spacer-small);
  background-color: var(--ion-toolbar-background);
  border-radius: 1000px;
  z-index: 12;
  height: 46px;

  > * {
    height: 100%;
  }

  ion-segment-button {
    &::before {
      height: 16px;
      align-self: center;
    }
  }
}
</style>