import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "visit-store-checkout" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_card_header = _resolveComponent("c-card-header")!
  const _component_visit_store_products_view = _resolveComponent("visit-store-products-view")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_c_card_list = _resolveComponent("c-card-list")!
  const _component_ion_button = _resolveComponent("ion-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_c_card_header, null, {
      default: _withCtx(() => [
        _createTextVNode(_toDisplayString(_ctx.$t('components.visit_store_checkout.title')), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_visit_store_products_view, {
      total: _ctx.total,
      "discount-percent": _ctx.discountPercent
    }, null, 8, ["total", "discount-percent"]),
    _createVNode(_component_c_card_list, null, {
      default: _withCtx(() => [
        _createVNode(_component_ion_item, {
          button: "",
          detail: false,
          onClick: _ctx.alertConfirmInvoice,
          class: "item-link-button ion-text-center item-no-detail-icon"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('components.visit_store_checkout.item_invoice')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_ion_item, {
          button: "",
          detail: false,
          onClick: _ctx.choiceDiscountAction,
          class: "item-link-button ion-text-center item-no-detail-icon"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('components.visit_store_checkout.item_choice_discount')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"]),
        _createVNode(_component_ion_item, {
          button: "",
          detail: false,
          onClick: _ctx.clearStoreOrder,
          class: "item-link-button ion-text-center item-no-detail-icon"
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ion_label, null, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.$t('components.visit_store_checkout.item_clear_order')), 1)
              ]),
              _: 1
            })
          ]),
          _: 1
        }, 8, ["onClick"])
      ]),
      _: 1
    }),
    (_openBlock(), _createBlock(_Teleport, {
      to: _ctx.completeBtnContainer,
      disabled: !_ctx.completeBtnContainer
    }, [
      _createVNode(_component_ion_button, {
        onClick: _ctx.payAction,
        disabled: _ctx.btnPayDisabled,
        color: "primary",
        expand: "block",
        shape: "round"
      }, {
        default: _withCtx(() => [
          _createTextVNode(_toDisplayString(_ctx.btnPayText), 1)
        ]),
        _: 1
      }, 8, ["onClick", "disabled"])
    ], 8, ["to", "disabled"]))
  ]))
}