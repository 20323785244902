import { inject, getCurrentInstance } from 'vue'
import { AnimationBuilder, useIonRouter } from '@ionic/vue';
import { RouteLocationRaw, useRouter } from 'vue-router'
import { createIonRouter } from '@ionic/vue-router/dist/types/router'

// export type RouteAction = 'push' | 'pop' | 'replace';
export type RouteDirection = 'forward' | 'back' | 'root' | 'none';

export interface NavigationOptions {
  routerLink: RouteLocationRaw;
  routerDirection?: RouteDirection;
  routerAnimation?: AnimationBuilder;
}

export type NavManagerInstance = Omit<ReturnType<typeof createIonRouter>, "navigate"> & {
  navigate: (navigationOptions: NavigationOptions) => void,
};

/**
 * TODO: Перейти на Ionic 7 и новый useIonRouter(), см: https://ionicframework.com/docs/vue/navigation#navigating-using-useionrouter
 * 
 * @deprecated
 * @returns 
 */
export function useNavManager() {
  return inject('navManager') as NavManagerInstance;
}

/**
 * TODO: В будущем доработать так, чтобы умел работать и с vue-router и ionic nav manager
 * 
 * @returns 
 */
export function useAppNavigation() {
  const router = useRouter();
  const ionRouter = useIonRouter();

  /**
   * Совпадает ли переданный маршрут с предыдущим в иториии
   * 
   * @param route 
   * @returns 
   */
  function isSameBackRoute(route: RouteLocationRaw): boolean {
    const nextFullpathResolve = router.resolve(route).fullPath;
    const backFullpath = router.options.history.state.back as string;

    return nextFullpathResolve === backFullpath;
  }

  /**
   * Ссли предыдущая страница совпадает с той, к которой мы хотим перейти,
   * то сделается шаг назад, иначе произойдет простой переход, вперед.
   * 
   * @param to 
   */
  function backIfMatch(to: RouteLocationRaw) {
    if (ionRouter.canGoBack() && isSameBackRoute(to)) {
      router.back();
    } else {
      router.push(to);

      // , direction: RouteDirection = 'forward'
      // ionNavManager.navigate({
      //   routerLink: to,
      //   routerDirection: direction,
      // });
    }
  }

  return {
    router,
    ionRouter,
    backIfMatch,
    isSameBackRoute,
  };
}

export function useIonNav(el?: HTMLElement) {
  if (!el) {
    const instance = getCurrentInstance();
    el = instance?.vnode.el as HTMLElement|undefined;
  }
  
  const nav = el?.closest('ion-nav');
  return nav || null;
}
