<template>
  <div class="c-service-provider-cost-modal">
    <c-modal-action-header>
      {{ $t('components.service_provide_item_cost_modal.modal_title') }}
    </c-modal-action-header>

    <div class="ion-padding">
      <input-price v-model="priceValue" :currency-unit="currencyConfig.symbol" />

      <ion-row class="text-muted">
        <ion-col class="pl-0">
          <cc :price="minValue" />
        </ion-col>

        <ion-col class="ion-text-end pr-0">
          <template v-if="maxValue === Number.MAX_VALUE">∞</template>
          <cc v-else :price="maxValue" />
        </ion-col>
      </ion-row>

      <ion-range
        v-if="showRange"
        v-model="priceValue"
        color="secondary"
        class="c-service-provider-cost-modal__range"
        :min="minValue"
        :max="maxValue"
        :step="10"
      />

      <c-divider />

      <ion-button color="primary" expand="block" shape="round" @click="apply">
        {{ $t('components.service_provide_item_cost_modal.btn_apply') }}
      </ion-button>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import { ServiceByContextItem } from '@/repositories/Models/Service';
import InputPrice from '@/components/visit/InputPrice.vue';
import { useToast } from '@/composables/toast';
import { modalController } from '@ionic/vue';
import { getServiceRangePrice } from '@/utils/price';
import { useCurrency } from '@/composables/currency';

export type SetPriceCallback = (price: number) => void;

export default defineComponent({
  components: {
    InputPrice,
  },

  props: {
    value: {
      type: [String, Number] as PropType<number|string>,
      required: false
    },
    service: {
      type: Object as PropType<ServiceByContextItem>,
      required: true
    },
    setPrice: {
      type: Function as PropType<SetPriceCallback>,
      required: false
    },
  },

  setup(props) {
    const toast = useToast();
    const { currencyConfig } = useCurrency();

    // У одной услуги есть несколько цен, которые зависят от выбранной группы или категории
    // до даненого компонента цены доходят уже отфильтрованные по данным параметрам
    // и в итоге должна оставаться всего одна цена.
    const { minValue, maxValue } = getServiceRangePrice(props.service.prices[0]);
    const priceValue = ref<number>(props.value ? +props.value : minValue);

    const showRange = computed(() => maxValue !== Number.MAX_VALUE);

    function apply() {
      if (priceValue.value < minValue) {
        toast.error(`The price must be at least ${minValue}`);
        return;
      }

      if (priceValue.value > maxValue) {
        toast.error(`The price should be no more ${maxValue}`);
        return;
      }

      if (props.setPrice) {
        props.setPrice(priceValue.value);
      }

      modalController.dismiss();
    }

    return {
      priceValue,
      apply,
      minValue,
      maxValue,
      showRange,
      currencyConfig,
    };
  }
});
</script>

<style lang="scss">
.c-service-provider-cost-modal {
  &__range {
    padding-left: 0;
    padding-right: 0;

    .c-keyboard-opened & {
      display: none;
    }
  }
}
</style>