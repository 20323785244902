import { LocalizationDictionary } from '../types';

export default {
  locale: 'English',
  views: {
    //#region Visits
    // Страница заказов (главная)
    // url: /visits
    visits: {
      title: 'Orders',
      tab_processing: 'In Progress',
      tab_archive: 'Archived',
      add_visit: 'Add order',
      search_input: 'Please enter a query',
    },

    // Карточка заказа
    // url: /visits/:id
    visit_single: {
      title: 'Order card',
      card_info_pay: 'Paid:',
      card_info_pay_points: 'Bonuses written off:',
      card_action_close_visit: 'Close visit',
      card_action_return_work: 'Return to work',
      card_action_return_pay: 'Cancel payment',
      card_action_invoice_fiscal: 'Fiscal receipt',
      card_action_invoice: 'Print guest bill',
      card_action_cancel_preentry_visit: 'Cancel Record',
      card_action_сhange_datetime_preentry_visit: 'Change datetime',
      action_pay: 'Accept payment {price}',
      action_preentry_get_started: 'Start order fulfillment',
      action_preentry_apply_doers_and_get_started: 'Apply and start',
      provide_services_title: 'Service List',
      provide_services_action_edit: 'Edit provided services',
      client_card_title: 'Client',

      update_visit_info_backgroung: 'Updating information ...',
      invoice_processing_wait: 'Processing guest bill ...',
      invoice_fiscal_processing_wait: 'Sending check ...',
      pay_warning_not_cashier: 'You must have cashier rights to pay!',

      // Подтверждение действий предварительной записи
      alert_preentry_get_started_header: 'Confirm Action',
      alert_preentry_get_started_message: 'Start order fulfillment? After confirmation, the status of the order will be changed to "In Progress" ',
      alert_preentry_cancel_header: 'Confirm Action',
      alert_preentry_cancel_message: 'After canceling a pre-appointment, it cannot be restored',
      
      // Подтверждение действий по заказу (завершить, вернуть в работу)
      alert_close_visit_header: 'Close order',
      alert_close_visit_message: 'Confirm order closure',
      alert_reopen_visit_header: 'Return to work',
      alert_reopen_visit_message: 'Confirm order return to work',
      
      // Подтверждения действий с чеками (обычный, фискальный)
      alert_invoice_header: 'Guest',
      alert_invoice_message: 'Confirm action',
      alert_invoice_btn_confirm: 'Submit',
      alert_invoice_fiscal_header: 'Fiscal receipt',
      alert_invoice_fiscal_message: 'Confirm issuance of a fiscal receipt',
      alert_invoice_fiscal_btn_confirm: 'Confirm',

      // Заголовки ошибок закрытия и открытия
      header_error_finish_visit: 'Failed to complete execution',
      header_error_process_visit: 'Failed to return to work',
    },

    // Оплата заказа и отмена платежа
    // url: /visits/:id/pay/:type/confirm
    // url: /visits/:id/pay/:type/return
    visit_pay: {
      title_accept: 'Payment for order',
      title_return: 'Refund',

      label_total_accept: 'Amount to be paid',
      label_total_return: 'Amount to be returned',
      label_payment_type_accept: 'Payment method',
      label_payment_type_return: 'Return method',
      label_cash_deposited: 'Cash deposited',
      label_pay_points: 'Pay with bonuses',
      label_odd_money: 'Change',

      alert_confirm_pay_header: 'Payment confirmation',
      alert_confirm_pay_message: 'Confirm the payment operation',
      alert_confirm_return_pay_header: 'Refund money',
      alert_confirm_return_pay_message: 'Confirm the refund operation',
      
      cash_deposited_error: 'You have deposited insufficient amount',
      pay_visit_success_message: 'The order in the store was successfully paid',

      confirm_btn_init: 'Initializing ...',
      confirm_btn_processing: 'Performing an operation ...',
      confirm_btn_confirm_return: 'Confirm return',
      confirm_btn_confirm_pay: 'Confirm payment',
      confirm_btn_confirm_pay_and_close: 'Confirm payment and close order',

      payment_type_card: 'Bank Card',

      header_error_init: 'Checkout initialization error',
      header_error_pos: 'Checkout error',
      header_error_pay_visit: 'Order not paid',
      header_error_return_pay_visit: 'Failed to cancel payment',
    },

    // Распознование номера и фото (предварительная запись, новый визит)
    // url: /visits/new/photo-scanner
    // url: /visits/preentry/photo-scanner
    visit_new_photo: {
      title: 'Recognize number',
      btn_skip_no_recognition: 'Continue without recognizing number',
      btn_skip_no_photo: 'Continue checkout without photo',
      btn_number_recognition: 'Recognize number',
      btn_create_photo: 'Take a photo of the car',
      error_camera_no_init: 'Camera not initialized',
      error_create_photo: 'Failed to take photo',
      error_recognize_photo: 'Failed to recognize number',
      wait_recognize_number: 'License plate recognition ...',
    },

    // Ввод номера автомобиля
    // url: /visits/new/car-number
    // url: /visits/preentry/car-number
    visit_new_car_number: {
      title: 'Car registration',
      btn_outher_number_format: 'Other number format',
      btn_next: 'Continue',
      modal_input_number_title: 'Enter number',
      modal_input_number_btn_next: 'Confirm and Continue',
      error_empty_car_number: 'Please enter car number!',
      header_error_create_car: 'Car not created',
    },

    // Ввод информации о клиенте
    // url: /visits/new/client/car/:number
    // url: /visits/preentry/client/car/:number
    visit_new_client: {
      title: 'About the client',
      popular_services_title: 'Popular Services',
      client_form_title: 'Client Information',
      client_input_name: 'Name',
      client_input_phone: 'Phone',
      client_input_comment: 'Phone',
      client_agrees_personal_data: 'The client agrees to the processing, storage, distribution of his personal data',
      btn_next: 'Continue',
      error_load_car_not_exist: 'The car could not be found at the specified number, repeat the procedure for registering the number',
      header_error_load_car_not_exists: 'Vehicle not found',
      header_error_update: 'Customer data not updated',
    },

    // Выбор бокса
    // url: /visits/new/0/box
    visit_new_box: {
      title: 'Box selection',
      client_card_info_title: 'Client',
    },

    // Тип категории
    // url: /visits/new/0/type
    // url: /visits/preentry/0/type
    visit_new_services_type: {
      title: 'Service type',
      client_card_info_title: 'Client',
    },

    // Выбор категории
    // url: /visits/new/0/type/:typeId/cat
    visit_new_service_category: {
      default_title: 'Category',
      client_card_info_title: 'Client',
    },

    // Выбор товаров и услуг
    // url: /visits/new/0/services
    // url: /visits/preentry/0/services
    visit_new_services: {
      title: 'List of services',
      services_card_title: 'Select a service',
      btn_next: 'Continue',
      alert_remove_cateory_header: 'Remove category?',
      alert_remove_cateory_message: 'If you remove a category, then all related services will be excluded from the order.',
      alert_remove_cateory_btn_confirm: 'Remove',
      error_empty_services: 'Select services to continue!',
    },

    // Выбор исполнителя
    // url: /visits/new/0/doers
    // url: /visits/preentry/0/doers
    visit_new_doers: {
      title: 'Select Artist',
      doers_card_title: 'Choose an artist',
      btn_next: 'Continue',
      error_doer_all_required: 'Select all doers',
    },

    // Выбор скидок
    // url: /visits/new/0/discounts
    // url: /visits/preentry/0/discounts
    visit_new_discounts: {
      title: 'Discount',
      discounts_card_title: 'Choose a discount',
      btn_create_visit: 'Place an order',
      btn_update_couterparty: 'Save',
      btn_loading_create_visit: 'Creating order ...',
      message_visit_created: 'Order has been successfully created!',
      error_fail_upload_photo: 'Failed to upload image',
    },

    // Выбор даты и времени для предварительной записи
    // url: /visits/preentry/datetime
    visits_new_preentry_datetime: {
      title: 'Select date and time',
      tab_current_day: 'Today',
      tab_next_day: 'Tomorrow',
      tab_select_date: 'Select date',
      modal_confirm_title: 'Confirmation',
      modal_confirm_btn: 'Confirm',
      modal_info_label_time: 'Service rendering time',
      modal_info_label_date: 'Date',
      modal_info_label_box_name: 'Boxing',
      modal_info_label_box_number: 'Box number',
      error_empty_datetime: 'No recording time selected',
    },

    // История автомобиля
    // url: /visits/car/:carId/history
    visits_car_history: {
      title: 'History of visits {carNumber}',
      popular_services_card_title: 'Popular services',
      section_visits_title: 'Visits history',
      visits_not_found: 'No visits',
    },

    // Просмотр календаря предварительной записи
    // url: /visits/preentry
    visits_preentry: {
      tab_current_day: 'Today',
      tab_next_day: 'Tomorrow',
      tab_select_date: 'Select date',
      tab_choice_month: 'Choice month',
      btn_add_preentry: 'Add order',
    },

    // Добавление фото к визиту, камера с превью
    // url: /visits/:visitId/photo/add - legacy // TODO:
    // url: /visits/:visitId/photo/create
    visits_add_photo: {
      title: 'Create photo',
      create_photo_button: 'Take a photo of the car',
      create_photo_save_processing: 'Processing...',
    },

    // Добавление фото к визиту, подтверждение загрузки на сервер
    // url: /visits/:visitId/photo/confirm
    visits_add_photo_confirm: { // TODO: remove
      title: 'Save photo',
      upload_confirm_button: 'Confirm',
      upload_confirm_processing_button: 'Wait...',
    },
    //#endregion Visits

    //#region Market
    // Страница магазина
    // url: /market
    market: {
      title: 'Shop',
      provide_services_card_title: 'Select Title',
      btn_next: 'Continue',
      error_empty_products: 'Select products from the list and click continue',
      header_error_empty_products: 'Products not selected',
    },

    // Страница оформления заказа в магазине (только смартфоны)
    // url: /market/checkout
    market_checkout: {
      title: 'Order details',
    },
    //#endregion Market

    //#region Pos
    // Страница кассы
    // url: /pos
    pos: {
      title: 'Cashier',
      item_open_pos_session: 'Open cash register shift',
      item_cash_in: 'Cash deposit',
      item_cash_out: 'Cash withdrawal',
      item_report_x: 'X-report',
      item_report_z: 'Z-report',
      item_acquiring_revise: 'Reconciliation of totals',
      alert_confirm_btn_confirm: 'Yes',
      alert_confirm_btn_cancel: 'No',
      alert_confirm_open_session_header: 'Open shift',
      alert_confirm_open_session_message: 'Confirm opening',
      alert_confirm_report_x_header: 'X-report',
      alert_confirm_report_x_message: 'Are you sure you want to print the X-report?',
      alert_confirm_report_z_header: 'Z-report',
      alert_confirm_report_z_message: 'Are you sure you want to print the Z-report?',
      header_error_pos: 'Error pos',
    },

    // Внесение или изъятие наличных кассы
    // url: /pos/cash/in
    // url: /pos/cash/out
    pos_cash_in_or_out: {
      title_in: 'Cash Deposit',
      title_out: 'Cash withdrawal',
      input_comment: 'Comment',
      btn_confirm: 'Confirm',
      btn_processing: 'Processing ...',
      message_success_operation: 'Successful operation!',
      header_error_operation: 'Error pos',
    },
    //#endregion Pos

    //#region Pricelist
    // Страница прайс-листа (выбор типа услуг)
    // url: /pricelist
    pricelist: {
      title: 'Price',
    },

    // Страница выбора категрии прайс-листа (только смартфон)
    // url: /pricelist/:typeId/cat
    pricelist_cat: {
      default_title: 'Category',
    },

    // Прайс-листвыбранной категории (только смартфон)
    // url: /pricelist/:typeId/cat/:catId
    pricelist_services: {
      default_title: 'Services list',
    },

    // Прайс-лист со списком выбора категрии (планшет и десктопы)
    pricelist_cat_and_services: {
      default_title: 'Services list',
    },
    //#endregion Pricelist

    //#region Reports
    // Отчеты
    // url: /reports
    reports: {
      title: 'Reports',
      item_report_current: 'For this shift',
      item_report_previous: 'For the last shift',
      midal_report_current_title: 'This shift',
      midal_report_previous_title: 'For the last shift',
    },

    // Модальное окно с PDF отчетом
    report_modal: {
      loading_document: 'Loading document ...',
      document_undefined: 'Document not specified',
    },
    //#endregion Reports

    // Страница редактирования информации о пользователе
    // url: /client/car/:carId/edit
    user_edit: {
      title: 'Editing information',
      popular_services_card_title: 'Popular services',
      form_title: 'Client',
      input_client_name: 'Name',
      input_client_phone: 'Phone',
      input_client_comment: 'Comment',
      btn_loading_save: 'Please wait ...',
      btn_save: 'Save',
      header_error_saved: 'Customer data not updated',
    },

    // Всплывающие и модальные окна настроек (профиля и приложения)
    // NOTE: объединены несколько компонентов
    settings: {
      user_popover_title: 'Personal Profile',
      settings_modal_title: 'Settings',
      settings_popover_title: '@:views.settings.settings_modal_title',
    },

    // Всплывающие модальное окно с уведомлениями
    notifications: {
      notifications_modal_title: 'Notifications',
      notifications_list_empty: 'No notifications',
    },
  },
  components: {
    error_block: {
      btn_reload: 'Try again',
    },

    spinner: {
      loading: 'Loading...',
    },

    //#region Layout
    app_footer: {
      menu_item_visits: 'Orders',
      menu_item_pricelist: 'Price',
      menu_item_market: 'Shop',
      menu_item_pos: 'Cashier',
      menu_item_reports: 'Reports',
    },

    app_header_new_visit: {
      alert_close_header: 'Confirm close',
      alert_close_message: 'After closing the order, all entered data will be lost.',
      alert_close_btn_confirm: 'Close',
    },
    //#endregion Layout

    //#region Visits
    // Большие карточки визитов
    visit_card: {
      car_no_number: 'No number',
      remaining_work_time: 'End: {time}',
      start_time: 'Check in: {time}',
      item_label_preentry_date: 'Preentry date',
      item_label_preentry_time: 'Preentry time',
      item_label_visit_datetime: 'Date of visit',
      item_label_start_datetime: 'Check-in time',
      item_label_end_datetime: 'Departure time',
      item_label_box_name: 'Box',
      item_label_box_number: 'Box number',
    },

    // Маленькие карточки визитов
    visit_card_mini: {
      car_no_number: '@:components.visit_card.car_no_number',
      visit_finish: 'Completed: {datetime}',
      preentry_datetime: '{date}, at {time}',
    },

    // Поиск по архиву
    visit_archive_search: {
      not_found: 'Nothing found',
      found_info: 'Found records: {count}',
    },

    // Компонент календаря с визитами
    visits_preentry_shedule_box: {
      visit_card_client_no_name: 'No Name',
      visit_card_car_no_number: 'No number',
      shedule_loading: 'Loading the schedule',
      col_time_label: 'Time',
    },

    // Модальное окно выбора способа оплаты
    payment_type_modal: {
      modal_title: 'Choose a payment method',
      switch_use_points_label: 'Use {points} @:share.plur_points',
    },
    //#endregion Visits

    //#region Store
    // Блок оформления заказа в магазине
    visit_store_checkout: {
      title: 'Order details',
      item_invoice: 'Print guest bill',
      item_choice_discount: 'Select Discount',
      item_clear_order: 'Clear order',
      btn_pay: 'Accept payment {total}',
      btn_pay_loading: 'Please wait ...',
      alert_clear_order_header: 'Clear order',
      alert_clear_order_message: 'This order has been created and has not been paid, are you sure you want to close it without payment?',
      alert_invoice_header: 'Guest',
      alert_invoice_message: 'Confirm action',
      alert_invoice_btn_confirm: 'Submit',
      message_invoice_success: 'Guest bill sent successfully',
      header_invoice_error: 'Precheck not created',
      header_save_order_error: 'Error create order',
    },
    //#endregion Store

    //#region Services
    // Добавление категории к оказываемым услугам
    // или редактирование списка услуг в заказе
    add_category_modal: {
      view_point_type_title: 'Add service type',
      view_category_title: 'Select a Category',
      view_provided_services_title_default: 'Select Services',
      view_provided_services_label_total: 'Total:',
      view_provided_services_btn_next: 'Apply',
      view_doers_title: 'Artists',
      view_doers_btn_next: 'Continue',
      view_doers_btn_apply: 'Apply Changes',
      view_discount_title: 'Discount',
      view_discount_btn_apply: 'Apply Changes',
      error_doer_all_required: 'Select all doers',
    },

    // Общая карточка, где отображаются группы
    // услуг и их итоговая стоимость
    services_provided_view_card: {
      card_item_label_total: 'Total cost:',
    },

    // Карточка с популярными услугами автомобиля
    services_popular_card: {
      col_label_name: 'Name',
      col_label_count: 'Count',
      services_list_empty: 'No services',
    },

    // Отбражения списка услуг в разделе прайс-листа
    services_view: {
      text_category_empty_selected: 'Select a category',
      input_search: 'Search ...',
    },

    // Модальное окно для редактирование цены услуги
    service_provide_item_cost_modal: {
      modal_title: 'Price change',
      btn_apply: 'Apply',
    },

    // Компонент со списком выбора услуг
    services_provide_visit_state: {
      input_search: 'Search ...',
      label_total: 'Total:',
    },

    // Элемент для выбора скидки для оказываемой услуги
    service_provide_discount_item: {
      modal_title: 'Choice discount',
    },
    //#endregion Services

    //#region Car & Client
    // Информация о клиенте.
    // Используется в разделах оформления нового заказа
    client_side_info: {
      client_card_info_title: 'Client',
    },

    // Информационная карточка о клиенте
    client_info_card: {
      client_name_empty: 'Add client data',
      item_car_history: 'History of visits',
      item_client_phone: 'Owner\'s phone',
      item_group: 'Counterparty',
      client_phone_empty: 'Add',
    },
    //#endregion Car & Client

    //#region Group partner
    // Карточка для ввода контрагента
    group_partner_input_card: {
      label: 'Counterparty',
      btn_edit: 'Edit',
      btn_select: 'Select counterparty',
    },

    // Модальное окно для выбора контрагента
    group_partner_select_box: {
      modal_title: 'Select counterparty',
      option_empty: 'No counterparty',
      input_search: 'Search ...',
      options_empty: 'List is empty',
      options_not_found: 'Nothing found',
    },
    //#endregion Group partner

    //#region Settings (& App services)
    app_settings: {
      label_input_cid: 'Enter your Core ID number',
      label_input_language: 'Language app',
      btn_apply_cid: 'Apply specified number',
      label_network_speed: 'Internet connection speed',
      label_number_recognition: 'Automatic number recognition',
      description_number_recognition: 'Due to external factors, the system does not guarantee 100% recognition of the vehicle registration number',
      label_preentry: 'Pre-entry',
      description_preentry: 'If necessary, you can disable the pre-recording function',
      label_flashlight: 'Flashlight',
      description_flashlight: 'The card of the created visit will display a button to turn on the flashlight on your device',
      btn_support_chat: 'Contact support',
      btn_clear_cache: 'Clear cache',
      btn_view_privacy_policy: 'View Policy',
      btn_community: 'Join the community',
      alert_clear_cache_header: 'Clear cache',
      alert_clear_cache_message: 'Reset will delete all data received from the server.',
      alert_clear_cache_btn_confirm: 'Reset',
    },
    user_settings: {
      fullname_label: 'Shift Administrator',
      btn_exit: 'Exit',
      alert_exit_header: 'Confirm exit',
      alert_exit_message: 'After logging out, you will be redirected to the login page',
      alert_exit_btn_confirm: 'Exit',
    },
    login_form: {
      input_login: 'Login',
      input_password: 'Password',
      btn_login: 'Login',
      btn_login_loading: 'Loading ...',
      message_success_logged_in: 'Successful login!',
      error_logged_in_incorrect_login_or_password: 'Check if the username and password are correct',
      header_error_logged_in_incorrect_login_or_password: 'Invalid credentials',
      header_error_not_authorized: 'Not authorized',
      alert_coreid_header: 'Enter your Core ID',
      alert_coreid_message: 'Core ID can be found in the address bar of the browser of the manager\'s personal account',
      alert_btn_open_settings: 'Enter Core ID',
    },
    //#endregion Settings (& App services)
  },
  composables: {
    alert: {
      confirm_default_btn_cancel: 'Cancel',
      confirm_default_btn_confirm: 'Confirm',
    },

    visit_timings: {
      progress_text_wait: 'Waiting',
      progress_text_completed: 'Completed',
      progress_text_time_over: 'Time is up',
      progress_text_time_left: '~ {left} left',
    },

    visit_badges: {
      label_closed: 'Closed',
      label_finished: 'Completed',
      label_preentry: 'By appointment',
      label_opened: 'Opened',
      label_canceled: 'Canceled',
      label_paid: 'Paid',
      label_not_paid: 'Not paid',

      // Временно отключен
      label_not_sync: 'Not synchronized',
    },

    visit_archive_group: {
      action_show_text: 'Show more {count} @:share.plur_order',
      action_hide_text: 'Hide {count} @:share.plur_order',
    },

    visit_preentry: {
      header_error_load_data: 'Error load data',
    },

    discount: {
      modal_choice_title_default: 'Choice discount',
    },

    app_check_update: {
      open_app_store_error: 'Failed to open store',
      open_app_store_error_not_supported: 'Not supported on this platform',
      alert_info_header: 'Update the app',
      alert_info_message: 'We\'ve updated the app to make using Core12 even easier.',
      alert_info_btn_update: 'Update',
      alert_info_btn_update_skip: 'Update later',
    },
  },
  share: {
    plur_order: 'orders | order | orders',
    plur_points: 'bonuses | bonus | bonuses',
  }
} as LocalizationDictionary;