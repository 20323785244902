<script lang="ts" setup>
import { defineProps, withDefaults, computed } from 'vue';
import { Swiper, SwiperSlide } from "swiper/vue";
import { Swiper as SwiperInterface } from "swiper";
import { Navigation, Pagination } from 'swiper/modules';
import carNoImage from '@/img/car-no-image.svg';

interface Props {
  images: string[];
  showNoImage?: boolean;
  stopPropagation?: boolean;
}

const props = withDefaults(defineProps<Props>(), {
  showNoImage: false,
  stopPropagation: true,
});

const slides = computed<string[]>(() => {
  if (props.images.length === 0 && props.showNoImage) {
    return [ carNoImage ];
  }

  return props.images;
});

function onInitSwiper(swiper: SwiperInterface) {
  // Необходимо, чтобы при клике на навигацию не происходил переход по карточке визита
  swiper.navigation.nextEl.addEventListener('click', stopPropagationHandler);
  swiper.navigation.prevEl.addEventListener('click', stopPropagationHandler);
}

function stopPropagationHandler(ev: Event) {
  if (props.stopPropagation) {
    ev.stopPropagation();
  }
}
</script>

<template>
  <swiper
    class="c-visit-images-slider"
    :modules="[Navigation, Pagination]"
    :navigation="true"
    :pagination="{
      dynamicBullets: true,
      dynamicMainBullets: 5,
    }"
    @after-init="onInitSwiper"
  >
    <swiper-slide
      class="c-visit-images-slider__image"
      v-for="image in slides"
      :key="image"
      :style="{ backgroundImage: `url('${image}')` }"
    />
  </swiper>
</template>

<style lang="scss">
.c-visit-images-slider {
  &__image {
    padding: 30% 0;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
    max-height: 380px;
    min-width: 100%;
    max-width: 100%;
    scroll-snap-align: start;
    scroll-snap-stop: always;
  }

  //#region Swiper
  --swiper-navigation-sides-offset: 6px;

  .swiper-button-prev:after,
  .swiper-button-next:after {
    --swiper-navigation-size: 20px;
    font-family: inherit;
    width: 12px;
    height: 18px;
  }

  .swiper-button-next:after,
  .swiper-rtl .swiper-button-prev:after {
    content: '';
    background: var(--core-icon-right-arrow, none);
  }

  .swiper-button-prev:after,
  .swiper-rtl .swiper-button-next:after {
    content: '';
    background: var(--core-icon-left-arrow, none);
  }

  .swiper-button-prev,
  .swiper-button-next {
    &.swiper-button-disabled {
      opacity: 0;
    }
  }

  .swiper-pagination-bullet {
    --swiper-pagination-bullet-inactive-color: var(--core-light);
    --swiper-pagination-bullet-inactive-opacity: 0.8;
    --swiper-pagination-color: var(--core-light);
    --swiper-pagination-bullet-size: 6px;

    box-shadow: 0 0 2px var(--core-bg);

    &.swiper-pagination-bullet-active {
      transform: scale(1.35);
    }
  }

  .swiper-pagination-bullets {
    padding-top: 2px;
    padding-bottom: 2px;
  }
  //#endregion
}
</style>