import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "c-form-group ion-padding-top" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_input_pos = _resolveComponent("input-pos")!
  const _component_ion_textarea = _resolveComponent("ion-textarea")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_pos_slim_layout = _resolveComponent("pos-slim-layout")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header, { title: _ctx.pageTitle }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_pos_slim_layout, { class: "ion-padding" }, {
            footer: _withCtx(() => [
              _createVNode(_component_ion_button, {
                color: "primary",
                expand: "block",
                shape: "round",
                class: "mt-2",
                onClick: _ctx.confirmAction,
                disabled: _ctx.buttonConfirmDisabled
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.buttonConfirmText), 1)
                ]),
                _: 1
              }, 8, ["onClick", "disabled"])
            ]),
            default: _withCtx(() => [
              _createVNode(_component_input_pos, {
                modelValue: _ctx.form.sum,
                "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.form.sum) = $event))
              }, null, 8, ["modelValue"]),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_ion_textarea, {
                  class: "core-input",
                  placeholder: _ctx.$t('views.pos_cash_in_or_out.input_comment'),
                  autocapitalize: "sentences",
                  rows: 3,
                  modelValue: _ctx.form.comment,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.form.comment) = $event))
                }, null, 8, ["placeholder", "modelValue"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}