import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveDynamicComponent as _resolveDynamicComponent, createBlock as _createBlock, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_col = _resolveComponent("ion-col")!
  const _component_ion_row = _resolveComponent("ion-row")!
  const _component_ion_grid = _resolveComponent("ion-grid")!
  const _component_c_content_loading = _resolveComponent("c-content-loading")!

  return (_openBlock(), _createBlock(_component_c_content_loading, { action: _ctx.loadVisits }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_grid, null, {
        default: _withCtx(() => [
          _createVNode(_component_ion_row, null, {
            default: _withCtx(() => [
              (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.visitsProcessing, (visit) => {
                return (_openBlock(), _createBlock(_component_ion_col, {
                  "size-xl": "3",
                  "size-lg": "4",
                  "size-sm": "6",
                  size: "12",
                  key: visit.id
                }, {
                  default: _withCtx(() => [
                    (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.cardComponent), {
                      class: "ion-no-margin",
                      visit: visit,
                      onClick: ($event: any) => (_ctx.onClickCard(visit))
                    }, null, 8, ["visit", "onClick"]))
                  ]),
                  _: 2
                }, 1024))
              }), 128))
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }, 8, ["action"]))
}