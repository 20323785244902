<template>
  <div class="c-progress-info">
    <div class="c-progress-info__message" v-show="ctx.message">
      {{ ctx.message }}
    </div>

    <c-progress-bar :color="color" :value="progressCoof" />
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, Ref } from 'vue';
import { ProgressInformation } from '@/composables/progress';

export type GetProgressContext = () => Ref<ProgressInformation>;

export default defineComponent({
  props: {
    getContext: {
      type: Function as PropType<GetProgressContext>,
      required: true
    },
    color: {
      type: String,
      required: false,
    }
  },

  setup(props) {
    // Может быть реактивным
    const ctx = props.getContext();
    const progressCoof = computed(() => ctx.value.progress / 100);

    return {
      ctx,
      progressCoof,
    };
  }
});
</script>

<style lang="scss">
.c-progress-info {
  padding: var(--ion-padding) 34px;
  max-width: 623px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  &__message {
    padding-bottom: 10px;
    color: var(--core-text);
    font-size: var(--core-font-size-large);
    text-align: center;
  }
}
</style>