<template>
  <ion-page>
    <app-header :title="$t('views.market_checkout.title')" />

    <ion-content>
      <visit-store-checkout
        class="ion-padding"
      />
    </ion-content>

    <app-footer />
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, provide } from 'vue';
import { useStore } from '@/composables/useApp';
import VisitStoreCheckout from '@/components/store/VisitStoreCheckout.vue';

export default defineComponent({
  components: {
    VisitStoreCheckout,
  },

  setup() {
    const store = useStore();

    provide('visitState', store.visit.newStoreVisitState);

    return {};
  }
});
</script>

<style lang="scss">
</style>