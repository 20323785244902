import { ref } from 'vue'
import { useStore } from './useApp'
import { DoerCollectionItem } from '@/repositories/Models/User'

export function useDoers() {
  const store = useStore();

  const doers = ref<DoerCollectionItem[]>([]);
  async function loadDoers() {
    const { data } = await store.user.getDoers();
    doers.value = data;
    return data;
  }

  return {
    doers,
    loadDoers,
  };
}