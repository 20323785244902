<template>
  <ion-card>
    <teleport :to="searchbarContainer" :disabled="searchbarTeleportDisabled">
      <div class="core-searchbar-mobile ion-padding">
        <ion-searchbar
          class="core-searchbar"
          inputmode="search"
          :placeholder="$t('components.services_provide_visit_state.input_search')"
          clear-icon="close-outline"
          search-icon="core-search"
          :debounce="300"
          v-model="searchWords"
        />
      </div>
    </teleport>

    <c-content-loading :action="contentLoading">
      <div class="padding-x-adaptive pb-3">
        <services-by-category-group
          v-for="(servicesGroup, i) in servicesGroups"
          :key="generateKeyProvideService(servicesGroup.typeId, servicesGroup.categoryId)"
          :context="servicesGroup"
          :search-words="searchWords"
          :short-title="shortGroupName"
          :is-collapsed-content="isCollapsedGroup"
          :show-content="(i < 1)"
          @toggle-services-group="toggleGroup"
          v-model:provided="providedServicesGroupValue[
            generateKeyProvideService(servicesGroup.typeId, servicesGroup.categoryId)
          ]"
        />

        <ion-row class="color-default size-default">
          <ion-col class="px-0 pb-0">
            {{ $t('components.services_provide_visit_state.label_total') }}
          </ion-col>

          <ion-col class="px-0 pb-0" size="auto">
            <cc :price="total" />
          </ion-col>
        </ion-row>
      </div>
    </c-content-loading>
  </ion-card>
</template>

<script lang="ts">
import { defineComponent, computed, inject, ref, watch, PropType } from 'vue';
import { ServiceByContextQuery, ServiceCategoriesCollectionItem } from '@/repositories/Models/Service';
import { isEmpty, pick } from 'lodash';
import { NewVisitSatate } from '@/store/NewVisitState';
import { useVisitProvidedServicesState } from '@/composables/visit';
import ServicesByCategoryGroup from './ServicesProvideByCategoryGroup.vue';
import { isTabletDown } from '@/helpers/adaptive';
import { useMetrica } from '@/composables/useMetrica';

export default defineComponent({
  components: {
    ServicesByCategoryGroup,
  },

  emits: ['input-search'],

  props: {
    restoreFilterBySelected: {
      type: Boolean,
      default: false,
    },
    shortGroupName: {
      type: Boolean,
      default: true,
    },
    mobileSearchbarContainer: {
      type: Object as PropType<HTMLDivElement|null>,
      required: false,
    },
    isCollapsedGroup: {
      type: Boolean,
      default: false,
    },
    servicesGroups: {
      type: Array as PropType<ServiceByContextQuery[]>,
      required: true,
    },
    contentLoading: {
      type: Function as PropType<() => Promise<void>>,
      default: () => Promise.resolve(),
    },
  },

  setup(props, { emit }) {
    const { emitEvent } = useMetrica();

    const newVisitSatete = inject<NewVisitSatate|null>('visitState', null);
    if (!newVisitSatete) throw new Error('Не передается обязательное состояние нового визита в магазин');

    const {
      providedServicesGroupValue,
      generateKeyProvideService,
      total,
    } = useVisitProvidedServicesState(newVisitSatete, {
      restoreFilterBySelected: props.restoreFilterBySelected,
    });

    watch(newVisitSatete, () => {
      // Если тело стало пустым, то произошла очистка состояния,
      // => нужно сбросить выбранные группы
      if (isEmpty(newVisitSatete.body)) {
        providedServicesGroupValue.value = {};
      }
    });

    const searchWords = ref('');
    const searchbarTeleportDisabled = computed(() => !(isTabletDown.value && props.mobileSearchbarContainer));
    const searchbarContainer = computed(() => searchbarTeleportDisabled.value ? null : props.mobileSearchbarContainer);
    watch(searchWords, s => emit('input-search', s));

    function toggleGroup(serviceCategory: ServiceCategoriesCollectionItem, state: boolean) {
      if (!state) {
        emitEvent('service/sub-category/collapsed', pick(serviceCategory, ['id', 'name']));
      }
    }

    return {
      providedServicesGroupValue,
      generateKeyProvideService,
      total,
      searchWords,
      searchbarTeleportDisabled,
      searchbarContainer,
      toggleGroup,
    };
  }
});
</script>

<style lang="scss">
</style>