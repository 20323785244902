<!-- 
  Компонент для отображения уведомления
  (используется на странице просмотра уведомлений)
 -->
<script lang="ts" setup>
import { defineProps, PropType, computed } from 'vue';
import { NotificationData } from '@/composables/notifications';
import { textLinkToHtmlLink, nl2br } from '@/utils/string';
import moment from 'moment';

const props = defineProps({
  notification: {
    type: Object as PropType<NotificationData>,
    required: true,
  },
});

const dateFormatted = computed<string>(() => {
  return moment(props.notification.createAt).calendar();
});


const preparedBody = computed(() => textLinkToHtmlLink(nl2br(props.notification.body || '')));
</script>

<template>
  <section class="notification-item">
    <span class="notification-item__new-badge" v-if="props.notification.meta.read !== true"></span>

    <h3 class="notification-item__header">
      {{ props.notification.title }}
    </h3>

    <!-- TODO: subtitle -->

    <p v-if="props.notification.body" class="notification-item__content" v-html="preparedBody"></p>

    <div class="notification-item__datetime">
      {{ dateFormatted }}
    </div>
  </section>
</template>

<style lang="scss">
.notification-item {
  --c-notification-new-badge: var(--ion-color-danger, #d44d4d);

  font-size: var(--core-font-size);
  padding: calc(var(--ion-padding) * 0.75) 0; // NOTE: <spacer> * 0.75 - примерно как в дизайне
  border-bottom: var(--core-divider-width) solid var(--core-divider-color);
  position: relative;

  &__header {
    font-size: var(--core-font-size-heading);
    font-weight: 500;
    line-height: 1.2em;
    margin: 0 0 var(--ion-margin);
    padding-right: 40px;
  }
  
  &__content {
    margin: 0;
    line-height: 1.3em;
    color: rgba(var(--core-light-rgb), 0.7);
  }

  &__datetime {
    font-size: var(--core-font-size-small);
    margin-top: var(--ion-margin);
    color: rgba(var(--core-light-rgb), 0.5);
  }

  &__new-badge {
    width: 8px;
    height: 8px;
    border-radius: 1000px;
    background: var(--c-notification-new-badge);
    display: block;
    position: absolute;
    right: 0;
    top: var(--ion-padding);
  }
}
</style>