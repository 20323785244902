<template>
  <ion-page>
    <app-header-new-visit
      :title="$t('views.visit_new_car_number.title')"
      :progress="0.125"
    />

    <ion-content :class="{
      'content-vertical-align': isPreentry,
    }">
      <div class="ion-padding page-content-visit-new-car-number">
        <!-- Во время предзаписи, фото не создаются -->
        <template v-if="!isPreentry">
          <visit-image-container class="ion-margin-bottom" rounded>
            <template #header-side>
              <visit-add-photo-button
                :visitId="visitId"
                :metrica-event-name="`visit/${visitType}/photo/add`"
                :router-link="addNewPhotoRouteLocation"
                @click="onClickAddPhoto"
              />
            </template>
            
            <template #default>
              <visit-images-slider :images="imagesItems" :show-no-image="true" />
            </template>
          </visit-image-container>
        </template>

        <input-car-number
          v-if="numberConfigLoaded"
          class="ion-margin-bottom"
          v-model="car.number"
          :car-number-placeholder="numberConfig.placeholder"
          :car-number-reg-exp="numberConfig.regexp"
        />

        <ion-button color="card" expand="block" @click="setOpenModal(true)">
          {{ $t('views.visit_new_car_number.btn_outher_number_format') }}
        </ion-button>

        <div class="fixed-actions-spacer"></div>
      </div>

      <!-- eslint-disable-next-line -->
      <ion-fab vertical="bottom" horizontal="end" slot="fixed">
        <ion-button color="primary" shape="round" @click="next">
          {{ $t('views.visit_new_car_number.btn_next') }}
        </ion-button>
      </ion-fab>

      <ion-modal 
        :is-open="isOpenModal"
        css-class="core-modal-actions modal-visit-new-car-number"
        @didDismiss="setOpenModal(false)"
        :swipe-to-close="true"
      >
        <div class="modal-visit-new-car-number__content">
          <c-modal-action-header>
            {{ $t('views.visit_new_car_number.modal_input_number_title') }}
          </c-modal-action-header>

          <div class="car-outher-number-input-wrap">
            <ion-input class="core-input" v-model="car.numberOther" />
          </div>

          <c-divider class="my-0" />

          <div class="p-3">
            <ion-button
              color="primary"
              expand="block"
              shape="round"
              @click="nextOtherCarNumber"
            >
              {{ $t('views.visit_new_car_number.modal_input_number_btn_next') }}
            </ion-button>
          </div>
        </div>
      </ion-modal>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, reactive, computed, onMounted } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import InputCarNumber from '@/components/car/InputCarNumber.vue';
import { RouteLocationRaw, useRoute, useRouter } from 'vue-router';
import { useToast } from '@/composables/toast';
import { useStore } from '@/composables/useApp';
import { getCarNumberPreparedAndType } from '@/utils/car';
import { CarNumberTypeEnum } from '@/repositories/Models/Car';
import { useCreateVisitStep } from '@/composables/visit';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
import { get } from 'lodash';
import { useVisitImages } from '@/composables/visit/visitImages';
import VisitImageContainer from '@/components/visit/VisitImageContainer.vue';
import VisitImagesSlider from '@/components/visit/VisitImagesSlider.vue';
import VisitAddPhotoButton from '@/components/visit/VisitAddPhotoButton.vue';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
    InputCarNumber,
    VisitImageContainer,
    VisitImagesSlider,
    VisitAddPhotoButton,
  },
  setup() {
    const router = useRouter();
    const route = useRoute();
    const toast = useToast();
    const store = useStore();
    const { isPreentry, visitType, visitState } = useCreateVisitStep();
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    const visitId = '0'; // NOTE: Т.к. черновиков пока нет, такой идентификатор допустим, при создании
    const { imagesItems } = useVisitImages({ visitId });

    const car = reactive({
      number: visitState.meta.savedCarNumber || '',
      numberOther: visitState.meta.savedCarNumberOther || '',
      createLoading: false,
    });

    const isOpenModal = ref(false);
    function setOpenModal(value: boolean) {
      isOpenModal.value = value;
    }

    const numberConfig = computed(() => store.config.cidConfigPrepared.value.inputCarNumber);
    const numberConfigLoaded = computed(() => !!store.config.cidConfig);
    onMounted(async () => {
      if (route.query.number) {
        // Обязательно предварительно нужно прогрузить конфиг!
        // Это может понадобится, если данную страницу сразу открыть в браузере или перезагрузить ее,
        // в этом случае конфиг скореевсего еще не успееет загрузиться с сервера.
        await store.config.loadCIDConfig();

        const recognize = getCarNumberPreparedAndType(route.query.number as string, {
          translitChars: numberConfig.value.translitChars,
          typesRecognition: numberConfig.value.typesRecognition,
        });
        if (recognize.numberType === CarNumberTypeEnum.Citizen) {
          car.number = recognize.number;
        } else {
          car.numberOther = recognize.number;
          isOpenModal.value = true;
        }
      }
    });

    /**
     * Создать новый автомобиль
     * 
     * @throws {Error|ClientError}
     * @returns Идентификатор созданного автомобиля
     */
    async function createCar(carNumber: string) {
      try {
        const { number, numberType } = getCarNumberPreparedAndType(carNumber, {
          translitChars: numberConfig.value.translitChars,
          typesRecognition: numberConfig.value.typesRecognition,
        });

        const createInfo = await store.car.create({
          id: number, // ID -> номер автомобиля (так реализованно в предыдущем приложении для айпада)
          number,
          numberType,
        });

        // TODO: Здесь должен происходить запрос на получение последнего фото автомобиля
        // visitState.meta.visitPhotoUrl = 'https://core12.inaliv.ru/__tmp/muscule_car.jpg';
        visitState.meta.visitPhotoUrl = null;

        // Последние категрии клиента
        visitState.meta.lastCategoryId = get(createInfo.response, 'data.priceCategories.0.id', null);
        visitState.meta.lastTypeCategoryId = get(createInfo.response, 'data.priceCategories.0.pointType.id', null);

        // if (createInfo.sendingDelayed) {
        //   toast.warning('Интернет недоступен, данные сохранены, и синхрнизируются после пояления интернета');
        // }
        // else {
        //   toast.success('Автомобиль успешно добавлен')
        // }

        return createInfo.entityId;
      } catch (e) {
        toast.error(e, 5000, {
          defaultHeader: t('views.visit_new_car_number.header_error_create_car')
        });

        throw e;
      }
    }

    /**
     * Добавить автомобиль и перейти к заполнению информации о клиенте
     */
    async function toNewVisitClient(carNumber: string) {
      if (!carNumber) {
        return toast.error(t('views.visit_new_car_number.error_empty_car_number'));
      }

      const carId = await createCar(carNumber); // throw exit

      router.push({
        name: isPreentry ? 'preentry-client' : 'visit-new-client',
        params: { carId },
      });
    }

    const addNewPhotoRouteLocation = computed<RouteLocationRaw>(() => {
        if (imagesItems.value.length > 1) {
          return {
            name: 'visit-new-photo-gallery',
            params: { visitId },
          };
        }
        
        return {
          name: 'visit-new-create-photo',
          params: { visitId },
        };
    });
    
    function next() {
      emitEvent(`visits/${visitType}/car-number/next/citizen`);
      toNewVisitClient(car.number);
    }

    function nextOtherCarNumber() {
      emitEvent(`visits/${visitType}/car-number/next/other`);
      toNewVisitClient(car.numberOther);
      setOpenModal(false);
    }

    function onClickAddPhoto() {
      // Сохраняем последние введеные номера (а то при покторном открытии данного экрана они не восстановятся)
      visitState.meta.savedCarNumber = car.number;
      visitState.meta.savedCarNumberOther = car.numberOther;
    }

    return {
      car,
      isOpenModal,
      setOpenModal,
      nextOtherCarNumber,
      next,
      isPreentry,
      numberConfig,
      numberConfigLoaded,
      imagesItems,
      visitId,
      visitType,
      addNewPhotoRouteLocation,
      onClickAddPhoto,
    };
  },
});
</script>

<style lang="scss">
@import "@/scss/abstract";

.page-content-visit-new-car-number {
  max-width: 625px;
  margin-left: auto;
  margin-right: auto;

  @include ionic-tablet {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    justify-content: center;
    min-height: 100%;
  }
}

.modal-visit-new-car-number {
  @include ionic-tablet {
    --width: auto;

    &__content {
      max-width: 450px;
      width: 100vw;
    }
  }
}

.car-outher-number-input-wrap {
  padding: 16px var(--ion-padding);
}

</style>