import { toDisplayString as _toDisplayString, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "c-progress-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_c_progress_bar = _resolveComponent("c-progress-bar")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("div", { class: "c-progress-info__message" }, _toDisplayString(_ctx.ctx.message), 513), [
      [_vShow, _ctx.ctx.message]
    ]),
    _createVNode(_component_c_progress_bar, {
      color: _ctx.color,
      value: _ctx.progressCoof
    }, null, 8, ["color", "value"])
  ]))
}