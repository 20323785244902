import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, Teleport as _Teleport, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementBlock as _createElementBlock, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "core-searchbar-mobile ion-padding" }
const _hoisted_2 = { class: "padding-x-adaptive" }
const _hoisted_3 = {
  key: 1,
  class: "ion-text-center ion-padding"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_searchbar = _resolveComponent("ion-searchbar")!
  const _component_services_view_category_group = _resolveComponent("services-view-category-group")!
  const _component_ion_card = _resolveComponent("ion-card")!

  return (_openBlock(), _createBlock(_component_ion_card, null, {
    default: _withCtx(() => [
      (_openBlock(), _createBlock(_Teleport, {
        to: _ctx.searchbarContainer,
        disabled: _ctx.searchbarTeleportDisabled
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_ion_searchbar, {
            class: "core-searchbar",
            inputmode: "search",
            placeholder: _ctx.$t('components.services_view.input_search'),
            "clear-icon": "close-outline",
            "search-icon": "core-search",
            debounce: 300,
            modelValue: _ctx.searchWords,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchWords) = $event))
          }, null, 8, ["placeholder", "modelValue"])
        ])
      ], 8, ["to", "disabled"])),
      _createElementVNode("div", _hoisted_2, [
        (_ctx.catId)
          ? (_openBlock(), _createBlock(_component_services_view_category_group, {
              key: 0,
              "cat-id": _ctx.catId,
              "search-words": _ctx.searchWords
            }, null, 8, ["cat-id", "search-words"]))
          : (_openBlock(), _createElementBlock("div", _hoisted_3, _toDisplayString(_ctx.$t('components.services_view.text_category_empty_selected')), 1))
      ])
    ]),
    _: 1
  }))
}