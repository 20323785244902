<!-- 
  Модальное окно со списком уведомлений
 -->
<script lang="ts" setup>
import { modalController } from '@ionic/vue';
import NotificationItem from '@/components/service/notification/NotificationItem.vue';
import { useNotifications } from '@/composables/notifications';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const { loadNotifications, notifications, markAllAsRead } = useNotifications();

async function loadNotificationsAndMarkRead() {
  await loadNotifications();
  setTimeout(markAllAsRead, 2000);
}

function close() {
  return modalController.dismiss()
}
</script>

<template>
  <app-header :title="t('views.notifications.notifications_modal_title')">
    <template #end>
      <c-transparent-button @click="close">
        <ion-icon name="close-outline" class="icon-medium" />
      </c-transparent-button>
    </template>
  </app-header>

  <ion-content class="ion-padding">
    <c-content-loading :action="loadNotificationsAndMarkRead">
      <div class="ion-text-center text-muted ion-margin" v-if="notifications.length === 0">
        {{ t('views.notifications.notifications_list_empty') }}
      </div>

      <notification-item
        v-for="notification in notifications"
        :key="notification.id"
        :notification="notification"
      />
    </c-content-loading>
  </ion-content>

  <ion-footer></ion-footer>
</template>
