<template>
  <c-modal-action-header tag="ion-header" :show-close="userCloseModal">
    <template #start>
      <ion-button class="c-modal-header-back-button" color="transparent-text" @click="back">
        <ion-icon name="chevron-back-outline" />
      </ion-button>
    </template>
    {{ $t('components.add_category_modal.view_discount_title') }}
  </c-modal-action-header>

  <c-modal-content>
    <services-provide-choice-discounts
      class="ion-padding-horizontal"
      state-inject-key="visitState"
    />
  </c-modal-content>

  <ion-footer>
    <div class="c-select-discount-view__footer-total">
      <div class="c-select-discount-view__footer-total-text">
        {{ $t('components.add_category_modal.view_provided_services_label_total') }}
      </div>

      <div class="c-select-discount-view__footer-total-price">
        <cc :price="total" />
      </div>
    </div>

    <div class="core-modal-footer-toolbal">
      <ion-button color="primary" expand="block" shape="round" @click="apply">
        {{ $t('components.add_category_modal.view_discount_btn_apply') }}
      </ion-button>
    </div>
  </ion-footer>
</template>

<script lang="ts">
import { defineComponent, PropType, provide, computed } from 'vue';
import type { ModalServicesContext } from '@/composables/visit';
import { useModalNav } from './modal';
import { ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import ServicesProvideChoiceDiscounts from '../provide-form/ServicesProvideChoiceDiscounts.vue';
import { prepareProvidedServicesBodyItems } from '@/helpers/visit';

export default defineComponent({
  inheritAttrs: false,

  components: {
    ServicesProvideChoiceDiscounts,
  },

  props: {
    // HACK: Не передаем значения напрямую,
    // т.к. это вызовет проблемы с обновлением props значений
    useModalServicesContext: {
      type: Function as PropType<() => ModalServicesContext>,
      required: true,
    },

    setProvidedServices: {
      type: Function as PropType<(providedServices: ProvideServiceBodyItem[]) => void>,
      required: false,
    },
    
    userCloseModal: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const nav = useModalNav();
    const ctx = props.useModalServicesContext();
    provide('visitState', ctx.visitState);

    const providedServices = computed(() => ctx.visitState.body.providedServices || []);

    function apply() {
      props.setProvidedServices?.call(null, [...providedServices.value]);
    }

    const total = computed(() => {
      return prepareProvidedServicesBodyItems(providedServices.value)
        .reduce((s, item) => s + item.totalPrice, 0)
      ;
    });

    return {
      apply,
      total,
      back: () => nav.backView(),
    };
  },
});
</script>

<style lang="scss">
.c-select-discount-view { // TODO: Сделать универсальным (с ./SelectProvidedServicesView.vue)
  &__footer-total {
    display: flex;
    margin: 0 var(--ion-padding);
    padding: var(--core-spacer-small) 0;
    align-items: center;
    min-height: 55px;
  }

  &__footer-total-text {
    flex: 1 1 0%;
  }
}
</style>