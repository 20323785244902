import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "ion-padding" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_app_header = _resolveComponent("app-header")!
  const _component_services_categories = _resolveComponent("services-categories")!
  const _component_services_view = _resolveComponent("services-view")!
  const _component_tablet_side_layout = _resolveComponent("tablet-side-layout")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_app_footer = _resolveComponent("app-footer")!
  const _component_ion_page = _resolveComponent("ion-page")!

  return (_openBlock(), _createBlock(_component_ion_page, null, {
    default: _withCtx(() => [
      _createVNode(_component_app_header, { title: _ctx.pageTitle }, null, 8, ["title"]),
      _createVNode(_component_ion_content, null, {
        default: _withCtx(() => [
          _createVNode(_component_tablet_side_layout, null, {
            side: _withCtx(() => [
              _createVNode(_component_services_categories, {
                "type-id": _ctx.typeId,
                onSelectCategory: _ctx.selectCategory,
                "tablet-adaptive": false,
                "selected-cat-id": _ctx.catId,
                onTypeLoaded: _ctx.onTypeLoaded
              }, null, 8, ["type-id", "onSelectCategory", "selected-cat-id", "onTypeLoaded"])
            ]),
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_services_view, {
                  class: "m-0",
                  "cat-id": _ctx.catId,
                  onInputSearch: _ctx.inputSearchDebounce
                }, null, 8, ["cat-id", "onInputSearch"])
              ])
            ]),
            _: 1
          })
        ]),
        _: 1
      }),
      _createVNode(_component_app_footer)
    ]),
    _: 1
  }))
}