<template>
  <ion-page>
    <app-header-big class="login-header" />

    <ion-content class="content-beetween" :scroll-y="false">
      <div />
      
      <!--
        NOTE: v-if="!isAuth" - fix: лишнее появление формы, до момента
        перенапрвления, в случае если пользователь уже авторизован.
      -->
      <div class="ion-padding-horizontal ion-padding-bottom" v-if="!isAuth">
        <div class="p-login-logo">
          <img src="@/img/core12-logo.svg" alt="CORE12" />
          <span>Администратор</span>
        </div>
          
        <login-form @success="loginned" />
      </div>

      <div />
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import LoginForm from '@/components/user/LoginForm.vue';
import { phonePrepare } from '@/utils/string';
import { useSettingsApp } from '@/composables/useSettingsApp';
import { useNavManager } from '@/composables/useNavManager';
import { useApp, useStore } from '@/composables/useApp';
import { useMetrica } from '@/composables/useMetrica';

const PHONE_DEFAULT = '+7 (499) 346-09-00';

export default defineComponent({
  components: {
    LoginForm,
  },

  setup() {
    const store = useStore();
    const app = useApp();
    const navManager = useNavManager();
    const { openSettings } = useSettingsApp();
    const { emitEvent } = useMetrica();

    if (store.user.isAuth) {
      navManager.navigate({
        routerDirection: 'root',
        routerLink: {
          name: 'visits',
        }
      });
    }

    const supportPhone = { // TODO: Брать номер из конфига [config]
      text: PHONE_DEFAULT,
      link: 'tel:' + phonePrepare(PHONE_DEFAULT, '+74993460900'),
    };

    function toHome() {
      navManager.navigate({
        routerDirection: 'root',
        routerLink: { name: 'visits' },
      });
    }

    function loginned() {      
      app.loginPreloadCacheJob(); // async
      emitEvent('user/login');
      toHome();
    }

    return {
      loginned,
      supportPhone,
      openSettings,
      isAuth: store.user.isAuth,
    };
  },
});
</script>

<style lang="scss">
.p-login-logo {
  margin: 0 auto 4vh;
  display: flex;
  max-width: 335px;
  align-items: center;
  justify-content: center;

  img {
    display: block;
  }

  span {
    margin-left: 15px;
    font-size: 24px;
  }
}

.login-header {
  ion-toolbar {
    --background: transparent;
    --min-height: 60px;
  }

  .header-big-title {
    display: none;
  }
}
</style>