<template>
  <c-content-loading :action="loadVisits">
    <ion-grid>
      <ion-row>
        <ion-col
          size-xl="3" size-lg="4" size-sm="6" size="12"
          v-for="visit in visitsProcessing"
          :key="visit.id"
        >
          <component :is="cardComponent"
            class="ion-no-margin"
            :visit="visit"
            @click="onClickCard(visit)"
          />
        </ion-col>
      </ion-row>
    </ion-grid>
  </c-content-loading>
</template>

<script lang="ts">
import { computed, defineComponent, ref, watch } from 'vue';
import { useRouter } from 'vue-router';
import { CarVisitCollectionItem } from '@/repositories/Models/CarVisit';
import { useStore } from '@/composables/useApp';
import VisitCardProcessing from '@/components/visit/VisitCardProcessing.vue';
import VisitCardMini from '@/components/visit/VisitCardMini.vue';

export default defineComponent({
  components: {
    VisitCardProcessing,
    VisitCardMini,
  },
  props: {
    viewType: {
      type: String,
      default: 'normal'
    }
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const visitsCounters = store.visit.getCountersRef();
    const visitsProcessing = ref<CarVisitCollectionItem[]>([]);

    const cardComponent = computed(() => props.viewType === 'mini' ? 'VisitCardMini' : 'VisitCardProcessing');

    async function loadVisits() {
      visitsProcessing.value = await store.visit.getWorkVisits();
    }

    let emptyInited = !!visitsCounters.value.emptyInited;
    watch(visitsCounters, () => {
      // Чтобы при первом запуске не делать лишний повторный запрос 
      if (emptyInited === true) {
        emptyInited = false;
        return;
      }

      loadVisits();
    }, { deep: true });

    function onClickCard(visit: CarVisitCollectionItem) {
      router.push({
        name: 'visit-single',
        params: { visitId: visit.id }
      });
    }

    return {
      visitsProcessing,
      loadVisits,
      onClickCard,
      cardComponent,
    };
  },
});
</script>

<style lang="scss">
</style>