import { ref, provide, inject } from 'vue';
export const PROVIDE_SCROLL_CONTEXT = 'scrollingInfinite/ctx';

export interface ScrollEventCtx {
  scrollTop: number;
  containerEl: HTMLDivElement;
  scrollContentEl: HTMLDivElement;
}

export function createScrollInfiniteContext() {
  const scrollTop = ref(0);
  const wheelTransition = ref(false);

  const containerEl = ref<HTMLDivElement|null>(null);
  const scrollContentEl = ref<HTMLDivElement|null>(null);

  const moveStartScrollTop = ref<number>(0);

  return {
    scrollTop,
    moveStartScrollTop,
    wheelTransition,
    containerEl,
    scrollContentEl,
  };
}

export function useScrollInfinite() {
  const ctx = createScrollInfiniteContext();
  provide(PROVIDE_SCROLL_CONTEXT, ctx);
  return {
    ...ctx as Omit<ScrollInfiniteContext, "moveStartScrollTop">,

    updateSilentlyScrollTop(value: number) {
      const delta = value - ctx.scrollTop.value;
      ctx.scrollTop.value = value;
      ctx.moveStartScrollTop.value += delta;
    }
  };
}

export function getScrollInfiniteContext(): ScrollInfiniteContext {
  const ctx = inject<ScrollInfiniteContext|null>(PROVIDE_SCROLL_CONTEXT, null);
  return ctx || createScrollInfiniteContext();
}

export type ScrollInfiniteContext = ReturnType<typeof createScrollInfiniteContext>;
export type UseScrollInfinite = ReturnType<typeof useScrollInfinite>;