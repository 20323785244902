import { VirtualKeyboardContext } from '@/composables/useVirtualKeyboard';
import { Directive, DirectiveBinding } from 'vue';

export function getEventNames(el: HTMLElement) {
  switch (el.tagName.toLowerCase()) {
    case 'ion-textarea':
    case 'ion-input':
      return {
        focus: 'ion-focus',
        blur: 'ion-blur',
        input: 'ion-input',
      };
  }

  return {
    focus: 'focus',
    blur: 'blur',
    input: 'input',
  };
}

function bindEvents(input: HTMLElement|undefined, ctx: VirtualKeyboardContext|undefined) {
  if (input && ctx) {
    const evNames = getEventNames(input);

    input.addEventListener(evNames.focus, ctx.onFocus as EventListener);
    input.addEventListener(evNames.blur, ctx.onBlur as EventListener);
    input.addEventListener(evNames.input, ctx.onInput as EventListener);
  }
}

function unbindEvents(input: HTMLElement|undefined, ctx: VirtualKeyboardContext|null) {
  if (input && ctx) {
    const evNames = getEventNames(input);

    input.removeEventListener(evNames.focus, ctx.onFocus as EventListener);
    input.removeEventListener(evNames.blur, ctx.onBlur as EventListener);
    input.removeEventListener(evNames.input, ctx.onInput as EventListener);
  }
}

export const virtualKeyboard: Directive = {
  async mounted(el: HTMLElement, binding: DirectiveBinding<VirtualKeyboardContext>) {
    bindEvents(el, binding.value);
  },

  async beforeUnmount(el: HTMLElement, binding: DirectiveBinding<VirtualKeyboardContext>) {
    unbindEvents(el, binding.value);
  },

  async updated(el: HTMLElement, binding: DirectiveBinding<VirtualKeyboardContext>) {
    if (binding.oldValue !== binding.value) {
      unbindEvents(el, binding.oldValue);
      bindEvents(el, binding.value);
    }
  },
};

export default virtualKeyboard;