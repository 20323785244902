<template>
  <div class="c-pos-slim-layout">
    <div class="c-pos-slim-layout__content">
      <div class="c-pos-slim-layout__inner-scroll">
        <slot />
      </div>
    </div>

    <div class="c-pos-slim-layout__footer" v-if="$slots.footer">
      <slot name="footer" />
    </div>
  </div>
</template>

<style lang="scss">
@import '@/scss/abstract';

.c-pos-slim-layout {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  min-height: 100%;
  justify-content: space-between;
  max-width: 390px;
  margin: 0 auto;

  &__content {
    position: relative;
    flex: 1 1 0%;
  }

  &__inner-scroll {
    @include parent-full-size;
    position: absolute;
    z-index: 0;
    overflow-y: auto;
  }

  @include ionic-tablet {
    justify-content: center;

    &__content {
      flex: 0 0 0%;
    }

    &__inner-scroll {
      position: relative;
      top: auto;
      left: auto;
      right: auto;
      bottom: auto;
      width: auto;
      height: auto;
    }
  }
}
</style>