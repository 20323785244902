import { computed, ref, Ref, UnwrapRef, watch, onMounted, provide, WatchStopHandle } from 'vue';
import { useRouter, useRoute } from 'vue-router';
import { get, includes, isEqual, toNumber } from 'lodash';
import carNoImage from '@/img/car-no-image.svg';
import { carNumberTranslitEnToRu } from '@/utils/car';
import moment from 'moment';
import { useApp, useStore } from './useApp';
import { CarVisitBodyRequest, CarVisitCheckupEnum, ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import { clearNewVisitState, NewVisitSatate } from '@/store/NewVisitState';
import { VisitStore } from '@/store/VisitStore';
import { asyncComputed } from '@/helpers/vue';
import { currentTimeMoment as currentTimeMomentGlobal } from '@/helpers/datetime';
import { useI18n } from 'vue-i18n';
import { modalController } from '@ionic/vue';
import GroupPartnerSelectBox from '@/components/group-partner/GroupPartnerSelectBox.vue';
import { useToast } from './toast';
import { mergeCarVisitResponseToNewVisitState } from '@/helpers/visit'

import {
  CarVisitExt,
  CarVisitResponse,
  CarVisitStatusEnum
} from '@/repositories/Models/CarVisit';

import {
  filterByIssetTypesAndCategories,
  mapProvideServicesBodyItemsForGroup,
  ProvideServicesBodyItemsGroup,
  generateKeyProvideService,
  provideServicesBodyGroupToList
} from '@/helpers/visit';

export function useVisit(visit: Ref<CarVisitExt>) {
  const totalPrice = computed(() => visit.value.price);
  const formatCarNumber = computed(() => carNumberTranslitEnToRu(visit.value?.car?.number));
  const hasCarNumber = computed(() => !!visit.value?.car?.number);
  const isPreentry = computed(() => {
    return visit.value?.status === CarVisitStatusEnum.New
    && visit.value?.checkup === CarVisitCheckupEnum.PendingPreentry;
  });
  const images = computed(() => {
    if (visit.value.frames?.length) {
      const frames = visit.value.frames.map(img => img.image.thumbUrl)
      return frames || [carNoImage]
    }

    // TODO: Если оффлайн, то доставать изображение из кэша, если оно там есть

    return [carNoImage];
  });

  const preentryDateFormat = computed(() => moment(visit.value?.creationDate).format('D MMMM YYYY'));
  const preentryTimeFormat = computed(() => moment(visit.value?.creationDate).format('H:mm'));

  return {
    totalPrice,
    formatCarNumber,
    hasCarNumber,
    images,
    isPreentry,
    preentryDateFormat,
    preentryTimeFormat,
  };
}

//#region Рассчет времени обслуживания
export function useVisitTimings(visit: Ref<CarVisitResponse>) {
  const store = useStore();
  const { t } = useI18n();
  const processedDate = computed(() => visit.value.processedDate ? moment(visit.value.processedDate) : null);

  // FIXME: Так рассчитывать время не стоит, но в API сейчас нет возможности
  // сразу определить время для конкретной услуги, приходится подгружать
  // и рассчитывать их из общего списка с услугами. В будущем надо переделать логику.
  const normativMinutes = asyncComputed<number>(async () => {
    return await store.service.calcNormativesProvideService(visit.value.providedServices || []);
  }, 0);

  //#region Текущее время
  // NOTE: Опимизация текущего времени, чтобы
  // не происходило постоянного обновления компонентов
  const currentTimeMoment = ref(moment());
  let watchStopTimeMomentGlobal: WatchStopHandle|null = null;

  function subscribeGlobalTime() {
    unsubscribeGlobalTime();

    const isSubscribe = visit.value.status === CarVisitStatusEnum.Processed;

    if (isSubscribe) {
      watchStopTimeMomentGlobal = watch(currentTimeMomentGlobal, t => currentTimeMoment.value = t);
    }
  }

  function unsubscribeGlobalTime() {
    if (watchStopTimeMomentGlobal) {
      watchStopTimeMomentGlobal();
      watchStopTimeMomentGlobal = null;
    }
  }

  watch(() => [visit.value.status, normativMinutes], () => subscribeGlobalTime(), { immediate: true });
  //#endregion

  /** Рассчитанное время окончания на основе нормативов  */
  const predictedFinishDate = computed(() => {
    return processedDate.value
      ? processedDate.value.clone().add(normativMinutes.value, 'minute')
      : null;
  });

  /** Значение прогресса в процентных единицах (от 0 до 1) */
  const progressValue = computed<number>(() => {
    if (includes([
      CarVisitStatusEnum.Finished,
      CarVisitStatusEnum.Archive,
      CarVisitStatusEnum.Opened
    ], visit.value.status)) {
      unsubscribeGlobalTime();
      return 1;
    }

    // Если норматив времени еще не рассчитан или его не удалось
    // рассчитать, то полоса прогресса должна быть полупустой
    if (!normativMinutes.value) {
      unsubscribeGlobalTime();
      return 0.0;
    }

    if (
      !processedDate.value
      || !predictedFinishDate.value
      || !includes([CarVisitStatusEnum.Processed], visit.value.status)
    ) {
      unsubscribeGlobalTime();
      return 0;
    }

    const stu = processedDate.value.unix();
    const etu = predictedFinishDate.value.unix();
    const ctu = currentTimeMoment.value.unix();

    return Math.max(0, Math.min(1, (ctu - stu) / (etu - stu)));
  });

  /** Текстовый статус выполнения */
  const progressText = computed(() => {
    if (!processedDate.value || !predictedFinishDate.value) {
      unsubscribeGlobalTime();
      return t('composables.visit_timings.progress_text_wait');
    }

    if (visit.value.status === CarVisitStatusEnum.Finished) {
      unsubscribeGlobalTime();
      return t('composables.visit_timings.progress_text_completed');
    }

    if (currentTimeMoment.value.unix() > predictedFinishDate.value.unix()) {
      // TODO: Возможно добавить оптимизацию, путем замены динамического
      // currentTimeMoment на статичное время окончания, чтобы при изменении не пересчитывать
      unsubscribeGlobalTime();
      return t('composables.visit_timings.progress_text_time_over');
    }

    return t('composables.visit_timings.progress_text_time_left', {
      left: moment().to(predictedFinishDate.value, true)
    });
  });

  /** Цвет прогресс бара */
  const progressColor = computed(() => {
    if (visit.value.status === CarVisitStatusEnum.Finished) {
      return 'success';
    }

    if (visit.value.status === CarVisitStatusEnum.Canceled || progressValue.value >= 0.95) {
      return 'danger';
    }

    if (progressValue.value >= 0.75) {
      return 'primary';
    }

    return 'secondary';
  });

  return {
    progressText,
    progressValue,
    progressColor,
    predictedFinishDate,
    normativMinutes,
    processedDate,
  };
}
//#endregion

//#region Метки с информацией о состоянии заказа
export interface VisitBadgeItem {
  text: string;
  color: string;
  icon?: string;
}

export function useVisitBadges(visit: Ref<CarVisitExt|null>) {
  const { t } = useI18n();

  const isFinished = computed(() => visit.value?.status === CarVisitStatusEnum.Finished);
  const isCanceled = computed(() => visit.value?.status === CarVisitStatusEnum.Canceled);
  const isPayed = computed(() => !!visit.value?.isPayed);

  const isOpened = computed(() => includes([
    CarVisitStatusEnum.New,
    CarVisitStatusEnum.Processed,
    CarVisitStatusEnum.Opened
  ], visit.value?.status));

  const isClosed = computed(() => {
    return isPayed.value && includes(
      [
        CarVisitStatusEnum.Finished,
        CarVisitStatusEnum.Archive,
      ],
      visit.value?.status
    );
  });

  const isPreentry = computed(() => {
    return (visit.value?.status === CarVisitStatusEnum.New)
      && (visit.value?.checkup === CarVisitCheckupEnum.PendingPreentry);
  });

  const badgeList = computed(() => {
    let list: VisitBadgeItem[] = [];

    if (isClosed.value) {
      list.push({ text: t('composables.visit_badges.label_closed'), color: 'secondary' });
    }
    
    else if (isFinished.value) {
      list.push({ text: t('composables.visit_badges.label_finished'), color: 'success' });
    }
    
    // Прездапись
    else if (isPreentry.value) {
      let icon = 'core-label-preentry-admin';

      if (visit.value?.labels?.includes('yandex')) {
        icon = 'core-label-preentry-yandex-map';
      } else if (visit.value?.labels?.includes('telegram')) {
        icon = 'core-label-preentry-telegram';
      }

      list.push({ text: t('composables.visit_badges.label_preentry'), color: 'light', icon });
    }
    
    else if (isOpened.value) {
      list.push({ text: t('composables.visit_badges.label_opened'), color: 'secondary' });
    }
    
    else if (isCanceled.value) {
      list.push({ text: t('composables.visit_badges.label_canceled'), color: 'danger' });
    }

    if (!isPreentry.value) {
      if (isPayed.value) {
        if (!isClosed.value) {
          list.push({ text: t('composables.visit_badges.label_paid'), color: 'success' });
        }
      } else {
        list.push({ text: t('composables.visit_badges.label_not_paid'), color: 'primary' });
      }
    }

    // TODO: В будущем реализовать режим разработчика и в нем отображать данную метку
    // if (visit.value?.isOffline) {
    //   list.push({ text: t('composables.visit_badges.label_not_sync'), color: 'warning' });
    // }

    return list;
  });

  return {
    badgeList,
    isOpened,
    isClosed,
    isFinished,
    isCanceled,
    isPayed,
  };
}
//#endregion

export interface VisitProvidedServicesStateOptions {
  /** Удалять разделы, которые не указаны в списке выбранных (по умолчанию нет) */
  restoreFilterBySelected?: boolean;

  /** Генерировать providedServicesGroupValue из переданного состояния */
  restore?: boolean;
}

export function useVisitProvidedServicesState(
  newVisitState: UnwrapRef<NewVisitSatate>,
  options: VisitProvidedServicesStateOptions = {}
) {
  const providedServicesGroupValue = ref<ProvideServicesBodyItemsGroup>({});
  const providedServicesList = computed(() => provideServicesBodyGroupToList(providedServicesGroupValue.value));

  const total = computed(() => providedServicesList.value.reduce((s, ps) => s + ps.totalPrice, 0));

  function restoreProvidedServices() {
    const initialProvidedServices: ProvideServiceBodyItem[] = get(newVisitState, 'body.providedServices', []);
    let preparedProvidedServices = mapProvideServicesBodyItemsForGroup(initialProvidedServices);

    if (options.restoreFilterBySelected) {
      preparedProvidedServices = filterByIssetTypesAndCategories(
        preparedProvidedServices,
        newVisitState.selectedTypeAndCategories
      );
    }

    providedServicesGroupValue.value = preparedProvidedServices;

    // Если данной условие сработало - это означает что за время между
    // выбором услуг и текущем временем был изменен список с услугами
    if (providedServicesList.value.length !== initialProvidedServices.length) {
      saveProvidedServices();
    }
  }

  function saveProvidedServices() {
    // FIXME: исправить ошибочное генерирование события обновления где-то внутри компонентов
    // hotfix: При старте частенько генерируется событие обновления значени,
    // в некоторых частях приложение из-за этого работает не как ожидается
    if (isEqual(newVisitState.body.providedServices, providedServicesList.value)) return;

    newVisitState.body.providedServices = providedServicesList.value;
  }

  if (false !== options.restore) {
    restoreProvidedServices();
  }

  watch(providedServicesGroupValue, () => {
    // Удалим все undefined для моего спокойствия
    // for (const keyProvideService of Object.keys(providedServicesGroupValue.value)) {
    //   if (!providedServicesGroupValue.value[keyProvideService]) {
    //     delete providedServicesGroupValue.value[keyProvideService];
    //   }
    // }

    saveProvidedServices();
  }, { deep: true });

  return {
    total,
    providedServicesGroupValue,
    providedServicesList,
    saveProvidedServices,
    restoreProvidedServices,
    generateKeyProvideService,
  };
}

export interface NewVisitPhotoOptions {
  /** [default: true] */
  autoloadCache?: boolean;

  visitState?: NewVisitSatate;

  /** Ключ по которому хранится ранее сохраненное изображение в БД */
  visitPhotoKey?: string;

  /** Использовать значение только из мета-поля */
  onlyMeta?: boolean;
}

const NEW_VISIT_PHOTO_KEY_DEFAULT = 'newVisitPhoto';

/**
 * 
 * @deprecated Следует использовать useVisitFrames
 * 
 * @param options 
 * @returns 
 */
export function useNewVisitPhoto(options: NewVisitPhotoOptions = {}) {
  const visitPhotoKey = options.visitPhotoKey || NEW_VISIT_PHOTO_KEY_DEFAULT;

  const { getDb } = useApp();
  const visitPhoto = ref<null|string>(null);
  const visitImageCover = computed(() => visitPhoto.value || carNoImage);

  async function getNewVisitPhotoFromDb() {
    const db = getDb();

    const row = await db.keyValue.get({ key: visitPhotoKey });
    return row ? row.value as string|null : null;
  }

  async function loadNewVisitPhoto() {
    const visitPhotoUrl = get(options.visitState, 'meta.visitPhotoUrl', null);

    if (visitPhotoUrl) {
      // Если в состоянии у мета-поля есть ссылка, то необходимо брать именно
      // это значение (это используется для предварительной записи например,
      // когда мы кэшируем фото автомобиля с последнего визита).
      visitPhoto.value = visitPhotoUrl;
    } else if (options.onlyMeta) {
      visitPhoto.value = null;
    } else {
      visitPhoto.value = await getNewVisitPhotoFromDb();
    }
  }

  async function saveNewVisitPhotoToDb(imageBase64: string|null) {
    const db = getDb();

    if (imageBase64) {
      await db.keyValue.put({
        key: visitPhotoKey,
        value: imageBase64
      });
    } else {
      await db.keyValue.delete(visitPhotoKey);
    }

    visitPhoto.value = imageBase64;
  }

  async function removeNewVisitPhotoInDb() {
    const db = getDb();
    await db.keyValue.delete(visitPhotoKey);
  }

  if (options.autoloadCache !== false) {
    onMounted(loadNewVisitPhoto);
  }

  return {
    visitPhoto,
    visitImageCover,
    getNewVisitPhotoFromDb,
    loadNewVisitPhoto,
    saveNewVisitPhotoToDb,
    removeNewVisitPhotoInDb,
  };
}

//#region Create visit steps helpers
export type VisitStateType = 'preentry'|'market'|'new'|'change-counterparty'|'change-preentry-datetime'|string;

/**
 * Вернет нужное хранилище с данными заказа, в зависимости
 * от его типа (визит/заказ в магазине/предварительная запись)
 *
 * По умолчанию: данные визита.
 *
 * @param visitStore
 * @param visitType
 * @returns
 */
function getVisitState(visitStore: VisitStore, visitType: VisitStateType): UnwrapRef<NewVisitSatate> {
  switch (visitType) {
    case 'change-preentry-datetime':
    case 'preentry':
      return visitStore.preentryVisitState;

    case 'market':
      return visitStore.newStoreVisitState;

    case 'change-counterparty':
    case 'new':
        return visitStore.newVisitState;
  }

  return visitStore.newVisitState;
}

export interface UseCreateVisitStepOptions {
  stateProvideKey?: string|false;
}

/**
 * Вспомогательные данные, которые пригодятся, в шагах оформления заказа
 * (визита/заказа в магазине/предварительной записи).
 */
export function useCreateVisitStep(options: UseCreateVisitStepOptions = {}) {
  const route = useRoute();
  const store = useStore();

  const visitType = (route.meta.visit || 'unknown') as string;

  /**
   * NOTE: На данном этапе в нем нет необходимости (visitId), он всегда будет = 0.
   * Заложено на будущее, когда будут реализованы черновики заказов.
   */
  const visitId = route.params.visitId as string;

  const isPreentry = visitType === 'preentry';
  const isMarket = visitType === 'market';
  const isVisit = visitType === 'new';

  /** Обновление контрагента */
  const isUpdateVisitCounterparty = visitType === 'change-counterparty';

  /** Смена времени предзаписи */
  const isChangePreentryDatetime = visitType === 'change-preentry-datetime';

  const visitState = getVisitState(store.visit, visitType);
  const carId = computed(() => visitState.body.car?.id)

  if (options.stateProvideKey) {
    provide(options.stateProvideKey, visitState);
  }

  function clearVisitState() {
    clearNewVisitState(visitState);
  }

  function generateBodyVisit(): Promise<CarVisitBodyRequest> {
    switch (visitType) {
      case 'change-preentry-datetime':
      case 'preentry':
          return store.visit.generateBodyPreentryVisit();

      case 'market':
        return store.visit.generateBodyNewStoreVisit();

      case 'change-counterparty':
      case 'new':
        return store.visit.generateBodyNewVisit();
    }

    return store.visit.generateBodyNewVisit();
  }

  function prefixNamePage(pageName: string, def: string = '') {
    switch (visitType) {
      case 'preentry': return `preentry-${pageName}`;
      case 'change-counterparty': return `change-counterparty-${pageName}`;
      case 'new': return `visit-new-${pageName}`;
      // case 'market': return '';
    }

    return def;
  }

  return {
    visitType,
    visitId,
    isPreentry,
    isMarket,
    isVisit,
    visitState,
    carId,
    clearVisitState,
    generateBodyVisit,
    isUpdateVisitCounterparty,
    isChangePreentryDatetime,
    prefixNamePage,
  };
}
//#endregion Create visit steps helpers

//#region Counterparty
export interface UseVisitCounterpartyOptions {
  visit: Ref<CarVisitResponse|null>;
}

/**
 * Логика, для смены контрагента
 *
 * @param options
 * @returns
 */
export function useVisitCounterparty(options: UseVisitCounterpartyOptions) {
  const store = useStore();
  const router = useRouter();
  const toast = useToast();
  const { visit } = options;

  /**
   * Выбор происходит после выбора пункта в списке
   * @protected
   */
  function selectGroupValue(groupId: string|number /*, group?: CarGroup */ ) {
    if (!visit.value || !visit.value.car) return;

    // Идентификатор не изменился => менять ничего не надо
    if (visit.value.group?.id == groupId) {
      toast.success('Данный контрагент уже выбран');
      return;
    }

    // TODO: Не до конца нравится, как это выглядит, стоит подумать, как это улучшить
    store.visit.clearNewVisitState();
    const state = mergeCarVisitResponseToNewVisitState(store.visit.newVisitState, visit.value);

    // NOTE: groupId может вернуться, как пустое значение - '', это означает, что был выбран пункт "Без контрагента" - в этом случае
    // установить обязательно null (если установить undefined, то контрагент не обновится!).
    state.body.group = groupId ? { id: toNumber(groupId) } : null;

    // NOTE: У контрагента может полностью измениться прайс, старые выбранные услуги не подходят
    state.body.providedServices = [];

    router.push({
      name: 'change-counterparty-service-type',
      params: { visitId: visit.value.id },
    });
  }

  async function openSelectGroupModal() {
    if (!visit.value) return;

    const modal = await modalController.create({
      component: GroupPartnerSelectBox,
      cssClass: 'core-modal-actions',
      swipeToClose: true,
      componentProps: {
        selectValue: selectGroupValue,
        value: visit.value.group?.id,
      },
    });

    await modal.present();
    return modal;
  }

  function startChangeCounterparty() {
    openSelectGroupModal();
  }

  return {
    startChangeCounterparty,
  };
}
//#endregion Counterparty

//#region Preentry
// export interface UseVisitPreentryChangeDatetimeOptions {
//   // ...
// }

export function useVisitPreentryChangeDatetime(/*options: UseVisitPreentryChangeDatetimeOptions*/) {
  const store = useStore();
  const router = useRouter();

  function preparePreentryVisitState(visit: CarVisitResponse) {
    store.visit.clearPreentryVisitState();
    mergeCarVisitResponseToNewVisitState(store.visit.preentryVisitState, visit);
  }

  /**
   * Перейти на страницу редактирования
   */
  function toChangePreentryDatetime() {
    router.push({
      name: 'change-preentry-datetime',
      params: { visitId: '0' },
    });
  }

  async function updatePreentryDatetime() {
    const body = store.visit.preentryVisitState.body;

    if (!body.id) {
      throw new Error('Не передан идентификатор визита, для обновления');
    }

    return await store.visit.update(body.id, {
      creationDate: body.creationDate,
      box: body.box,
    });
  }

  return {
    preparePreentryVisitState,
    updatePreentryDatetime,
    toChangePreentryDatetime
  };
}
//#endregion Preentry
export interface ModalServicesContext {
  visitState: NewVisitSatate;
  providedServicesGroupsValues: Ref<ProvideServicesBodyItemsGroup>;

  /**
   * При смене категории данные временно перенесятся сюда.
   */
  providedServicesGroupsReplaced: Ref<ProvideServicesBodyItemsGroup>;

}
