<template>
  <div class="c-input-counter">
    <button
      class="c-input-counter__button"
      type="button"
      @click="decrement"
      :disabled="downDisabled"
    >
      <slot name="decrement-btn">-</slot>
    </button>

    <span class="c-input-counter__count">
      <slot name="count" v-bind="{ intValue }">
        {{ intValue }}
      </slot>
    </span>

    <button
      class="c-input-counter__button"
      type="button"
      @click="increment"
      :disabled="upDisabled"
    >
      <slot name="increment-btn">+</slot>
    </button>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from 'vue'
export default defineComponent({
  emits: [
    'update:modelValue',
    'max-limit', 'min-limit',
    'increment',  'decrement'
  ],

  props: {
    modelValue: {
      type: [Number, String] as PropType<number|string>,
      default: 0,
    },
    step: {
      type: Number as PropType<number>,
      default: 1,
    },
    min: {
      type: Number as PropType<number>,
      default: Number.MIN_VALUE,
    },
    max: {
      type: Number as PropType<number>,
      default: Number.MAX_VALUE,
    },
    isInput: {
      type: Boolean as PropType<boolean>,
      default: false,
    },
  },

  setup(props, { emit }) {
    const intValue = ref(+props.modelValue);
    watch(() => props.modelValue, newVal => {
      if (newVal > props.max) {
        intValue.value = props.max;
        emit("max-limit", newVal);
      } else if (newVal < props.min) {
        intValue.value = props.min;
        emit("min-limit", newVal);
      } else {
        intValue.value = +newVal;
      }
    });

    const upDisabled = computed(() => (intValue.value >= props.max));
    const downDisabled = computed(() => (intValue.value <= props.min));

    function increment() {
      if (intValue.value + props.step > props.max) {
        emit("max-limit", intValue.value + props.step);
      } else {
        intValue.value += props.step;
        emit("increment", intValue.value);
        emit("update:modelValue", intValue.value);
      }
    }

    function decrement() {
      if (intValue.value - props.step < props.min) {
        emit("min-limit", intValue.value - props.step);
      } else {
        intValue.value -= props.step;
        emit("decrement", intValue.value);
        emit("update:modelValue", intValue.value);
      }
    }

    return {
      intValue,
      upDisabled,
      downDisabled,
      increment,
      decrement,
    };
  },
});
</script>

<style lang="scss">
.c-input-counter {
  font-weight: bold;
  display: flex;
  align-items: stretch;

  &__button {
    border: none;
    background: transparent;
    font-weight: inherit;
    padding: 1px 9px 0px;
    outline: none !important;
    border-radius: 4px;
    color: inherit;

    transition: background-color 0.5s ease,
      color 0.2s ease;

    &:active {
      background-color: var(--oh-bg-primary);
      color: var(--ion-color-primary);
    }

    &:disabled,
    &[disabled] {
      opacity: 0.4;
    }
  }

  &__count {
    min-width: 1.8rem;
    font-weight: normal;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: var(--ion-text-color);
  }
}
</style>
