<script lang="ts" setup>
import { PropType, ref, watch, toRaw, toRef, defineProps, defineEmits, provide, computed } from 'vue';
import { useStore } from '@/composables/useApp';
import { ProvideServiceBodyItem } from '@/repositories/Models/CarVisit';
import { isEmpty } from 'lodash';
import CollapseTransition from '@/components/core/CollapseTransition.vue';
import { useServicesCategoriesContext } from '@/composables/useServicesCategoriesContext';
import { ServiceByContextQuery } from '@/repositories/Models/Service';
import { ProvideServiceItemPart } from '@/composables/provideServices';
import ServicesProvideByCategoryItem from './ServicesProvideByCategoryItem.vue';

const props = defineProps({
  provided: {
    type: null,
  },
  replaced: {
    type: Array as PropType<ProvideServiceBodyItem[]>,
    required: false
  },
  context: {
    type: Object as PropType<ServiceByContextQuery>,
    required: true,
  },
  searchWords: {
    type: String,
    required: false,
  },
  showTitle: {
    type: Boolean,
    default: true
  },
  shortTitle: {
    type: Boolean,
    default: false,
  },
  isCollapsedContent: {
    type: Boolean,
    default: false,
  },
  showContent: {
    type: Boolean,
    default: true,
  },
  loadingServices: {
    type: Boolean,
    default: false,
  }
});

const emit = defineEmits<{
  (e: 'update:showContent', value: boolean): void;
  (e: 'update:provided', value: ProvideServiceItemPart[]): void;
  (e: 'toggle-services-group', value: ProvideServiceItemPart[]): void;
  (e: 'update:loadingServices', value: boolean): void;
}>();

// ['update:showContent', 'update:provided', 'toggle-services-group']

const store = useStore();

//#region Load, search and view list
const titleGroup = store.point.getCategoryBreadcrumbsComputed(props.context.categoryId, props.shortTitle);
const searchWords = toRef(props, 'searchWords');

const {
  servicesCategoriesFiltered,
  loadServicesCategoriesByContext,
  loading: loadingSerives,
} = useServicesCategoriesContext(searchWords);

watch(loadingSerives, loading => {
  emit('update:loadingServices', loading);
}, { immediate: true });

const loadAction = () => loadServicesCategoriesByContext({
  categoryId: props.context.categoryId,
  groupId: props.context.groupId || 0,
  carId: props.context.discountAccountId,
});

const showContentItems = ref(props.isCollapsedContent ? props.showContent : true);
const isSearchStarted = computed<boolean>(() => !!searchWords.value?.trim());

watch(() => props.showContent, val => showContentItems.value = !!val);
watch(() => props.isCollapsedContent, () => {
  if (props.isCollapsedContent === false && showContentItems.value === false) {
    showContentItems.value = true;
    emit('update:showContent', showContentItems.value);
  }
});

function toggleContentItems() {
  if (!props.isCollapsedContent) return;

  showContentItems.value = !showContentItems.value;
  emit('update:showContent', showContentItems.value);
}
//#endregion Load, search and view list

//#region Value
// Ключем является идентификатор услуги
const servicesSelected = ref<Record<string, ProvideServiceItemPart|undefined>>({});
provide('servicesProvide/servicesSelected', servicesSelected);

// Данное значение используется для блокировки циклического обновления
let lastProvidedServices: ProvideServiceBodyItem[] = [];

function mergeProvided() {
  if (isEmpty(props.provided) || !Array.isArray(props.provided)) {
    if (!isEmpty(servicesSelected.value)) {
      servicesSelected.value = {};
    }
    
    return;
  }

  if (lastProvidedServices === toRaw(props.provided)) {
    return; // stop recursion
  }

  let servicesMap: Record<string, ProvideServiceItemPart> = {};
  for (let provideService of props.provided as ProvideServiceItemPart[]) {
    servicesMap[`${provideService?.service?.id}`] = provideService;
  }

  servicesSelected.value = servicesMap;
}

function emitProvided() {
  const servicesMap = servicesSelected.value;
  let services: ProvideServiceBodyItem[] = [];

  for (let serviceId in servicesMap) {
    if (!servicesMap[serviceId]) continue;

    const provideService: ProvideServiceBodyItem = {
      doers: [], // Будут добавлены на след шаге

      ...servicesMap[serviceId] as ProvideServiceItemPart,
      
      carCategory: {
        id: props.context.categoryId,
      },
      pointType: {
        id: props.context.typeId
      },
    };

    services.push(provideService);
  }

  lastProvidedServices = services;
  emit('update:provided', services);
}

watch(() => props.provided, mergeProvided, { deep: true, immediate: true });
watch(servicesSelected, emitProvided, { deep: true });
//#endregion Value

// TODO: В будующих доработках, нужно будет реализовать подсвечивание ранее выбранных элементов
// watch(() => props.replaced, replaced => console.log(replaced));
</script>

<template>
  <div
    :data-cat-id="props.context.categoryId"
    :class="{
      'c-services-category-group': true,
      'c-services-category-group--content-collapsed': !showContentItems
    }"
  >
    <div class="c-services-category-group__title" v-if="showTitle" @click="toggleContentItems">
      <span v-html="titleGroup"></span>

      <span class="c-services-category-group__collapse-arrow-wrap" v-if="isCollapsedContent">
        <ion-icon name="core-collapse-arrow" />
      </span>
    </div>

    <collapse-transition v-show="showContentItems">
      <div class="c-services-category-group__services-container">
        <c-content-loading :action="loadAction">
          <services-provide-by-category-item
            v-for="servicesCategory in servicesCategoriesFiltered"
            :key="servicesCategory.id"
            :category="servicesCategory"
            :is-search-started="isSearchStarted"
            @update:show="$emit('toggle-services-group', servicesCategory, $event)"
          ></services-provide-by-category-item>
        </c-content-loading>
      </div>
    </collapse-transition>
  </div>
</template>

<style lang="scss">
@import '@/scss/abstract';

.c-services-category-group {
  &__title {
    @extend %card-head-title;
    border-bottom: $border-divider;
    position: relative;
    user-select: none;
    cursor: pointer;
  }

  &__collapse-arrow-wrap {
    position: absolute;
    display: flex;
    align-items: center;
    top: 0;
    right: 0;
    bottom: 0;
    font-size: 13px;
    
    ion-icon {
      transition: transform 0.25s ease;
      transform: rotate(180deg);
    }
  }

  &--content-collapsed &__title {
    border-bottom: none;
  }

  &--content-collapsed &__collapse-arrow-wrap {
    ion-icon {
      transform: rotate(0deg);
    }
  }
}
</style>