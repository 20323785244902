<template>
  <ion-page>
    <app-header-new-visit
      :title="$t('views.visit_new_photo.title')"
      :progress="0.001"
    />

    <ion-content :class="{
      'camera-transparent': isInited
    }">
      <div :class="{
        'camera-resize-wait': true,
        'camera-resize-wait--show': !isInited
      }" />

      <div
        ref="containerSizeRef"
        class="camera-preview-container"
      >
        <!-- Пока больше нет необходимости -->
      </div>

      <!-- eslint-disable-next-line -->
      <div class="camera-preview-controls-wrap" slot="fixed">

        <!-- В новой версии решили сделать без рамки -->
        <!-- TODO: Удалить, если больше не пригодиться -->
        <!-- <div class="camera-preview-backgroup">
          <div class="camera-preview-backgroup__comment">
            Наведите прямоугольник на номер <br>
            автомобиля
          </div>

          <div class="camera-preview-backgroup__number-rect"></div>
        </div> -->

        <div class="camera-preview-actions">
          <div class="camera-preview-actions__left"></div>

          <div class="camera-preview-actions__center">
            <ion-button class="camera-preview-skip-btn" expand="block" @click="skipAction">
              {{ skipBtnText }}
            </ion-button>
          </div>

          <div class="camera-preview-actions__right">
            <ion-button
              v-if="useNumberRecognition"
              shape="round"
              color="primary"
              @click="recognizePhotoAction"
            >
              {{ $t('views.visit_new_photo.btn_number_recognition') }}
            </ion-button>

            <ion-button
              v-else
              shape="round"
              color="primary"
              @click="createPhotoAction"
            >
              {{ $t('views.visit_new_photo.btn_create_photo') }}
            </ion-button>
          </div>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
import { defineComponent, ref, computed, watch } from 'vue';
import AppHeaderNewVisit from '@/components/layout/AppHeaderNewVisit.vue';
import { useCameraPreview } from '@/composables/cameraPreview';
import { useToast } from '@/composables/toast';
import { LocationQueryRaw, useRouter } from 'vue-router';
import { base64ToBlob, compressImage, getExtensionFromMimiType } from '@/helpers/image';
import { useApp } from '@/composables/useApp';
import { awaitLoading } from '@/ext/loading';
import { get } from 'lodash';
import { useMetrica } from '@/composables/useMetrica';
import { useI18n } from 'vue-i18n';
import { onIonViewDidEnter, onIonViewDidLeave, isPlatform } from '@ionic/vue';
import { windowSize } from '@/helpers/adaptive';
import { useVisitImages } from '@/composables/visit/visitImages';

export default defineComponent({
  components: {
    AppHeaderNewVisit,
  },

  setup() {
    const visitId = '0'; // NOTE: Т.к. черновиков пока нет, такой идентификатор допустим, при создании

    const toast = useToast();
    const router = useRouter();
    const { repositories, store } = useApp();
    const { addImage, removeImage, galleryItems } = useVisitImages({ visitId });
    const { emitEvent } = useMetrica();
    const { t } = useI18n();

    const useNumberRecognition = store.config.useNumberRecognition;
    const containerSizeRef = ref<HTMLElement|null>(null);

    const {
      startPreview,
      stopPreview,
      captureOptimizeSize,
      isInited,
    } = useCameraPreview({
      autostart: false,
      autostop: true,
      parentId: 'app',
      className: 'c-visit-new-preview-video',
    });

    onIonViewDidEnter(startPreview);
    onIonViewDidLeave(stopPreview);

    if (isPlatform('capacitor') && isPlatform('android')) {
      watch(windowSize, async () => {
        if (false === isInited.value) return;

        await stopPreview();
        await startPreview();
      });
    }

    async function createAndSavePhoto() {
      if (false === isInited.value) {
        toast.error(t('views.visit_new_photo.error_camera_no_init'));
        return null;
      }

      const imageBase64 = await captureOptimizeSize();

      // NOTE: Если у нас уже существует одна фотография, скореевсего пользователь вернулся на шаг
      // назад из-за неудачного распознования номера и ему нужно обновить (заменить) текущее фото.
      if (galleryItems.value.length === 1) {
        await removeImage(galleryItems.value[0].id);
      }

      await addImage(imageBase64);

      return imageBase64;
    }

    function nextStep(query: LocationQueryRaw = {}) {
      router.push({
        name: 'visit-new-car-number',
        query,
      });
    }

    async function recognizePhotoAction() {
      emitEvent('visit/new/photo/recognize');

      const imageBase64 = await createAndSavePhoto();

      try {
        if (!imageBase64) {
          throw new Error(t('views.visit_new_photo.error_create_photo'));
        }

        let imageBlob = base64ToBlob(imageBase64);
        if (!imageBlob) {
          throw new Error('Failed to convert image, contact your administrator');
        }

        imageBlob = await compressImage(imageBlob, {
          width: 1080,
          height: 1080,
          resize: 'cover',
          quality: 0.7
        });

        const extensionFile = getExtensionFromMimiType(imageBlob.type);
        const formData = new FormData();
        formData.append('file', imageBlob, `photo.${extensionFile}`);

        await awaitLoading(async () => {
          const res = await repositories.car.recognizeNumber(formData);
          const carNumber = get(res.data, 'number.0') as string|undefined;

          if (carNumber) {
            emitEvent('car/number/recognize/success');
            nextStep({ number: carNumber });
          } else {
            emitEvent('car/number/recognize/error');
            toast.warning(t('views.visit_new_photo.error_recognize_photo'));
            nextStep();
          }
        }, {
          message: t('views.visit_new_photo.wait_recognize_number'),
          showToastError: false,
        });
      } catch (e) {
        toast.error(e);
      }
    }

    async function skipAction() {
      if (useNumberRecognition.value) {
        emitEvent('visit/new/photo/recognize/skip');
        await createAndSavePhoto();
      } else {
        emitEvent('visit/new/photo/skip');
      }

      // Даже если при создании фото возникла ошибка, продолжим оформление
      nextStep();
    }

    /** @click Сделать фото автомобиля */
    async function createPhotoAction() {
      emitEvent('visit/new/photo/create');
      await createAndSavePhoto();
      nextStep();
    }

    const skipBtnText = computed(() => {
      return (useNumberRecognition.value)
        ? t('views.visit_new_photo.btn_skip_no_recognition')
        : t('views.visit_new_photo.btn_skip_no_photo');
    });

    return {
      containerSizeRef,
      recognizePhotoAction,
      skipAction,
      useNumberRecognition,
      createPhotoAction,
      skipBtnText,
      isInited,
    };
  },
});
</script>

<style lang="scss">
</style>