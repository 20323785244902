import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, KeepAlive as _KeepAlive, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_visits_archive_search = _resolveComponent("visits-archive-search")!
  const _component_visits_archive_pane = _resolveComponent("visits-archive-pane")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_openBlock(), _createBlock(_KeepAlive, null, [
      (_ctx.isSearchWords)
        ? (_openBlock(), _createBlock(_component_visits_archive_search, {
            key: 0,
            search: _ctx.search
          }, null, 8, ["search"]))
        : (_openBlock(), _createBlock(_component_visits_archive_pane, { key: 1 }))
    ], 1024))
  ]))
}