<template>
  <c-modal-view-select
    :set-value="setValueCallback"
    :multiselect="false"
    :autoclose="true"
    :options="options"
    :modal-title="$t('components.payment_type_modal.modal_title')"
  >
    <template #select-after v-if="hasPoints">
      <div class="c-modal-select-option payment-type-points-switch-option">
        <div class="c-modal-select-option__label" @click="targetSwitch($refs.pointsSwitch)">
          {{ pointsLabel }}
        </div>

        <div class="c-modal-select-option__icon-col">
          <ion-toggle class="core-toggle" v-model="usePoints" ref="pointsSwitch" />
        </div>
      </div>
    </template>
  </c-modal-view-select>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref } from 'vue';
import CModalViewSelect from '@/components/core/CModalViewSelect.vue';
import { PaymentTypeEnum } from '@/repositories/Models/Pos';
import { getPaymentTypesOptions } from '@/helpers/visit';
import { modalController } from '@ionic/core';
import { useI18n } from 'vue-i18n';

export type PaymentTypeModalSetValueCallback = (paymentType: PaymentTypeEnum, usePoints: boolean) => void;

const PaymentTypeModal = defineComponent({
  inheritAttrs: false,
  components: { CModalViewSelect },

  props: {
    setValue: {
      type: Function as PropType<PaymentTypeModalSetValueCallback>,
      required: true,
    },
    hasPoints: {
      type: Boolean,
      default: false
    },
    points: {
      type: Number,
      required: false
    },
    hasGroup: {
      type: Boolean,
      default: true,
    },
  },

  setup(props) {
    const usePoints = ref(false);
    const { t } = useI18n();

    function setValueCallback(paymentType: PaymentTypeEnum) {
      return props.setValue.call(null, paymentType, usePoints.value);
    }

    const options = computed(() => getPaymentTypesOptions(props.hasGroup));
    const pointsLabel = computed(() => {
      return t(
        'components.payment_type_modal.switch_use_points_label',
        { points: props.points },
        { plural: props.points }
      );
    });

    function targetSwitch(switchEl: any) {
      (switchEl?.$el as HTMLElement)?.dispatchEvent(new CustomEvent('click'));
    }

    return {
      setValueCallback,
      usePoints,
      options,
      pointsLabel,
      targetSwitch,
    };
  },
});

export default PaymentTypeModal;

export interface PaymentTypeModalProps {
  setValue: PaymentTypeModalSetValueCallback;
  hasPoints?: boolean;
  points?: number;
  hasGroup?: boolean;
}

/** @deprecated В новой версии ionic не рабтает (почему - неизвестно) */
export async function openPaymentTypeModal(props: PaymentTypeModalProps) {
  const modal = await modalController.create({
    cssClass: 'core-modal-actions',
    component: PaymentTypeModal,
    componentProps: { ...props },
    swipeToClose: true,
  });

  modal.present(); // async
  return modal;
}
</script>

<style lang="scss">
.payment-type-points-switch-option {
  .c-modal-select-option {
    &__icon-col {
      padding-top: 10px;
    }
  }

  &:hover {
    background-color: transparent !important;
  }

  &:active {
    background-color: transparent !important;
  }
}
</style>