import { onBeforeMount, ref } from 'vue';
import { modalController } from '@ionic/vue';
import NotificationsModal from '@/views/services/NotificationsModal.vue';
import { useStore } from '@/composables/useApp';
import { usePush } from '@/composables/push';

export interface NotificationData {
  id: string;
  title?: string;
  subtitle?: string;
  body?: string;
  image?: string; // url or base64 image
  badge?: number;
  data?: any;
  meta: {
    [key: string]: any;
  };
  createAt: Date;
}

/**
 * Делаем глобальной, чтобы не пересчитывать при кадом вызове
 */
export const unreadCount = ref({
  count: 0,
  actual: false,
});

/**
 * Информация о кол-ве непрочитанных
 * 
 * NOTE: немного корявый способ, когда история уведомлений будет серверной, все переделается
 * @returns 
 */
export function useNotificationUnreadCount() {
  const store = useStore();

  const { notificationHistoryTable } = store.notification;

  async function actualizeUnread() {
    let count = 0;

    await notificationHistoryTable.each(notification => {
      if (notification.meta.read !== true) {
        ++count;
      }
    });

    unreadCount.value.count = count;
    unreadCount.value.actual = true;
  }

  async function checkAndActualizeUnread() {
    if (unreadCount.value.actual !== true) {
      await actualizeUnread();
    }
  }

  /**
   * При инициализации, автоматически проверяем и актуализируем кол-во
   */
  onBeforeMount(checkAndActualizeUnread);

  return {
    unreadCount,
    actualizeUnread,
    checkAndActualizeUnread,
  };
}

/**
 * Взаимодействие с модальным окном уведомления
 * @returns 
 */
export function useNotificationsModal() {
  async function openModal() {
    const modal = await modalController.create({
      component: NotificationsModal as any, // NOTE: `as any` - fix ionic bug 5.x
    });

    await modal.present();
  }

  return {
    openModal,
  };
}

export function useNotifications() {
  const store = useStore();
  const push = usePush();

  const { notificationHistoryTable } = store.notification;

  const notifications = ref<NotificationData[]>([]);
  async function loadNotifications() {
    notifications.value = await store.notification.getAll();
  }

  /**
   * Отметить все уведомления как прочитанные
   */
  function markAllAsRead() {
    for (let notification of notifications.value) {
      if (notification.meta.read === true) continue;

      notification.meta.read = true;
      notificationHistoryTable.update(notification.id, {
        'meta.read': true,
      }); // async
    }

    unreadCount.value.count = 0;
    unreadCount.value.actual = true;

    push.removeAllDelivered(); // async
  }

  return {
    loadNotifications,
    markAllAsRead,
    notifications,
  };
}