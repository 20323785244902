<template>
  <c-infinite-loading
    :infinite="loadVisits"
    :per-page="40"
    v-slot="{ items }"
  >
    <visits-archive-groups-by-date :visits="items" />
  </c-infinite-loading>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { useStore } from '@/composables/useApp'
import { BaseCollectionResponse, BaseQueryPage } from '@/repositories/Models/Base'
import VisitsArchiveGroupsByDate from '@/components/visit/VisitsArchiveGroupsByDate.vue'

export default defineComponent({
  components: {
    VisitsArchiveGroupsByDate,
  },

  setup() {
    const store = useStore();

    async function loadVisits(params: BaseQueryPage): Promise<BaseCollectionResponse> {
      const { data } = await store.visit.getCarVisitCollection({
        ...params,
        pane: 'archive',
      });

      return data;
    }

    return {
      loadVisits,
    };
  }
});
</script>

<style lang="scss">
</style>