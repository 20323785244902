<script lang="ts" setup>
import { defineProps } from 'vue';
import { useRouter, RouteLocationRaw } from 'vue-router';
import { useMetrica } from '@/composables/useMetrica';

const router = useRouter();
const { emitEvent } = useMetrica();

const props =  defineProps<{
  visitId: string;
  metricaEventName?: string;
  routerLink?: RouteLocationRaw;
}>();

function addVisitPhotoVisit() {
  if (props.metricaEventName) {
    emitEvent(props.metricaEventName, {
      visitId: props.visitId,
    });
  }

  const to: RouteLocationRaw = props.routerLink || {
    name: 'visit-create-photo',
    params: { visitId: props.visitId }
  };

  router.push(to);
}
</script>

<template>
  <ion-button color="light" @click.stop="addVisitPhotoVisit">
    <ion-icon name="core-plus-small" :style="{ fontSize: '11px' }"></ion-icon>
    <ion-icon name="core-camera"></ion-icon>
  </ion-button>
</template>
