<template>
  <div class="c-modal-select">
    <slot />
  </div>
</template>

<style lang="scss">
.c-modal-select {
  position: relative;
  padding: var(--ion-padding) 0;
}
</style>