<script lang="ts" setup>
import { defineProps, PropType, computed } from 'vue';

const props = defineProps({
  value: {
    type: [String, Number] as PropType<string|number>,
    default: 0,
  },
  color: {
    type: String,
    required: false
  },
  small: {
    type: Boolean,
    default: false
  },
});

const progressNumber = computed<number>(() => Number(props.value));
const progressWidth = computed<number>(() => Math.max(0, Math.min(1, progressNumber.value)) * 100);
</script>

<template>
  <div :class="{
    'c-progress-bar': true,
    'c-progress-bar--small': small,
    [`ion-color-${color}`]: !!color
  }">
    <div class="c-progress-bar__progress"
      :style="{ width: `${progressWidth}%` }"
    ></div>
  </div>
</template>

<style lang="scss">
.c-progress-bar {
  --border-radius: 8px;
  --height: 16px;
  --background-track: #20212A;
  --background-progress: var(--ion-color-base, var(--ion-color-secondary));

  height: var(--height);
  border-radius: var(--border-radius);
  background: var(--background-track);
  overflow: hidden;

  &__progress {
    background: var(--background-progress);
    height: 100%;
    width: 0;
  }

  &--small {
    --height: 8px;
  }
}
</style>