import { renderSlot as _renderSlot, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = {
  type: "button",
  tabindex: "-1",
  class: "c-modal-select-option"
}
const _hoisted_2 = { class: "c-modal-select-option__label" }
const _hoisted_3 = { class: "c-modal-select-option__icon-col" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_icon = _resolveComponent("ion-icon")!

  return (_openBlock(), _createElementBlock("button", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _renderSlot(_ctx.$slots, "default")
    ]),
    _createElementVNode("div", _hoisted_3, [
      _renderSlot(_ctx.$slots, "icon", {}, () => [
        (_ctx.selected)
          ? (_openBlock(), _createBlock(_component_ion_icon, {
              key: 0,
              name: "checkmark-outline"
            }))
          : _createCommentVNode("", true)
      ])
    ])
  ]))
}