import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_visits_archive_groups_by_date = _resolveComponent("visits-archive-groups-by-date")!
  const _component_c_infinite_loading = _resolveComponent("c-infinite-loading")!

  return (_openBlock(), _createBlock(_component_c_infinite_loading, {
    infinite: _ctx.loadVisits,
    "per-page": 40
  }, {
    default: _withCtx(({ items }) => [
      _createVNode(_component_visits_archive_groups_by_date, { visits: items }, null, 8, ["visits"])
    ]),
    _: 1
  }, 8, ["infinite"]))
}